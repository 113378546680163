<template>
  <div class="welcome_page">
    <!-- <div class="row">
      <div class="col-12">
        <h2 class="text-center">WELCOME</h2>
      </div>
    </div> -->
    <div class="row">
      <div class="col-8 offset-2 col-md-6 offset-md-3 text-center;">
        <img src="@/assets/logo.svg" class="img-responsive welcome_logo" />
      </div>
    </div>
    <div class="row">
      <div class="col-8 offset-2 col-md-4 offset-md-4 loginbox text-center">
        ENTER YOUR TASTING CODE<br />
        <form @submit.prevent="skipLogin" class="mt-1 pt-1">
          <input
            type="text"
            id="inputCode"
            class="form-control"
            name="bypass_code"
            placeholder="CODE"
            v-model="bypassCode"
            required
            autofocus
          />
          <input type="submit" class="btn btn-primary" name="code_submitted" value="LETS START" />
        </form>

        <div v-if="loggedIn">
          <hr />
          You are currently logged in as:<br />
          {{ get_user_email }}<br />
          <router-link class="mt-2" style="display: block" :to="{ name: 'Logout' }">LOG OUT &gt;</router-link>
        </div>
        <div v-else>
          <hr />
          LOG IN WITH YOUR SKURNIK.COM<br />
          TRADE ACCOUNT<br />
          <router-link class="btn btn-primary mt-2" :to="{ name: 'Login' }">LOG IN &gt;</router-link>
          <br />
          <router-link class="btn btn-secondary mt-2" :to="{ name: 'Register' }">Register &gt;</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'Welcome',
  data() {
    return {
      bypassCode: '',
      currentTastingDeets: {},
    };
  },
  components: {},
  methods: {
    ...mapActions(['toggleModal', 'setCurrentTasting', 'saveAllData']),
    skipLogin() {
      this.$store.dispatch('fetchTastings', {}).then((response) => {
        // Initialize currentTastingDeets as null
        this.currentTastingDeets = null;

        for (const idx in response) {
          if (response[idx].tasting_id == this.bypassCode.toUpperCase()) {
            this.currentTastingDeets = response[idx];
            break; // Break once a match is found
          } else if (response[idx].code.toUpperCase() == this.bypassCode.toUpperCase()) {
            this.currentTastingDeets = response[idx];
            break; // Break once a match is found
          }
        }

        // Check if currentTastingDeets is not null and has tasting_id
        if (this.currentTastingDeets && 'tasting_id' in this.currentTastingDeets && this.currentTastingDeets.tasting_id >= 1) {
          this.setCurrentTasting(this.currentTastingDeets);
          window.localStorage.setItem('tasting_id', this.currentTastingDeets.tasting_id);
          this.$router.push({ name: 'Cover' });
        } else {
          alert('Looks like you entered an invalid Tasting Code, please try again.');
        }
      });
    },
  },

  computed: {
    ...mapGetters(['user', 'currentTasting', 'currentTastingDeets', 'loggedIn']),
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
    get_user_email() {
      // /get email address from localStorage user object
      var user = JSON.parse(localStorage.getItem('user'));
      if (user) {
        return user.email;
      }
      return '';
    },
  },
  created() {
    // save a local storage value with todays date, so if it's a new day, we can clear the local storage

    const today = new Date();
    const today_date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

    const last_tasting_viewed = localStorage.getItem('last_tasting_viewed');
    if (last_tasting_viewed != today_date) {
      localStorage.setItem('tasting_id', '');
      localStorage.setItem('last_tasting_viewed', today_date);
    }

    const show_tasting = localStorage.getItem('tasting_id');
    if (show_tasting) {
      this.$router.push({ name: 'Browse' });
    }
  },
};
</script>
