<template></template>

<script>
// @ is an alias to /src
import { useRoute } from 'vue-router';
import { computed } from 'vue';

import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'QR',
  components: {},
  props: ['tasting_id'],
  data() {
    return {
      route: useRoute(),
    };
  },
  methods: {
    ...mapActions(['toggleModal', 'setCurrentTasting']),
  },

  created() {
    this.$store.dispatch('fetchTastings', {}).then((response) => {
      //console.log(response);
      for (const idx in response) {
        if (response[idx].tasting_id == this.tasting_id) {
          this.currentTastingDeets = response[idx];
        }
      }
      //console.log(this.currentTastingDeets.length);
      if (this.currentTastingDeets && 'tasting_id' in this.currentTastingDeets && this.currentTastingDeets.tasting_id >= 1) {
        //console.log(this.currentTastingDeets);
        //this.$store.dispatch('fetchTastings', {})
        this.setCurrentTasting(this.currentTastingDeets);
        localStorage.setItem('tasting_id', this.tasting_id);
        localStorage.setItem('skip_tasting_select', this.tasting_id);
        this.$router.push({ name: 'Cover' });
      } else {
        alert('Looks like you scanned an invalid QR Code, please try again.');
        this.$router.push({ name: 'Welcome' });
      }
    });
  },
};
</script>
