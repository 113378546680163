<template>
  <footer class="footer">
    <div class="container">
      <div class="d-flex flex-row justify-content-between text-center">
        <router-link
          id="tourMap"
          v-if="showMapAndItin == true && showInteractiveMap == false"
          to="/map"
          class="footer_click"
          v-slot="{ isActive, isExactActive }"
          ><div :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']">
            <img src="@/assets/n_map.svg" /><!--<br />MAP-->
          </div></router-link
        >
        <router-link
          id="tourMap"
          v-if="showMapAndItin == true && showInteractiveMap == true"
          to="/map-interactive"
          class="footer_click"
          v-slot="{ isActive, isExactActive }"
          ><div :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']">
            <img src="@/assets/n_map.svg" /><!--<br />MAP-->
          </div></router-link
        >
        <router-link to="/browse" class="footer_click" v-slot="{ isActive, isExactActive }"
          ><div :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']">
            <img src="@/assets/n_browse.svg" /><!--<br />BROWSE-->
          </div></router-link
        ><router-link to="/search-filter" class="footer_click" v-slot="{ isActive, isExactActive }"
          ><div :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']">
            <img src="@/assets/n_filter.svg" /></div
        ></router-link>
        <router-link v-if="loggedIn" to="/favorites" class="footer_click" v-slot="{ isActive, isExactActive }"
          ><div :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']">
            <img src="@/assets/n_favs.svg" /></div
        ></router-link>
        <router-link v-if="loggedIn" to="/account" v-slot="{ isActive, isExactActive }"
          ><div :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']">
            <img src="@/assets/user.svg" style="height: 28px; margin-top: 10px" /><!--<br />MY ACCOUNT-->
          </div></router-link
        >
        <router-link v-if="!loggedIn" to="/login" v-slot="{ isActive, isExactActive }"
          ><div :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']">
            <img src="@/assets/user.svg" id="tourLogin" style="height: 28px; margin-top: 10px" /><!--<br />MY ACCOUNT-->
          </div></router-link
        >
        <!--<router-link
          to="/logout"
          class="footer_click"
          v-slot="{ isActive, isExactActive }"
          style="position: absolute; right: 4px; bottom: 4px"
          ><div :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']">X</div></router-link
        >-->
        <!--<router-link v-if="loggedIn" to="/itinerary" v-slot="{ isActive, isExactActive }"
          ><div :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']">
            <img src="@/assets/n_itin.svg" /><br />ITINERARY
          </div></router-link
        >-->
        <!--   <router-link :to="{ name: 'NewSearch' }"
        ><img id="tourSearch" src="@/assets/sliders-h.svg" style="height: 23px; display: inline-block"
      /></router-link> -->
      </div>
    </div>
  </footer>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'NavFooter',
  components: {},
  data() {
    return {};
  },
  methods: {},
  computed: {
    ...mapGetters(['user', 'currentTasting', 'currentTastingDeets', 'loggedIn']),
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
    showModal() {
      return this.$store.getters.showModal;
    },
    showMapAndItin() {
      if (this.$store.getters.tastingSize == 'large' || this.$store.getters.tastingSize == 'medium') {
        return true;
      } else {
        return false;
      }
    },
    showInteractiveMap() {
      if (this.$store.getters.tastingSize == 'large') {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {},
};
</script>
