import { createRouter, createWebHistory } from 'vue-router';
import Browse from '../views/Browse.vue';
import Map from '../views/Map.vue';
import MapInteractive from '../views/Map_SVG.vue';
import Favorites from "../views/Favorites.vue";
import SkuDetail from '../views/SkuDetail.vue';
import TableDetail from '../views/TableDetail.vue';
import Login from '../views/Login.vue';
import Logout from '../views/Logout.vue';
import Register from '../views/Register.vue';
import FirstTasting from '../views/FirstTasting.vue';
import Welcome from '../views/Welcome.vue';
import Introduction from '../views/Introduction.vue';
import Cover from '../views/Cover.vue';
import SelectTasting from '../views/SelectTasting.vue';
import SelectState from '../views/SelectState.vue';
import EncourageLogin from '../views/EncourageLogin.vue';
import NewSearch from '../views/NewSearch.vue';
import Results from '../views/Results.vue';
import Account from '../views/Account.vue';
import AccessPass from '../views/AccessPass.vue';
import UseToken from '../views/UseToken.vue';
import StateSelection from '../views/StateSelection.vue';
import QR from '../views/QR.vue';
import store from '../store';
import Recover from '../views/Recover.vue';
import Lists from '../views/Lists.vue';
import TutorialOne from '../views/Tutorial-One-Step.vue';

const routes = [
  {
    path: '/browse',
    name: 'Browse',
    component: Browse,
    
  },
  {
    path: '/map',
    name: 'Map',
    component: Map,
  },
  {
    path: '/map-interactive',
    name: 'Map Interactive',
    component: MapInteractive,
  },
  {
    path: '/favorites',
    name: 'Favorites',
    component: Favorites,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/lists',
    name: 'My Lists',
    component: Lists,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/account',
    name: 'Account',
    component: Account,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/stateselection',
    name: 'State Selection',
    component: StateSelection,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/search-filter',
    name: 'NewSearch',
    component: NewSearch,
  },
  {
    path: '/select-tasting',
    name: 'Select a Tasting',
    component: SelectTasting,
  },
  {
    path: '/select-state',
    name: 'Select a State',
    component: SelectState,
  },
  {
    path: '/select-login',
    name: 'Encourage Login',
    component: EncourageLogin,
  },
  {
    path: '/recover',
    name: 'Recover',
    component: Recover,
  },
  {
    path: '/accesspass',
    name: 'Access Pass',
    component: AccessPass,
  },
  {
    path: '/usetoken/:token',
    name: 'Use Token',
    component: UseToken,
    props: true,
  },
  {
    path: '/first-tasting',
    name: 'First Tasting',
    component: FirstTasting,
  },
  {
    path: '/results',
    name: 'Results',
    component: Results,
  },
  {
    path: '/sku/:sku_id/:table_no',
    name: 'Sku',
    component: SkuDetail,
    
    props: (route) => ({
      sku_id: route.params.sku_id, // Assuming sku_id can stay as a string
      table_no: parseInt(route.params.table_no, 10) // Convert table_no to a number
    })
  },
  // {
  //   path: '/live/:sku_id',
  //   name: 'SkuDetailLive',
  //   component: () => import('@/views/SkuDetailLive.vue'),
  //   props: true,
  // },
  {
    path: '/table/:table_no',
    name: 'Table',
    component: TableDetail,
    props: true,
  },
  {
    path: '/qr/:tasting_id',
    name: 'QR',
    component: QR,
    props: true,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      requiresVisitor: true,
    },
  },
  {
    path: '/tutorial',
    name: 'Tutorial',
    component: TutorialOne,
   
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      requiresVisitor: true,
    },
  },
  {
    path: '/',
    name: 'Welcome',
    component: Welcome,
  },
  {
    path: '/intro',
    name: 'Introduction',
    component: Introduction,
  },
  {
    path: '/cover',
    name: 'Cover',
    component: Cover,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.name == 'EEBrowse') {
      if (to.hash) {
        //console.log('Browse hash set: ' + to.hash);
        const isHash = to.hash ? to.hash.split('#') : undefined
        if (isHash) {
          let tmp = setTimeout(() => {
            document.getElementById(isHash[1]).scrollIntoView()
            clearTimeout(tmp)
          })
        }
        return null
 
      } else {
        //console.log ('no hash on browse page');
        return { x: 0, y: 0 };
      }
    } else if (savedPosition) {
        //console.log('scroll to saved.');
        return savedPosition;
    } else {
        //console.log('scroll to top.');
        return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (from.name == 'Select a Tasting' && to.name == 'Browse' && !store.getters.sawIntro) {
    next({
      name: 'Cover',
    });
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.loggedIn) {
      next({
        name: 'Login',
      });
    } else {
      next();
      window.scrollTo(0, 0);
    }
  } else if (to.matched.some((record) => record.meta.requiresVisitor)) {
    if (store.getters.loggedIn) {
      next({
        name: 'Select a Tasting',
      });
    } else {
      next();
      if (to.hash) {      return { x: 0, y: 900 }    } else {
        window.scrollTo(0, 0);
        }
    }
  } else {
    next();
    if (to.hash) {     var ele = document.getElementById(to.hash); window.scrollTo(ele.offsetLeft,ele.offsetTop);   } else {
    window.scrollTo(0, 0);
    }
  }
});

export default router;
