<template>
  <div class="register page">
    <div class="container content_area">
      <button class="btn btn-primary btn-top" @click="goBack">
        <img src="@/assets/arrow-left.svg" style="height: 25px" /> <small>BACK</small>
      </button>
      <div class="login-form">
        <div class="text-center" style="display: none">
          <img class="mb-4" src="@/assets/logo.png" alt="Skurnik Logo" width="120" height="120" />
        </div>
        <h1>Register</h1>
        Registration is quick and easy! We just need a few details and you'll be tasting away.
        <div class="alert alert-danger" role="alert" v-if="areErrors">
          <b>Please correct the following error(s):</b>
          <ul>
            <li v-for="error in errors" :key="error">{{ error }}</li>
          </ul>
          <div v-if="forgotPassword"><router-link to="/forgot-password" class="btn btn-primary">Forgot Password?</router-link></div>
        </div>

        <form v-if="currentStep == 1" action="#" @submit.prevent="stepOne">
          <h2>Step 1</h2>
          <div class="form-group">
            <label for="businesstype">Business Type</label>
            <div>
              <select id="businesstype" v-model="businessType" name="businessType" class="custom-select" required="required">
                <option value="" selected>Please Select</option>
                <option value="Restaurant">Restaurant</option>
                <option value="Bar">Bar</option>
                <option value="Retail">Retail Shop</option>
                <!-- <option value="Grocery">Grocery Store</option> -->
                <option value="NAT">Distribution Partner</option>
                <option value="Supplier">Supplier</option>
                <option value="Media">Media / Journalist</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label for="firstname">First Name</label>
            <input
              id="firstname"
              v-model="firstName"
              name="firstname"
              placeholder="First Name"
              type="text"
              class="form-control"
              required="required"
            />
          </div>
          <div class="form-group">
            <label for="lastname">Last Name</label>
            <input
              id="lastname"
              v-model="lastName"
              name="lastname"
              placeholder="Last Name"
              type="text"
              class="form-control"
              required="required"
            />
          </div>
          <div class="form-group">
            <label for="email">Email</label>
            <input
              id="email"
              v-model="email"
              name="email"
              placeholder="Email Address"
              type="text"
              class="form-control"
              required="required"
            />
          </div>
          <div class="form-group">
            <label for="password">Password</label>
            <input id="password" v-model="passwordValue" name="password" type="password" class="form-control" required="required" />
          </div>
          <div class="form-group">
            <label for="passwordvalue2">Confirm Password</label>
            <input
              id="passwordvalue2"
              v-model="passwordValue2"
              name="passwordvalue2"
              type="password"
              class="form-control"
              required="required"
            />
            <small v-if="!passwordMatch">Entered passwords don't match</small>
          </div>

          <div
            class="form-group"
            v-if="businessType == 'Restaurant' || businessType == 'Bar' || businessType == 'Retail' || businessType == 'NAT'"
          >
            <label for="select">Account State</label>
            <div>
              <select id="select" name="select" v-model="state" @change="onChangeState($event)" class="custom-select" required="required">
                <option value="" disabled>Please Select</option>
                <option v-for="(st, state) in usStateList" :key="state" :value="state">{{ st }}</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <button name="submit" type="submit" class="btn btn-primary">Next</button>
          </div>
        </form>

        <form v-if="currentStep == 2" action="#" @submit.prevent="stepTwo">
          <h2>Step 2</h2>

          <!--<div v-if="businessType == 'Supplier'">
            <div class="form-group">
            <label for="supplierCode">Supplier Code</label>
              <div>
                <input type="text" id="supplierCode" name="supplierCode" v-model="supplierCode" required="required" style="width: 100%" />
                <select id="businessName" name="businessName" v-model="businessName" class="custom-select" required="required">
                  <option value="" disabled>Please Select</option>
                  <option v-for="cust in businessNameGroup" :key="cust.cust_id" :value="cust.cust_id">{{ cust.name }}</option>
                </select>
              </div>
            </div>-->
          <div class="form-group">
            <label for="businessName">Business Name</label>
            <div>
              <input
                class="form-control"
                type="text"
                id="businessName"
                name="businessName"
                v-model="businessName"
                required="required"
                style="width: 100%"
              />
              <!--<select id="businessName" name="businessName" v-model="businessName" class="custom-select" required="required">
                <option value="" disabled>Please Select</option>
                <option v-for="cust in businessNameGroup" :key="cust.cust_id" :value="cust.cust_id">{{ cust.name }}</option>
              </select>-->
            </div>
          </div>

          <div
            class="form-group"
            v-if="
              (businessType == 'Restaurant' || businessType == 'Bar' || businessType == 'Retail') &&
              (state == 'NY' ||
                state == 'NJ' ||
                state == 'CT' ||
                state == 'RI' ||
                state == 'PA' ||
                state == 'CA' ||
                state == 'KY' ||
                state == 'OH' ||
                state == 'IN')
            "
          >
            <label for="salesrep">Sales Representative</label>
            <div>
              <select
                id="salesrep"
                name="salesrep"
                v-model="salesRep"
                @change="onChangeRep($event)"
                class="custom-select"
                required="required"
              >
                <option value="" disabled>Please Select</option>
                <option v-for="rep in salesRepGroup" :key="rep.rep_id" :value="rep.rep_id">{{ rep.name }}</option>
                <option value="99999">I don't have a Sales Rep</option>
              </select>
            </div>
          </div>
          <!--
          <div class="form-group" v-if="businessType == 'Distributor'">
            <label for="natState">State / Distributor</label>
            <div>
              <select id="natState" name="natState" v-model="natState" class="custom-select" required="required">
                <option value="" disabled>Please Select</option>
                <option v-for="st in natStateList" :key="st" :value="st">{{ st }}</option>
              </select>
            </div>
          </div>
         -->
          <div class="form-group">
            <div class="form-check">
              <input class="form-check-input" v-model="terms" type="checkbox" :value="terms" id="defaultCheck1" />
              <label class="form-check-label" for="defaultCheck1">
                I agree to the <a href="https://www.skurnik.com/terms-of-use/">terms & conditions</a>
              </label>
            </div>
          </div>
          <div class="form-group">
            <button name="submit" type="submit" @click="decreaseStep" class="btn btn-secondary">Back</button>&nbsp; &nbsp;<button
              name="submit"
              type="submit"
              class="btn btn-primary"
            >
              Next
            </button>
          </div>
        </form>

        <form v-if="currentStep == 3" action="#" @submit.prevent="stepThree">
          <h2>Step 3</h2>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      currentStep: 1,
      errors: [],
      forgotPassword: false,
      firstName: '',
      lastName: '',
      email: '',
      state: '',
      natState: '',
      secState: '',
      businessName: '',
      businessType: '',
      supplierCode: '',
      salesRep: '',
      terms: true,
      passwordValue: '',
      passwordValue2: '',
      usStateList: {
        AL: 'Alabama',
        AK: 'Alaska',
        AZ: 'Arizona',
        AR: 'Arkansas',
        CA: 'California',
        CO: 'Colorado',
        CT: 'Connecticut',
        DC: 'District of Columbia',
        DE: 'Delaware',
        FL: 'Florida',
        GA: 'Georgia',
        HI: 'Hawaii',
        ID: 'Idaho',
        IL: 'Illinois',
        IN: 'Indiana',
        IA: 'Iowa',
        KS: 'Kansas',
        KY: 'Kentucky',
        LA: 'Louisiana',
        ME: 'Maine',
        MD: 'Maryland',
        MA: 'Massachusetts',
        MI: 'Michigan',
        MN: 'Minnesota',
        MS: 'Mississippi',
        MO: 'Missouri',
        MT: 'Montana',
        NE: 'Nebraska',
        NV: 'Nevada',
        NH: 'New Hampshire',
        NJ: 'New Jersey',
        NM: 'New Mexico',
        NY: 'New York',
        NC: 'North Carolina',
        ND: 'North Dakota',
        OH: 'Ohio',
        OK: 'Oklahoma',
        OR: 'Oregon',
        PA: 'Pennsylvania',
        RI: 'Rhode Island',
        SC: 'South Carolina',
        SD: 'South Dakota',
        TN: 'Tennessee',
        TX: 'Texas',
        UT: 'Utah',
        VT: 'Vermont',
        VA: 'Virginia',
        WA: 'Washington',
        WV: 'West Virginia',
        WI: 'Wisconsin',
        WY: 'Wyoming',
      },
      natStateList: [
        'ALASKA: Specialty Imports',
        'ARIZONA: Quench',
        'ARIZONA: Synergy Fine Wines',
        'ARKANSAS: Natural State Distributing',
        'ALABAMA: Grassroots Wine',
        'COLORADO: Maverick Wine Company',
        'DC: Prestige-Ledroit',
        'DC: Bacchus Importers',
        'DELAWARE: Prestige-Ledroit',
        'DELAWARE: Bacchus Importers',
        'FLORIDA: Southern Wines and Spirits',
        'GEORGIA: Winebow',
        'GEORGIA: Northeast Sales & Distribution',
        'HAWAII: Southern Wine and Spirits',
        'IDAHO: Northwest Specialty Beverage',
        'IOWA: Okoboji Wines',
        'ILLINOIS: Maverick Wine Company',
        'KANSAS: LDF Sales & Distributing',
        'LOUISIANA: Wines Unlimited',
        'MAINE: South Portland Wine Co.',
        'MASSCHUSETTS: Arborway Imports',
        'MASSCHUSETTS: Atlantic Importing Co.',
        'MASSCHUSETTS: Boston Wine Company',
        'MASSCHUSETTS: Ruby Wine',
        'MARYLAND: Prestige-Ledroit',
        'MARYLAND: Bacchus Importers',
        'MICHIGAN: Elite Brands/Imperial Beverages',
        'MINNESOTA: The Wine Company',
        'MINNESOTA: Great Ciao',
        'MISSOURI: A.Bommarito',
        'MISSOURI: Lohr',
        'MISSOURI: Pilar Wine Brokers',
        'MONTANA: Rocky Mountain Wine Co.',
        'NEBRASKA: Quench',
        'NEW HAMPSHIRE: Perfecta',
        'NEW MEXICO: Favorite Brands',
        'NORTH CAROLINA: Bordeaux Fine & Rare',
        'NEVADA: Vin Sauvage',
        'OKLAHOMA: Thirst Wine Merchants',
        'OREGON: Galaxy Wine Co',
        'PUERTO RICO: Ambrosia Fine Foods',
        'PUERTO RICO: B. Fernandez',
        'RHODE ISLAND: Wine Wizards',
        'SOUTH CAROLINA: Grassroots Wine Co',
        'SOUTH DAKOTA: Okoboji Wines',
        'TENNESSE: Athens Distributing',
        'TENNESSE: Empire Distributors Nashville',
        'TENNESSE: Empire Distributors Chattanooga',
        'TENNESSE: Empire Distributors Knoxville',
        'TENNESSE: DET Distributing',
        'TEXAS: MexCor Fine Wine',
        'UTAH: Utah Dept of Alcohol',
        'VIRGINIA: Virginia Imports',
        'VERMONT: Vermont Wine Merchants',
        'WASHINGTON: Cavatappi Distribuzione',
        'WISCONSIN: Left Bank Wine Co.',
        'WEST VIRGINIA: Country Vintner WV',
        'WYOMING: Wyoming Liquor Division',
      ],
    };
  },
  components: {},
  computed: {
    areErrors: function () {
      if (this.errors.length >= 1) {
        return true;
      } else {
        return false;
      }
    },
    salesRepGroup: function () {
      return this.$store.getters.salesRepGroup;
    },
    businessNameGroup: function () {
      //return this.$store.getters.businessNameGroup;
      return false;
    },
    passwordMatch: function () {
      if (this.passwordValue == this.passwordValue2) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapActions(['getSalesRepGroup', 'getBusinessNameGroup']),
    decreaseStep: function () {
      this.currentStep = this.currentStep - 1;
    },
    async stepOne() {
      this.errors = [];
      if (this.businessType == 'Media' || this.businessType == 'Supplier') {
        this.state = 'NA';
      }
      if (!this.firstName) {
        this.errors.push('First Name required.');
      }
      if (!this.lastName) {
        this.errors.push('Last Name required.');
      }
      if (!this.email) {
        this.errors.push('Email required.');
      } else if (!this.validEmail(this.email)) {
        this.errors.push('Valid email required.');
      }
      if (!this.state) {
        this.errors.push('State required.');
      }
      if (!this.businessType) {
        this.errors.push('Business Type required.');
      }

      if (this.errors.length >= 1) {
        console.log('errors found');
        return false;
      }
      console.log('passed basic checks');

      let emailGood = await this.$store
        .dispatch('checkEmailExists', {
          email: this.email,
        })
        .then((response) => {
          return response;
        });

      if (!emailGood) {
        this.errors.push('There is already a trade account for that email address.');

        //console.log('success not true');
      } else {
        this.currentStep = 2;
        return false;
      }
      if (!this.passwordMatch) {
        this.errors.push('The passwords you entered do not match.');
      }
      if (this.errors.length >= 1) {
        console.log('errors found');
        this.forgotPassword = true;
        return false;
      }
    },
    async stepTwo() {
      this.errors = [];
      // var correctsuppliercode = 'supplier22';

      if (!this.terms) {
        this.errors.push('You must agree to the terms to use this app.');
      }
      // if (this.supplierCode!= ''  && this.supplierCode!=correctsuppliercode) {
      //   if (this.supplierCode!=correctsuppliercode) {
      //     this.errors.push('You have entered an invalid supplier code\.');
      //   } else if (!this.salesRep && this.supplierCode!= '' ) {
      //     this.errors.push('Please select your Sales Representative.');
      //   }
      // } else {
      //   if (this.supplierCode==correctsuppliercode) {
      //   this.salesRep = 'Supplier';
      //   }
      // }
      if (this.businessType == 'Supplier') {
        this.salesRep = 'Supplier';
      } else if (this.businessType == 'Media') {
        this.salesRep = 'Media';
      } else if (this.businessType == 'NAT') {
        this.salesRep = 'Distributor';
      }
      if (this.errors.length >= 1) {
        console.log('errors found');
        return false;
      } else {
        console.log('2 - passed basic checks');

        let accountCreated = await this.$store
          .dispatch('createAccount', {
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            state: this.state,
            businessName: this.businessName,
            businessType: this.businessType,
            salesRep: this.salesRep,
            password: this.passwordValue,
          })
          .then((response) => {
            return response;
          });

        if (!accountCreated) {
          this.errors.push('Error Creating Account.');

          console.log('Unable to create account.');
        } else {
          this.$router.push({ name: 'Select a Tasting' });
          return true;
        }
      }
    },
    async stepThree() {
      this.errors = [];
      if (!this.passwordMatch) {
        this.errors.push('The passwords you entered do not match.');
      }

      if (this.errors.length >= 1) {
        console.log('errors found');
        return false;
      } else {
        let accountCreated = await this.$store
          .dispatch('createAccount', {
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            state: this.state,
            businessName: this.businessName,
            businessType: this.businessType,
            salesRep: this.salesRep,
            password: this.passwordValue,
          })
          .then((response) => {
            return response;
          });

        if (!accountCreated) {
          this.errors.push('Error Creating Account.');

          console.log('Unable to create account.');
        } else {
          let tasting_id = window.localStorage.getItem('tasting_id');
          if (tasting_id) {
            this.$router.push({ name: 'Browse' });
          } else {
            this.$router.push({ name: 'Select a Tasting' });
          }
          return true;
        }
      }
    },

    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    onChangeState(event) {
      //console.log(event.target.value);
      let chosenState = { s: event.target.value };

      console.log(this.getSalesRepGroup(chosenState));
    },
    onChangeRep(event) {
      //console.log(event.target.value);
      let chosen = { r: event.target.value, s: this.state };

      console.log(this.getBusinessNameGroup(chosen));
    },
    goBack() {
      if (true) {
        if (this.currentStep == 1) {
          this.$router.go(-1);
        } else {
          this.decreaseStep();
        }
      }
    },
  },
};
</script>
