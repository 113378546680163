<template></template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
   methods: {
    ...mapActions(['saveAllData']),
   },
  created() {
    this.saveAllData();
    this.$store.dispatch('clearAllData');
    localStorage.clear();
    this.$store.dispatch('destroyToken').then((response) => {
      this.$router.push({ name: 'Welcome' });
    });
  },
};
</script>
