<template>
  <div class="modal-wrapper">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Let's connect your account</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="toggleModal">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p style="text-transform: none">
            Save all your notes and favorites & lists to your Skurnik Trade Resources account. All you need to do is log in or create an
            account (it’s easy!) to continue.<br /><br />
            <router-link a class="btn btn-primary" :to="$router.resolve({ name: 'Login' }).href">Login</router-link>&nbsp;&nbsp;
            <router-link class="btn btn-primary" :to="$router.resolve({ name: 'Register' }).href">Register</router-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  data() {
    return {};
  },
  computed: {
    showModal() {
      return this.$store.getters.showModal;
    },
  },
  methods: {
    // PUT OUR FUNCTIONS HERE FOR INTERACTION.
    ...mapActions(['toggleModal']),
  },
};
</script>
