import axios from "axios";
//import router from 'vue-router';
import router from '../../router/index.js';

axios.defaults.baseURL = "https://www.skurnik.com/api/tastings";

const state = {
  alldata: [],
  currentlyOnline: true,  // new thing to track current state.
  token: localStorage.getItem("access_token") || null,  // THIS IS THE OLD WAY SHOULD GET UPDATED THEN REMOVED.
  user: {
    email: "",
    name: "",
    role: "",
    state: false,
    salesRep: '',
    custId: '',
    businessType: '',
    token: localStorage.getItem("access_token") || null
  },
  tastings: [],
  currentTasting: localStorage.getItem("currentTasting") || null,
  currentTastingDeets: JSON.parse(localStorage.getItem("currentTastingDeets")) || {
    tastingSize: 'small',
  },
  sawIntro: localStorage.getItem("sawIntro") || false,
  sawTutorial: localStorage.getItem("sawTutorial") || false,
  showModal: false,
  showModalTag: false,
  showModalNote: false,
  showModalListname: false,
  showLoading: false,
  listnameEditDeets: {
    listname: "",
    tasting_id: "",
    token: ""
  },
  // start this isn't used yet...
  //showModalNotice: false,
  //showModalNoticeContent: '',
  // end this isn't used yet...
  listSku: String,
  noteSku: String,
  salesRepGroup: {},
  businessNameGroup: {},
  // USED FOR NEW SEARCH
  resultTables: JSON.parse(localStorage.getItem("setResultTables")) || null,
  resultFilters: JSON.parse(localStorage.getItem("setResultFilters")) || false,
  resultCount: JSON.parse(localStorage.getItem("setResultCount")) || false,
  //tablesExpandedVu: JSON.parse(localStorage.getItem("tablesExpandedVu")) ||[],
  tablesExpandedVu: [],
  // USED FOR SMALL TASTING SKU / KEYWORD SEARCH
  searchResults: JSON.parse(localStorage.getItem("searchResults")) || [],
  currentLiveSku: {
    ats: {
      appelation: '',
      color: '',
      country: '',
      region: '',
      rs_level: '',
      varietal: '',
      vintage: '',
      wine_type: '',
    },
    d: '',
    map: '',
    name: '',
    pricing: '',
    producer: '',
    th: '',
    type: '',
    vars: '',
  },

};

const getters = {
  loggedIn(state) {
    return state.user.token !== null;
  },
  currentlyOnline(state) {
    return state.currentlyOnline;
  },
  sawIntro(state) {
    return state.sawIntro;
  },
  sawTutorial(state) {
    return state.sawTutorial;
  },
  token: (state) => state.token,
  tastingSize(state) {

    if (state.currentTastingDeets.tastingSize) {
      return state.currentTastingDeets.tastingSize;
    } else {
      return 'small';
    }
  },
  alldata: (state) => state.alldata,
  resultTables: (state) => state.resultTables, // Object of tables w/ skus shown when they meet search criteria.
  resultFilters: (state) => state.resultFilters, // used to show filters used on new search / filter results page.
  resultCount: (state) => state.resultCount, // used to show expanded tables when # of results falls below target #.
  getTablesExpandedVu: (state) => state.tablesExpandedVu,
  user: (state) => state.user,
  tastings: (state) => state.tastings,
  currentTasting: (state) => state.currentTasting,
  currentTastingDeets: (state) => state.currentTastingDeets,
  itin: (state) => state.alldata.itinerary,
  showModal: (state) => state.showModal,
  showModalTag: (state) => state.showModalTag,
  showModalNote: (state) => state.showModalNote,
  showModalListname: (state) => state.showModalListname,
  showLoading: (state) => state.showLoading,
  getListnameEditDeets: (state) => state.listnameEditDeets,
  // start this isn't used yet...
  //showModalNotice: (state)=> state.showModalNotice,
  //showModalNoticeContent: (state)=> state.showModalNoticeContent,
  // end this isn't used yet...
  getNoteSku: (state) => state.noteSku,
  getListSku: (state) => state.listSku,
  getLists: (state) => state.alldata.lists,
  salesRepGroup: (state) => state.salesRepGroup,
  businessNameGroup: (state) => state.businessNameGroup,
  searchResults: (state) => state.searchResults,

  currentLiveSku: (state) => state.currentLiveSku,
};

const actions = {
  async fetchAlldata({ state, commit }) {

    const localdata = window.localStorage.getItem("alldata");
    const token = window.localStorage.getItem("access_token");
    var tasting_id = window.localStorage.getItem("tasting_id");
    if (window.localStorage.getItem("chosen_state")) {
      var chosen_state = window.localStorage.getItem("chosen_state");
      var tokenstate = "&token=" +
        token +
        "&chosen_state=" +
        chosen_state
    } else {
      var tokenstate = "&token=" +
        token
    }

    if (!localdata || localdata == "" || tasting_id <= 1) {
      if (tasting_id <= 1) {
        // WE DON'T HAVE A TASTING ID... let's go home.
        router.push({ name: 'Logout' });
      }
      const response = await axios.get(
        "https://www.skurnik.com/api/tastings/data.php?tasting_id=" +
        tasting_id +
        tokenstate
      );
      //console.log(response.data);
      if (response.data.tables == null) {
        console.log('remote call, no data');
        commit("setAlldata", false);
      } else {
        console.log('remote call, valid data');

        if (typeof response.data.ratings == 'array' && response.data.ratings.length === 0) {

          response.data.ratings = {}
        }
        window.localStorage.setItem("alldata", JSON.stringify(response.data));
        commit("setAlldata", response.data);

        // OK LETS PULL PRICING.
        const response2 = await axios.get(
          "https://www.skurnik.com/api/pricing/tasting-pricing.php?tasting_id=" +
          tasting_id +
          tokenstate
        );
        if (response2.data.data != null) {
          console.log('Pricing Data Loaded: ' + response2.data.source + ' - ' + response2.data.state);
          window.localStorage.setItem("pricing", JSON.stringify(response2.data));
        } else {
          console.log('Failied Pricing Retrieval.');
          window.localStorage.setItem("pricing", 'Failed Pricing Retrieval');
        }



        commit("setShowLoading", false);
      }

    } else {
      const alldata = JSON.parse(localdata)
      //console.log(alldata);
      if (alldata.tables == null) {
        console.log('local storage, no data');
        commit("setAlldata", false);

      } else {
        //console.log('local storage, valid data');
        commit("setAlldata", alldata);
        commit("setShowLoading", false);
      }


    }
  },

  async fetchUser({ state, commit }) {
    commit("setUserdata", state.user);
  },



  async fetchPricedata(context, data) {
    //console.log(data);
    let user_data = JSON.parse(window.localStorage.getItem("user"))
    user_data.state = data;
    window.localStorage.setItem("user", JSON.stringify(user_data));

    const token = window.localStorage.getItem("access_token");
    let tasting_id = window.localStorage.getItem("tasting_id");


    if (tasting_id <= 1) {
      if (tasting_id <= 1) {
        // WE DON'T HAVE A TASTING ID... let's go home.
        router.push({ name: 'Logout' });
      }
    } else {
      const response2 = await axios.get(
        "https://www.skurnik.com/api/pricing/tasting-pricing.php?tasting_id=" +
        tasting_id +
        "&token=" +
        token +
        "&force=" +
        data
      );
      if (response2.data.data != null) {
        console.log('Pricing Data Loaded: ' + response2.data.source + ' - ' + response2.data.state);
        window.localStorage.setItem("pricing", JSON.stringify(response2.data));
      } else {
        console.log('Failied Pricing Retrieval.');
        window.localStorage.setItem("pricing", 'Failed Pricing Retrieval');
      }


    }
  },


  async saveAllData({ state, commit }) {
    const allData = JSON.parse(localStorage.getItem('alldata'));
    const pricing = JSON.parse(localStorage.getItem('pricing'));

    // Initialize the result array
    const result = [];

    // Helper function to add or update an SKU in the result array
    function addOrUpdateSKU(sku, listName) {
      // Check if SKU exists in allData.skus
      if (!allData.skus[sku]) {
        console.warn(`SKU ${sku} not found in allData.skus`);
        return; // Skip this SKU if it's not found
      }

      const skuInfo = allData.skus[sku];
      const pricingInfo = pricing.data[sku]?.find(item => item.d.includes(sku));

      const item = {
        'Description': skuInfo.name || 'N/A', // Use 'N/A' if name is not available
        'SKU': sku,
        'Rating': allData.ratings[sku]?.r || '',
        'Notes': allData.ratings[sku]?.n || '',
        'List': listName,
        'Bottle Size': pricingInfo?.d.split('|')[1].trim() || 'N/A',
        'Case Pack': pricingInfo?.d.split('|')[0].split(' ')[2] || 'N/A',
        'Pricing': pricingInfo?.pricing || 'N/A',
        'Color': skuInfo.ats?.color || 'N/A',
        'Region': skuInfo.ats?.region || 'N/A',
        'Varietal': skuInfo.ats?.varietal || 'N/A',
        'Appellation': skuInfo.ats?.appelation || 'N/A',
        'Vintage': skuInfo.ats?.vintage || 'N/A',
        'Farming Practice': skuInfo.ats?.farming_practice || 'N/A',
      };

      const existingItemIndex = result.findIndex(item => item.SKU === sku);
      if (existingItemIndex > -1) {
        // Update the existing item if it's already in the result array
        result[existingItemIndex] = { ...result[existingItemIndex], ...item };
      } else {
        // Add the new item to the result array
        result.push(item);
      }
    }

    if (!allData || typeof allData !== 'object' || !allData.ratings || typeof allData.ratings !== 'object') {
      console.warn('allData or allData.ratings is not set or not an object');
      return; // Skip the operation or handle as necessary
    }
    const ratings = allData.ratings && typeof allData.ratings === 'object' ? allData.ratings : {};

    Object.keys(ratings).forEach((sku) => {
      const listInfo = Object.keys(allData.lists).find((list) => allData.lists[list].includes(sku)) || 'Uncategorized';
      addOrUpdateSKU(sku, listInfo);
    });

    // Iterate over all lists and add any SKUs not already included
    Object.values(allData.lists).flat().forEach((sku) => {
      if (!result.some(item => item.SKU === sku)) {
        const listName = Object.keys(allData.lists).find((list) => allData.lists[list].includes(sku)) || 'Uncategorized';
        addOrUpdateSKU(sku, listName);
      }
    });

    // Convert the result array to a JSON string
    const jsonResult = JSON.stringify(result, null, 2);
    const token = window.localStorage.getItem("access_token");
    // parse tasting_id as integer
    const tasting_id = parseInt(window.localStorage.getItem("tasting_id"));
    // get current tasting name
    const deets = window.localStorage.getItem("currentTastingDeets");
    // check and make sure tasting_id is an integer >=1
    if (tasting_id <= 1) {
      // WE DON'T HAVE A TASTING ID... let's check deets['tasting_id']
      tasting_id = JSON.parse(deets).tasting_id;

    }
    const tasting_name = JSON.parse(deets).name;
    //console.log(tasting_name)
    return new Promise((resolve, reject) => {
      axios
        .post("https://www.skurnik.com/api/tastings/save-data.php", {
          data: jsonResult,
          token: token,
          tasting_id: tasting_id,
          tasting_name: tasting_name,
          deets: deets
        })

        .then((response) => {

          //console.log(response);
          //window.location.reload(true);
          //console.log('Saved Favs to Server')
        })
        .catch((error) => {
          console.log('Failed to save Favs to Server')
        });
    });



  },

  async emailAllData({ state, commit }) {
    const allData = JSON.parse(localStorage.getItem('alldata'));
    const pricing = JSON.parse(localStorage.getItem('pricing'));

    // Initialize the result array
    const result = [];

    // Helper function to add or update an SKU in the result array
    function addOrUpdateSKU(sku, listName) {
      // Check if SKU exists in allData.skus
      if (!allData.skus[sku]) {
        console.warn(`SKU ${sku} not found in allData.skus`);
        return; // Skip this SKU if it's not found
      }

      const skuInfo = allData.skus[sku];
      const pricingInfo = pricing.data[sku]?.find(item => item.d.includes(sku));

      const item = {
        'Description': skuInfo.name || 'N/A', // Use 'N/A' if name is not available
        'SKU': sku,
        'Rating': allData.ratings[sku]?.r || '',
        'Notes': allData.ratings[sku]?.n || '',
        'List': listName,
        'Bottle Size': pricingInfo?.d.split('|')[1].trim() || 'N/A',
        'Case Pack': pricingInfo?.d.split('|')[0].split(' ')[2] || 'N/A',
        'Pricing': pricingInfo?.pricing || 'N/A',
        'Color': skuInfo.ats?.color || 'N/A',
        'Region': skuInfo.ats?.region || 'N/A',
        'Varietal': skuInfo.ats?.varietal || 'N/A',
        'Appellation': skuInfo.ats?.appelation || 'N/A',
        'Vintage': skuInfo.ats?.vintage || 'N/A',
        'Farming Practice': skuInfo.ats?.farming_practice || 'N/A',
      };

      const existingItemIndex = result.findIndex(item => item.SKU === sku);
      if (existingItemIndex > -1) {
        // Update the existing item if it's already in the result array
        result[existingItemIndex] = { ...result[existingItemIndex], ...item };
      } else {
        // Add the new item to the result array
        result.push(item);
      }
    }

    // Iterate over the ratings to get the SKUs and add them to the result
    Object.keys(allData.ratings).forEach((sku) => {
      const listInfo = Object.keys(allData.lists).find((list) => allData.lists[list].includes(sku)) || 'Uncategorized';
      addOrUpdateSKU(sku, listInfo);
    });

    // Iterate over all lists and add any SKUs not already included
    Object.values(allData.lists).flat().forEach((sku) => {
      if (!result.some(item => item.SKU === sku)) {
        const listName = Object.keys(allData.lists).find((list) => allData.lists[list].includes(sku)) || 'Uncategorized';
        addOrUpdateSKU(sku, listName);
      }
    });

    // Convert the result array to a JSON string
    const jsonResult = JSON.stringify(result, null, 2);
    const token = window.localStorage.getItem("access_token");
    const tasting_id = window.localStorage.getItem("tasting_id");
    // get current tasting name
    const deets = window.localStorage.getItem("currentTastingDeets");
    const tasting_name = JSON.parse(deets).name;
    //console.log(tasting_name)
    return new Promise((resolve, reject) => {
      axios
        .post("https://www.skurnik.com/api/tastings/email-data.php", {
          data: jsonResult,
          token: token,
          tasting_id: tasting_id,
          tasting_name: tasting_name
        })

        .then((response) => {

          //console.log(response);
          //window.location.reload(true);
          console.log('Saved Favs to Server')
        })
        .catch((error) => {
          console.log('Failed to save Favs to Server')
        });
    });



  },
  async saveAllData_old({ state, commit }) {
    var isdata = window.localStorage.getItem("alldata");
    //console.log('----' + isdata);
    if (typeof (isdata) == 'undefined' || isdata == null) { return; }
    var thedata = JSON.parse(isdata);
    const token = window.localStorage.getItem("access_token");
    if (typeof (thedata.ratings) == 'undefined') {
      var ratings = null;
    } else {
      var ratings = thedata.ratings
    }
    if (typeof (thedata.lists) == 'undefined') {
      var lists = null
    } else {
      var lists = thedata.lists
    }
    var user = window.localStorage.getItem("user");
    return new Promise((resolve, reject) => {
      axios
        .post("https://www.skurnik.com/api/tastings/saveme.php", {
          user: user,
          ratings: ratings,
          lists: lists,
          token: token,
        })

        .then((response) => {

          //console.log(response);
          //window.location.reload(true);
        })
        .catch((error) => {
          //console.log('failed extra saveme attempt')
        });
    });
  },



  async fetchTastings(context, data) {
    //const localdata = window.localStorage.getItem('alldata');
    const token = window.localStorage.getItem("access_token");
    return new Promise((resolve, reject) => {
      axios
        .get("/data-tastings.php?token=" + token)
        .then((response) => {
          context.commit("setTastings", response.data);

          resolve(response.data);
          return response.data;
        })
        .catch((error) => {
          if (error.message === 'Network Error' && !error.response) {
            // do something to tell the user there are problems.
          }
          var payload = { error: error, way: 'Fetching Tastings List' };
          context.dispatch('handleError', payload);
          //reject(error);
        });
    });

  },
  async getResults(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/data-search.php", {
          s: data,
        })
        .then((response) => {
          context.commit('setResults', response.data)
          resolve(response.data);
        })
        .catch((error) => {
          var payload = { error: error, way: 'Searching Tasting' };
          context.dispatch('handleError', payload);
        });
    });
  },
  async getLiveSku(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/data-sku.php", {
          s: data,
        })
        .then((response) => {
          context.commit('setLiveSku', response.data)
          resolve(response.data);
        })
        .catch((error) => {
          var payload = { error: error, way: 'Retrieve Live Sku' };
          context.dispatch('handleError', payload);
        });
    });
  },

  // ---------------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------

  //           REGISTRATION ACTIONS

  // ---------------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------
  async getSalesRepGroup(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/data-reps.php", {
          s: data.s,
        })
        .then((response) => {
          context.commit('setSalesRepGroup', response.data)
          resolve(response.data);
        })
        .catch((error) => {
          var payload = { error: error, way: 'Retrieving Rates' };
          context.dispatch('handleError', payload);
        });
    });
  },

  async getBusinessNameGroup(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/data-custs.php", {
          s: data.s,
          r: data.r
        })
        .then((response) => {
          context.commit('setBusinessNameGroup', response.data)
          resolve(response.data);
        })
        .catch((error) => {
          var payload = { error: error, way: 'Retrieve Business Names' };
          context.dispatch('handleError', payload);
        });
    });
  },

  checkEmailExists(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/user-check-email.php", {
          e: data.email,
        })
        .then((response) => {
          resolve(response.data.success);
        })
        .catch((error) => {
          var payload = { error: error, way: 'Check Email Exists' };
          context.dispatch('handleError', payload);
        });
    });
  },

  createAccount(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/user-create.php", {
          firstname: data.firstName,
          lastname: data.lastName,
          email: data.email,
          state: data.state,
          businessname: data.businessName,
          businesstype: data.businessType,
          salesrep: data.salesRep,
          password: data.password
        })
        .then((response) => {
          let userData = {
            email: response.data.email,
            role: response.data.role,
            state: response.data.state,
            salesRep: response.data.salesRep,
            custId: response.data.custId,
            businessType: response.data.businessType,
            token: response.data.token,
          }
          context.commit("setUserdata", userData);
          context.commit("setNewToken", response.data.token);
          window.localStorage.setItem("user", JSON.stringify(userData));
          window.localStorage.setItem("access_token", response.data.token);
          resolve(response);
        })
        .catch((error) => {
          var payload = { error: error, way: 'Creating Account' };
          context.dispatch('handleError', payload);
        });
    });
  },


  updateAccount(context, data) {
    const token = window.localStorage.getItem("access_token");
    return new Promise((resolve, reject) => {
      axios
        .post("/user-update.php", {
          token,
          state: data.state,
          businessname: data.businessName,
          businesstype: data.businessType,
          salesrep: data.salesRep,
        })
        .then((response) => {
          let userData = {
            email: response.data.email,
            role: response.data.role,
            state: response.data.state,
            salesRep: response.data.salesRep,
            custId: response.data.custId,
            businessType: response.data.businessType,
            token: response.data.token,
          }
          context.commit("setUserdata", userData);
          window.localStorage.setItem("user", JSON.stringify(userData));
          context.commit("setNewToken", response.data.token);

          resolve(response);
        })
        .catch((error) => {
          var payload = { error: error, way: 'Update Account' };
          context.dispatch('handleError', payload);
        });
    });
  },




  deleteUserList(context, data) {
    const token = window.localStorage.getItem("access_token");
    return new Promise((resolve, reject) => {
      axios
        .post("/user-list-delete.php", {
          token,
          list: data
        })
        .then((response) => {

          //context.commit("setUserdata", userData);
          //window.localStorage.setItem("user", JSON.stringify(userData));
          //context.commit("setNewToken", response.data.token);
          alert(response);
          resolve(response);
        })
        .catch((error) => {
          var payload = { error: error, way: 'Delete User List' };
          context.dispatch('handleError', payload);
        });
    });
  },

  // -------------------------------------------
  // -------------------------------------------
  //   START UPDATED ACTIONS 2024 --------------
  // -------------------------------------------

  async saveNote({ commit, state }, data) {
    const token = window.localStorage.getItem("access_token");
    const tasting_id = window.localStorage.getItem("tasting_id");

    try {
      const response = await axios.post("https://www.skurnik.com/api/tastings/user-note.php", {
        token: token,
        tasting_id: tasting_id,
        sku: data.sku,
        note: data.note,
      });

      if (response.data.success) {
        commit('updateRatings', data);
        commit('updateLists', data);
        window.localStorage.setItem("alldata", JSON.stringify(state.alldata));
        // Handle success response
      } else {
        // Handle non-successful responses
        console.error(response.error);
      }
    } catch (error) {
      // Handle error
      console.error(error);
      dispatch('handleError', { error: error, way: 'Save Note' }); // Assuming 'handleError' is another action defined in your store
    }
  },
  async saveSkuRating({ commit, state }, data) {
    const tasting_id = window.localStorage.getItem("tasting_id");
    const token = window.localStorage.getItem("access_token");

    try {
      const response = await axios.post("https://www.skurnik.com/api/tastings/user-rating.php", {
        token: token,
        tasting_id: tasting_id,
        sku: data.sku,
        rating: data.rating,
      });

      if (response.data.success) {
        commit('SET_SKU_RATING', data); // Commit mutation to update state
        window.localStorage.setItem("alldata", JSON.stringify(state.alldata)); // Consider moving this to a mutation if you need reactivity
      } else {
        console.error(response.error);
        // Handle non-successful response
      }
    } catch (error) {
      console.error(error);
      // Handle error, possibly dispatch another action for error handling
    }
  },
  // -------------------------------------------
  // -------------------------------------------
  //   END UPDATED ACTIONS 2024 --------------
  // -------------------------------------------

  // ---------------------------------------------------------------------------------


  destroyToken(context) {
    if (context.getters.loggedIn) {
      return new Promise((resolve, reject) => {
        axios
          .post("/user-logout.php", {
            token: context.state.token,
          })
          .then((response) => {
            localStorage.removeItem("access_token");
            context.commit("destroyToken");
            resolve(response);
            // console.log(response);
            // context.commit('addTodo', response.data)
          })
          .catch((error) => {
            localStorage.removeItem("access_token");
            context.commit("destroyToken");
            var payload = { error: error, way: 'Logout' };
            context.dispatch('handleError', payload);
            reject(error);
          });
      });
    }
  },
  clearAllData(context) {
    context.commit("clearAllData");
  },
  toggleModal(context) {
    context.commit("toggleModal");
  },

  // start this isn't used yet...
  toggleModalNotice(context, data) {
    context.commit("toggleModalNotice");
    context.commit("toggleModalNoticeContent", data);
  },
  // end this isn't used yet...

  toggleModalTag(context, data) {
    context.commit("toggleModalTag");
    context.commit("setlistSku", data);
  },
  toggleModalNote(context, data) {
    context.commit("toggleModalNote");
    context.commit("setNoteSku", data);
  },
  toggleModalListname(context, data) {
    context.commit("toggleModalListname");
    context.commit("setListnameEditDeets", data);
    //console.log( data);
  },
  closeModal(context) {
    context.commit("closeModal");
  },
  closeModalTag(context) {
    context.commit("closeModalTag");
  },
  closeModalNote(context) {
    context.commit("closeModalNote");
  },
  closeModalListname(context) {
    context.commit("closeModalListname");
  },
  hideIntro(context) {
    context.commit("hideIntro")
  },
  hideTutorial(context) {
    context.commit("hideTutorial")
  },
  setResultTables(context, data) {
    window.localStorage.setItem("setResultTables", JSON.stringify(data));
    context.commit("setResultTables", data)
  },
  setResultFilters(context, data) {
    window.localStorage.setItem("setResultFilters", JSON.stringify(data));
    context.commit("setResultFilters", data)
  },
  setResultCount(context, data) {
    window.localStorage.setItem("setResultCount", JSON.stringify(data));
    context.commit("setResultCount", data)
  },
  setTablesExpandedVu(context, data) {
    //window.localStorage.setItem("tablesExpandedVu", JSON.stringify(data));
    //context.commit("mkTablesExpandedVu",data)
  },
  setTableFlop(context, tableno) {
    var currentExpanded = JSON.parse(window.localStorage.getItem("tablesExpandedVu"));
    // check if this table is expanded.
    //console.log('UPDATING TABLE ' + tableno);
    //console.log(tableno);
    if (currentExpanded == null) {

      var tbls = JSON.parse(window.localStorage.getItem("alldata"));
      var currentExpanded = [false];
      for (const table in tbls.tables) {
        currentExpanded[tbls.tables[table].table] = false;
      }
    }


    if (typeof (currentExpanded) == 'undefined') {
      currentExpanded = { 0: false, };
    }
    if (currentExpanded[tableno]) {
      //console.log ('Closing ' + tableno);
      // lets close it.

      currentExpanded[tableno] = false;

    } else {
      // its not open. Lets close all the rest, then open this one.
      if (currentExpanded.length >= 1) {
        for (const no in currentExpanded) {

          //console.log('TABLE : '+no)
          if (no == tableno) {
            //console.log('Opening Table '+no);
            currentExpanded[no] = true;
          } else {
            //console.log('Closing Table '+no);
            currentExpanded[no] = false;
          }
        }
      }
    }
    window.localStorage.setItem("tablesExpandedVu", JSON.stringify(currentExpanded));
    context.commit("mkTablesExpandedVu", currentExpanded)
  },
  setTableOpen(context, tableno) {
    var currentExpanded = JSON.parse(window.localStorage.getItem("tablesExpandedVu"));
    if (currentExpanded == null) {

      var tbls = JSON.parse(window.localStorage.getItem("alldata"));
      var currentExpanded = [false];
      for (const table in tbls.tables) {
        currentExpanded[tbls.tables[table].table] = false;
      }
    }
    //console.log('-- Opening Table '+tableno);
    currentExpanded[tableno] = true;
    window.localStorage.setItem("tablesExpandedVu", JSON.stringify(currentExpanded));
    context.commit("mkTablesExpandedVu", currentExpanded)
  },
  setTablesClosed(context) {
    var tbls = JSON.parse(window.localStorage.getItem("alldata"));
    var currentExpanded = [false];
    for (const table in tbls.tables) {
      currentExpanded[tbls.tables[table].table] = false;
    }
    window.localStorage.setItem("tablesExpandedVu", JSON.stringify(currentExpanded));
  },
  setCurrentTasting(context, data) {

    context.commit("setCurrentTasting", data);
  },
  saveListInfoAct(context, data) {

    context.commit("saveListInfoMut", data);
  },
  saveNewListAct(context, data) {

    context.commit("saveNewListMut", data);
  },

  setShowLoading(context, data) {

    context.commit("setShowLoading", data);
  },

  saveListnameAct(context, data) {
    //console.log(data);
    context.commit("saveListnameMut", data);
  },
  deleteListAct(context, data) {

    context.commit("deleteListMut", data);

  },
  removeSkuFromListAct(context, data) {

    context.commit("removeSkuFromListMut", data);
  },
  closeWarning(context, data) {
    context.commit('setCurrentlyOnlineClosed')
  },
  sendAccessPass(context, credentials) {
    return new Promise((resolve, reject) => {
      axios
        .post("/user-temp-access-email.php", {
          e: credentials.username,

        })
        .then((response) => {
          //console.log(response);
          if (typeof response.data.success != "undefined") {
            this.clearAllData;
            localStorage.removeItem("alldata");
            //console.log('success');

            resolve(response);
          } else {
            //resolve(response.error);
            /*
                        if (typeof response.data.error != "undefined") {
                            alert(response.data.error)
                        }
                        console.log(response.error);
                        
                        */
            console.log('fail user access pass send');
            reject(response);
          }
        })
        .catch((error) => {
          var payload = { error: error, way: 'Request Access Pass' };
          context.dispatch('handleError', payload);
        });
    });
  },
  retrieveToken(context, credentials) {
    return new Promise((resolve, reject) => {
      axios
        .post("/user-auth.php", {
          u: credentials.username,
          p: credentials.password,
        })
        .then((response) => {
          //console.log(response);
          if (typeof response.data.token != "undefined") {
            this.clearAllData;
            localStorage.removeItem("alldata");
            //console.log(response);

            localStorage.setItem("access_token", response.data.token);

            let userData = {
              email: response.data.email,
              role: response.data.role,
              state: response.data.state,
              salesRep: response.data.salesRep,
              custId: response.data.custId,
              businessType: response.data.businessType,
              token: response.data.token,
            }
            context.commit("setUserdata", userData);
            context.commit("setNewToken", response.data.token);

            window.localStorage.setItem("user", JSON.stringify(userData));
            resolve(response);
          } else {
            //resolve(response.error);
            /*
                        if (typeof response.data.error != "undefined") {
                            alert(response.data.error)
                        }
                        console.log(response.error);
                        
                        */

            reject(response);
          }
        })
        .catch((error) => {
          var payload = { error: error, way: 'Retrieve User Auth Token' };
          context.dispatch('handleError', payload);
        });
    });
  },
  retrievePassToken(context, credentials) {
    return new Promise((resolve, reject) => {
      axios
        .post("/user-auth-tk.php", {
          tk: credentials.tk,

        })
        .then((response) => {
          //console.log(response);
          if (typeof response.data.token != "undefined") {
            this.clearAllData;
            localStorage.removeItem("alldata");
            //console.log(response);

            localStorage.setItem("access_token", response.data.token);

            let userData = {
              email: response.data.email,
              role: response.data.role,
              state: response.data.state,
              salesRep: response.data.salesRep,
              custId: response.data.custId,
              businessType: response.data.businessType,
              token: response.data.token,
            }
            context.commit("setUserdata", userData);
            context.commit("setNewToken", response.data.token);

            window.localStorage.setItem("user", JSON.stringify(userData));
            resolve(response);
          } else {
            //resolve(response.error);
            /*
                        if (typeof response.data.error != "undefined") {
                            alert(response.data.error)
                        }
                        console.log(response.error);
                        
                        */

            reject(response);
          }
        })
        .catch((error) => {
          var payload = { error: error, way: 'Retrieve User Auth Token From Access Pass' };
          context.dispatch('handleError', payload);
        });
    });
  },
  handleError(context, payload) {
    if (payload.error.message === 'Network Error' && !payload.error.response) {
      console.log('USER HAS LOST INTERNET -- ' + payload.way);
      context.commit("setCurrentlyOnline", false);
      return;
    }
    let message = {
      html: 'Network Error.',
      icon: 'fa-warning',
      timeout: 2500,
    }   //Setup Error Message
    if (typeof error !== 'undefined') {
      if (error.hasOwnProperty('message')) {
        message.html = error.message

      }
    }

    if (typeof error.response !== 'undefined') {

      //Setup Generic Response Messages
      if (error.response.status === 401) {
        message.html = 'UnAuthorized'
      } else if (error.response.status === 404) {
        message.html = 'API Route is Missing or Undefined'
      } else if (error.response.status === 405) {
        message.html = 'API Route Method Not Allowed'
      } else if (error.response.status === 422) {
        //Validation Message
      } else if (error.response.status >= 500) {
        message.html = 'Server Error'
      }

      //Try to Use the Response Message
      if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
        if (error.response.data.hasOwnProperty('message') && error.response.data.message.length > 0) {
          message.html = error.response.data.message
        }
      }
    }

    //Toast the Message
    if (message.html.length > 0) {
      console.log(message);
      context.commit("setCurrentlyOnline", false);
      //this.setCurrentlyOnline(state,false);
      alert(message.html);
    }
  }


};

const mutations = {
  setAlldata: (state, alldata) => (state.alldata = alldata),
  setTastings: (state, tastings) => (state.tastings = tastings),



  // New search method results 
  setResultTables: (state, resultTables) => (state.resultTables = resultTables),
  setResultFilters: (state, resultFilters) => (state.resultFilters = resultFilters),
  setResultCount: (state, resultCount) => (state.resultCount = resultCount),
  mkTablesExpandedVu: (state, tablesExpandedVu) => (state.tablesExpandedVu = tablesExpandedVu),
  toggleModal: (state) => (state.showModal = !state.showModal),
  // start this isn't used yet...
  toggleModalNotice: (state) => (state.showModal = !state.showModalNotice),
  // end this isn't used yet...
  closeModal: (state) => (state.showModal = false),
  toggleModalTag(state, sku) { state.showModalTag = !state.showModalTag; state.listSku = sku; },
  closeModalTag: (state) => (state.showModalTag = false),
  toggleModalNote(state) { state.showModalNote = !state.showModalNote; },
  closeModalNote: (state) => (state.showModalNote = false),
  toggleModalListname(state) { state.showModalListname = !state.showModalListname; },
  closeModalListname: (state) => (state.showModalListname = false),
  // Live SKU Helpers
  setResults(state, res) {
    state.searchResults = res;
    window.localStorage.setItem("searchResults", JSON.stringify(res));
  },
  setLiveSku: (state, skudata) => (state.currentLiveSku = skudata),
  setlistSku: (state, sku) => (state.listSku = sku),
  setNoteSku: (state, sku) => (state.NoteSku = sku),
  setListnameEditDeets: (state, payload) => (state.listnameEditDeets = payload),
  // USER / LOGIN
  setNewToken: (state, token) => (state.token = token),
  setUserdata: (state, user) => (state.user = user),
  setShowLoading(state, value) {
    state.showLoading = value;
  },
  // USER / REGISTRATION
  setBusinessNameGroup: (state, custs) => (state.businessNameGroup = custs),
  setSalesRepGroup: (state, reps) => (state.salesRepGroup = reps),

  // CURRENT TASTING
  hideIntro(state) {
    state.sawIntro = true;
    window.localStorage.setItem("sawIntro", true);
  },
  hideTutorial(state) {
    state.sawTutorial = true;
    window.localStorage.setItem("sawTutorial", true);
  },
  setCurrentTasting(state, tastingInfo) {
    state.alldata = [];
    state.resultTables = [];
    localStorage.removeItem("alldata");
    localStorage.removeItem("resultTables");
    localStorage.removeItem("currentTasting"); // should be able to remove eventually, replaced by currentTastingDeets
    localStorage.removeItem("tasting_id");
    localStorage.removeItem("currentTastingDeets");
    localStorage.removeItem("tablesExpandedVu");
    localStorage.removeItem("setResultCount");
    localStorage.removeItem("setResultFilters");
    localStorage.removeItem("setResultTables");


    state.currentTasting = tastingInfo.tasting_id;
    state.currentTastingDeets = tastingInfo;
    state.sawIntro = false;
    window.localStorage.setItem("currentTastingDeets", JSON.stringify(tastingInfo));
    window.localStorage.setItem("tasting_id", tastingInfo.tasting_id);
    //console.log("setting current tasting to : " + tastingInfo.tasting_id);
  },
  clearAllData(state) {
    state.alldata = [];
    localStorage.removeItem("alldata");
    localStorage.removeItem("tablesExpandedVu");
    localStorage.removeItem("currentTastingDeets");
    localStorage.removeItem("last_tasting_viewed");
    localStorage.removeItem("tasting_id");
    localStorage.removeItem("pricing");
    localStorage.removeItem("user");
    localStorage.removeItem("access_token");
    localStorage.removeItem("setResultCount");
    localStorage.removeItem("setResultFilters");
    localStorage.removeItem("setResultTables");
  },
  SET_ITINERARY(state, data) {
    const token = window.localStorage.getItem("access_token");
    const tasting_id = window.localStorage.getItem("tasting_id");
    let itin = data.itin;
    return new Promise((resolve, reject) => {
      axios
        .post("https://www.skurnik.com/api/tastings/user-itin.php", {
          token: token,
          tasting_id: tasting_id,
          sku: data.sku,
          itin: itin,
        })
        .then((response) => {
          if (typeof response.data.success != "undefined") {
            //console.log(response);
            // ONLY STORING THIS RIGHT NOW IF WE HAVE A SUCCESSFUL RESPONSE MUST ENHANCE

            if (itin) {
              // set to true so we are adding to state.
              state.alldata.itinerary.skus.push(data.sku);
              // add our table to itinerary also.
              //state.alldata.itinerary.tables.push(data.tableno);
            } else {
              // set to false so we are removing from state
              const index = state.alldata.itinerary.skus.indexOf(data.sku);
              //console.log('looking for sku in itin.')
              if (index > -1) {
                state.alldata.itinerary.skus.splice(index, 1);
                //console.log ('found sku in itin, removin' + index);
              }
            }
            window.localStorage.setItem(
              "alldata",
              JSON.stringify(state.alldata)
            );
            resolve(response);
          } else {
            //console.log(response.error);
            reject(response.error);
          }
        })
        .catch((error) => {
          var payload = { error: error, way: 'Setting Itinerary' };
          context.dispatch('handleError', payload);
        });
    });
  },

  SET_SKU_RATING(state, data) {
    // Update notes if exists
    let notes = state.alldata.ratings[data.sku]?.n || "";

    // Update rating and notes
    state.alldata.ratings[data.sku] = { r: data.rating, n: notes };

    // Sort ratings
    let byThis = "r";
    let compare = (k, kk) => state.alldata.ratings[kk][byThis] - state.alldata.ratings[k][byThis];
    state.alldata.ratings = Object.keys(state.alldata.ratings)
      .sort(compare)
      .reduce((a, d) => ({ ...a, [d]: state.alldata.ratings[d] }), {});

    // Update uncategorized list
    const listUncat = 'Uncategorized';
    if (!Object.values(state.alldata.lists).flat().includes(data.sku)) {
      state.alldata.lists[listUncat] = state.alldata.lists[listUncat] || [];
      state.alldata.lists[listUncat].push(data.sku);
    }
  },



  deleteListMut(state, data) {

    const listname = data;

    const token = window.localStorage.getItem("access_token");
    const tasting_id = window.localStorage.getItem("tasting_id");

    // BEFORE WE REMOVE THE LIST, LETS MOVE THE SKUS TO UNCATGORIZED IN THE APP.
    var listuncat = 'Uncategorized'
    for (const sku in state.alldata.lists[listname]) {
      console.log("Moving Sku " + sku);
      if (typeof state.alldata.lists[listuncat] != 'undefined') {
        //state.alldata.lists.push(data);
        console.log("trying 1 to add " + state.alldata.lists[listname][sku])
        state.alldata.lists[listuncat].push(state.alldata.lists[listname][sku]);

      } else {
        console.log("trying 2 to add " + state.alldata.lists[listname][sku])
        state.alldata.lists[listuncat] = [state.alldata.lists[listname][sku]];
      }
    }

    //console.log('Ok, skus should now be in ' + listuncat);

    delete state.alldata.lists[listname];
    console.log('Removed ' + listname);
    window.localStorage.setItem("alldata", JSON.stringify(state.alldata));


    return new Promise((resolve, reject) => {
      axios
        .post("https://www.skurnik.com/api/tastings/user-list-delete.php", {
          token: token,
          tasting_id: tasting_id,
          listname: listname,

        })
        .then((response) => {
          if (typeof response.data.success != "undefined") {
            //console.log(response);
            resolve(response);
          } else {
            console.log(response.error);
            reject(response.error);
          }
        })
        .catch((error) => {
          var payload = { error: error, way: 'Delete User List' };
          context.dispatch('handleError', payload);
        });
    });
  },

  removeSkuFromListMut(state, data) {
    const listname = data.listname;
    const sku = data.sku;
    const token = window.localStorage.getItem("access_token");
    const tasting_id = window.localStorage.getItem("tasting_id");



    var index = state.alldata.lists[listname].indexOf(sku);
    if (index !== -1) {
      state.alldata.lists[listname].splice(index, 1);
    }



    window.localStorage.setItem("alldata", JSON.stringify(state.alldata));


    return new Promise((resolve, reject) => {
      axios
        .post("https://www.skurnik.com/api/tastings/user-list-sku-delete.php", {
          token: token,
          tasting_id: tasting_id,
          listname: listname,
          sku: sku,
        })
        .then((response) => {
          if (typeof response.data.success != "undefined") {
            console.log(response);
            resolve(response);
          } else {
            console.log(response.error);
            reject(response.error);
          }
        })
        .catch((error) => {
          var payload = { error: error, way: 'Remove Sku From List' };
          context.dispatch('handleError', payload);
        });
    });
  },





  saveListnameMut(state, data) {

    const newname = data.listname.replace(/['"]+/g, '');
    const previous = data.previous;
    const token = window.localStorage.getItem("access_token");
    const tasting_id = window.localStorage.getItem("tasting_id");
    if (newname == previous) {
      return;
    }
    // RENAME THE LIST...

    var found = false;
    for (const listno in state.alldata.lists) {
      //console.log(listno);
      if (listno == previous) {

        console.log('found old list, renaming');
        state.alldata.lists[newname] = state.alldata.lists[listno];
        delete state.alldata.lists[listno];
        found = true;
      }
    }
    if (found == false) {
      //console.log('could not find the old list....');
    }

    const ordered = Object.keys(state.alldata.lists).sort().reduce(
      (obj, key) => {
        obj[key] = state.alldata.lists[key];
        return obj;
      },
      {}
    );
    state.alldata.lists = ordered;


    window.localStorage.setItem("alldata", JSON.stringify(state.alldata));


    return new Promise((resolve, reject) => {
      axios
        .post("https://www.skurnik.com/api/tastings/user-list-change-listname.php", {
          token: token,
          tasting_id: tasting_id,
          listname: newname,
          previous: previous,
        })
        .then((response) => {
          if (typeof response.data.success != "undefined") {
            //console.log(response);
            resolve(response);
          } else {
            console.log(response.error);
            reject(response.error);
          }
        })
        .catch((error) => {
          var payload = { error: error, way: 'Change List Name' };
          context.dispatch('handleError', payload);
        });
    });
  },


  saveListInfoMut(state, data) {
    const sku = state.listSku;
    const list = data
    const token = window.localStorage.getItem("access_token");
    const tasting_id = window.localStorage.getItem("tasting_id");

    if (typeof state.alldata.lists[data] != 'undefined') {
      //state.alldata.lists.push(data);
      state.alldata.lists[data].push(sku);
    } else {
      state.alldata.lists[data] = [sku];
    }


    // make sure the sku is not on the uncategorized list
    for (const checksku in state.alldata.lists['Uncategorized']) {
      if (state.alldata.lists['Uncategorized'][checksku] == sku) {

        //console.log("Match " + state.alldata.lists['Uncategorized'][checksku] + ' -- ' + sku);
        state.alldata.lists['Uncategorized'].splice(checksku, 1);

      } else {
        //console.log("No match " + state.alldata.lists['Uncategorized'][checksku] + ' -- ' + sku);
      }
    }







    //console.log(state.alldata.lists);

    window.localStorage.setItem("alldata", JSON.stringify(state.alldata));
    return new Promise((resolve, reject) => {
      axios
        .post("https://www.skurnik.com/api/tastings/user-lists.php", {
          token: token,
          tasting_id: tasting_id,
          sku: sku,
          list: list,
        })
        .then((response) => {
          if (typeof response.data.success != "undefined") {
            //console.log(response);
            resolve(response);
          } else {
            // console.log(response.error);
            reject(response.error);
          }
        })
        .catch((error) => {
          var payload = { error: error, way: 'Set User List' };
          context.dispatch('handleError', payload);
        });
    });
  },

  saveNewListMut(state, data) {
    const sku = state.listSku;
    const list = data
    const token = window.localStorage.getItem("access_token");
    const tasting_id = window.localStorage.getItem("tasting_id");
    if (typeof state.alldata.lists != 'undefined') {
      //const newList = [list[sku] ]
      //console.log (data);
      const test = [list];
      state.alldata.lists[test] = [sku];


      //state.alldata.lists[data].push(sku);
    } else {
      state.alldata.lists[data] = [sku];
    }
    // make sure the sku is not on the uncategorized list
    for (const checksku in state.alldata.lists['Uncategorized']) {
      if (state.alldata.lists['Uncategorized'][checksku] == sku) {

        //console.log("Match " + state.alldata.lists['Uncategorized'][checksku] + ' -- ' + sku);
        state.alldata.lists['Uncategorized'].splice(checksku, 1);

      } else {
        //console.log("No match " + state.alldata.lists['Uncategorized'][checksku] + ' -- ' + sku);
      }
    }

    //console.log(state.alldata.lists);

    window.localStorage.setItem("alldata", JSON.stringify(state.alldata));
    return new Promise((resolve, reject) => {
      axios
        .post("https://www.skurnik.com/api/tastings/user-list-new.php", {
          token: token,
          tasting_id: tasting_id,
          sku: sku,
          list: list,
        })
        .then((response) => {
          if (typeof response.data.success != "undefined") {
            //console.log(response);
            resolve(response);
          } else {
            //console.log(response.error);
            reject(response.error);
          }
        })
        .catch((error) => {
          var payload = { error: error, way: 'Create User List' };
          context.dispatch('handleError', payload);
        });
    });
  },

  retrieveToken(state, token) {
    state.user.token = token;
  },
  retrieveState(state, add) {
    state.user.state = add;
  },
  retrieveEmail(state, add) {
    state.user.email = add;
  },
  retrieveRole(state, add) {
    state.user.role = add;
  },
  setCurrentlyOnline(state, value) {
    state.currentlyOnline = value;
  },

  setCurrentlyOnlineClosed(state, value) {
    state.currentlyOnline = true;
  },

  destroyToken(state) {
    state.token = null;
    state.user.token = null;
  },

  // ---------------------------------------------------
  // ---------------------------------------------------
  //   START UPDATED MUTATIONS 2024 --------------
  // ---------------------------------------------------


  updateRatings(state, data) {
    let rating = '0';
    const sku = data.sku;
    if (state.alldata.ratings[sku]?.r !== undefined) {
      rating = state.alldata.ratings[sku].r;
    }
    state.alldata.ratings[sku] = { r: rating, n: data.note };
  },

  updateLists(state, data) {
    const listUncat = 'Uncategorized';
    let found = false;

    for (const listno in state.alldata.lists) {
      if (state.alldata.lists[listno] !== listUncat) {
        if (Object.values(state.alldata.lists[listno]).includes(data.sku)) {
          found = true;
          break;
        }
      }
    }

    if (!found) {
      if (state.alldata.lists[listUncat] !== undefined) {
        state.alldata.lists[listUncat].push(data.sku);
      } else {
        state.alldata.lists[listUncat] = [data.sku];
      }
    }
  },



  // ---------------------------------------------------
  // ---------------------------------------------------
  //   END UPDATED MUTATIONS 2024 --------------
  // ---------------------------------------------------
  // ---------------------------------------------------





};

export default {
  state,
  getters,
  actions,
  mutations,
};
