<template>
  <div class="map" style="padding-top: 30px">
    <div id="map" class="map_page text-center">
      <map108 v-if="currentTasting == 108" />
      <map109 v-if="currentTasting == 109" />
      <map110 v-if="currentTasting == 110" />
      <map111 v-if="currentTasting == 111" />
      <map119 v-if="currentTasting == 119" />
      <map121 v-if="currentTasting == 121" />
      <map122 v-if="currentTasting == 122" />
      <map145 v-if="currentTasting == 145" />
      <map153 v-if="currentTasting == 153" />
      <map154 v-if="currentTasting == 154" />
    </div>
  </div>
</template>

<script>
//import SvgPanZoom from 'vue-svg-pan-zoom';
import { useRoute } from 'vue-router';
import { mapGetters, mapActions } from 'vuex';

//import map41 from '@/components/maps/map-41.vue';
//import map42 from '@/components/maps/map-42.vue';
//import map46 from '@/components/maps/map-46.vue';

//import map59 from '@/components/maps/map-59.vue';
//import map60 from '@/components/maps/map-60.vue';
//import map56 from '@/components/maps/map-56.vue';
//import map74 from '@/components/maps/map-74.vue';
//import map95 from '@/components/maps/map-95.vue';
//import map99 from '@/components/maps/map-99.vue';
//import map100 from '@/components/maps/map-100.vue';

import map108 from '@/components/maps/map-108.vue';
import map109 from '@/components/maps/map-109.vue';
import map110 from '@/components/maps/map-110.vue';
import map111 from '@/components/maps/map-111.vue';
import map119 from '@/components/maps/map-119.vue';
import map121 from '@/components/maps/map-121.vue';
import map122 from '@/components/maps/map-122.vue';
import map145 from '@/components/maps/map-145.vue';
import map153 from '@/components/maps/map-153.vue';
import map154 from '@/components/maps/map-154.vue';
export default {
  name: 'Map',
  data() {
    return {
      //mySVG: require('@/assets/ex_map.svg'),
      tasting: 42,
    };
  },
  components: {
    // ##003: declare the component
    //map41,
    //map42,
    //map46,
    //map59,
    //map60,
    //map56,
    // map74,
    //map95,
    //map99,
    // map100,

    map108,
    map109,
    map110,
    map111,
    map119,
    map121,
    map122,
    map145,
    map153,
    map154,
  },
  methods: {
    ...mapActions(['setTablesExpandedVu', 'setTableOpen', 'setTablesClosed']),
  },
  computed: {
    ...mapGetters(['getTablesExpandedVu']),
  },
  created() {
    this.tasting = localStorage.getItem('tasting_id');
    this.currentTasting = this.tasting;
    //console.log(this.currentTasting)
  },
  mounted() {
    this.$loadScript('https://unpkg.com/panzoom@9.4.0/dist/panzoom.min.js')
      .then(() => {
        // Script is loaded, do something
        // grab the DOM SVG element that you want to be draggable/zoomable:

        var element = document.getElementById('mapsvg' + this.currentTasting);

        // and forward it it to panzoom.
        panzoom(element, {
          maxZoom: 5.5,
          minZoom: 0.8,
          initialZoom: 0.9,

          //transformOrigin: { x: 0.5, y: 0.5 },
          onTouch: function (e) {
            // `e` - is current touch event.
            //console.log(e);
            return false; // tells the library to not preventDefault.
          },
        });
        //console.log('loaded.');
      })
      .then(() => {
        let counter = 1;
        var vm = this;
        while (counter <= 244) {
          //console.log (counter);
          if (document.getElementById('TABLE-' + counter) !== null) {
            document.getElementById('TABLE-' + counter).addEventListener('click', function (event) {
              var destination = '/browse' + '#tb' + this.id.substring(6);
              //alert('clicked ' + this);
              //console.log('Browse to table ' + destination);
              //this.gotoTable();
              vm.setTablesClosed();
              vm.setTableOpen(this.id.substring(6));
              vm.$router.push({ path: '/browse', hash: '#tb' + this.id.substring(6) });
              //event.stopPropagation();
              //event.preventDefault();
            });

            document.getElementById('TABLE-' + counter).addEventListener('touchstart', function (event) {
              var destination = '/browse' + '#tb' + this.id.substring(6);
              //alert('clicked ' + this);
              //console.log('Browse to table ' + destination);
              //this.gotoTable();
              vm.setTablesClosed();
              vm.setTableOpen(this.id.substring(6));
              vm.$router.push({ path: '/browse', hash: '#tb' + this.id.substring(6) });
              //event.stopPropagation();
              //event.preventDefault();
            });
          } else {
            //console.log(counter +' not found ')
          }
          counter = counter + 1;
        }
      });
  },
};
</script>
<style scoped>
.content_area {
  background-color: #000 !important;
}
.map_page svg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 30px;
  left: 30px;
}
body {
  background-color: #000 !important;
}
</style>
