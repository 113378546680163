<template>
  <div id="top" class="browse page">
    <TastingHeader v-bind:tastingInfo="currentTastingDeets" />
    <div class="container browse_content_area">
      <div class="spacer"></div>
      <div v-if="currentTastingDeets.tastingSize != 'large'">
        <div class="form-row">
          <div class="col-10">
            <input type="text" class="form-control" placeholder="Search All Skus  ..." REMOVEv-model="search" @keyup.enter="searchSkus" />
          </div>
          <div class="col-2">
            <button type="submit" class="btn btn-primary" @click="searchSkus">Search</button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12"><h1>Results:</h1></div>
        <div class="col-12 table_block">
          <div v-for="(info, sku) in searchResultsArray.skus" :key="sku">
            <div class="sku_block">
              <SkuPreviewSearch v-bind:sku="sku" v-bind:data="info" v-bind:showDetail="false" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import Tables from '@/components/Tables.vue';
import { useRoute } from 'vue-router';
import TastingHeader from '@/components/TastingHeader.vue';
import SkuPreviewSearch from '@/components/SkuPreviewSearch.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'Results',
  components: {
    TastingHeader,
    SkuPreviewSearch,
  },
  props: {
    search: String,
  },
  data() {
    return {
      //resultTables: [],
      route: useRoute(),
      results: [],
    };
  },
  methods: {
    ...mapActions(['fetchAlldata', 'fetchUser', 'getResults']),

    searchSkus() {
      //alert(this.search);
      this.getResults(this.search);
    },
    startSearch() {
      //alert(this.search);
      this.getResults(this.route.params.search);
    },
  },
  computed: {
    ...mapGetters(['user', 'currentTasting', 'currentTastingDeets', 'searchResults']),
    u() {
      return this.$store.getters.user;
    },
    searchResultsArray() {
      return this.$store.getters.searchResults;
    },
  },
  created() {
    this.startSearch();
    //this.search = this.route.params.search;
    //this.searchSkus();
  },
};
</script>
