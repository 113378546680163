<template>
  <svg id="mapsvg153" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1400 2357.77">
    <g id="VENUE">
      <rect class="cls-8" x="1390.93" y="1245.3" width="5.3" height="2.64" transform="translate(-190.69 253.54) rotate(-9.73)" />
      <path class="cls-3" d="M1396.08,1247.24l-4.65.8-.35-2.03,4.65-.8.35,2.03M1396.75,1247.71l-.55-3.18-5.79,1,.55,3.18,5.79-1h0Z" />
      <rect class="cls-8" x="1391.84" y="1242.72" width="2.6" height="2.6" transform="translate(-191.26 255.03) rotate(-9.79)" />
      <path class="cls-3" d="M1394.31,1244.84l-1.99.34-.34-1.99,1.99-.34.34,1.99M1394.98,1245.32l-.54-3.14-3.14.54.54,3.14,3.14-.54h0Z" />
      <rect class="cls-8" x="1392.55" y="1264.78" width="2.64" height="5.3" transform="translate(-63.53 2461.26) rotate(-81.6)" />
      <path class="cls-3" d="M1396.35,1266.75l-.3,2.04-4.66-.69.3-2.04,4.66.69M1397.01,1266.26l-5.81-.86-.47,3.19,5.81.86.47-3.19h0Z" />
      <rect class="cls-8" x="1392.2" y="1268.74" width="2.6" height="2.6" transform="translate(-66.89 2462.56) rotate(-81.58)" />
      <path class="cls-3" d="M1394.65,1269.19l-.29,2-2-.29.29-2,2,.29M1395.31,1268.7l-3.15-.46-.46,3.15,3.15.46.46-3.15h0Z" />
      <polygon class="cls-4" points="1396.75 1247.71 1390.96 1248.71 1391.2 1265.4 1397.01 1266.26 1396.75 1247.71" />
      <g>
        <rect class="cls-8" x="1243.19" y="978.81" width="5.25" height="73.12" transform="translate(-250.17 1602.79) rotate(-60.62)" />
        <path
          class="cls-3"
          d="M1278.56,1031.13l-2.29,4.07-63.21-35.59,2.29-4.07,63.21,35.59M1279.35,1030.91l-64.22-36.16-2.86,5.08,64.22,36.16,2.86-5.08h0Z"
        />
      </g>
      <g>
        <rect class="cls-8" x="1245.77" y="974.23" width="5.26" height="73.12" transform="translate(-244.87 1602.68) rotate(-60.62)" />
        <path
          class="cls-3"
          d="M1281.15,1026.55l-2.29,4.07-63.21-35.59,2.29-4.07,63.21,35.59M1281.94,1026.33l-64.22-36.16-2.86,5.08,64.22,36.16,2.86-5.08h0Z"
        />
      </g>
      <path class="cls-4" d="M1142.89,971.94c26.95,3.21,51.86,11.88,75.76,21.77l-2.89,5.12c-23.39-9.62-46.8-17.93-72.88-21.04v-5.85Z" />
      <path
        class="cls-3"
        d="M598.58,921.45v-2.6c0-.15.12-.28.28-.28,1.29,0,2.34-1.05,2.34-2.35v-115.6c0-1.28-1.05-2.32-2.34-2.32-.15,0-.28-.12-.28-.28v-2.62c0-.15.12-.28.28-.28,3.03,0,5.5,2.47,5.5,5.5v115.6c0,3.03-2.47,5.5-5.5,5.5-.15,0-.28-.12-.28-.28ZM599.14,919.12v2.05c2.6-.15,4.67-2.3,4.67-4.94v-115.6c0-2.63-2.07-4.79-4.67-4.94v2.07c1.47.14,2.62,1.37,2.62,2.87v115.6c0,1.51-1.15,2.75-2.62,2.89ZM679.58,916.23v-115.6c0-3.03,2.47-5.5,5.5-5.5.15,0,.28.12.28.28v2.62c0,.15-.12.28-.28.28-1.29,0-2.34,1.04-2.34,2.32v115.6c0,1.29,1.05,2.35,2.34,2.35.15,0,.28.12.28.28v2.6c0,.15-.12.28-.28.28-3.03,0-5.5-2.47-5.5-5.5ZM680.14,865.61v50.62c0,2.63,2.07,4.79,4.67,4.94v-2.05c-1.47-.14-2.62-1.38-2.62-2.89v-115.6c0-1.49,1.15-2.73,2.62-2.87v-2.07c-2.6.15-4.67,2.3-4.67,4.94v64.98Z"
      />
      <path
        class="cls-7"
        d="M685.09,897.63v13.38h-86.22v-13.38h86.22ZM685.09,884.23v13.4h-86.22v-13.4h86.22ZM685.09,870.83v13.4h-86.22v-13.4h86.22ZM685.09,857.45v13.38h-86.22v-13.38h86.22ZM685.09,844.05v13.4h-86.22v-13.4h86.22ZM685.09,830.68v13.38h-86.22v-13.38h86.22Z"
      />
      <path
        class="cls-3"
        d="M598.58,911v-80.33c0-.15.12-.28.28-.28h86.22c.15,0,.28.12.28.28v80.33c0,.15-.12.28-.28.28h-86.22c-.15,0-.28-.12-.28-.28ZM599.14,897.91v12.82h85.67v-12.82h-85.67ZM599.14,884.51v12.84h85.67v-12.84h-85.67ZM599.14,871.11v12.84h85.67v-12.84h-85.67ZM599.14,857.73v12.82h85.67v-12.82h-85.67ZM599.14,844.33v12.84h85.67v-12.84h-85.67ZM599.14,830.96v12.82h85.67v-12.82h-85.67Z"
      />
      <path class="cls-3" d="M1098.93,1839.32h-4.66v-195.81h4.66v195.81M1099.51,1839.9v-196.97h-5.82v196.97h5.82Z" />
      <path class="cls-3" d="M1093.71,1839.32h-4.69v-195.81h4.69v195.81M1094.29,1839.9v-196.97h-5.85v196.97h5.85Z" />
      <path class="cls-3" d="M1070.55,2357.19h-129.49v-4.69h129.49v4.69M1071.13,2357.77v-5.85h-130.65v5.85h130.65Z" />
      <path class="cls-3" d="M1070.55,2351.97h-129.49v-4.69h129.49v4.69M1071.13,2352.55v-5.85h-130.65v5.85h130.65Z" />
      <g>
        <rect class="cls-8" x="943.37" y="2349.58" width="2.6" height="5.3" />
        <path class="cls-3" d="M945.69,2354.58h-2.02v-4.72h2.02v4.72M946.27,2355.16v-5.88h-3.18v5.88h3.18Z" />
      </g>
      <g>
        <rect class="cls-8" x="945.99" y="2350.93" width="2.6" height="2.6" />
        <path class="cls-3" d="M948.3,2353.25h-2.02v-2.02h2.02v2.02M948.88,2353.83v-3.18h-3.18v3.18h3.18Z" />
      </g>
      <g>
        <rect class="cls-8" x="1065.64" y="2349.58" width="2.6" height="5.32" />
        <path class="cls-3" d="M1067.95,2354.61h-2.02v-4.74h2.02v4.74M1068.53,2355.19v-5.9h-3.18v5.9h3.18Z" />
      </g>
      <g>
        <rect class="cls-8" x="1063.02" y="2350.94" width="2.6" height="2.6" />
        <path class="cls-3" d="M1065.33,2353.25h-2.02v-2.02h2.02v2.02M1065.91,2353.83v-3.18h-3.18v3.18h3.18Z" />
      </g>
      <path class="cls-3" d="M777.86,2357.18h-129.49v-4.69h129.49v4.69M778.44,2357.76v-5.85h-130.65v5.85h130.65Z" />
      <path class="cls-3" d="M777.86,2351.95h-129.49v-4.69h129.49v4.69M778.44,2352.53v-5.85h-130.65v5.85h130.65Z" />
      <g>
        <rect class="cls-8" x="115.23" y="1598.55" width="5.27" height="52.23" />
        <path class="cls-3" d="M120.21,1650.49h-4.69v-51.65h4.69v51.65M120.79,1651.07v-52.81h-5.85v52.81h5.85Z" />
      </g>
      <g>
        <rect class="cls-8" x="120.48" y="1598.55" width="5.23" height="52.23" />
        <path class="cls-3" d="M125.42,1650.49h-4.65v-51.65h4.65v51.65M126,1651.07v-52.81h-5.81v52.81h5.81Z" />
      </g>
      <g>
        <rect class="cls-8" x="115.21" y="1500.56" width="5.27" height="78.35" />
        <path class="cls-3" d="M120.19,1578.63h-4.69v-77.77h4.69v77.77M120.77,1579.21v-78.93h-5.85v78.93h5.85Z" />
      </g>
      <g>
        <rect class="cls-8" x="120.47" y="1500.56" width="5.23" height="78.35" />
        <path class="cls-3" d="M125.41,1578.63h-4.65v-77.77h4.65v77.77M125.99,1579.21v-78.93h-5.81v78.93h5.81Z" />
      </g>
      <g>
        <rect class="cls-8" x="115.21" y="1418.29" width="5.27" height="77.02" />
        <path class="cls-3" d="M120.19,1495.03h-4.69v-76.44h4.69v76.44M120.77,1495.61v-77.6h-5.85v77.6h5.85Z" />
      </g>
      <g>
        <rect class="cls-8" x="120.47" y="1418.29" width="5.23" height="77.02" />
        <path class="cls-3" d="M125.41,1495.03h-4.65v-76.44h4.65v76.44M125.99,1495.61v-77.6h-5.81v77.6h5.81Z" />
      </g>
      <g>
        <rect class="cls-8" x="52.54" y="1410.41" width="60.08" height="5.27" />
        <path class="cls-3" d="M112.32,1415.39h-59.5v-4.69h59.5v4.69M112.9,1415.97v-5.85h-60.66v5.85h60.66Z" />
      </g>
      <g>
        <rect class="cls-8" x="52.54" y="1415.64" width="60.08" height="5.25" />
        <path class="cls-3" d="M112.32,1420.6h-59.5v-4.67h59.5v4.67M112.9,1421.18v-5.83h-60.66v5.83h60.66Z" />
      </g>
      <g>
        <rect class="cls-8" x="107.41" y="1413.02" width="2.45" height="5.23" />
        <path class="cls-3" d="M109.57,1417.96h-1.87v-4.64h1.87v4.64M110.15,1418.54v-5.81h-3.03v5.81h3.03Z" />
      </g>
      <g>
        <rect class="cls-8" x="104.79" y="1414.35" width="2.6" height="2.6" />
        <path class="cls-3" d="M107.1,1416.66h-2.02v-2.02h2.02v2.02M107.68,1417.24v-3.18h-3.18v3.18h3.18Z" />
      </g>
      <g>
        <rect class="cls-8" x="55.14" y="1413.04" width="2.6" height="5.23" />
        <path class="cls-3" d="M57.45,1417.97h-2.02v-4.64h2.02v4.64M58.03,1418.55v-5.81h-3.18v5.81h3.18Z" />
      </g>
      <g>
        <rect class="cls-8" x="57.76" y="1414.34" width="2.6" height="2.6" />
        <path class="cls-3" d="M60.07,1416.65h-2.02v-2.02h2.02v2.02M60.65,1417.23v-3.18h-3.18v3.18h3.18Z" />
      </g>
      <g>
        <rect class="cls-8" x="49.94" y="1300.7" width="60.08" height="5.27" />
        <path class="cls-3" d="M109.73,1305.67h-59.5v-4.69h59.5v4.69M110.31,1306.25v-5.85h-60.66v5.85h60.66Z" />
      </g>
      <g>
        <rect class="cls-8" x="49.94" y="1305.93" width="60.08" height="5.25" />
        <path class="cls-3" d="M109.73,1310.89h-59.5v-4.67h59.5v4.67M110.31,1311.47v-5.83h-60.66v5.83h60.66Z" />
      </g>
      <g>
        <rect class="cls-8" x="120.43" y="1223.64" width="5.27" height="78.35" />
        <path class="cls-3" d="M125.41,1301.7h-4.69v-77.77h4.69v77.77M125.99,1302.28v-78.93h-5.85v78.93h5.85Z" />
      </g>
      <g>
        <rect class="cls-8" x="115.21" y="1223.64" width="5.25" height="78.35" />
        <path class="cls-3" d="M120.17,1301.7h-4.67v-77.77h4.67v77.77M120.75,1302.28v-78.93h-5.83v78.93h5.83Z" />
      </g>
      <g>
        <rect class="cls-8" x="117.84" y="1296.79" width="5.23" height="2.77" />
        <path class="cls-3" d="M122.78,1299.27h-4.64v-2.19h4.64v2.19M123.36,1299.85v-3.35h-5.81v3.35h5.81Z" />
      </g>
      <g>
        <rect class="cls-8" x="119.17" y="1294.19" width="2.6" height="2.6" />
        <path class="cls-3" d="M121.48,1296.5h-2.02v-2.02h2.02v2.02M122.06,1297.08v-3.18h-3.18v3.18h3.18Z" />
      </g>
      <g>
        <rect class="cls-8" x="120.44" y="1140.04" width="5.27" height="78.35" />
        <path class="cls-3" d="M125.42,1218.1h-4.69v-77.77h4.69v77.77M126,1218.68v-78.93h-5.85v78.93h5.85Z" />
      </g>
      <g>
        <rect class="cls-8" x="115.23" y="1140.04" width="5.25" height="78.35" />
        <path class="cls-3" d="M120.19,1218.1h-4.67v-77.77h4.67v77.77M120.77,1218.68v-78.93h-5.83v78.93h5.83Z" />
      </g>
      <path class="cls-3" d="M1118.97,747.43h-4.65v-77.31h4.65v77.31M1119.56,748.01v-78.47h-5.81v78.47h5.81Z" />
      <path class="cls-3" d="M1113.76,747.43h-4.62v-77.31h4.62v77.31M1114.34,748.01v-78.47h-5.78v78.47h5.78Z" />
      <path class="cls-3" d="M1118.97,968.81h-4.62v-55.41h4.62v55.41M1119.55,969.39v-56.57h-5.78v56.57h5.78Z" />
      <path class="cls-3" d="M1113.75,968.81h-4.62v-55.41h4.62v55.41M1114.33,969.39v-56.57h-5.78v56.57h5.78Z" />
      <path class="cls-3" d="M1109.27,54.94h-286.77v-4.62h286.77v4.62M1109.85,55.52v-5.78h-287.93v5.78h287.93Z" />
      <path class="cls-3" d="M1109.27,49.71h-286.77v-4.62h286.77v4.62M1109.85,50.3v-5.78h-287.93v5.78h287.93Z" />
      <g>
        <rect class="cls-8" x="119.17" y="1210.57" width="2.62" height="20.89" />
        <path class="cls-3" d="M121.5,1231.17h-2.04v-20.31h2.04v20.31M122.08,1231.75v-21.47h-3.2v21.47h3.2Z" />
      </g>
      <g>
        <rect class="cls-8" x="117.82" y="1213.19" width="5.27" height="15.65" />
        <path class="cls-3" d="M122.8,1228.55h-4.69v-15.07h4.69v15.07M123.38,1229.13v-16.23h-5.85v16.23h5.85Z" />
      </g>
      <rect class="cls-3" x="1103.94" y="357.98" width="20.16" height="5.83" transform="translate(1474.9 -753.13) rotate(90)" />
      <path class="cls-3" d="M1118.95,353.45h-4.62V70.56h4.62v282.88M1119.53,354.03V69.98h-5.78v284.04h5.78Z" />
      <path class="cls-3" d="M1113.74,353.45h-4.62V70.56h4.62v282.88M1114.32,354.03V69.98h-5.78v284.04h5.78Z" />
      <g>
        <rect class="cls-8" x="1112.75" y="345.88" width="2.6" height="2.6" />
        <path class="cls-3" d="M1115.06,348.19h-2.02v-2.02h2.02v2.02M1115.64,348.77v-3.18h-3.18v3.18h3.18Z" />
      </g>
      <g>
        <rect class="cls-8" x="1111.39" y="348.49" width="5.25" height="2.6" />
        <path class="cls-3" d="M1116.35,350.8h-4.67v-2.02h4.67v2.02M1116.93,351.38v-3.18h-5.83v3.18h5.83Z" />
      </g>
      <path class="cls-3" d="M1118.93,655.16h-4.62v-286.77h4.62v286.77M1119.51,655.74v-287.93h-5.78v287.93h5.78Z" />
      <path class="cls-3" d="M1113.72,655.16h-4.62v-286.77h4.62v286.77M1114.3,655.74v-287.93h-5.78v287.93h5.78Z" />
      <g>
        <rect class="cls-8" x="1112.75" y="373.31" width="2.6" height="2.6" />
        <path class="cls-3" d="M1115.06,375.63h-2.02v-2.02h2.02v2.02M1115.64,376.21v-3.18h-3.18v3.18h3.18Z" />
      </g>
      <g>
        <rect class="cls-8" x="1111.41" y="370.7" width="5.25" height="2.6" />
        <path class="cls-3" d="M1116.37,373.01h-4.67v-2.02h4.67v2.02M1116.95,373.59v-3.18h-5.83v3.18h5.83Z" />
      </g>
      <g>
        <rect class="cls-8" x="1112.74" y="647.63" width="2.6" height="2.6" />
        <path class="cls-3" d="M1115.05,649.95h-2.02v-2.02h2.02v2.02M1115.63,650.53v-3.18h-3.18v3.18h3.18Z" />
      </g>
      <g>
        <rect class="cls-8" x="1111.42" y="650.24" width="5.25" height="2.6" />
        <path class="cls-3" d="M1116.38,652.56h-4.67v-2.02h4.67v2.02M1116.96,653.14v-3.18h-5.83v3.18h5.83Z" />
      </g>
      <g>
        <rect class="cls-8" x="1112.75" y="675.05" width="2.6" height="2.6" />
        <path class="cls-3" d="M1115.07,677.36h-2.02v-2.02h2.02v2.02M1115.65,677.94v-3.18h-3.18v3.18h3.18Z" />
      </g>
      <g>
        <rect class="cls-8" x="1111.42" y="672.43" width="5.25" height="2.6" />
        <path class="cls-3" d="M1116.38,674.75h-4.67v-2.02h4.67v2.02M1116.96,675.33v-3.18h-5.83v3.18h5.83Z" />
      </g>
      <rect class="cls-3" x="1103.95" y="659.73" width="20.16" height="5.83" transform="translate(1776.67 -451.39) rotate(90)" />
      <g>
        <rect class="cls-8" x="1112.71" y="75.49" width="2.6" height="2.6" />
        <path class="cls-3" d="M1115.03,77.8h-2.02v-2.02h2.02v2.02M1115.61,78.38v-3.18h-3.18v3.18h3.18Z" />
      </g>
      <g>
        <rect class="cls-8" x="1111.42" y="72.88" width="5.25" height="2.6" />
        <path class="cls-3" d="M1116.38,75.19h-4.67v-2.02h4.67v2.02M1116.96,75.77v-3.18h-5.83v3.18h5.83Z" />
      </g>
      <g>
        <rect class="cls-8" x="1101.74" y="48.68" width="2.6" height="2.6" />
        <path class="cls-3" d="M1104.05,50.99h-2.02v-2.02h2.02v2.02M1104.63,51.57v-3.18h-3.18v3.18h3.18Z" />
      </g>
      <g>
        <rect class="cls-8" x="1104.35" y="47.39" width="2.6" height="5.25" />
        <path class="cls-3" d="M1106.66,52.35h-2.02v-4.67h2.02v4.67M1107.24,52.93v-5.83h-3.18v5.83h3.18Z" />
      </g>
      <rect class="cls-3" x="1101.08" y="57.18" width="25.94" height="5.83" transform="translate(1174.14 -1053.95) rotate(90)" />
      <rect class="cls-3" x="1106.67" y="47.1" width="10.26" height="5.83" transform="translate(2223.6 100.03) rotate(180)" />
      <g>
        <rect class="cls-8" x="827.42" y="48.71" width="2.6" height="2.6" />
        <path class="cls-3" d="M829.73,51.03h-2.02v-2.02h2.02v2.02M830.31,51.61v-3.18h-3.18v3.18h3.18Z" />
      </g>
      <g>
        <rect class="cls-8" x="824.81" y="47.36" width="2.6" height="5.25" />
        <path class="cls-3" d="M827.12,52.32h-2.02v-4.67h2.02v4.67M827.7,52.9v-5.83h-3.18v5.83h3.18Z" />
      </g>
      <g>
        <polygon
          class="cls-7"
          points="774.67 49.37 775.32 49.37 775.32 47.39 777.92 47.39 777.92 52.64 775.31 52.64 775.31 51.31 774.67 51.31 774.67 49.37"
        />
        <path
          class="cls-3"
          d="M777.63,52.35h-2.02v-1.33h-.65v-1.36h.65v-1.98h2.02v4.67M778.21,52.93v-5.83h-3.18v1.98h-.65v2.52h.65v1.33h3.18Z"
        />
      </g>
      <rect class="cls-3" x="798.46" y="26.32" width="5.81" height="47.42" transform="translate(851.39 -751.33) rotate(90)" />
      <rect class="cls-3" x="693.98" y="21.07" width="5.81" height="57.92" transform="translate(746.91 -646.85) rotate(90)" />
      <g>
        <polygon
          class="cls-7"
          points="725.58 47.39 728.18 47.39 728.18 49.37 728.83 49.37 728.83 51.31 728.18 51.31 728.18 52.64 725.57 52.64 725.58 47.39"
        />
        <path
          class="cls-3"
          d="M728.54,51.02h-.65v1.33h-2.02v-4.67h2.02v1.98h.65v1.36M729.12,51.6v-2.52h-.65v-1.98h-3.18v5.83h3.18v-1.33h.65Z"
        />
      </g>
      <g>
        <polygon
          class="cls-7"
          points="664.94 49.38 665.59 49.38 665.59 47.4 668.19 47.4 668.19 52.65 665.59 52.65 665.59 51.32 664.94 51.32 664.94 49.38"
        />
        <path
          class="cls-3"
          d="M667.9,52.36h-2.02v-1.33h-.65v-1.36h.65v-1.98h2.02v4.67M668.48,52.94v-5.83h-3.18v1.98h-.65v2.52h.65v1.33h3.18Z"
        />
      </g>
      <rect class="cls-3" x="586.72" y="255.32" width="5.81" height="19.5" transform="translate(854.7 -324.55) rotate(90)" />
      <rect class="cls-3" x="601.95" y="39.03" width="5.81" height="21.97" transform="translate(654.87 -554.84) rotate(90)" />
      <g>
        <polygon
          class="cls-7"
          points="576.89 264.43 577.53 264.43 577.53 262.45 580.14 262.45 580.14 267.7 577.53 267.7 577.53 266.37 576.89 266.37 576.89 264.43"
        />
        <path
          class="cls-3"
          d="M579.85,267.41h-2.02v-1.33h-.65v-1.36h.65v-1.98h2.02v4.67M580.43,267.99v-5.83h-3.18v1.98h-.65v2.52h.65v1.33h3.18Z"
        />
      </g>
      <g>
        <polygon
          class="cls-7"
          points="615.85 47.39 618.45 47.39 618.45 49.37 619.1 49.37 619.1 51.31 618.45 51.31 618.45 52.64 615.85 52.64 615.85 47.39"
        />
        <path
          class="cls-3"
          d="M618.81,51.02h-.65v1.33h-2.02v-4.67h2.02v1.98h.58s.07,0,.07,0v1.36M619.39,51.6v-2.52h-.65s0-1.98,0-1.98h-3.18v5.83h3.18v-1.33h.65Z"
        />
      </g>
      <rect class="cls-3" x="593.86" y="47.07" width="5.81" height="220.95" />
      <g>
        <rect class="cls-8" x="530.55" y="262.45" width="47.01" height="1.95" />
        <path class="cls-3" d="M577.27,264.11h-46.43v-1.37h46.43v1.37M577.86,264.69v-2.53h-47.59v2.53h47.59Z" />
      </g>
      <g>
        <polygon
          class="cls-7"
          points="527.93 262.44 530.53 262.44 530.53 264.42 531.18 264.42 531.18 266.36 530.53 266.36 530.53 267.69 527.93 267.69 527.93 262.44"
        />
        <path
          class="cls-3"
          d="M530.89,266.07h-.65v1.33h-2.02v-4.67h2.02v1.98h.65v1.36M531.47,266.65v-2.52h-.65v-1.98h-3.18v5.83h3.18v-1.33h.65Z"
        />
      </g>
      <rect class="cls-3" x="516.42" y="256.45" width="5.81" height="17.23" transform="translate(784.39 -254.26) rotate(90)" />
      <rect class="cls-3" x="510.7" y="266.37" width="5.81" height="192.35" />
      <rect class="cls-3" x="515.6" y="448.28" width="5.81" height="15.03" transform="translate(62.71 974.29) rotate(-90)" />
      <rect class="cls-3" x="587.16" y="446.18" width="5.81" height="19.22" transform="translate(134.27 1045.85) rotate(-90)" />
      <rect class="cls-3" x="593.87" y="454.26" width="5.81" height="523.48" transform="translate(1193.54 1432.01) rotate(-180)" />
      <g>
        <polygon
          class="cls-7"
          points="576.91 455.14 577.55 455.14 577.55 453.16 580.16 453.16 580.16 458.41 577.55 458.41 577.55 457.08 576.91 457.08 576.91 455.14"
        />
        <path
          class="cls-3"
          d="M579.87,458.12h-2.02v-1.33h-.65v-1.36h.65v-1.98h2.02v4.67M580.45,458.7v-5.83h-3.18v1.98h-.65v2.52h.65v1.33h3.18Z"
        />
      </g>
      <g>
        <rect class="cls-8" x="528.63" y="453.17" width="48.93" height="1.95" />
        <path class="cls-3" d="M577.27,454.83h-48.35v-1.37h48.35v1.37M577.85,455.41v-2.53h-49.51v2.53h49.51Z" />
      </g>
      <g>
        <polygon
          class="cls-7"
          points="526.02 453.16 528.63 453.16 528.63 455.14 529.27 455.14 529.27 457.08 528.63 457.08 528.63 458.41 526.02 458.41 526.02 453.16"
        />
        <path
          class="cls-3"
          d="M528.99,456.79h-.65v1.33h-2.02v-4.67h2.02v1.98h.65v1.36M529.57,457.37v-2.52h-.65v-1.98h-3.18v5.83h3.18v-1.33h.65Z"
        />
      </g>
      <rect class="cls-3" x="1028.46" y="827.5" width="171.16" height="5.83" transform="translate(283.62 1944.46) rotate(-90)" />
      <rect class="cls-3" x="1108.63" y="968.98" width="10.83" height="5.83" transform="translate(142.14 2085.93) rotate(-90)" />
      <g>
        <rect class="cls-8" x="1112.7" y="739.89" width="2.6" height="2.6" />
        <path class="cls-3" d="M1115.02,742.2h-2.02v-2.02h2.02v2.02M1115.6,742.78v-3.18h-3.18v3.18h3.18Z" />
      </g>
      <g>
        <rect class="cls-8" x="1111.39" y="742.5" width="5.25" height="2.6" />
        <path class="cls-3" d="M1116.35,744.82h-4.67v-2.02h4.67v2.02M1116.93,745.4v-3.18h-5.83v3.18h5.83Z" />
      </g>
      <g>
        <rect class="cls-8" x="1112.73" y="918.32" width="2.6" height="2.6" />
        <path class="cls-3" d="M1115.05,920.64h-2.02v-2.02h2.02v2.02M1115.63,921.22v-3.18h-3.18v3.18h3.18Z" />
      </g>
      <g>
        <rect class="cls-8" x="1111.4" y="915.71" width="5.25" height="2.6" />
        <path class="cls-3" d="M1116.36,918.03h-4.67v-2.02h4.67v2.02M1116.94,918.61v-3.18h-5.83v3.18h5.83Z" />
      </g>
      <g>
        <rect class="cls-8" x="1112.74" y="961.27" width="2.6" height="2.6" />
        <path class="cls-3" d="M1115.05,963.59h-2.02v-2.02h2.02v2.02M1115.63,964.17v-3.18h-3.18v3.18h3.18Z" />
      </g>
      <g>
        <rect class="cls-8" x="1111.42" y="963.89" width="5.25" height="2.6" />
        <path class="cls-3" d="M1116.38,966.2h-4.67v-2.02h4.67v2.02M1116.96,966.78v-3.18h-5.83v3.18h5.83Z" />
      </g>
      <rect class="cls-3" x="1012.08" y="971.94" width="130.81" height="5.85" />
      <rect class="cls-3" x="758.96" y="971.89" width="154.41" height="5.89" />
      <rect class="cls-3" x="783.19" y="1060.01" width="170.22" height="5.78" transform="translate(-194.6 1931.2) rotate(-90)" />
      <rect class="cls-3" x="802.57" y="1135.83" width="65.73" height="5.78" transform="translate(1670.87 2277.44) rotate(-180)" />
      <rect class="cls-3" x="865.83" y="1142.4" width="26.24" height="5.62" transform="translate(1757.89 2290.41) rotate(-180)" />
      <rect class="cls-3" x="813.09" y="1212.97" width="149.55" height="8.4" transform="translate(2105.03 329.31) rotate(90)" />
      <rect class="cls-3" x="644.57" y="922.92" width="5.81" height="103.87" transform="translate(1622.33 327.38) rotate(90)" />
      <rect class="cls-3" x="640.77" y="892.22" width="5.81" height="93.83" transform="translate(1582.81 295.46) rotate(90)" />
      <rect class="cls-3" x="593.87" y="977.19" width="5.81" height="163.88" />
      <rect class="cls-3" x="586.22" y="835.59" width="5.81" height="301.09" />
      <rect class="cls-3" x="642.94" y="1075.5" width="5.81" height="126.47" transform="translate(-492.89 1784.58) rotate(-90)" />
      <rect class="cls-3" x="444.13" y="1054.84" width="5.81" height="167.76" transform="translate(-691.69 1585.76) rotate(-90)" />
      <rect class="cls-3" x="579.55" y="829.19" width="5.81" height="18.56" transform="translate(-256.02 1420.92) rotate(-90)" />
      <rect class="cls-3" x="435.39" y="748.01" width="5.81" height="180.93" transform="translate(-400.18 1276.76) rotate(-90)" />
      <rect class="cls-3" x="374.61" y="610.9" width="5.81" height="230.52" transform="translate(755.03 1452.33) rotate(-180)" />
      <rect class="cls-3" x="249.47" y="487.05" width="5.81" height="253.48" transform="translate(866.16 361.42) rotate(90)" />
      <rect class="cls-3" x="125.62" y="611.46" width="5.81" height="229.9" />
      <rect class="cls-3" x="211.25" y="749.94" width="5.81" height="177.06" transform="translate(-624.32 1052.62) rotate(-90)" />
      <rect class="cls-3" x="283.16" y="836.14" width="5.81" height="305.53" transform="translate(572.13 1977.8) rotate(-180)" />
      <rect class="cls-3" x="232.5" y="1070.53" width="5.81" height="136.41" transform="translate(1374.14 903.33) rotate(90)" />
      <g>
        <polygon
          class="cls-7"
          points="302.39 835.86 305 835.86 305 837.19 305.64 837.19 305.64 839.12 304.99 839.12 304.99 841.1 302.39 841.1 302.39 835.86"
        />
        <path
          class="cls-3"
          d="M305.35,838.83h-.65s0,.58,0,.58v1.4h-2.02v-4.67h2.02v1.33h.65v1.36M305.93,839.41v-2.52h-.65v-1.33h-3.18s0,5.83,0,5.83h3.18s0-1.98,0-1.98h.65Z"
        />
      </g>
      <g>
        <polygon
          class="cls-7"
          points="344.84 837.19 345.49 837.19 345.49 835.86 348.09 835.86 348.09 841.1 345.49 841.1 345.49 839.12 344.84 839.12 344.84 837.19"
        />
        <path
          class="cls-3"
          d="M347.8,840.81h-2.02v-1.98h-.65v-1.36h.65v-1.33h2.02v4.67M348.38,841.39v-5.83h-3.18s0,1.33,0,1.33h-.65v2.52h.65v1.98h3.18Z"
        />
      </g>
      <g>
        <polygon
          class="cls-7"
          points="570.19 837.83 570.84 837.83 570.84 835.85 573.44 835.85 573.44 841.09 570.84 841.09 570.84 839.76 570.19 839.76 570.19 837.83"
        />
        <path
          class="cls-3"
          d="M573.15,840.8h-2.02v-1.33h-.65v-1.36h.65v-1.98h2.02v4.67M573.73,841.38v-5.83h-3.18v1.98h-.65v2.52h.65v1.33h3.18Z"
        />
      </g>
      <g>
        <polygon
          class="cls-7"
          points="529.05 835.86 531.65 835.86 531.65 837.83 532.29 837.83 532.29 839.77 531.65 839.77 531.65 841.1 529.04 841.1 529.05 835.86"
        />
        <path
          class="cls-3"
          d="M532,839.48h-.65v1.33h-2.02v-4.67h2.02v1.98h.65v1.36M532.59,840.06v-2.52h-.65v-1.98h-3.18s0,5.83,0,5.83h3.18s0-1.33,0-1.33h.65Z"
        />
      </g>
      <g>
        <polygon
          class="cls-7"
          points="360.17 1138.08 360.82 1138.08 360.82 1136.1 363.42 1136.1 363.42 1141.35 360.82 1141.35 360.82 1140.02 360.17 1140.02 360.17 1138.08"
        />
        <path
          class="cls-3"
          d="M363.13,1141.06h-2.02v-1.33h-.65v-1.36h.65s0-.58,0-.58v-1.4h2.02v4.67M363.71,1141.64v-5.83h-3.18s0,1.98,0,1.98h-.65s0,2.52,0,2.52h.65v1.33h3.18Z"
        />
      </g>
      <g>
        <polygon
          class="cls-7"
          points="303.4 1136.1 306 1136.1 306 1138.08 306.65 1138.08 306.65 1140.02 306 1140.02 306 1141.35 303.4 1141.35 303.4 1136.1"
        />
        <path
          class="cls-3"
          d="M306.36,1139.73h-.65v1.33h-2.02v-4.67h2.02v1.98h.65v1.36M306.94,1140.31v-2.52h-.65v-1.98h-3.18s0,5.83,0,5.83h3.18s0-1.33,0-1.33h.65Z"
        />
      </g>
      <rect class="cls-3" x="170.98" y="1135.84" width="5.81" height="36.58" />
      <rect class="cls-3" x="117.55" y="1215.52" width="5.81" height="11.01" />
      <rect class="cls-3" x="117.55" y="1299.12" width="5.81" height="9.71" />
      <rect class="cls-3" x="47.02" y="1300.4" width="5.81" height="33.23" />
      <rect class="cls-3" x="47" y="1385.32" width="5.81" height="35.86" />
      <rect class="cls-3" x="117.55" y="1135.84" width="5.81" height="9.68" />
      <rect class="cls-3" x="170.48" y="1271.72" width="5.81" height="42.34" />
      <rect class="cls-3" x="169.92" y="1397.1" width="5.81" height="21.46" />
      <rect class="cls-3" x="117.55" y="1412.77" width="5.81" height="7.08" />
      <rect class="cls-3" x="218.93" y="1412.77" width="5.81" height="10.45" />
      <rect class="cls-3" x="137.88" y="1272.28" width="5.81" height="67.31" transform="translate(-1165.15 1446.72) rotate(-90)" />
      <rect class="cls-3" x="164.34" y="1358.13" width="5.81" height="115.04" transform="translate(-1248.4 1582.9) rotate(-90)" />
      <g>
        <polygon
          class="cls-7"
          points="164.22 1138.09 164.87 1138.09 164.87 1136.11 167.47 1136.11 167.47 1141.36 164.87 1141.36 164.87 1140.03 164.22 1140.03 164.22 1138.09"
        />
        <path
          class="cls-3"
          d="M167.18,1141.07h-2.02v-1.33h-.65v-1.36h.65v-1.98h2.02v4.67M167.76,1141.65v-5.83h-3.18v1.98h-.65v2.52h.65v1.33h3.18Z"
        />
      </g>
      <g>
        <rect class="cls-8" x="170.76" y="1269.37" width="5.24" height="2.6" />
        <path class="cls-3" d="M175.71,1271.68h-4.66v-2.02h4.66v2.02M176.29,1272.26v-3.18h-5.82v3.18h5.82Z" />
      </g>
      <g>
        <rect class="cls-8" x="171.25" y="1172.71" width="5.24" height="2.6" />
        <path class="cls-3" d="M176.2,1175.02h-4.66v-2.02h4.66v2.02M176.78,1175.6v-3.18h-5.82v3.18h5.82Z" />
      </g>
      <g>
        <rect class="cls-8" x="170.76" y="1313.78" width="5.24" height="2.6" />
        <path class="cls-3" d="M175.71,1316.1h-4.66v-2.02h4.66v2.02M176.29,1316.68v-3.18h-5.82v3.18h5.82Z" />
      </g>
      <g>
        <rect class="cls-8" x="170.19" y="1394.77" width="5.24" height="2.6" />
        <path class="cls-3" d="M175.14,1397.09h-4.66v-2.02h4.66v2.02M175.72,1397.67v-3.18h-5.82v3.18h5.82Z" />
      </g>
      <g>
        <polygon
          class="cls-7"
          points="579.62 1138.09 580.27 1138.09 580.27 1136.11 582.87 1136.11 582.87 1141.36 580.27 1141.36 580.27 1140.03 579.62 1140.03 579.62 1138.09"
        />
        <path
          class="cls-3"
          d="M582.58,1141.07h-2.02v-1.33h-.65v-1.36h.65v-1.98h2.02v4.67M583.16,1141.65v-5.83h-3.18v1.98h-.65v2.52h.65v1.33h3.18Z"
        />
      </g>
      <g>
        <polygon
          class="cls-7"
          points="530.62 1136.11 533.23 1136.11 533.23 1138.09 533.87 1138.09 533.87 1140.03 533.22 1140.03 533.22 1141.36 530.62 1141.36 530.62 1136.11"
        />
        <path
          class="cls-3"
          d="M533.58,1139.74h-.65v1.33h-2.02v-4.67h2.02v1.98h.65v1.36M534.16,1140.32v-2.52h-.65v-1.98h-3.18v5.83h3.18v-1.33h.65Z"
        />
      </g>
      <g>
        <rect class="cls-8" x="531.67" y="835.88" width="39.09" height="1.95" />
        <path class="cls-3" d="M570.46,837.54h-38.51v-1.37h38.51v1.37M571.04,838.12v-2.53h-39.67v2.53h39.67Z" />
      </g>
      <g>
        <rect class="cls-8" x="533.25" y="1136.13" width="47.01" height="1.95" />
        <path class="cls-3" d="M579.97,1137.79h-46.43v-1.37h46.43v1.37M580.55,1138.37v-2.53h-47.59v2.53h47.59Z" />
      </g>
      <g>
        <rect class="cls-8" x="728.19" y=".34" width="1.95" height="47.01" />
        <path class="cls-3" d="M729.85,47.05h-1.37V.63h1.37v46.43M730.43,47.64V.05h-2.53s0,47.59,0,47.59h2.53Z" />
      </g>
      <g>
        <rect class="cls-8" x="663.63" y=".34" width="1.95" height="47.01" />
        <path class="cls-3" d="M665.29,47.06h-1.37V.63h1.37v46.43M665.87,47.64V.05h-2.53v47.59h2.53Z" />
      </g>
      <rect class="cls-3" x="684.8" y="795.13" width="5.81" height="146.63" />
      <g>
        <polygon
          class="cls-7"
          points="699.2 972.23 701.8 972.23 701.8 973.56 702.45 973.56 702.45 975.5 701.8 975.5 701.8 977.48 699.2 977.48 699.2 972.23"
        />
        <path
          class="cls-3"
          d="M702.16,975.21h-.65v1.98h-2.02v-4.67h2.02v1.33h.65v1.36M702.74,975.79v-2.52h-.65v-1.33h-3.18v5.83h3.18v-1.98h.65Z"
        />
      </g>
      <g>
        <polygon
          class="cls-7"
          points="755.97 973.56 756.62 973.56 756.62 972.23 759.22 972.23 759.22 977.48 756.62 977.48 756.62 975.5 755.97 975.5 755.97 973.56"
        />
        <path
          class="cls-3"
          d="M758.93,977.19h-2.02v-1.98h-.65v-1.36h.65v-1.33h2.02v4.67M759.51,977.77v-5.83h-3.18v1.33h-.65v2.52h.65v1.98h3.18Z"
        />
      </g>
      <g>
        <rect class="cls-8" x="701.77" y="977.48" width="1.95" height="54.84" />
        <path class="cls-3" d="M703.42,1032.03h-1.37v-54.26h1.37v54.26M704,1032.61v-55.42h-2.53v55.42h2.53Z" />
      </g>
      <g>
        <rect class="cls-8" x="915.69" y="925.22" width="1.95" height="47.01" />
        <path class="cls-3" d="M917.35,971.94h-1.37v-46.43h1.37v46.43M917.93,972.52v-47.59h-2.53v47.59h2.53Z" />
      </g>
      <g>
        <rect class="cls-8" x="1007.79" y="925.22" width="1.95" height="47.01" />
        <path class="cls-3" d="M1009.45,971.94h-1.37v-46.43h1.37v46.43M1010.03,972.52v-47.59h-2.53v47.59h2.53Z" />
      </g>
      <g>
        <polygon
          class="cls-7"
          points="1008.82 974.23 1009.47 974.23 1009.47 972.25 1012.07 972.25 1012.07 977.5 1009.47 977.5 1009.47 976.17 1008.82 976.17 1008.82 974.23"
        />
        <path
          class="cls-3"
          d="M1011.78,977.21h-2.02v-1.33h-.65v-1.36h.65v-1.98h2.02v4.67M1012.36,977.79v-5.83h-3.18v1.98h-.65v2.52h.65v1.33h3.18Z"
        />
      </g>
      <g>
        <polygon
          class="cls-7"
          points="913.07 972.22 915.68 972.22 915.68 974.2 916.32 974.2 916.32 976.14 915.68 976.14 915.68 977.47 913.07 977.47 913.07 972.22"
        />
        <path
          class="cls-3"
          d="M916.03,975.84h-.65v1.33h-2.02v-4.67h2.02v1.98h.65v1.36M916.61,976.43v-2.52h-.65v-1.98h-3.18v5.83h3.18v-1.33h.65Z"
        />
      </g>
      <rect class="cls-3" x="119.87" y="1133.55" width="4.47" height="9.04" transform="translate(-1015.97 1260.17) rotate(-90)" />
      <g>
        <rect class="cls-8" x="125.69" y="1136.12" width="39.17" height="1.95" />
        <path class="cls-3" d="M164.57,1137.77h-38.58v-1.37h38.58v1.37M165.15,1138.36v-2.53h-39.75v2.53h39.75Z" />
      </g>
      <g>
        <rect class="cls-8" x="119.17" y="1145.26" width="2.6" height="2.6" />
        <path class="cls-3" d="M121.49,1147.57h-2.02v-2.02h2.02v2.02M122.07,1148.15v-3.18h-3.18v3.18h3.18Z" />
      </g>
      <g>
        <rect class="cls-8" x="104.82" y="1303.31" width="2.45" height="5.23" />
        <path class="cls-3" d="M106.98,1308.25h-1.87v-4.64h1.87v4.64M107.56,1308.83v-5.81h-3.03v5.81h3.03Z" />
      </g>
      <g>
        <rect class="cls-8" x="102.19" y="1304.64" width="2.6" height="2.6" />
        <path class="cls-3" d="M104.5,1306.95h-2.02v-2.02h2.02v2.02M105.08,1307.53v-3.18h-3.18v3.18h3.18Z" />
      </g>
      <g>
        <rect class="cls-8" x="52.54" y="1303.32" width="2.6" height="5.23" />
        <path class="cls-3" d="M54.85,1308.26h-2.02v-4.64h2.02v4.64M55.44,1308.84v-5.81h-3.18v5.81h3.18Z" />
      </g>
      <g>
        <rect class="cls-8" x="55.17" y="1304.62" width="2.6" height="2.6" />
        <path class="cls-3" d="M57.48,1306.93h-2.02v-2.02h2.02v2.02M58.06,1307.52v-3.18h-3.18v3.18h3.18Z" />
      </g>
      <g>
        <polygon
          class="cls-7"
          points="47.3 1333.36 52.55 1333.36 52.55 1335.97 51.22 1335.97 51.22 1336.61 49.29 1336.61 49.29 1335.97 47.3 1335.97 47.3 1333.36"
        />
        <path
          class="cls-3"
          d="M52.26,1335.68h-1.33v.65h-1.36v-.65h-1.98v-2.02h4.67v2.02M52.84,1336.26v-3.18h-5.83v3.18h1.98v.65h2.52v-.65h1.33Z"
        />
      </g>
      <g>
        <polygon
          class="cls-7"
          points="47.28 1383.01 49.26 1383.01 49.26 1382.36 51.2 1382.36 51.2 1383.01 52.53 1383.01 52.53 1385.61 47.28 1385.61 47.28 1383.01"
        />
        <path
          class="cls-3"
          d="M52.24,1385.32h-4.67v-2.02h1.98v-.65h1.36v.65h1.33v2.02M52.82,1385.9v-3.18h-1.33v-.65h-2.52v.65h-1.98v3.18h5.83Z"
        />
      </g>
      <g>
        <rect class="cls-8" x=".29" y="1335.98" width="47.01" height="1.95" />
        <path class="cls-3" d="M47.01,1337.64H.58v-1.37h46.43v1.37M47.59,1338.22v-2.53H0v2.53h47.59Z" />
      </g>
      <g>
        <rect class="cls-8" x="219.21" y="1426.11" width="1.95" height="39.17" />
        <path class="cls-3" d="M220.87,1464.99h-1.37v-38.58h1.37v38.58M221.45,1465.57v-39.75h-2.53v39.75h2.53Z" />
      </g>
      <g>
        <polygon
          class="cls-7"
          points="219.21 1423.51 224.46 1423.51 224.46 1426.11 223.13 1426.11 223.13 1426.76 221.19 1426.76 221.19 1426.11 219.21 1426.11 219.21 1423.51"
        />
        <path
          class="cls-3"
          d="M224.17,1425.82h-1.33v.65h-1.36v-.65h-1.98v-2.02h4.67v2.02M224.75,1426.4v-3.18h-5.83v3.18h1.98v.65h2.52v-.65h1.33Z"
        />
      </g>
      <g>
        <polygon
          class="cls-7"
          points="219.2 1465.28 221.18 1465.28 221.18 1464.64 223.12 1464.64 223.12 1465.29 224.44 1465.29 224.44 1467.89 219.2 1467.89 219.2 1465.28"
        />
        <path
          class="cls-3"
          d="M224.15,1467.6h-4.67v-2.02h1.98v-.58s0-.07,0-.07h1.36v.65h1.33v2.02M224.73,1468.18v-3.18h-1.33v-.65h-2.52v.65s-1.98,0-1.98,0v3.18h5.83Z"
        />
      </g>
      <g>
        <rect class="cls-8" x="117.84" y="1419.59" width="5.23" height="2.58" />
        <path class="cls-3" d="M122.78,1421.88h-4.64v-2h4.64v2M123.36,1422.46v-3.16h-5.81v3.16h5.81Z" />
      </g>
      <g>
        <rect class="cls-8" x="119.17" y="1422.19" width="2.6" height="2.6" />
        <path class="cls-3" d="M121.48,1424.5h-2.02v-2.02h2.02v2.02M122.06,1425.08v-3.18h-3.18v3.18h3.18Z" />
      </g>
      <g>
        <rect class="cls-8" x="117.84" y="1490.12" width="5.23" height="2.6" />
        <path class="cls-3" d="M122.78,1492.42h-4.64v-2.02h4.64v2.02M123.36,1493.01v-3.18h-5.81v3.18h5.81Z" />
      </g>
      <g>
        <rect class="cls-8" x="119.17" y="1487.51" width="2.6" height="2.6" />
        <path class="cls-3" d="M121.48,1489.82h-2.02v-2.02h2.02v2.02M122.06,1490.4v-3.18h-3.18v3.18h3.18Z" />
      </g>
      <g>
        <rect class="cls-8" x="117.84" y="1573.71" width="5.23" height="2.6" />
        <path class="cls-3" d="M122.78,1576.01h-4.64v-2.02h4.64v2.02M123.36,1576.59v-3.18h-5.81v3.18h5.81Z" />
      </g>
      <g>
        <rect class="cls-8" x="119.17" y="1571.1" width="2.6" height="2.6" />
        <path class="cls-3" d="M121.48,1573.41h-2.02v-2.02h2.02v2.02M122.06,1573.99v-3.18h-3.18v3.18h3.18Z" />
      </g>
      <g>
        <rect class="cls-8" x="117.84" y="1503.19" width="5.23" height="2.58" />
        <path class="cls-3" d="M122.78,1505.48h-4.64v-2h4.64v2M123.36,1506.06v-3.16h-5.81v3.16h5.81Z" />
      </g>
      <g>
        <rect class="cls-8" x="119.17" y="1505.79" width="2.6" height="2.6" />
        <path class="cls-3" d="M121.48,1508.1h-2.02v-2.02h2.02v2.02M122.06,1508.68v-3.18h-3.18v3.18h3.18Z" />
      </g>
      <rect class="cls-3" x="117.55" y="1492.45" width="5.81" height="11.01" />
      <rect class="cls-3" x="117.55" y="1576.21" width="5.82" height="25.22" />
      <rect class="cls-3" x="117.55" y="1647.9" width="5.82" height="37.13" />
      <g>
        <rect class="cls-8" x="117.86" y="1601.15" width="5.23" height="2.6" />
        <path class="cls-3" d="M122.79,1603.46h-4.64v-2.02h4.64v2.02M123.37,1604.04v-3.18h-5.81v3.18h5.81Z" />
      </g>
      <g>
        <rect class="cls-8" x="119.17" y="1603.77" width="2.6" height="2.6" />
        <path class="cls-3" d="M121.48,1606.08h-2.02v-2.02h2.02v2.02M122.06,1606.66v-3.18h-3.18v3.18h3.18Z" />
      </g>
      <g>
        <rect class="cls-8" x="117.84" y="1645.56" width="5.23" height="2.6" />
        <path class="cls-3" d="M122.78,1647.87h-4.64v-2.02h4.64v2.02M123.36,1648.45v-3.18h-5.81v3.18h5.81Z" />
      </g>
      <g>
        <rect class="cls-8" x="119.16" y="1642.94" width="2.6" height="2.6" />
        <path class="cls-3" d="M121.47,1645.26h-2.02v-2.02h2.02v2.02M122.05,1645.84v-3.18h-3.18v3.18h3.18Z" />
      </g>
      <rect class="cls-3" x="168.24" y="1628.57" width="5.82" height="107.15" transform="translate(-1510.99 1853.29) rotate(-90)" />
      <rect class="cls-3" x="218.91" y="1467.9" width="5.82" height="216.57" transform="translate(443.64 3152.37) rotate(-180)" />
      <rect class="cls-3" x="258.51" y="1639.94" width="5.82" height="84.39" transform="translate(1943.56 1420.72) rotate(90)" />
      <g>
        <rect class="cls-8" x="305.97" y="1682.8" width="46.72" height="1.95" />
        <path class="cls-3" d="M352.4,1684.46h-46.14v-1.37h46.14v1.37M352.98,1685.04v-2.53h-47.3v2.53h47.3Z" />
      </g>
      <g>
        <polygon
          class="cls-7"
          points="303.52 1679.51 306.12 1679.51 306.12 1680.84 306.77 1680.84 306.77 1682.78 306.12 1682.78 306.12 1684.76 303.52 1684.76 303.52 1679.51"
        />
        <path
          class="cls-3"
          d="M306.48,1682.49h-.65v1.98h-2.02v-4.66h2.02v1.33h.65v1.36M307.06,1683.07v-2.52h-.65v-1.33h-3.18v5.82h3.18v-1.98h.65Z"
        />
      </g>
      <g>
        <polygon
          class="cls-7"
          points="352.05 1680.84 352.7 1680.84 352.7 1679.51 355.3 1679.51 355.3 1684.76 352.7 1684.76 352.7 1682.78 352.05 1682.78 352.05 1680.84"
        />
        <path
          class="cls-3"
          d="M355.01,1684.47h-2.02v-1.98h-.65v-1.36h.65v-1.33h2.02v4.67M355.6,1685.05v-5.83h-3.18v1.33h-.65v2.52h.65v1.98h3.18Z"
        />
      </g>
      <rect class="cls-3" x="413.62" y="1620.91" width="5.82" height="122.44" transform="translate(2098.65 1265.6) rotate(90)" />
      <rect class="cls-3" x="360.51" y="1541.36" width="5.82" height="143.12" />
      <rect class="cls-3" x="426.86" y="1475.03" width="5.82" height="138.47" transform="translate(-1114.5 1974.03) rotate(-90)" />
      <rect class="cls-3" x="261.99" y="1889.39" width="425.62" height="5.89" transform="translate(2367.14 1417.54) rotate(90)" />
      <rect class="cls-3" x="375.95" y="2253.31" width="197.71" height="5.9" transform="translate(2731.06 1781.45) rotate(90)" />
      <rect class="cls-3" x="472.49" y="1810.26" width="186.94" height="5.9" />
      <rect class="cls-3" x="559.66" y="1541.9" width="147.39" height="5.9" />
      <rect class="cls-3" x="806.89" y="1542.39" width="121.15" height="5.9" />
      <rect class="cls-3" x="1027.32" y="1542.36" width="92.14" height="5.9" />
      <rect class="cls-3" x="1067.32" y="1566.04" width="53.32" height="5.9" transform="translate(-475 2662.97) rotate(-90)" />
      <rect class="cls-3" x="1068.53" y="2349.27" width="19.95" height="5.9" />
      <rect class="cls-3" x="521.28" y="1677.55" width="271.31" height="5.9" transform="translate(-1023.57 2337.43) rotate(-90)" />
      <rect class="cls-3" x="775.84" y="2349.27" width="167.25" height="5.9" />
      <rect class="cls-3" x="471.86" y="2349.27" width="178.53" height="5.9" />
      <rect class="cls-3" x="824.19" y="1480.37" width="127.44" height="8.42" transform="translate(-596.66 2372.49) rotate(-90)" />
      <g>
        <rect class="cls-8" x="650.68" y="2349.56" width="2.6" height="5.3" />
        <path class="cls-3" d="M652.99,2354.57h-2.02v-4.72h2.02v4.72M653.57,2355.15v-5.88h-3.18v5.88h3.18Z" />
      </g>
      <g>
        <rect class="cls-8" x="653.29" y="2350.92" width="2.6" height="2.6" />
        <path class="cls-3" d="M655.6,2353.23h-2.02v-2.02h2.02v2.02M656.19,2353.81v-3.18h-3.18v3.18h3.18Z" />
      </g>
      <g>
        <rect class="cls-8" x="772.94" y="2349.56" width="2.6" height="5.32" />
        <path class="cls-3" d="M775.25,2354.59h-2.02v-4.74h2.02v4.74M775.83,2355.17v-5.9h-3.18v5.9h3.18Z" />
      </g>
      <g>
        <rect class="cls-8" x="770.33" y="2350.92" width="2.6" height="2.6" />
        <path class="cls-3" d="M772.64,2353.23h-2.02v-2.02h2.02v2.02M773.22,2353.81v-3.18h-3.18v3.18h3.18Z" />
      </g>
      <path class="cls-3" d="M1098.93,2357.19h-4.69v-259.82h4.69v259.82M1099.52,2357.77v-260.99h-5.85v260.99h5.85Z" />
      <path class="cls-3" d="M1093.71,2357.19h-4.69v-259.82h4.69v259.82M1094.29,2357.77v-260.99h-5.85v260.99h5.85Z" />
      <g>
        <rect class="cls-8" x="1091.31" y="2352.01" width="5.3" height="2.82" />
        <path class="cls-3" d="M1096.32,2354.54h-4.72v-2.24h4.72v2.24M1096.9,2355.12v-3.4h-5.88v3.4h5.88Z" />
      </g>
      <g>
        <rect class="cls-8" x="1092.67" y="2349.39" width="2.6" height="2.6" />
        <path class="cls-3" d="M1094.99,2351.7h-2.02v-2.02h2.02v2.02M1095.57,2352.28v-3.18h-3.18v3.18h3.18Z" />
      </g>
      <g>
        <rect class="cls-8" x="1091.39" y="2099.68" width="5.19" height="2.6" />
        <path class="cls-3" d="M1096.28,2101.99h-4.61v-2.02h4.61v2.02M1096.87,2102.57v-3.18h-5.77v3.18h5.77Z" />
      </g>
      <g>
        <rect class="cls-8" x="1092.68" y="2102.3" width="2.6" height="2.6" />
        <path class="cls-3" d="M1094.99,2104.61h-2.02v-2.02h2.02v2.02M1095.57,2105.19v-3.18h-3.18v3.18h3.18Z" />
      </g>
      <path class="cls-3" d="M1098.94,2091.77h-4.66v-243.63h4.66v243.63M1099.52,2092.35v-244.79h-5.82v244.79h5.82Z" />
      <path class="cls-3" d="M1093.72,2091.77h-4.69v-243.63h4.69v243.63M1094.3,2092.35v-244.79h-5.85v244.79h5.85Z" />
      <g>
        <rect class="cls-8" x="1091.39" y="2086.62" width="5.19" height="2.82" />
        <path class="cls-3" d="M1096.28,2089.15h-4.61v-2.24h4.61v2.24M1096.87,2089.73v-3.4h-5.77v3.4h5.77Z" />
      </g>
      <g>
        <rect class="cls-8" x="1092.68" y="2084.24" width="2.6" height="2.32" />
        <path class="cls-3" d="M1095,2086.27h-2.02v-1.74h2.02v1.74M1095.58,2086.85v-2.9h-3.18v2.9h3.18Z" />
      </g>
      <g>
        <rect class="cls-8" x="1091.39" y="1850.46" width="5.19" height="2.6" />
        <path class="cls-3" d="M1096.28,1852.77h-4.61v-2.02h4.61v2.02M1096.87,1853.35v-3.18h-5.77v3.18h5.77Z" />
      </g>
      <g>
        <rect class="cls-8" x="1092.69" y="1853.07" width="2.6" height="2.6" />
        <path class="cls-3" d="M1095,1855.38h-2.02v-2.02h2.02v2.02M1095.58,1855.96v-3.18h-3.18v3.18h3.18Z" />
      </g>
      <rect class="cls-3" x="1088.58" y="2091.68" width="10.8" height="5.77" transform="translate(-1000.59 3188.55) rotate(-90)" />
      <rect class="cls-3" x="1086.98" y="1840.85" width="14" height="5.79" transform="translate(-749.77 2937.72) rotate(-90)" />
      <g>
        <rect class="cls-8" x="1091.39" y="1834.4" width="5.19" height="2.6" />
        <path class="cls-3" d="M1096.29,1836.71h-4.61v-2.02h4.61v2.02M1096.87,1837.29v-3.18h-5.77v3.18h5.77Z" />
      </g>
      <g>
        <rect class="cls-8" x="1092.68" y="1831.79" width="2.6" height="2.6" />
        <path class="cls-3" d="M1094.99,1834.1h-2.02v-2.02h2.02v2.02M1095.57,1834.68v-3.18h-3.18v3.18h3.18Z" />
      </g>
      <rect class="cls-3" x="1092.61" y="1641.84" width="2.72" height="5.78" transform="translate(-550.75 2738.7) rotate(-90)" />
      <g>
        <rect class="cls-8" x="1091.37" y="1645.82" width="5.2" height="2.6" />
        <path class="cls-3" d="M1096.29,1648.13h-4.62v-2.02h4.62v2.02M1096.87,1648.71v-3.18h-5.78v3.18h5.78Z" />
      </g>
      <g>
        <rect class="cls-8" x="1092.67" y="1648.43" width="2.6" height="2.6" />
        <path class="cls-3" d="M1094.98,1650.74h-2.02v-2.02h2.02v2.02M1095.57,1651.32v-3.18h-3.18v3.18h3.18Z" />
      </g>
      <g>
        <polygon
          class="cls-7"
          points="499.3 1541.65 501.9 1541.65 501.9 1542.97 502.55 1542.97 502.55 1544.91 501.9 1544.91 501.9 1546.89 499.29 1546.89 499.3 1541.65"
        />
        <path
          class="cls-3"
          d="M502.26,1544.62h-.65v1.98h-2.02v-4.66h2.02v1.33h.65v1.36M502.84,1545.2v-2.52h-.65v-1.33h-3.18v5.82h3.18v-1.98h.65Z"
        />
      </g>
      <g>
        <polygon
          class="cls-7"
          points="556.12 1543.55 556.77 1543.55 556.77 1542.22 559.37 1542.22 559.37 1547.47 556.77 1547.47 556.77 1545.49 556.12 1545.49 556.12 1543.55"
        />
        <path
          class="cls-3"
          d="M559.08,1547.18h-2.02v-1.98h-.65v-1.36h.65v-1.33h2.02v4.67M559.66,1547.76v-5.83h-3.18v1.33h-.65v2.52h.65v1.98h3.18Z"
        />
      </g>
      <g>
        <polygon
          class="cls-7"
          points="803.35 1544.65 803.99 1544.65 803.99 1542.68 806.6 1542.68 806.6 1548 803.99 1548 803.99 1546.59 803.35 1546.59 803.35 1544.65"
        />
        <path
          class="cls-3"
          d="M806.31,1547.7h-2.02v-1.4h-.65v-1.36h.65v-1.98h2.02v4.74M806.89,1548.29v-5.9h-3.18v1.98h-.65v2.52h.65v1.4h3.18Z"
        />
      </g>
      <g>
        <polygon
          class="cls-7"
          points="707.35 1542.19 709.95 1542.19 709.95 1544.17 710.6 1544.17 710.6 1546.15 709.95 1546.15 709.95 1547.51 707.35 1547.51 707.35 1542.19"
        />
        <path
          class="cls-3"
          d="M710.31,1545.86h-.65v1.36h-2.02v-4.74h2.02v1.98h.65v1.4M710.89,1546.44v-2.56h-.65v-1.98h-3.18v5.9h3.18v-1.36h.65Z"
        />
      </g>
      <g>
        <rect class="cls-8" x="501.9" y="1546.89" width="1.95" height="54.83" />
        <path class="cls-3" d="M503.56,1601.43h-1.37v-54.25h1.37v54.25M504.14,1602.01v-55.41h-2.53v55.41h2.53Z" />
      </g>
      <g>
        <polygon
          class="cls-7"
          points="928.33 1542.68 930.93 1542.68 930.93 1544.08 931.58 1544.08 931.58 1546.02 930.93 1546.02 930.93 1548 928.33 1548 928.33 1542.68"
        />
        <path
          class="cls-3"
          d="M931.29,1545.73h-.65v1.98h-2.02v-4.74h2.02v1.4h.65v1.36M931.87,1546.31v-2.52h-.65v-1.4h-3.18v5.9h3.18v-1.98h.65Z"
        />
      </g>
      <g>
        <polygon
          class="cls-7"
          points="1024.33 1543.99 1024.98 1543.99 1024.98 1542.65 1027.58 1542.65 1027.58 1547.97 1024.98 1547.97 1024.98 1545.94 1024.33 1545.94 1024.33 1543.99"
        />
        <path
          class="cls-3"
          d="M1027.29,1547.68h-2.02v-2.03h-.65v-1.37h.65v-1.33h2.02v4.74M1027.87,1548.26v-5.9h-3.18v1.33h-.65v2.53h.65v2.03h3.18Z"
        />
      </g>
      <g>
        <rect class="cls-8" x="1096.6" y="1598.56" width="42.47" height="1.95" />
        <path class="cls-3" d="M1138.78,1600.21h-41.89v-1.37h41.89v1.37M1139.36,1600.79v-2.53h-43.05v2.53h43.05Z" />
      </g>
      <g>
        <polygon
          class="cls-7"
          points="1091.35 1641.03 1092.68 1641.03 1092.68 1640.39 1094.62 1640.39 1094.62 1641.03 1096.6 1641.03 1096.6 1643.64 1091.35 1643.64 1091.35 1641.03"
        />
        <path
          class="cls-3"
          d="M1096.31,1643.35h-4.66v-2.02h1.33v-.65h1.36v.65h1.98v2.02M1096.89,1643.93v-3.18h-1.98v-.65h-2.52v.65h-1.33v3.18h5.82Z"
        />
      </g>
      <g>
        <polygon
          class="cls-7"
          points="1091.39 1595.94 1096.64 1595.94 1096.64 1598.54 1094.66 1598.54 1094.66 1599.19 1092.72 1599.19 1092.72 1598.54 1091.39 1598.54 1091.39 1595.94"
        />
        <path
          class="cls-3"
          d="M1096.35,1598.25h-1.98v.65h-1.36v-.65h-1.33v-2.02h4.67v2.02M1096.93,1598.83v-3.18h-5.83v3.18h1.33v.65h2.52v-.65h1.98Z"
        />
      </g>
      <g>
        <rect class="cls-8" x="1023.07" y="1547.94" width="1.95" height="46.98" />
        <path class="cls-3" d="M1024.73,1594.63h-1.37v-46.4h1.37v46.4M1025.31,1595.21v-47.56h-2.53v47.56h2.53Z" />
      </g>
      <g>
        <rect class="cls-8" x="930.94" y="1547.96" width="1.95" height="46.98" />
        <path class="cls-3" d="M932.6,1594.65h-1.37v-46.4h1.37v46.4M933.18,1595.23v-47.56h-2.53v47.56h2.53Z" />
      </g>
      <g>
        <rect class="cls-8" x="886.94" y="1387.47" width="1.95" height="62.1" />
        <path class="cls-3" d="M888.6,1449.28h-1.37v-61.52h1.37v61.52M889.18,1449.86v-62.68h-2.53v62.68h2.53Z" />
      </g>
      <g>
        <rect class="cls-8" x="886.94" y="1263.22" width="1.95" height="62.1" />
        <path class="cls-3" d="M888.6,1325.03h-1.37v-61.52h1.37v61.52M889.18,1325.61v-62.68h-2.53v62.68h2.53Z" />
      </g>
      <g>
        <rect class="cls-8" x="884" y="1291.68" width="2.13" height="2.58" />
        <path class="cls-3" d="M885.84,1293.97h-1.55v-2h1.55v2M886.42,1294.55v-3.16h-2.72v3.16h2.72Z" />
      </g>
      <g>
        <rect class="cls-8" x="889.68" y="1291.68" width="2.13" height="2.58" />
        <path class="cls-3" d="M891.52,1293.97h-1.55v-2h1.55v2M892.1,1294.55v-3.16h-2.72v3.16h2.72Z" />
      </g>
      <g>
        <rect class="cls-8" x="884.01" y="1418.53" width="2.13" height="2.58" />
        <path class="cls-3" d="M885.85,1420.82h-1.55v-2h1.55v2M886.43,1421.4v-3.16h-2.72v3.16h2.72Z" />
      </g>
      <g>
        <rect class="cls-8" x="889.69" y="1418.53" width="2.13" height="2.58" />
        <path class="cls-3" d="M891.53,1420.82h-1.55v-2h1.55v2M892.11,1421.4v-3.16h-2.72v3.16h2.72Z" />
      </g>
      <path
        class="cls-3"
        d="M915.41,925.22c0-.15.12-.28.28-.28,26.08,0,47.3,21.22,47.3,47.3,0,.15-.12.28-.28.28s-.28-.12-.28-.28c0-25.78-20.97-46.75-46.75-46.75-.15,0-.28-.12-.28-.28Z"
      />
      <path
        class="cls-3"
        d="M962.43,972.24c0-26.08,21.22-47.3,47.3-47.3.15,0,.28.12.28.28s-.12.28-.28.28c-25.78,0-46.75,20.97-46.75,46.75,0,.15-.12.28-.28.28s-.28-.12-.28-.28Z"
      />
      <path
        class="cls-3"
        d="M1025.25,1594.95c0,.15-.12.28-.28.28-26.08,0-47.3-21.22-47.3-47.3,0-.15.12-.28.28-.28s.28.12.28.28c0,25.78,20.97,46.75,46.75,46.75.15,0,.28.12.28.28Z"
      />
      <path
        class="cls-3"
        d="M978.22,1547.93c0,26.08-21.22,47.3-47.3,47.3-.15,0-.28-.12-.28-.28,0-.15.12-.28.28-.28,25.78,0,46.75-20.97,46.75-46.75,0-.15.12-.28.28-.28s.28.12.28.28Z"
      />
      <path
        class="cls-3"
        d="M1096.3,1641.05c0-.15.12-.28.28-.28,23.28,0,42.22-18.94,42.22-42.22,0-.15.12-.28.28-.28s.28.12.28.28c0,23.59-19.19,42.78-42.78,42.78-.15,0-.28-.12-.28-.28Z"
      />
      <path
        class="cls-3"
        d="M0,1335.98c0-.15.12-.28.28-.28s.28.12.28.28c0,25.78,20.97,46.75,46.74,46.75.15,0,.28.12.28.28s-.12.28-.28.28c-26.08,0-47.3-21.22-47.3-47.3Z"
      />
      <g>
        <rect class="cls-8" x="305.96" y="1083.16" width="1.95" height="54.92" />
        <path class="cls-3" d="M307.62,1137.79h-1.37v-54.34h1.37v54.34M308.2,1138.37v-55.5h-2.53v55.5h2.53Z" />
      </g>
      <path
        class="cls-3"
        d="M305.67,1083.18c0-.15.12-.28.28-.28,30.4,0,55.13,24.74,55.13,55.15,0,.15-.12.28-.28.28s-.28-.12-.28-.28c0-30.1-24.48-54.6-54.57-54.6-.15,0-.28-.12-.28-.28Z"
      />
      <g>
        <rect class="cls-8" x="425.19" y="2108.06" width="47.01" height="1.95" />
        <path class="cls-3" d="M471.91,2109.72h-46.43v-1.37h46.43v1.37M472.49,2110.3v-2.53h-47.59v2.53h47.59Z" />
      </g>
      <path
        class="cls-3"
        d="M424.9,2108.05c0-.15.12-.28.28-.28s.28.12.28.28c0,25.78,20.97,46.75,46.74,46.75.15,0,.28.12.28.28s-.12.28-.28.28c-26.08,0-47.3-21.22-47.3-47.3Z"
      />
      <g>
        <polygon
          class="cls-7"
          points="472.21 2105.44 477.45 2105.44 477.45 2108.04 476.12 2108.04 476.12 2108.69 474.19 2108.69 474.19 2108.04 472.21 2108.04 472.21 2105.44"
        />
        <path
          class="cls-3"
          d="M477.16,2107.75h-1.33v.65h-1.36v-.65h-1.98v-2.02h4.67v2.02M477.74,2108.33v-3.18h-5.83v3.18h1.98v.65h2.52v-.65h1.33Z"
        />
      </g>
      <g>
        <polygon
          class="cls-7"
          points="472.18 2155.09 474.16 2155.09 474.16 2154.44 476.1 2154.44 476.1 2155.09 477.43 2155.09 477.43 2157.69 472.18 2157.69 472.18 2155.09"
        />
        <path
          class="cls-3"
          d="M477.14,2157.4h-4.67v-2.02h1.98v-.65h1.36v.65h1.33v2.02M477.72,2157.98v-3.18h-1.33v-.65h-2.52v.65h-1.98v3.18h5.83Z"
        />
      </g>
      <path
        class="cls-7"
        d="M292.23,1619.24h11.75v58.78h-11.75v-58.78ZM280.48,1619.24h11.75v58.78h-11.75v-58.78ZM268.73,1619.24h11.75v58.78h-11.75v-58.78ZM256.96,1619.24h11.77v58.78h-11.77v-58.78ZM245.21,1619.24h11.75v58.78h-11.75v-58.78Z"
      />
      <path
        class="cls-3"
        d="M244.93,1678.02v-58.78c0-.15.12-.28.28-.28h58.77c.15,0,.28.12.28.28v58.78c0,.15-.12.28-.28.28h-58.77c-.15,0-.28-.12-.28-.28ZM245.49,1619.52v58.22h11.19v-58.22h-11.19ZM257.24,1619.52v58.22h11.22v-58.22h-11.22ZM269.01,1619.52v58.22h11.19v-58.22h-11.19ZM280.76,1619.52v58.22h11.19v-58.22h-11.19ZM292.51,1619.52v58.22h11.19v-58.22h-11.19Z"
      />
      <path
        class="cls-3"
        d="M223.38,1666.27v-2.62c0-.15.12-.28.28-.28,1.29,0,2.34-1.04,2.34-2.32v-137.18c0-1.28-1.05-2.32-2.34-2.32-.15,0-.28-.12-.28-.28v-2.63c0-.15.12-.28.28-.28,3.03,0,5.5,2.47,5.5,5.5v137.18c0,3.03-2.47,5.5-5.5,5.5-.15,0-.28-.12-.28-.28ZM223.94,1663.91v2.07c2.6-.15,4.67-2.31,4.67-4.94v-137.18c0-2.63-2.07-4.79-4.67-4.94v2.08c1.47.14,2.62,1.37,2.62,2.86v137.18c0,1.49-1.15,2.73-2.62,2.87ZM265.18,1661.04v-137.18c0-3.03,2.47-5.5,5.5-5.5.15,0,.28.12.28.28v2.63c0,.15-.12.28-.28.28-1.28,0-2.32,1.04-2.32,2.32v137.18c0,1.28,1.04,2.32,2.32,2.32.15,0,.28.12.28.28v2.62c0,.15-.12.28-.28.28-3.03,0-5.5-2.47-5.5-5.5ZM265.74,1599.64v61.4c0,2.63,2.07,4.79,4.67,4.94v-2.07c-1.46-.14-2.6-1.37-2.6-2.87v-137.18c0-1.49,1.14-2.72,2.6-2.86v-2.08c-2.6.15-4.67,2.31-4.67,4.94v75.78Z"
      />
      <path
        class="cls-7"
        d="M270.68,1636.03v14.56h-47.03v-14.56h47.03ZM270.68,1621.47v14.56h-47.03v-14.56h47.03ZM270.68,1606.91v14.56h-47.03v-14.56h47.03ZM270.68,1592.37v14.54h-47.03v-14.54h47.03ZM270.68,1577.81v14.56h-47.03v-14.56h47.03ZM270.68,1563.25v14.56h-47.03v-14.56h47.03ZM270.68,1548.69v14.56h-47.03v-14.56h47.03Z"
      />
      <path
        class="cls-3"
        d="M223.38,1650.59v-101.9c0-.15.12-.28.28-.28h47.03c.15,0,.28.12.28.28v101.9c0,.15-.12.28-.28.28h-47.03c-.15,0-.28-.12-.28-.28ZM223.94,1636.31v14h46.47v-14h-46.47ZM223.94,1621.75v14h46.47v-14h-46.47ZM223.94,1607.19v14h46.47v-14h-46.47ZM223.94,1592.65v13.98h46.47v-13.98h-46.47ZM223.94,1578.09v14h46.47v-14h-46.47ZM223.94,1563.53v14h46.47v-14h-46.47ZM223.94,1548.97v14h46.47v-14h-46.47Z"
      />
      <rect class="cls-8" x="223.66" y="1619.24" width="47.03" height="58.78" />
      <path
        class="cls-3"
        d="M223.38,1678.02v-58.78c0-.15.12-.28.28-.28h47.03c.15,0,.28.12.28.28v58.78c0,.15-.12.28-.28.28h-47.03c-.15,0-.28-.12-.28-.28ZM223.94,1619.52v58.22h46.47v-58.22h-46.47Z"
      />
      <path
        class="cls-3"
        d="M223.38,1648.62c0-.09.05-.18.13-.24l47.02-29.38s0,0,0,0h0s.08-.03.12-.04c.04,0,.08,0,.12.01.03,0,.05.02.08.04h0s.06.06.07.09c.02.04.03.09.03.13,0,.03,0,.05-.01.08,0,0,0,.02-.01.03l-23.5,58.77c-.06.14-.22.21-.36.15s-.21-.22-.15-.36l23.18-57.99-46.3,28.93c-.13.08-.3.04-.38-.09-.03-.05-.04-.1-.04-.15Z"
      />
      <path
        class="cls-3"
        d="M727.91.28c0-.15.12-.28.28-.28,26.13,0,47.4,21.27,47.4,47.4,0,.15-.12.28-.28.28s-.28-.12-.28-.28c0-25.83-21.01-46.85-46.84-46.85-.15,0-.28-.12-.28-.28Z"
      />
      <path
        class="cls-3"
        d="M618.18,47.4c0-26.14,21.26-47.4,47.4-47.4.15,0,.28.12.28.28s-.12.28-.28.28c-25.83,0-46.84,21.01-46.84,46.85,0,.15-.12.28-.28.28s-.28-.12-.28-.28Z"
      />
      <path
        class="cls-3"
        d="M501.44,1601.73h0c0-.15.13-.28.28-.28,30.1.09,54.67-24.32,54.76-54.41,0-.15.13-.28.28-.28s.28.12.28.28h0c-.09,30.4-24.91,55.06-55.32,54.97-.15,0-.28-.12-.28-.28Z"
      />
      <path
        class="cls-3"
        d="M701.48,1032.32c0-.15.12-.28.28-.28,30.09,0,54.57-24.48,54.57-54.57,0-.15.12-.28.28-.28s.28.12.28.28c0,30.4-24.73,55.13-55.13,55.13-.15,0-.28-.12-.28-.28Z"
      />
      <path
        class="cls-3"
        d="M304.73,841.08c0-.15.12-.28.28-.28s.28.12.28.28c0,22.18,18.04,40.22,40.22,40.22,2.69,0,5.36-.26,7.91-.78.15-.03.3.07.33.22.03.16-.07.3-.22.33-2.59.53-5.29.79-8.02.79-22.49,0-40.78-18.29-40.78-40.78Z"
      />
      <path
        class="cls-3"
        d="M343.3,841.48c0-.13.09-.25.22-.27l1.93-.39c.07-.01.15,0,.21.04s.1.11.12.18l7.97,39.71c.04.14-.07.3-.22.33l-1.9.39c-.07.01-.15,0-.21-.04-.06-.04-.1-.1-.12-.18l-7.99-39.71s0-.04,0-.05ZM345.29,841.41l-1.38.28,7.88,39.16,1.36-.28-7.86-39.17Z"
      />
      <path
        class="cls-3"
        d="M704.87,1495.47c0-.14.11-.26.25-.28,1.77-.18,3.44-.26,4.98-.23,12.63.03,24.49,4.98,33.4,13.94,8.88,8.93,13.76,20.76,13.76,33.35v.13c0,.15-.12.28-.28.28s-.28-.12-.28-.28h0v-.13c0-12.43-4.83-24.13-13.6-32.95-8.8-8.85-20.53-13.75-33.01-13.78-1.52-.02-3.17.05-4.92.23-.15.02-.29-.1-.3-.25,0,0,0-.02,0-.03Z"
      />
      <path
        class="cls-3"
        d="M756.7,1542.38h0c.07-26.08,21.35-47.24,47.44-47.17,1.65,0,3.33.1,4.98.28.14.02.25.14.25.28,0,0,0,.02,0,.03-.02.15-.15.26-.31.24-1.63-.18-3.28-.28-4.91-.28-25.78-.07-46.81,20.84-46.88,46.61,0,.15-.12.28-.28.28s-.28-.12-.28-.28Z"
      />
      <path
        class="cls-3"
        d="M704.87,1495.47c0-.06.02-.13.06-.18.05-.06.12-.09.19-.1l1.95-.19c.15-.02.29.1.3.25l4.81,46.77c.02.19-.1.29-.25.31l-1.95.21c-.07,0-.15-.01-.2-.06-.06-.05-.1-.12-.1-.19l-4.81-46.79s0-.02,0-.03ZM706.85,1495.58l-1.39.13,4.75,46.24,1.39-.15-4.75-46.22Z"
      />
      <path
        class="cls-3"
        d="M801.78,1542.31s0-.02,0-.03l5.08-46.75c.02-.15.15-.26.31-.25l1.95.21c.07,0,.14.05.19.1.04.06.08.13.06.2l-5.08,46.75c-.02.15-.15.26-.31.25l-1.95-.21c-.07,0-.14-.05-.19-.1-.04-.05-.06-.11-.06-.17ZM807.39,1495.87l-5.03,46.19,1.4.15,5.03-46.19-1.4-.15Z"
      />
      <path
        class="cls-3"
        d="M390.3,935.13v-94.05c0-.15.12-.28.28-.28h109.73c.15,0,.28.12.28.28v94.05c0,.15-.12.28-.28.28h-25.2v3.65c0,.15-.12.28-.28.28h-58.8c-.15,0-.28-.12-.28-.28v-3.65h-25.17c-.15,0-.28-.12-.28-.28ZM390.86,841.36v93.49h25.17c.15,0,.28.12.28.28v3.65h58.24v-3.65c0-.15.12-.28.28-.28h25.2v-93.49h-109.17Z"
      />
      <path
        class="cls-3"
        d="M390.3,935.13c0-.08.03-.16.1-.21l54.62-46.81-54.62-46.81c-.12-.1-.13-.28-.03-.39.1-.12.27-.13.39-.03l54.68,46.87,54.68-46.87c.12-.1.29-.09.39.03.1.11.09.29-.03.39l-54.62,46.81,54.62,46.81c.12.1.13.28.03.39-.1.12-.27.13-.39.03l-54.68-46.87-54.68,46.87c-.12.1-.29.09-.39-.03-.05-.05-.07-.12-.07-.18Z"
      />
      <path
        class="cls-3"
        d="M382.45,946.91v-6.55c0-.15.12-.28.28-.28h33.3c.15,0,.28.12.28.28v6.55c0,.15-.12.28-.28.28h-33.3c-.15,0-.28-.12-.28-.28ZM383.01,940.64v5.99h32.74v-5.99h-32.74ZM474.55,946.91v-6.55c0-.15.12-.28.28-.28h33.3c.15,0,.28.12.28.28v6.55c0,.15-.12.28-.28.28h-33.3c-.15,0-.28-.12-.28-.28ZM475.11,940.64v5.99h32.74v-5.99h-32.74Z"
      />
      <path
        class="cls-3"
        d="M382.45,940.36v-99.28c0-.15.12-.28.28-.28s.28.12.28.28v99.28c0,.15-.12.28-.28.28s-.28-.12-.28-.28ZM507.85,940.36v-99.28c0-.15.12-.28.28-.28s.28.12.28.28v99.28c0,.15-.12.28-.28.28s-.28-.12-.28-.28Z"
      />
      <path
        class="cls-5"
        d="M595.35,1679.65l-7.56-24.96c-1-3.71-3.85-9.56-12.98-9.56h-10.56c-9.27,0-11.98,5.85-12.98,9.56l-7.56,24.96c-.86,2.42,0,4.14,1.71,4.56,2,.57,3.57-.57,4.28-2.56l6.42-22.11c.43-1.43,1.29-2.14,2.43-1.71,1.14.42,1.14,1.57.71,2.85l-11.84,39.79h13.26v30.1c0,4.56,2.43,4.99,3.57,4.99,1,0,3.28-.43,3.28-4.85v-28.67c0-1.28.43-1.85,1.86-1.85,1.56,0,1.99.57,1.99,1.85v28.67c0,4.42,2.57,4.85,3.57,4.85,1.14,0,3.42-.43,3.42-4.99v-30.1h13.26s-11.84-39.79-11.84-39.79c-.57-1.28-.43-2.43.71-2.85,1.15-.43,2,.28,2.43,1.71l6.42,22.11c.72,1.99,2.28,3.13,4.28,2.56,1.71-.42,2.57-2.14,1.71-4.56M578.67,1632.58c0-4.85-4.14-8.85-8.99-8.85-4.99,0-8.99,4-8.99,8.85,0,5.14,4,9.13,8.99,9.13,4.85,0,8.99-3.99,8.99-9.13"
      />
      <path
        class="cls-5"
        d="M274.84,730.41v-25.1c0-11.13-5.56-13.98-14.4-13.98h-14.83c-8.99,0-14.4,2.86-14.4,13.98v25.1c0,3.71,1.14,4.71,3.42,4.71,2,0,3.57-.86,3.57-4.71v-19.54c0-2.28,1.14-2.57,1.71-2.57s1.86,0,1.86,2.57v66.18c0,4.56,3.57,4.99,4.85,4.99,1.14,0,5.14-.43,5.14-4.85v-38.79c0-1.43.28-2,1.28-2,1.14,0,1.14,1,1.14,2v38.79c0,4.28,3.71,4.85,5,4.85,1.42,0,4.99-.43,4.99-4.99v-66.18c0-2.14,1.28-2.57,1.86-2.57s1.71.43,1.71,2.57v19.54c0,3.71,1.42,4.71,3.42,4.71,2.43,0,3.71-1,3.71-4.71M262.01,679.07c0-4.85-4-8.85-8.85-8.85-5.13,0-9.13,4-9.13,8.85,0,5.14,4,9.13,9.13,9.13,4.85,0,8.85-3.99,8.85-9.13"
      />
      <g>
        <rect class="cls-8" x="1115.75" y="1530.46" width="107.97" height="5.25" transform="translate(-311.5 298.35) rotate(-12.84)" />
        <path
          class="cls-3"
          d="M1222.61,1523.43l-104.7,23.87-1.04-4.56,104.7-23.87,1.04,4.56M1223.3,1523.87l-1.3-5.69-105.83,24.13,1.3,5.69,105.83-24.13h0Z"
        />
      </g>
      <g>
        <rect class="cls-8" x="1116.92" y="1535.59" width="107.97" height="5.26" transform="translate(-312.62 298.74) rotate(-12.84)" />
        <path
          class="cls-3"
          d="M1223.78,1528.56l-104.7,23.87-1.04-4.56,104.7-23.87,1.04,4.56M1224.47,1529l-1.3-5.69-105.83,24.13,1.3,5.69,105.83-24.13h0Z"
        />
      </g>
      <g>
        <rect class="cls-8" x="1225.56" y="1493.96" width="73.1" height="5.25" transform="translate(-615.02 940.65) rotate(-33.36)" />
        <path
          class="cls-3"
          d="M1293.69,1478.61l-60.59,39.85-2.57-3.9,60.59-39.85,2.57,3.9M1294.5,1478.77l-3.21-4.87-61.56,40.49,3.21,4.87,61.56-40.49h0Z"
        />
      </g>
      <g>
        <rect class="cls-8" x="1228.46" y="1498.35" width="73.1" height="5.26" transform="translate(-616.83 942.52) rotate(-33.35)" />
        <path
          class="cls-3"
          d="M1296.58,1483l-60.59,39.85-2.57-3.91,60.59-39.85,2.57,3.91M1297.39,1483.17l-3.21-4.88-61.56,40.49,3.21,4.88,61.56-40.49h0Z"
        />
      </g>
      <g>
        <rect class="cls-8" x="1279.71" y="1446.66" width="74.85" height="5.25" transform="translate(-641.23 1459.28) rotate(-48.03)" />
        <path
          class="cls-3"
          d="M1343.69,1423.23l-49.64,55.24-3.48-3.12,49.64-55.24,3.48,3.12M1344.51,1423.19l-4.34-3.9-50.41,56.1,4.34,3.9,50.41-56.1h0Z"
        />
      </g>
      <g>
        <rect class="cls-8" x="1283.62" y="1450.18" width="74.85" height="5.25" transform="translate(-642.54 1463.92) rotate(-48.04)" />
        <path
          class="cls-3"
          d="M1347.6,1426.75l-49.64,55.24-3.48-3.12,49.64-55.24,3.48,3.12M1348.42,1426.7l-4.34-3.9-50.41,56.1,4.34,3.9,50.41-56.1h0Z"
        />
      </g>
      <g>
        <rect class="cls-8" x="1324.74" y="1379.06" width="74.85" height="5.25" transform="translate(-469.67 2021.16) rotate(-64.65)" />
        <path
          class="cls-3"
          d="M1380.17,1349.12l-31.79,67.12-4.22-2,31.79-67.12,4.22,2M1380.94,1348.85l-5.27-2.5-32.28,68.17,5.27,2.5,32.28-68.17h0Z"
        />
      </g>
      <g>
        <rect class="cls-8" x="1329.49" y="1381.31" width="74.85" height="5.26" transform="translate(-468.98 2026.75) rotate(-64.65)" />
        <path
          class="cls-3"
          d="M1384.92,1351.38l-31.79,67.12-4.22-2,31.79-67.12,4.22,2M1385.69,1351.1l-5.27-2.5-32.28,68.17,5.27,2.5,32.28-68.17h0Z"
        />
      </g>
      <g>
        <rect class="cls-8" x="1349.22" y="1297.49" width="74.85" height="5.25" transform="translate(-94.11 2491.94) rotate(-81.99)" />
        <path
          class="cls-3"
          d="M1394.13,1263.68l-10.36,73.54-4.63-.65,10.36-73.54,4.63.65M1394.79,1263.18l-5.78-.81-10.52,74.69,5.78.81,10.52-74.69h0Z"
        />
      </g>
      <g>
        <rect class="cls-8" x="1354.43" y="1298.23" width="74.85" height="5.25" transform="translate(-90.36 2497.73) rotate(-81.99)" />
        <path
          class="cls-3"
          d="M1399.34,1264.41l-10.36,73.54-4.63-.65,10.36-73.54,4.63.65M1400,1263.92l-5.78-.81-10.52,74.69,5.78.81,10.52-74.69h0Z"
        />
      </g>
      <g>
        <rect class="cls-8" x="1382.82" y="1176.45" width="5.26" height="74.85" transform="translate(-182.52 247.22) rotate(-9.57)" />
        <path
          class="cls-3"
          d="M1393.92,1250.1l-4.61.78-12.34-73.23,4.61-.78,12.34,73.23M1394.59,1250.58l-12.54-74.38-5.75.97,12.54,74.38,5.75-.97h0Z"
        />
      </g>
      <g>
        <rect class="cls-8" x="1388" y="1175.57" width="5.26" height="74.85" transform="translate(-182.31 248.07) rotate(-9.57)" />
        <path
          class="cls-3"
          d="M1399.11,1249.23l-4.61.78-12.35-73.23,4.61-.78,12.34,73.23M1399.78,1249.7l-12.54-74.38-5.75.97,12.54,74.38,5.75-.97h0Z"
        />
      </g>
      <g>
        <rect class="cls-8" x="1356.23" y="1095.94" width="5.25" height="74.85" transform="translate(-366.17 739.3) rotate(-26.96)" />
        <path
          class="cls-3"
          d="M1377.77,1165.4l-4.16,2.12-33.67-66.19,4.16-2.12,33.67,66.19M1378.55,1165.66l-34.2-67.23-5.2,2.65,34.2,67.23,5.2-2.64h0Z"
        />
      </g>
      <g>
        <rect class="cls-8" x="1360.92" y="1093.56" width="5.25" height="74.85" transform="translate(-364.59 741.2) rotate(-26.96)" />
        <path
          class="cls-3"
          d="M1382.46,1163.02l-4.17,2.12-33.67-66.19,4.17-2.12,33.67,66.19M1383.24,1163.27l-34.2-67.23-5.2,2.65,34.2,67.23,5.2-2.65h0Z"
        />
      </g>
      <g>
        <rect class="cls-8" x="1302.42" y="1023.11" width="5.25" height="73.12" transform="translate(-365.3 1249.32) rotate(-45.55)" />
        <path
          class="cls-3"
          d="M1332.58,1083.4l-3.27,3.34-51.79-50.8,3.27-3.34,51.79,50.8M1333.4,1083.39l-52.62-51.61-4.08,4.16,52.62,51.61,4.08-4.16h0Z"
        />
      </g>
      <g>
        <rect class="cls-8" x="1306.1" y="1019.35" width="5.26" height="73.12" transform="translate(-361.52 1250.85) rotate(-45.55)" />
        <path
          class="cls-3"
          d="M1336.26,1079.64l-3.27,3.34-51.79-50.8,3.27-3.34,51.79,50.8M1337.08,1079.63l-52.62-51.61-4.09,4.17,52.62,51.61,4.09-4.17h0Z"
        />
      </g>
      <g>
        <rect class="cls-8" x="1120.15" y="1544.25" width="2.64" height="5.29" transform="translate(-319.11 291.79) rotate(-12.99)" />
        <path
          class="cls-3"
          d="M1123.01,1548.96l-2.01.46-1.06-4.59,2.01-.46,1.06,4.59M1123.7,1549.4l-1.32-5.73-3.14.72,1.32,5.73,3.14-.72h0Z"
        />
      </g>
      <g>
        <rect class="cls-8" x="1122.75" y="1545.02" width="2.6" height="2.6" transform="translate(-318.67 292.07) rotate(-12.98)" />
        <path class="cls-3" d="M1125.26,1547.08l-1.97.45-.45-1.97,1.97-.45.45,1.97M1125.95,1547.51l-.71-3.1-3.1.71.71,3.1,3.1-.71h0Z" />
      </g>
      <g>
        <rect class="cls-8" x="1234.89" y="1514.13" width="2.64" height="5.3" transform="translate(-625.48 914.32) rotate(-32.89)" />
        <path
          class="cls-3"
          d="M1238.36,1518.2l-1.73,1.12-2.56-3.96,1.73-1.12,2.56,3.96M1239.16,1518.37l-3.19-4.93-2.71,1.75,3.19,4.93,2.71-1.75h0Z"
        />
      </g>
      <g>
        <rect class="cls-8" x="1237.13" y="1514.06" width="2.6" height="2.6" transform="translate(-624.49 915.73) rotate(-32.9)" />
        <path
          class="cls-3"
          d="M1239.83,1515.66l-1.7,1.1-1.1-1.7,1.7-1.1,1.1,1.7M1240.63,1515.83l-1.73-2.67-2.67,1.73,1.73,2.67,2.67-1.73h0Z"
        />
      </g>
      <g>
        <rect class="cls-8" x="1295.31" y="1473.39" width="2.64" height="5.29" transform="translate(-667.83 1447.33) rotate(-47.87)" />
        <path
          class="cls-3"
          d="M1299.08,1476.85l-1.38,1.53-3.5-3.16,1.38-1.53,3.5,3.16M1299.9,1476.81l-4.36-3.94-2.16,2.39,4.36,3.94,2.16-2.39h0Z"
        />
      </g>
      <g>
        <rect class="cls-8" x="1297.12" y="1472.79" width="2.6" height="2.6" transform="translate(-665.8 1447.99) rotate(-47.86)" />
        <path
          class="cls-3"
          d="M1299.84,1474.02l-1.36,1.5-1.5-1.36,1.36-1.5,1.5,1.36M1300.66,1473.98l-2.36-2.13-2.13,2.36,2.36,2.13,2.13-2.36h0Z"
        />
      </g>
      <g>
        <rect class="cls-8" x="1348.82" y="1410.53" width="2.64" height="5.3" transform="translate(-510.57 2011.28) rotate(-64.13)" />
        <path
          class="cls-3"
          d="M1352.71,1413.28l-.9,1.86-4.24-2.06.9-1.86,4.24,2.06M1353.49,1413.01l-5.29-2.57-1.41,2.9,5.29,2.57,1.41-2.9h0Z"
        />
      </g>
      <g>
        <rect class="cls-8" x="1350.01" y="1409.51" width="2.6" height="2.6" transform="translate(-507.53 2011.77) rotate(-64.15)" />
        <path
          class="cls-3"
          d="M1352.66,1410.35l-.88,1.82-1.82-.88.88-1.82,1.82.88M1353.43,1410.08l-2.86-1.39-1.39,2.86,2.86,1.39,1.39-2.86h0Z"
        />
      </g>
      <g>
        <rect class="cls-8" x="1383.2" y="1331.08" width="2.64" height="5.29" transform="translate(-130.52 2517.09) rotate(-81.92)" />
        <path class="cls-3" d="M1387,1333.04l-.29,2.04-4.67-.66.29-2.04,4.67.66M1387.66,1332.54l-5.82-.83-.45,3.19,5.82.83.45-3.19h0Z" />
      </g>
      <g>
        <rect class="cls-8" x="1383.61" y="1329.82" width="2.6" height="2.6" transform="translate(-128.54 2514.05) rotate(-81.88)" />
        <path class="cls-3" d="M1386.05,1330.26l-.28,2-2-.28.28-2,2,.28M1386.71,1329.77l-3.15-.45-.45,3.15,3.15.45.45-3.15h0Z" />
      </g>
      <g>
        <rect class="cls-8" x="1373.69" y="1160.77" width="5.29" height="2.64" transform="translate(-379.39 759.39) rotate(-27.26)" />
        <path
          class="cls-3"
          d="M1378.9,1161.93l-4.19,2.16-.94-1.83,4.19-2.16.94,1.83M1379.69,1162.18l-1.48-2.87-5.22,2.69,1.48,2.87,5.22-2.69h0Z"
        />
      </g>
      <g>
        <rect class="cls-8" x="1373.84" y="1158.44" width="2.6" height="2.6" transform="translate(-378.38 758.25) rotate(-27.25)" />
        <path
          class="cls-3"
          d="M1376.5,1160.18l-1.8.93-.93-1.8,1.8-.93.93,1.8M1377.28,1160.43l-1.46-2.83-2.83,1.46,1.46,2.83,2.83-1.46h0Z"
        />
      </g>
      <g>
        <rect class="cls-8" x="1327.49" y="1079.43" width="5.3" height="2.64" transform="translate(-373.35 1268.98) rotate(-45.4)" />
        <path
          class="cls-3"
          d="M1332.52,1079.8l-3.31,3.36-1.47-1.45,3.31-3.36,1.47,1.45M1333.35,1079.79l-2.3-2.26-4.12,4.18,2.3,2.26,4.12-4.18h0Z"
        />
      </g>
      <g>
        <rect class="cls-8" x="1326.97" y="1077.59" width="2.6" height="2.6" transform="translate(-372.56 1267.29) rotate(-45.41)" />
        <path
          class="cls-3"
          d="M1329.7,1078.88l-1.42,1.44-1.44-1.42,1.42-1.44,1.44,1.42M1330.52,1078.88l-2.27-2.23-2.23,2.27,2.27,2.23,2.23-2.27h0Z"
        />
      </g>
      <g>
        <rect class="cls-8" x="1272.91" y="1027.76" width="5.3" height="2.64" transform="translate(-246.5 1636.87) rotate(-60.66)" />
        <path
          class="cls-3"
          d="M1277.61,1027.53l-2.31,4.11-1.8-1.01,2.31-4.11,1.8,1.01M1278.4,1027.31l-2.81-1.58-2.88,5.12,2.81,1.58,2.88-5.12h0Z"
        />
      </g>
      <g>
        <rect class="cls-8" x="1271.96" y="1026.48" width="2.6" height="2.6" transform="translate(-246.52 1634.22) rotate(-60.66)" />
        <path
          class="cls-3"
          d="M1274.64,1027.39l-.99,1.76-1.76-.99.99-1.76,1.76.99M1275.43,1027.17l-2.77-1.56-1.56,2.77,2.77,1.56,1.56-2.77h0Z"
        />
      </g>
      <g>
        <rect class="cls-8" x="1215.97" y="995.74" width="5.3" height="2.64" transform="translate(-248.72 1568.09) rotate(-60.55)" />
        <path
          class="cls-3"
          d="M1220.67,995.51l-2.32,4.11-1.8-1.01,2.32-4.11,1.8,1.01M1221.46,995.29l-2.81-1.59-2.89,5.12,2.81,1.59,2.89-5.12h0Z"
        />
      </g>
      <g>
        <rect class="cls-8" x="1219.62" y="997.04" width="2.6" height="2.6" transform="translate(-248.68 1570.71) rotate(-60.55)" />
        <path
          class="cls-3"
          d="M1222.3,997.96l-.99,1.76-1.76-.99.99-1.76,1.76.99M1223.09,997.74l-2.77-1.56-1.56,2.77,2.77,1.56,1.56-2.77h0Z"
        />
      </g>
      <g>
        <rect class="cls-8" x="1280.87" y="1033.73" width="5.29" height="2.65" transform="translate(-355.48 1215.21) rotate(-45.16)" />
        <path
          class="cls-3"
          d="M1285.91,1034.11l-3.33,3.34-1.46-1.46,3.33-3.34,1.46,1.46M1286.73,1034.11l-2.28-2.27-4.15,4.16,2.28,2.27,4.15-4.16h0Z"
        />
      </g>
      <g>
        <rect class="cls-8" x="1284.09" y="1035.61" width="2.6" height="2.6" transform="translate(-356.28 1216.66) rotate(-45.14)" />
        <path
          class="cls-3"
          d="M1286.82,1036.9l-1.43,1.43-1.43-1.43,1.43-1.43,1.43,1.43M1287.64,1036.9l-2.25-2.24-2.24,2.25,2.25,2.24,2.24-2.25h0Z"
        />
      </g>
      <g>
        <rect class="cls-8" x="1343.33" y="1101.03" width="5.3" height="2.64" transform="translate(-354 732.33) rotate(-27.04)" />
        <path
          class="cls-3"
          d="M1348.55,1102.2l-4.2,2.14-.94-1.84,4.2-2.14.94,1.84M1349.33,1102.45l-1.46-2.87-5.23,2.67,1.46,2.87,5.23-2.67h0Z"
        />
      </g>
      <g>
        <rect class="cls-8" x="1345.89" y="1103.39" width="2.6" height="2.6" transform="translate(-355.05 733.69) rotate(-27.06)" />
        <path
          class="cls-3"
          d="M1348.55,1105.14l-1.8.92-.92-1.8,1.8-.92.92,1.8M1349.33,1105.39l-1.45-2.83-2.83,1.45,1.45,2.83,2.83-1.45h0Z"
        />
      </g>
      <g>
        <rect class="cls-8" x="1379.75" y="1179.23" width="5.3" height="2.64" transform="translate(-175.54 243.77) rotate(-9.48)" />
        <path class="cls-3" d="M1384.89,1181.18l-4.65.77-.34-2.03,4.65-.77.34,2.04M1385.56,1181.66l-.53-3.18-5.8.96.53,3.18,5.8-.96h0Z" />
      </g>
      <g>
        <rect class="cls-8" x="1381.55" y="1181.85" width="2.6" height="2.6" transform="translate(-175.22 242.65) rotate(-9.43)" />
        <path class="cls-3" d="M1384.01,1183.98l-1.99.33-.33-1.99,1.99-.33.33,1.99M1384.68,1184.46l-.52-3.14-3.14.52.52,3.14,3.14-.52h0Z" />
      </g>
      <g>
        <rect class="cls-8" x="1377.54" y="1349.99" width="2.64" height="5.29" transform="translate(-436.26 2013.11) rotate(-64.44)" />
        <path
          class="cls-3"
          d="M1381.44,1352.72l-.89,1.86-4.25-2.03.89-1.86,4.25,2.03M1382.21,1352.45l-5.3-2.53-1.39,2.91,5.3,2.53,1.39-2.91h0Z"
        />
      </g>
      <g>
        <rect class="cls-8" x="1376.44" y="1353.72" width="2.6" height="2.6" transform="translate(-438.59 2014.73) rotate(-64.49)" />
        <path
          class="cls-3"
          d="M1379.09,1354.55l-.87,1.82-1.82-.87.87-1.82,1.82.87M1379.86,1354.27l-2.87-1.37-1.37,2.87,2.87,1.37,1.37-2.87h0Z"
        />
      </g>
      <g>
        <rect class="cls-8" x="1340.12" y="1423.55" width="2.64" height="5.29" transform="translate(-616.03 1468.9) rotate(-48)" />
        <path
          class="cls-3"
          d="M1343.89,1427.01l-1.38,1.53-3.5-3.15,1.38-1.53,3.5,3.15M1344.71,1426.97l-4.37-3.93-2.16,2.4,4.37,3.93,2.16-2.4h0Z"
        />
      </g>
      <g>
        <rect class="cls-8" x="1338.4" y="1426.87" width="2.6" height="2.6" transform="translate(-618.07 1468.31) rotate(-48.01)" />
        <path
          class="cls-3"
          d="M1341.12,1428.1l-1.35,1.5-1.5-1.35,1.35-1.5,1.5,1.35M1341.94,1428.05l-2.36-2.13-2.13,2.36,2.37,2.13,2.13-2.36h0Z"
        />
      </g>
      <g>
        <rect class="cls-8" x="1289.51" y="1478.33" width="2.65" height="5.3" transform="translate(-602.11 955.31) rotate(-33.4)" />
        <path
          class="cls-3"
          d="M1292.99,1482.38l-1.72,1.14-2.6-3.94,1.72-1.14,2.6,3.94M1293.79,1482.55l-3.24-4.9-2.69,1.78,3.24,4.9,2.69-1.78h0Z"
        />
      </g>
      <g>
        <rect class="cls-8" x="1287.33" y="1481.15" width="2.6" height="2.6" transform="translate(-603.11 953.72) rotate(-33.39)" />
        <path
          class="cls-3"
          d="M1290.03,1482.73l-1.69,1.11-1.11-1.69,1.69-1.11,1.11,1.69M1290.84,1482.9l-1.75-2.66-2.66,1.75,1.75,2.66,2.66-1.75h0Z"
        />
      </g>
      <g>
        <rect class="cls-8" x="1217.78" y="1521.99" width="2.64" height="5.3" transform="translate(-308.84 309.69) rotate(-12.86)" />
        <path
          class="cls-3"
          d="M1220.63,1526.7l-2.01.46-1.05-4.6,2.01-.46,1.05,4.6M1221.33,1527.14l-1.31-5.73-3.14.72,1.31,5.73,3.14-.72h0Z"
        />
      </g>
      <g>
        <rect class="cls-8" x="1215.23" y="1523.93" width="2.6" height="2.6" transform="translate(-308.48 308.42) rotate(-12.84)" />
        <path class="cls-3" d="M1217.74,1526l-1.97.45-.45-1.97,1.97-.45.45,1.97M1218.44,1526.43l-.71-3.1-3.1.71.71,3.1,3.1-.71h0Z" />
      </g>
      <polyline class="cls-4" points="1284.44 1031.84 1280.3 1036 1275.52 1032.43 1278.4 1027.31" />
      <polygon class="cls-4" points="1347.87 1099.58 1342.63 1102.25 1329.22 1083.98 1333.35 1079.79 1347.87 1099.58" />
      <polygon class="cls-4" points="1379.69 1162.18 1374.46 1164.87 1379.24 1179.44 1385.03 1178.48 1379.69 1162.18" />
      <polygon class="cls-4" points="1387.2 1335.73 1381.39 1334.91 1376.91 1349.92 1382.21 1352.45 1387.2 1335.73" />
      <polygon class="cls-4" points="1352.08 1415.91 1346.79 1413.35 1340.34 1423.04 1344.71 1426.97 1352.08 1415.91" />
      <polygon class="cls-4" points="1297.73 1479.2 1293.38 1475.26 1290.56 1477.64 1293.79 1482.55 1297.73 1479.2" />
      <polygon class="cls-4" points="1236.45 1520.12 1233.26 1515.19 1220.02 1521.41 1221.33 1527.14 1236.45 1520.12" />
      <polygon class="cls-4" points="1118.47 1550.59 1117.15 1544.86 1119.24 1544.39 1120.56 1550.12 1118.47 1550.59" />
      <rect class="cls-3" x="398.41" y="1519.04" width="62.7" height="23.51" />
      <polygon
        class="cls-3"
        points="567.3 1347.14 594.96 1342.41 594.96 1318.88 567.21 1315.17 567.21 1314.96 567.21 1283.61 595.94 1283.61 595.98 1283.82 595.98 1277.73 602.48 1277.73 602.48 1287.74 602.48 1287.53 633.83 1287.53 633.83 1314.96 606.39 1318.88 606.39 1342.41 633.83 1346.31 633.83 1373.71 633.83 1373.76 602.48 1373.76 602.48 1383.74 602.48 1383.77 595.98 1383.74 595.98 1377.66 567.12 1377.66 567.21 1377.66 567.21 1347.38 567.3 1347.14"
      />
      <path
        class="cls-4"
        d="M717.43,1351.65c10.82,0,19.58-8.78,19.58-19.6s-8.75-19.6-19.58-19.6-19.6,8.78-19.6,19.6,8.78,19.6,19.6,19.6Z"
      />
      <rect class="cls-3" x="431.36" y="1371.25" width="62.7" height="31.35" />
      <rect class="cls-3" x="431.36" y="1261.52" width="62.7" height="31.35" />
      <polygon class="cls-4" points="712.16 1393.4 689.99 1371.25 667.83 1393.4 689.99 1415.58 712.16 1393.4" />
      <polygon class="cls-4" points="689.99 1292.87 712.16 1270.7 689.99 1248.52 667.83 1270.7 689.99 1292.87" />
      <path
        class="cls-4"
        d="M851.03,1479.86c7.57,0,13.7-6.15,13.7-13.72s-6.13-13.72-13.7-13.72-13.72,6.15-13.72,13.72,6.13,13.72,13.72,13.72Z"
      />
      <path
        class="cls-4"
        d="M851.03,1231.01c7.57,0,13.7-6.13,13.7-13.72s-6.13-13.7-13.7-13.7-13.72,6.13-13.72,13.7,6.13,13.72,13.72,13.72Z"
      />
      <rect class="cls-3" x="407.83" y="1136.12" width="62.7" height="23.51" />
      <rect class="cls-3" x="431.36" y="1324.22" width="62.7" height="23.51" />
      <rect class="cls-3" x="196.23" y="1135.84" width="62.7" height="23.79" />
      <rect class="cls-3" x="1012.08" y="972.83" width="130.81" height="5.85" />
    </g>
    <g id="EXTRAS">
      <text class="cls-6" transform="translate(189.54 1256.96)">
        <tspan x="0" y="0">CHEC</tspan>
        <tspan class="cls-2" x="58.86" y="0">K</tspan>
        <tspan x="72.25" y="0">-IN</tspan>
      </text>
      <path
        class="cls-5"
        d="M826.41,686.09c0,9.87,8.03,17.89,17.89,17.89s17.89-8.03,17.89-17.89-8.03-17.89-17.89-17.89-17.89,8.03-17.89,17.89Z"
      />
      <path
        class="cls-5"
        d="M879.91,1770.8c0,9.87,8.03,17.89,17.89,17.89s17.89-8.03,17.89-17.89-8.03-17.89-17.89-17.89-17.89,8.03-17.89,17.89Z"
      />
      <path
        class="cls-5"
        d="M635.64,2146.01c0,9.87,8.03,17.89,17.89,17.89s17.89-8.03,17.89-17.89-8.03-17.89-17.89-17.89-17.89,8.03-17.89,17.89Z"
      />
      <path
        class="cls-5"
        d="M865.18,2142.29c0,9.87,8.03,17.89,17.89,17.89s17.89-8.03,17.89-17.89-8.03-17.89-17.89-17.89-17.89,8.03-17.89,17.89Z"
      />
      <path
        class="cls-5"
        d="M862.2,150.34c0-9.87-8.03-17.89-17.89-17.89s-17.89,8.03-17.89,17.89,8.03,17.89,17.89,17.89,17.89-8.03,17.89-17.89Z"
      />
      <path
        class="cls-5"
        d="M1056.17,1044.58c0,9.87,8.03,17.89,17.89,17.89s17.89-8.03,17.89-17.89-8.03-17.89-17.89-17.89-17.89,8.03-17.89,17.89Z"
      />
      <path
        class="cls-5"
        d="M1159.19,1067.07c0,9.87,8.03,17.89,17.89,17.89s17.89-8.03,17.89-17.89-8.03-17.89-17.89-17.89-17.89,8.03-17.89,17.89Z"
      />
      <path
        class="cls-5"
        d="M1056.17,1496.63c0-9.87,8.03-17.89,17.89-17.89,9.87,0,17.89,8.03,17.89,17.89,0,9.87-8.03,17.89-17.89,17.89-9.87,0-17.89-8.03-17.89-17.89Z"
      />
      <path
        class="cls-5"
        d="M1159.19,1455.46c0-9.87,8.03-17.89,17.89-17.89,9.87,0,17.89,8.03,17.89,17.89s-8.03,17.89-17.89,17.89c-9.87,0-17.89-8.03-17.89-17.89Z"
      />
      <g>
        <path
          class="cls-5"
          d="M701.87,1085.14v-.33c0-5.52,2.86-8.96,6.77-8.96,1.61,0,2.83.41,4.06,1.4l-1.02,1.51c-.74-.71-1.74-1.23-3.01-1.23-2.78,0-4.98,2.73-4.98,7.31v.23c0,4.78,2.12,7.38,5.06,7.38.95,0,1.79-.28,2.4-.69v-5.7h-3.42v-1.63h5.13v8.28c-1.07.77-2.55,1.38-4.24,1.38-4.04,0-6.74-3.27-6.74-8.94Z"
        />
        <path class="cls-5" d="M716.07,1076.02h1.79v16.22h6.26v1.66h-8.05v-17.88Z" />
        <path
          class="cls-5"
          d="M730.63,1075.92h1.86l5.31,17.98h-1.87l-1.35-4.67h-6.13l-1.35,4.67h-1.76l5.29-17.98ZM734.13,1087.62l-2.58-9.04-2.63,9.04h5.21Z"
        />
        <path
          class="cls-5"
          d="M738.83,1091.99l1.12-1.43c1.1,1.07,2.35,1.92,3.86,1.92,1.87,0,3.09-1.33,3.09-2.96v-.05c0-1.25-.54-2.25-3.17-3.78-3.27-1.89-4.19-3.27-4.19-5.31v-.05c0-2.55,1.97-4.52,4.8-4.52,1.46,0,3.01.51,4.34,1.79l-1.1,1.41c-.97-.92-2.09-1.53-3.32-1.53-1.76,0-2.91,1.23-2.91,2.71v.03c0,1.41.64,2.27,3.4,3.91,3.24,1.92,3.96,3.35,3.96,5.31v.05c0,2.66-2.09,4.67-4.88,4.67-1.74,0-3.4-.59-5.01-2.15Z"
        />
        <path
          class="cls-5"
          d="M749.84,1091.99l1.12-1.43c1.1,1.07,2.35,1.92,3.86,1.92,1.87,0,3.09-1.33,3.09-2.96v-.05c0-1.25-.54-2.25-3.17-3.78-3.27-1.89-4.19-3.27-4.19-5.31v-.05c0-2.55,1.97-4.52,4.8-4.52,1.46,0,3.01.51,4.34,1.79l-1.1,1.41c-.97-.92-2.09-1.53-3.32-1.53-1.76,0-2.91,1.23-2.91,2.71v.03c0,1.41.64,2.27,3.4,3.91,3.24,1.92,3.96,3.35,3.96,5.31v.05c0,2.66-2.09,4.67-4.88,4.67-1.74,0-3.4-.59-5.01-2.15Z"
        />
        <path
          class="cls-5"
          d="M760.8,1076.02h1.86l3.22,14.53,3.37-14.56h1.58l3.37,14.56,3.22-14.53h1.84l-4.21,17.98h-1.66l-3.37-14.33-3.37,14.33h-1.66l-4.19-17.98Z"
        />
        <path
          class="cls-5"
          d="M784.68,1075.92h1.86l5.31,17.98h-1.87l-1.35-4.67h-6.13l-1.35,4.67h-1.76l5.29-17.98ZM788.18,1087.62l-2.58-9.04-2.63,9.04h5.21Z"
        />
        <path
          class="cls-5"
          d="M794.29,1076.02h4.32c1.81,0,3.29.56,4.27,1.53.89.89,1.41,2.22,1.41,3.83v.08c0,2.71-1.46,4.37-3.53,5.06l3.99,7.38h-2.04l-3.73-6.97c-.23.03-.46.03-.69.03h-2.2v6.95h-1.79v-17.88ZM798.07,1085.32c3.17,0,4.44-1.58,4.44-3.83v-.03c0-2.5-1.56-3.78-4.04-3.78h-2.4v7.64h1.99Z"
        />
        <path class="cls-5" d="M807.22,1076.02h8.63v1.66h-6.84v6.36h6.16v1.66h-6.16v6.54h6.95v1.66h-8.74v-17.88Z" />
      </g>
      <rect class="cls-5" x="830.7" y="997.14" width="31.8" height="119.33" />
      <rect class="cls-5" x="181.38" y="1276.3" width="119.33" height="33.16" />
      <path
        class="cls-5"
        d="M862.2,410.51c0-9.87-8.03-17.89-17.89-17.89s-17.89,8.03-17.89,17.89,8.03,17.89,17.89,17.89,17.89-8.03,17.89-17.89Z"
      />
      <rect class="cls-5" x="1242.44" y="1273.87" width="33.17" height="88.02" />
      <rect class="cls-5" x="1330.94" y="1211.2" width="33.17" height="88.02" />
      <rect class="cls-5" x="1242.44" y="1148.52" width="33.17" height="119.35" />
      <g>
        <path
          class="cls-5"
          d="M1113.95,1248.07c1,.61,2.45,1.12,3.98,1.12,2.27,0,3.6-1.2,3.6-2.94,0-1.61-.92-2.53-3.24-3.42-2.81-1-4.55-2.45-4.55-4.88,0-2.68,2.22-4.67,5.57-4.67,1.76,0,3.04.41,3.81.84l-.61,1.81c-.56-.31-1.71-.82-3.27-.82-2.35,0-3.24,1.41-3.24,2.58,0,1.61,1.05,2.4,3.42,3.32,2.91,1.12,4.39,2.53,4.39,5.06,0,2.66-1.97,4.96-6.03,4.96-1.66,0-3.47-.49-4.39-1.1l.56-1.87Z"
        />
        <path
          class="cls-5"
          d="M1126.85,1250.78v-17.22h2.43l5.52,8.71c1.28,2.02,2.27,3.83,3.09,5.59l.05-.03c-.21-2.3-.26-4.39-.26-7.08v-7.2h2.09v17.22h-2.25l-5.47-8.74c-1.2-1.92-2.35-3.88-3.22-5.75l-.08.03c.13,2.17.18,4.24.18,7.1v7.36h-2.09Z"
        />
        <path
          class="cls-5"
          d="M1146.45,1245.36l-1.79,5.42h-2.3l5.85-17.22h2.68l5.88,17.22h-2.38l-1.84-5.42h-6.1ZM1152.09,1243.63l-1.69-4.96c-.38-1.12-.64-2.15-.89-3.14h-.05c-.25,1.02-.54,2.07-.87,3.12l-1.69,4.98h5.18Z"
        />
        <path
          class="cls-5"
          d="M1170.94,1250.22c-.82.41-2.45.82-4.55.82-4.85,0-8.51-3.07-8.51-8.71s3.65-9.04,8.99-9.04c2.15,0,3.5.46,4.09.77l-.54,1.81c-.84-.41-2.04-.72-3.47-.72-4.04,0-6.72,2.58-6.72,7.1,0,4.21,2.43,6.92,6.62,6.92,1.35,0,2.73-.28,3.63-.71l.46,1.76Z"
        />
        <path
          class="cls-5"
          d="M1173.73,1233.56h2.22v8.3h.08c.46-.66.92-1.28,1.35-1.84l5.26-6.46h2.76l-6.23,7.3,6.72,9.91h-2.63l-5.67-8.46-1.63,1.89v6.56h-2.22v-17.22Z"
        />
        <path
          class="cls-5"
          d="M1187.27,1248.07c1,.61,2.45,1.12,3.98,1.12,2.27,0,3.6-1.2,3.6-2.94,0-1.61-.92-2.53-3.24-3.42-2.81-1-4.55-2.45-4.55-4.88,0-2.68,2.22-4.67,5.57-4.67,1.76,0,3.04.41,3.81.84l-.61,1.81c-.56-.31-1.71-.82-3.27-.82-2.35,0-3.24,1.41-3.24,2.58,0,1.61,1.05,2.4,3.42,3.32,2.91,1.12,4.39,2.53,4.39,5.06,0,2.66-1.97,4.96-6.03,4.96-1.66,0-3.47-.49-4.39-1.1l.56-1.87Z"
        />
        <path
          class="cls-5"
          d="M1216.37,1250.78c-.49-.49-.95-.97-1.61-1.69-1.48,1.38-3.14,1.97-5.03,1.97-3.35,0-5.29-2.25-5.29-4.83,0-2.35,1.4-3.96,3.37-5.03v-.08c-.89-1.12-1.43-2.38-1.43-3.6,0-2.09,1.51-4.24,4.37-4.24,2.15,0,3.81,1.43,3.81,3.65,0,1.81-1.05,3.24-3.78,4.6v.08c1.46,1.69,3.09,3.58,4.19,4.8.79-1.2,1.33-2.86,1.66-5.03h2.04c-.46,2.71-1.2,4.85-2.48,6.36.92.97,1.81,1.94,2.83,3.04h-2.66ZM1213.66,1247.81c-1.02-1.12-2.94-3.22-4.85-5.42-.92.59-2.22,1.69-2.22,3.5,0,1.97,1.46,3.47,3.53,3.47,1.48,0,2.73-.66,3.55-1.56ZM1208.42,1237.32c0,1.23.56,2.17,1.3,3.09,1.74-1,2.86-1.92,2.86-3.37,0-1.05-.59-2.22-1.99-2.22s-2.17,1.18-2.17,2.5Z"
        />
        <path
          class="cls-5"
          d="M1051.67,1260.79c1.12-.23,2.73-.36,4.27-.36,2.38,0,3.91.43,4.98,1.41.87.77,1.35,1.94,1.35,3.27,0,2.27-1.43,3.78-3.24,4.39v.08c1.33.46,2.12,1.69,2.53,3.47.56,2.4.97,4.06,1.33,4.73h-2.3c-.28-.49-.66-1.97-1.15-4.11-.51-2.38-1.43-3.27-3.45-3.35h-2.09v7.46h-2.22v-16.99ZM1053.89,1268.63h2.27c2.38,0,3.88-1.3,3.88-3.27,0-2.22-1.61-3.19-3.96-3.22-1.07,0-1.84.1-2.2.21v6.28Z"
        />
        <path class="cls-5" d="M1074.33,1269.71h-6.69v6.21h7.46v1.86h-9.68v-17.22h9.3v1.86h-7.08v5.44h6.69v1.84Z" />
        <path class="cls-5" d="M1077.98,1260.56h9.27v1.86h-7.05v5.72h6.51v1.84h-6.51v7.79h-2.22v-17.22Z" />
        <path
          class="cls-5"
          d="M1090.42,1260.79c1.12-.23,2.73-.36,4.27-.36,2.38,0,3.91.43,4.98,1.41.87.77,1.35,1.94,1.35,3.27,0,2.27-1.43,3.78-3.24,4.39v.08c1.33.46,2.12,1.69,2.53,3.47.56,2.4.97,4.06,1.33,4.73h-2.3c-.28-.49-.66-1.97-1.15-4.11-.51-2.38-1.43-3.27-3.45-3.35h-2.09v7.46h-2.22v-16.99ZM1092.64,1268.63h2.27c2.38,0,3.88-1.3,3.88-3.27,0-2.22-1.61-3.19-3.96-3.22-1.07,0-1.84.1-2.2.21v6.28Z"
        />
        <path class="cls-5" d="M1113.08,1269.71h-6.69v6.21h7.46v1.86h-9.68v-17.22h9.3v1.86h-7.08v5.44h6.69v1.84Z" />
        <path
          class="cls-5"
          d="M1116.43,1275.07c1,.61,2.45,1.12,3.98,1.12,2.27,0,3.6-1.2,3.6-2.94,0-1.61-.92-2.53-3.24-3.42-2.81-1-4.55-2.45-4.55-4.88,0-2.68,2.22-4.67,5.57-4.67,1.76,0,3.04.41,3.81.84l-.61,1.81c-.56-.31-1.71-.82-3.27-.82-2.35,0-3.24,1.41-3.24,2.58,0,1.61,1.05,2.4,3.42,3.32,2.91,1.12,4.39,2.53,4.39,5.06,0,2.66-1.97,4.96-6.03,4.96-1.66,0-3.47-.49-4.39-1.1l.56-1.87Z"
        />
        <path class="cls-5" d="M1131.55,1260.56v7.2h8.33v-7.2h2.25v17.22h-2.25v-8.07h-8.33v8.07h-2.22v-17.22h2.22Z" />
        <path
          class="cls-5"
          d="M1160.5,1270.22c-.13-2.4-.28-5.29-.26-7.43h-.08c-.59,2.02-1.3,4.16-2.17,6.54l-3.04,8.35h-1.69l-2.78-8.2c-.82-2.43-1.51-4.65-1.99-6.69h-.05c-.05,2.15-.18,5.03-.33,7.61l-.46,7.38h-2.12l1.2-17.22h2.84l2.94,8.33c.71,2.12,1.3,4.01,1.74,5.8h.08c.43-1.74,1.05-3.63,1.81-5.8l3.07-8.33h2.83l1.07,17.22h-2.17l-.43-7.56Z"
        />
        <path class="cls-5" d="M1175.44,1269.71h-6.69v6.21h7.46v1.86h-9.68v-17.22h9.3v1.86h-7.08v5.44h6.69v1.84Z" />
        <path
          class="cls-5"
          d="M1179.1,1277.78v-17.22h2.43l5.52,8.71c1.28,2.02,2.27,3.83,3.09,5.59l.05-.03c-.21-2.3-.26-4.39-.26-7.08v-7.2h2.09v17.22h-2.25l-5.47-8.74c-1.2-1.92-2.35-3.88-3.22-5.75l-.08.03c.13,2.17.18,4.24.18,7.1v7.36h-2.09Z"
        />
        <path class="cls-5" d="M1199.18,1262.45h-5.24v-1.89h12.75v1.89h-5.26v15.33h-2.25v-15.33Z" />
        <path
          class="cls-5"
          d="M1208.14,1275.07c1,.61,2.45,1.12,3.98,1.12,2.27,0,3.6-1.2,3.6-2.94,0-1.61-.92-2.53-3.24-3.42-2.81-1-4.55-2.45-4.55-4.88,0-2.68,2.22-4.67,5.57-4.67,1.76,0,3.04.41,3.81.84l-.61,1.81c-.56-.31-1.71-.82-3.27-.82-2.35,0-3.24,1.41-3.24,2.58,0,1.61,1.05,2.4,3.42,3.32,2.91,1.12,4.39,2.53,4.39,5.06,0,2.66-1.97,4.96-6.03,4.96-1.66,0-3.47-.49-4.39-1.1l.56-1.87Z"
        />
      </g>
      <path
        class="cls-5"
        d="M671.11,1580.23c0,9.87,8.03,17.89,17.89,17.89s17.89-8.03,17.89-17.89-8.03-17.89-17.89-17.89-17.89,8.03-17.89,17.89Z"
      />
      <g>
        <rect class="cls-9" x=".15" y="108.86" width="69.39" height="69.39" />
        <rect class="cls-1" x=".15" y="208.44" width="69.39" height="69.39" />
        <g>
          <path
            class="cls-5"
            d="M91.11,133.09h2.23l3.84,17.36,4.03-17.39h1.89l4.03,17.39,3.84-17.36h2.2l-5.03,21.47h-1.98l-4.03-17.11-4.03,17.11h-1.98l-5-21.47Z"
          />
          <path
            class="cls-5"
            d="M114.81,144.01v-.46c0-6.65,3.05-10.77,7.26-10.77s7.23,4.06,7.23,10.74v.46c0,6.65-3.08,10.77-7.29,10.77s-7.2-4.06-7.2-10.74ZM127.1,143.95v-.37c0-5.52-2.2-8.78-5.06-8.78s-5.03,3.26-5.03,8.78v.37c0,5.52,2.17,8.78,5.03,8.78s5.06-3.26,5.06-8.78Z"
          />
          <path
            class="cls-5"
            d="M132.74,133.09h5.16c2.17,0,3.93.67,5.09,1.83,1.07,1.07,1.68,2.65,1.68,4.58v.09c0,3.23-1.74,5.22-4.21,6.04l4.76,8.81h-2.44l-4.45-8.33c-.27.03-.55.03-.82.03h-2.62v8.3h-2.13v-21.35ZM137.26,144.19c3.78,0,5.31-1.89,5.31-4.58v-.03c0-2.99-1.86-4.51-4.82-4.51h-2.87v9.12h2.38Z"
          />
          <path class="cls-5" d="M148.18,133.09h2.13v19.37h7.47v1.98h-9.61v-21.35Z" />
          <path
            class="cls-5"
            d="M160.53,133.09h4.42c5.12,0,8.54,3.9,8.54,10.46v.43c0,6.53-3.42,10.46-8.54,10.46h-4.42v-21.35ZM162.67,135.04v17.45h2.2c3.84,0,6.47-3.08,6.47-8.54v-.37c0-5.43-2.62-8.54-6.47-8.54h-2.2Z"
          />
        </g>
        <g>
          <path class="cls-5" d="M92.23,232.67h10.1v1.98h-7.96v7.9h7.14v1.98h-7.14v9.49h-2.13v-21.35Z" />
          <path
            class="cls-5"
            d="M105.38,232.67h5.16c2.17,0,3.93.67,5.09,1.83,1.07,1.07,1.68,2.65,1.68,4.58v.09c0,3.23-1.74,5.22-4.21,6.04l4.76,8.82h-2.44l-4.45-8.33c-.27.03-.55.03-.82.03h-2.62v8.3h-2.13v-21.35ZM109.9,243.77c3.78,0,5.31-1.89,5.31-4.58v-.03c0-2.99-1.86-4.51-4.82-4.51h-2.87v9.12h2.38Z"
          />
          <path
            class="cls-5"
            d="M125.7,232.55h2.23l6.34,21.47h-2.23l-1.62-5.55h-7.32l-1.62,5.55h-2.1l6.31-21.47ZM129.88,246.52l-3.08-10.8-3.14,10.8h6.22Z"
          />
          <path class="cls-5" d="M137.17,232.67h2.07l8.57,16.44v-16.44h2.07v21.35h-1.86l-8.78-16.87v16.87h-2.07v-21.35Z" />
          <path
            class="cls-5"
            d="M153.36,243.53v-.33c0-6.89,3.42-10.74,7.72-10.74,1.92,0,3.33.73,4.45,1.77l-1.19,1.68c-.92-.82-2.01-1.43-3.32-1.43-3.05,0-5.49,3.36-5.49,8.75v.21c0,5.55,2.44,8.78,5.46,8.78,1.31,0,2.47-.64,3.48-1.59l1.16,1.65c-1.25,1.16-2.84,1.95-4.76,1.95-4.3,0-7.5-3.75-7.5-10.71Z"
          />
          <path class="cls-5" d="M168.58,232.67h10.31v1.98h-8.17v7.6h7.35v1.98h-7.35v7.81h8.3v1.98h-10.43v-21.35Z" />
        </g>
        <g>
          <path
            class="cls-5"
            d="M2.43.34h13.89l11.31,18.36L38.95.34h13.89v47.42h-13.07v-27.23l-12.13,18.56h-.27l-12.06-18.49v27.17H2.43V.34Z"
          />
          <path
            class="cls-5"
            d="M78.84,0h12.67l20.19,47.76h-14.09l-3.39-8.54h-18.36l-3.39,8.54h-13.75L78.84,0ZM90.36,29l-5.28-13.41-5.35,13.41h10.64Z"
          />
          <path
            class="cls-5"
            d="M117.52.34h20.39c11.92,0,19.65,6.17,19.65,16.6v.14c0,11.25-8.6,17.14-20.32,17.14h-6.57v13.55h-13.14V.34ZM136.96,23.91c4.61,0,7.52-2.51,7.52-6.23v-.14c0-4.07-2.91-6.17-7.59-6.17h-6.23v12.53h6.3Z"
          />
          <path
            class="cls-5"
            d="M185.26.34h13.14v19.24L214.8.34h15.51l-17.95,20.32,18.56,27.1h-15.72l-11.99-17.61-4.81,5.28v12.33h-13.14V.34Z"
          />
          <path class="cls-5" d="M236.4.34h38.14v11.11h-25.13v7.25h22.76v10.37h-22.76v7.52h25.47v11.18h-38.48V.34Z" />
          <path class="cls-5" d="M297.57,30.01L279.48.34h14.97l9.76,17.28L314.03.34h14.7l-18.02,29.47v17.95h-13.14v-17.75Z" />
          <path class="cls-5" d="M330.56,11.11h13.35v13.21h-13.35v-13.21ZM330.56,34.55h13.35v13.21h-13.35v-13.21Z" />
        </g>
      </g>
    </g>
    <g id="TABLES">
      <g id="TABLE-20">
        <path
          class="cls-5"
          d="M560.36,1916.9l3.9-7.5c1.05-2.07,1.41-2.94,1.41-3.81,0-.96-.54-1.77-1.8-1.77-1.08,0-2.04.39-3.09.96v-4.35c1.11-.51,2.52-.96,4.26-.96,2.94,0,5.07,1.8,5.07,5.07v.15c0,1.83-.51,3.3-1.68,5.46l-3.36,6.21h4.95v4.44h-9.66v-3.9Z"
        />
        <path
          class="cls-5"
          d="M571.34,1910.96v-1.29c0-6.99,2.13-10.26,5.91-10.26s5.85,3.24,5.85,10.23v1.29c0,6.99-2.16,10.26-5.88,10.26s-5.88-3.24-5.88-10.23ZM578.63,1911.2v-1.8c0-4.95-.57-5.85-1.41-5.85s-1.41.9-1.41,5.85v1.8c0,4.92.57,5.82,1.41,5.82s1.41-.9,1.41-5.82Z"
        />
        <rect class="cls-1" x="516" y="1866.14" width="33.16" height="88" />
      </g>
      <g id="TABLE-19">
        <path class="cls-5" d="M564.41,2028.66l-2.16.66v-4.38l3.45-1.08h3.21v21.09h-4.5v-16.29Z" />
        <path
          class="cls-5"
          d="M577.22,2037.21c-.66.45-1.44.69-2.31.69-2.16,0-4.32-1.32-4.32-6.21v-.72c0-4.86,2.34-7.41,5.34-7.41,1.38,0,2.49.39,3.39,1.29,1.47,1.47,2.22,4.32,2.22,8.88v.6c0,8.67-3.03,10.92-6.72,10.92-1.2,0-2.25-.21-3.12-.54v-4.08c.78.36,1.77.66,2.76.66,1.62,0,2.64-1.02,2.76-4.08ZM577.22,2034.03v-2.97c0-2.79-.51-3.69-1.23-3.69s-1.2.78-1.2,3.39v.75c0,2.28.54,2.88,1.38,2.88.42,0,.78-.15,1.05-.36Z"
        />
        <rect class="cls-1" x="516" y="1990.3" width="33.16" height="88" />
      </g>
      <g id="TABLE-18">
        <rect class="cls-1" x="601.88" y="2274.03" width="88" height="33.16" />
        <path class="cls-5" d="M638.14,2245.72l-2.16.66v-4.38s3.45-1.08,3.45-1.08h3.21v21.09h-4.5v-16.29Z" />
        <path
          class="cls-5"
          d="M644.38,2256.28v-.24c0-2.46,1.05-4.2,2.37-5.13-1.32-.96-2.13-2.52-2.13-4.47v-.21c0-3.36,2.25-5.61,5.31-5.61s5.31,2.22,5.31,5.58v.21c0,1.92-.81,3.51-2.16,4.47,1.35.93,2.4,2.64,2.4,5.13v.24c0,3.78-2.25,6.15-5.58,6.15s-5.52-2.34-5.52-6.12ZM651.19,2255.95v-.27c0-2.13-.51-2.91-1.26-2.91s-1.29.78-1.29,2.91v.27c0,2.19.57,2.88,1.29,2.88s1.26-.69,1.26-2.88ZM651.1,2246.89v-.21c0-1.95-.51-2.55-1.17-2.55s-1.17.6-1.17,2.55v.21c0,1.8.45,2.58,1.17,2.58s1.17-.78,1.17-2.58Z"
        />
      </g>
      <g id="TABLE-17">
        <path class="cls-5" d="M759.3,2245.81l-2.16.66v-4.38l3.45-1.08h3.21v21.09h-4.5v-16.29Z" />
        <path class="cls-5" d="M770.43,2245.51h-4.95v-4.41s9.45,0,9.45,0v2.88l-3.87,18.12h-4.35l3.72-16.59Z" />
        <rect class="cls-1" x="722.14" y="2274.03" width="88" height="33.16" />
      </g>
      <g id="TABLE-16">
        <path class="cls-5" d="M878.84,2245.61l-2.16.66v-4.38s3.45-1.08,3.45-1.08h3.21v21.09h-4.5v-16.29Z" />
        <path
          class="cls-5"
          d="M887.39,2260.97c-1.47-1.47-2.22-4.32-2.22-8.85v-.6c0-8.7,3-10.92,6.69-10.92,1.11,0,2.04.18,2.82.42v4.11c-.66-.33-1.56-.57-2.46-.57-1.68,0-2.73,1.11-2.76,4.32.63-.45,1.44-.72,2.34-.72,2.13,0,4.32,1.35,4.32,6.06v.81c0,4.65-2.34,7.26-5.34,7.26-1.38,0-2.49-.42-3.39-1.32ZM891.92,2255.21v-.81c0-2.1-.6-2.7-1.38-2.7-.45,0-.78.15-1.05.33v2.76c0,2.76.51,3.66,1.23,3.66s1.2-.78,1.2-3.24Z"
        />
        <rect class="cls-1" x="842.4" y="2274.03" width="88" height="33.16" />
      </g>
      <g id="TABLE-15">
        <path class="cls-5" d="M982.17,2212.54l-2.16.66v-4.38s3.45-1.08,3.45-1.08h3.21v21.09h-4.5v-16.29Z" />
        <path
          class="cls-5"
          d="M988.68,2228.38v-4.35c.78.39,1.68.63,2.7.63,1.53,0,2.55-1.11,2.55-2.97v-.15c0-1.8-.84-2.49-1.95-2.49-.54,0-.9.06-1.35.24l-2.07-1.2.54-10.47h8.49v4.26h-5.13s-.21,3.33-.21,3.33c.24-.03.57-.06.96-.06,2.76,0,5.19,1.65,5.19,6.27v.24c0,4.77-2.67,7.29-6.51,7.29-1.23,0-2.43-.24-3.21-.57Z"
        />
        <rect class="cls-1" x="1012.03" y="2174.28" width="33.16" height="88" />
      </g>
      <g id="TABLE-14">
        <path class="cls-5" d="M981.22,2095.61l-2.16.66v-4.38l3.45-1.08h3.21v21.09h-4.5v-16.29Z" />
        <path
          class="cls-5"
          d="M993.34,2108.33h-5.97v-3.24l5.73-14.22h4.47v13.65h1.71v3.81h-1.71v3.57h-4.23v-3.57ZM993.4,2104.52v-6.72l-2.4,6.72h2.4Z"
        />
        <rect class="cls-1" x="1012.03" y="2057.24" width="33.16" height="88" />
      </g>
      <g id="TABLE-13">
        <path class="cls-5" d="M982.2,1978.57l-2.16.66v-4.38l3.45-1.08h3.21v21.09h-4.5v-16.29Z" />
        <path
          class="cls-5"
          d="M988.41,1994.59v-4.26c.93.36,1.74.57,2.88.57,1.44,0,2.43-.93,2.43-2.43v-.15c0-1.77-1.38-2.58-3.81-2.73v-2.94l3.39-4.5h-4.62v-4.29h9.33v3.96l-3.66,4.83c2.13.72,3.78,2.13,3.78,5.52v.27c0,4.23-2.58,6.75-6.18,6.75-1.5,0-2.58-.21-3.54-.6Z"
        />
        <rect class="cls-1" x="1012.03" y="1940.21" width="33.16" height="88" />
      </g>
      <g id="TABLE-12">
        <path class="cls-5" d="M982.14,1861.54l-2.16.66v-4.38l3.45-1.08h3.21v21.09h-4.5v-16.29Z" />
        <path
          class="cls-5"
          d="M988.47,1873.93l3.9-7.5c1.05-2.07,1.41-2.94,1.41-3.81,0-.96-.54-1.77-1.8-1.77-1.08,0-2.04.39-3.09.96v-4.35c1.11-.51,2.52-.96,4.26-.96,2.94,0,5.07,1.8,5.07,5.07v.15c0,1.83-.51,3.3-1.68,5.46l-3.36,6.21h4.95v4.44h-9.66v-3.9Z"
        />
        <rect class="cls-1" x="1012.03" y="1823.17" width="33.16" height="88" />
      </g>
      <g id="TABLE-11">
        <path class="cls-5" d="M983.46,1744.5l-2.16.66v-4.38l3.45-1.08h3.21v21.09h-4.5v-16.29Z" />
        <path class="cls-5" d="M991.8,1744.5l-2.16.66v-4.38l3.45-1.08h3.21v21.09h-4.5v-16.29Z" />
        <rect class="cls-1" x="1012.03" y="1706.14" width="33.16" height="88" />
      </g>
      <g id="TABLE-10">
        <path class="cls-5" d="M1005.21,783.87l-2.16.66v-4.38l3.45-1.08h3.21v21.09h-4.5v-16.29Z" />
        <path
          class="cls-5"
          d="M1011.54,790.32v-1.29c0-6.99,2.13-10.26,5.91-10.26s5.85,3.24,5.85,10.23v1.29c0,6.99-2.16,10.26-5.88,10.26s-5.88-3.24-5.88-10.23ZM1018.83,790.56v-1.8c0-4.95-.57-5.85-1.41-5.85s-1.41.9-1.41,5.85v1.8c0,4.92.57,5.82,1.41,5.82s1.41-.9,1.41-5.82Z"
        />
        <rect class="cls-1" x="1035.46" y="745.51" width="33.16" height="88" />
      </g>
      <g id="TABLE-9">
        <path
          class="cls-5"
          d="M1014.52,675.59c-.66.45-1.44.69-2.31.69-2.16,0-4.32-1.32-4.32-6.21v-.72c0-4.86,2.34-7.41,5.34-7.41,1.38,0,2.49.39,3.39,1.29,1.47,1.47,2.22,4.32,2.22,8.88v.6c0,8.67-3.03,10.92-6.72,10.92-1.2,0-2.25-.21-3.12-.54v-4.08c.78.36,1.77.66,2.76.66,1.62,0,2.64-1.02,2.76-4.08ZM1014.52,672.41v-2.97c0-2.79-.51-3.69-1.23-3.69s-1.2.78-1.2,3.39v.75c0,2.28.54,2.88,1.38,2.88.42,0,.78-.15,1.05-.36Z"
        />
        <rect class="cls-1" x="1035.52" y="628.68" width="33.16" height="88" />
      </g>
      <g id="TABLE-8">
        <path
          class="cls-5"
          d="M1007.9,560.76v-.24c0-2.46,1.05-4.2,2.37-5.13-1.32-.96-2.13-2.52-2.13-4.47v-.21c0-3.36,2.25-5.61,5.31-5.61s5.31,2.22,5.31,5.58v.21c0,1.92-.81,3.51-2.16,4.47,1.35.93,2.4,2.64,2.4,5.13v.24c0,3.78-2.25,6.15-5.58,6.15s-5.52-2.34-5.52-6.12ZM1014.71,560.43v-.27c0-2.13-.51-2.91-1.26-2.91s-1.29.78-1.29,2.91v.27c0,2.19.57,2.88,1.29,2.88s1.26-.69,1.26-2.88ZM1014.62,551.37v-.21c0-1.95-.51-2.55-1.17-2.55s-1.17.6-1.17,2.55v.21c0,1.8.45,2.58,1.17,2.58s1.17-.78,1.17-2.58Z"
        />
        <rect class="cls-1" x="1035.52" y="511.84" width="33.16" height="88" />
      </g>
      <g id="TABLE-7">
        <rect class="cls-9" x="1035.14" y="394.69" width="33.16" height="88.32" />
        <path class="cls-5" d="M1012.75,434.19h-4.95v-4.41h9.45v2.88l-3.87,18.12h-4.35l3.72-16.59Z" />
      </g>
      <g id="TABLE-6">
        <rect class="cls-9" x="1035.46" y="277.85" width="33.16" height="88" />
        <path
          class="cls-5"
          d="M1009.76,331.58c-1.47-1.47-2.22-4.32-2.22-8.85v-.6c0-8.7,3-10.92,6.69-10.92,1.11,0,2.04.18,2.82.42v4.11c-.66-.33-1.56-.57-2.46-.57-1.68,0-2.73,1.11-2.76,4.32.63-.45,1.44-.72,2.34-.72,2.13,0,4.32,1.35,4.32,6.06v.81c0,4.65-2.34,7.26-5.34,7.26-1.38,0-2.49-.42-3.39-1.32ZM1014.29,325.82v-.81c0-2.1-.6-2.7-1.38-2.7-.45,0-.78.15-1.05.33v2.76c0,2.76.51,3.66,1.23,3.66s1.2-.78,1.2-3.24Z"
        />
      </g>
      <g id="TABLE-5">
        <path
          class="cls-5"
          d="M1008.05,215.43v-4.35c.78.39,1.68.63,2.7.63,1.53,0,2.55-1.11,2.55-2.97v-.15c0-1.8-.84-2.49-1.95-2.49-.54,0-.9.06-1.35.24l-2.07-1.2.54-10.47h8.49v4.26h-5.13l-.21,3.33c.24-.03.57-.06.96-.06,2.76,0,5.19,1.65,5.19,6.27v.24c0,4.77-2.67,7.29-6.51,7.29-1.23,0-2.43-.24-3.21-.57Z"
        />
        <rect class="cls-9" x="1035.4" y="161.02" width="33.16" height="88" />
      </g>
      <g id="TABLE-4">
        <path
          class="cls-5"
          d="M694.56,194.77h-5.97v-3.24l5.73-14.22h4.47v13.65h1.71v3.81h-1.71v3.57h-4.23v-3.57ZM694.62,190.96v-6.72l-2.4,6.72h2.4Z"
        />
        <rect class="cls-9" x="638.66" y="143.69" width="33.16" height="88" />
      </g>
      <g id="TABLE-3">
        <path
          class="cls-5"
          d="M689.39,315.41v-4.26c.93.36,1.74.57,2.88.57,1.44,0,2.43-.93,2.43-2.43v-.15c0-1.77-1.38-2.58-3.81-2.73v-2.94l3.39-4.5h-4.62v-4.29h9.33v3.96l-3.66,4.83c2.13.72,3.78,2.13,3.78,5.52v.27c0,4.23-2.58,6.75-6.18,6.75-1.5,0-2.58-.21-3.54-.6Z"
        />
        <rect class="cls-9" x="638.66" y="261.03" width="33.16" height="88" />
      </g>
      <g id="TABLE-2">
        <path
          class="cls-5"
          d="M689.38,528.39l3.9-7.5c1.05-2.07,1.41-2.94,1.41-3.81,0-.96-.54-1.77-1.8-1.77-1.08,0-2.04.39-3.09.96v-4.35c1.11-.51,2.52-.96,4.26-.96,2.94,0,5.07,1.8,5.07,5.07v.15c0,1.83-.51,3.3-1.68,5.46l-3.36,6.21h4.95v4.44h-9.66v-3.9Z"
        />
        <rect class="cls-9" x="638.66" y="477.64" width="33.16" height="88" />
      </g>
      <g id="TABLE-1">
        <path class="cls-5" d="M692.71,633.35l-2.16.66v-4.38l3.45-1.08h3.21v21.09h-4.5v-16.29Z" />
        <rect class="cls-9" x="638.66" y="594.99" width="33.16" height="88" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  data() {
    return {
      mapid: 153,
    };
  },
};
</script>
