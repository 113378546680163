<template>
    <div class="container">
      <div v-if="currentmessage" v-html="currentmessage"></div>
      <div v-else v-for="the_state in currentTasting.states" :key="the_state">
        
        <div class="col-12 col-md-4 offset-md-4 paddme">
          <a @click="changeSelectedState(the_state)" class="btn btn-outline-primary btn-block" style="display:block;">{{ getStateFullName(the_state) }}</a>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapGetters, mapActions } from 'vuex';
  
  export default {
    name: 'Tastings',
  
    data() {
      return {
        chosenState: '',
        currentMessage: '',
        currentTasting: {},
        tastingsObj: {},
      };
    },
    methods: {
      changeSelectedState(the_state) {
        this.currentMessage = '<strong>Loading...</strong>';
        window.localStorage.setItem("chosen_state", the_state);
        this.$router.push({ name: 'Browse' });
      },
      getStateFullName(abbreviation) {
        const stateMap = {
  'AL': 'Alabama',
  'AK': 'Alaska',
  'AZ': 'Arizona',
  'AR': 'Arkansas',
  'CA': 'California',
  'CO': 'Colorado',
  'CT': 'Connecticut',
  'DE': 'Delaware',
  'FL': 'Florida',
  'GA': 'Georgia',
  'HI': 'Hawaii',
  'ID': 'Idaho',
  'IL': 'Illinois',
  'IN': 'Indiana',
  'IA': 'Iowa',
  'KS': 'Kansas',
  'KY': 'Kentucky',
  'LA': 'Louisiana',
  'ME': 'Maine',
  'MD': 'Maryland',
  'MA': 'Massachusetts',
  'MI': 'Michigan',
  'MN': 'Minnesota',
  'MS': 'Mississippi',
  'MO': 'Missouri',
  'MT': 'Montana',
  'NE': 'Nebraska',
  'NV': 'Nevada',
  'NH': 'New Hampshire',
  'NJ': 'New Jersey',
  'NM': 'New Mexico',
  'NY': 'New York',
  'NC': 'North Carolina',
  'ND': 'North Dakota',
  'OH': 'Ohio',
  'OK': 'Oklahoma',
  'OR': 'Oregon',
  'PA': 'Pennsylvania',
  'RI': 'Rhode Island',
  'SC': 'South Carolina',
  'SD': 'South Dakota',
  'TN': 'Tennessee',
  'TX': 'Texas',
  'UT': 'Utah',
  'VT': 'Vermont',
  'VA': 'Virginia',
  'WA': 'Washington',
  'WV': 'West Virginia',
  'WI': 'Wisconsin',
  'WY': 'Wyoming'
};

        return stateMap[abbreviation] || abbreviation; // Return the abbreviation if not found in the map
      },
    },
    computed: {
      ...mapGetters(['currentTasting']),
    },
    created() {
      if (localStorage.getItem('currentTastingDeets')) {
        this.currentTasting = JSON.parse(localStorage.getItem('currentTastingDeets'));
      }
    },
  };
  </script>
  