<template>
  <div class="tastings-list page">
    <div class="container content_area">
      <button class="btn btn-primary btn-top" @click="goBack">
        <img src="@/assets/arrow-left.svg" style="height: 25px" /> <small>BACK</small>
      </button>
      <div class="row">
        <div class="col-12">
          <h1>Select a Tasting</h1>
          <TastingList />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import TastingList from '@/components/TastingList.vue';

export default {
  name: 'Select a Tastings',
  components: {
    TastingList,
  },
  methods: {
  goBack() {
      if (true) {
        this.$router.go(-1);
      }
    }
  },
  created() {
    
    // ------------------------------------------------------
    // First route away from this page if we already have some situations
    // ------------------------------------------------------
    if (window.localStorage.getItem("skip_tasting_select")) {
        // user is already logged in. no need to encourage login.
        // clear local storage 'skip_tasting_select'
        window.localStorage.removeItem("skip_tasting_select");
        this.$router.push({ name: 'Browse' });
        
    }
  },
  
};
</script>
