<template>
  <div class="map" style="padding-top: 30px">
    <div id="map" class="map_page text-center">
      <div class="text-center">
        <img class="mb-4 img-fluid" v-bind:src="currentTastingDeets.map" v-bind:alt="currentTastingDeets.introduction_title" />
      </div>
    </div>
  </div>
</template>

<script>
//import SvgPanZoom from 'vue-svg-pan-zoom';
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'Map',
  data() {
    return {
      //mySVG: require('@/assets/ex_map.svg'),
    };
  },
  computed: {
    ...mapGetters(['alldata', 'currentTasting', 'currentTastingDeets']),
  },
  mounted() {},
};
</script>
<style scoped>
.content_area {
  background-color: #000 !important;
}
</style>
