<template>
  <div id="top" class="browse page" :class="currentTastingDeets.tastingSize">
    <TastingHeader v-bind:tastingInfo="currentTastingDeets" />

    <div class="container browse_content_area">
      <div class="spacer"></div>
      <!--<div v-if="currentTastingDeets.tastingSize == 'small'">
        <div class="form-row">
          <div class="col-8">
            <input type="text" class="form-control" placeholder="Search All Items  ..." v-model="search" @keyup.enter="searchSkus" />
          </div>
          <div class="col-4">
            <button type="submit" class="btn btn-primary btn-fw" @click="searchSkus">Search</button>
          </div>
        </div>
      </div>-->
      <div v-if="showLoading" class="showLoading">
        <h2>Refreshing Tasting Data</h2>
        <div class="sk-chase">
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
        </div>
      </div>
      <div v-else>
        <div v-if="haveFilteredResults">
          <div class="search_bar" v-if="resultFilters">
            Current Filters: <strong v-html="resultFilters"></strong>&nbsp;
            <button class="btn btn-secondary" @click="clearFilter">Clear</button>
          </div>
          <Tables v-bind:tables="resultTables" v-bind:alldata="alldata" />
        </div>
        <div v-else>
          <div class="search_bar" style="display: none">
            <button class="btn btn-secondary btn-fw" @click="searchFilter">Filter Tables</button>
          </div>
          <Tables v-bind:tables="alldata.tables" v-bind:alldata="alldata" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Tables from '@/components/Tables.vue';

import TastingHeader from '@/components/TastingHeader.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'Browse',
  components: {
    Tables,

    TastingHeader,
  },

  data() {
    return {
      //resultTables: [],

      search: '',
    };
  },
  methods: {
    ...mapActions(['fetchAlldata', 'fetchUser', 'setTablesExpandedVu', 'setTableOpen', 'setShowLoading']),
    searchFilter() {
      //this.$store.commit('hideIntro');
      this.$router.push({ name: 'NewSearch' });
    },
    clearFilter() {
      this.$store.dispatch('setResultTables', []);
      this.$store.dispatch('setResultFilters', false);
      this.$store.dispatch('setResultCount', false);
      this.$router.push({ name: 'Browse' });
    },
    searchSkus() {
      //alert(this.search);
      //this.$store.dispatch('searchSkus', []);
      this.$router.push({ name: 'Results', params: { search: this.search } });
    },
  },
  computed: {
    ...mapGetters([
      'alldata',
      'user',
      'currentTasting',
      'currentTastingDeets',
      'resultTables',
      'resultFilters',
      'getTablesExpandedVu',
      'showLoading',
    ]),
    u() {
      return this.$store.getters.user;
    },
    haveFilteredResults() {
      if (this.resultTables != null) {
        if (typeof this.resultTables != 'undefined') {
          if (Object.keys(this.resultTables).length >= 1) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
      return false;
    },
  },
  created() {
    this.setShowLoading(true);
    this.fetchAlldata();
    this.fetchUser();
  },
  mounted() {
    const isHash = window.location.hash ? window.location.hash.split('#') : undefined;
    if (isHash) {
      //console.log (isHash[1]+' OK')
      let tmp = setTimeout(() => {
        if (document.getElementById(isHash[1]) != null) {
          document.getElementById(isHash[1]).scrollIntoView();

          clearTimeout(tmp);
        } else {
          console.log('target not found' + window.location.hash);
        }
      }, 400);
      //setTimeout(function() {window.scrollTo(0, number);}, 1000)
    }
  },
};
</script>
