<template>
  <div class="login page">
    <div class="container content_area">
     
      <div class="login-form">
        <h2 class="login-heading">Recovery Attempted</h2>
        <div class="" >
            Any tasting data (Notes, Ratings, Lists etc) that are still on your device has been sent to the server for processing.
            <!--<br />Notes and/or Ratings: {{countReviews}}
            <br />Lists: {{countLists}}-->
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'Recover',
  data() {
    return {
      username: '',
      password: '',
      error: false,
    };
  },
  methods: {
    ...mapActions(['saveAllData']),
    goBack() {
      if (true) {
        this.$router.go(-1);
      }
    },
    
  },
  computed: {
    ...mapGetters(['alldata']),
    countReviews() {
       
        var i = 0;
        for (const table in this.alldata.ratings) {
            i = i + 1;
        }
        return i;
    },
    countLists() {
       
        var i = 0;
        for (const table in this.alldata.lists) {
            i = i + 1;
        }
        return i;
    }
  },
  mounted() {
    this.saveAllData();
  }
};
</script>
