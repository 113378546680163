<template>
  <div class="container">
    <div class="tasting_select" v-if="hasCurrent">
      <div class="row">
        <div class="col-12">
          <h2 @click="toggleCurrent(currentExpanded)">
            Current & Upcoming Tastings
            <div class="table_expand" v-if="!currentExpanded"></div>
            <div class="table_contract" v-else></div>
          </h2>
        </div>
      </div>
      <div v-if="currentExpanded">
        <div class="" v-for="tasting in tastings" :key="tasting.tasting_id">
          <div v-if="!tasting.hidden">
          <div class="row" v-if="!tasting.past">
            <div class="col-8">
              <div class="tasting_name">{{ tasting.name }}</div>
              <div class="tasting_date">
                <strong>{{ tasting.date }}</strong> <br />{{ tasting.starttime }} to {{ tasting.endtime }} | {{ tasting.location }}<br />
                <!-- Tasting Code: {{ tasting.code }}-->
              </div>
            </div>
            <div class="col-4 paddme">
              <a @click="changeTasting(tasting.tasting_id)" class="btn btn-primary">View Tasting </a>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tasting_select">
      <div class="spacer"></div>
      <div class="row">
        <div class="col-12">
          <h2 @click="togglePast(pastExpanded)">
            Past Tastings
            <div class="table_expand" v-if="!pastExpanded"></div>
            <div class="table_contract" v-else></div>
          </h2>
        </div>
      </div>
      <div v-if="pastExpanded">
        <div class="" v-for="tasting in tastings" :key="tasting.tasting_id">
          <div class="row" v-if="tasting.past">
            <div class="col-8">
              <div class="tasting_name">{{ tasting.name }}</div>
              <div class="tasting_date">
                <strong>{{ tasting.date }}</strong> <br />{{ tasting.starttime }} to {{ tasting.endtime }} | {{ tasting.location }}<br />
                <!-- Tasting Code: {{ tasting.code }}-->
              </div>
            </div>
            <div class="col-4 paddme">
              <a @click="changeTasting(tasting.tasting_id)" class="btn btn-primary">View Tasting </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*
clear my lists & ratings.
DELETE FROM `app_tastings_lists` WHERE user_id=1;
DELETE FROM `app_tastings_ratings` WHERE user_id=1;
DELETE FROM `app_tastings_user_lists` WHERE user_id=1;


*/


import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'Tastings',
  components: {},

  data() {
    return {
      chosenTasting: 0,
      tastingsObj: {},
      emptyCurrent: true,
      pastExpanded: false,
      currentExpanded: true,
    };
  },
  methods: {
    ...mapActions(['fetchTastings', 'setCurrentTasting','saveAllData']),
    changeTasting(tasting_id) {
      //console.log(tasting_id);
      this.saveAllData();
      for (const item in this.tastings) {
        if (this.tastings[item].tasting_id == tasting_id) {
          this.setCurrentTasting(this.tastings[item]);
          localStorage.setItem("tasting_id",tasting_id)
          this.$router.push('/browse');
        }
      }
    },
    toggleCurrent() {
      this.currentExpanded = !this.currentExpanded;
      if (this.pastExpanded) {
        this.pastExpanded = !this.pastExpanded;
      }
    },
    togglePast() {
      this.pastExpanded = !this.pastExpanded;
      if (this.currentExpanded) {
        this.currentExpanded = !this.currentExpanded;
      }
    },
  },
  computed: {
    ...mapGetters(['tastings', 'currentTasting']),
    hasCurrent() {
      return true;
      for (const t in this.tasting) {
        //console.log(this.tasting[t]);
        if (this.tasting[t].past == 'false') {
          return true;
        }
      }
      return false;
    },
  },
  created() {
    this.fetchTastings();
  },
};
</script>
