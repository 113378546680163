<template>
  <div class="register page">
    <div class="container content_area_no_head">
      <div class="login-form">
        <h1>WELCOME TO OUR DIGITAL TASTING BOOK!</h1>
        <h3>We just need a few more details:</h3>
        <div class="alert alert-danger" role="alert" v-if="areErrors">
          <b>Please correct the following error(s):</b>
          <ul>
            <li v-for="error in errors" :key="error">{{ error }}</li>
          </ul>
        </div>

        <form v-if="currentStep == 1" action="#" @submit.prevent="firstTasting">
          <div class="form-group">
            <label for="select">State</label>
            <div>
              <select id="select" name="select" v-model="state" @change="onChangeState($event)" class="custom-select" required="required">
                <option value="NY" selected="selected">New York</option>
                <option value="NJ">New Jersey</option>
                <option value="PA">Pennsylvania</option>
                <option value="CT">Connecticut</option>
                <option value="OH">Ohio</option>
                <option value="KY">Kentucky</option>
                <option value="IN">Indiana</option>
                <option value="CA">California</option>
                <option value="NA">Other</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label for="businesstype">Business Type</label>
            <div>
              <select id="businesstype" v-model="businessType" name="businessType" class="custom-select" required="required">
                <option value="" selected>Please Select</option>

                <option value="Restaurant">Restaurant</option>
                <option value="Bar">Bar</option>
                <option value="Retail">Retail Shop</option>

                <option value="NAT">Distribution Partner</option>
              </select>
            </div>
          </div>
          <div class="form-group" v-if="businessType != 'NAT'">
            <label for="salesrep">Sales Representative</label>
            <div>
              <select
                id="salesrep"
                name="salesrep"
                v-model="salesRep"
                @change="onChangeRep($event)"
                class="custom-select"
                required="required"
              >
                <option value="" disabled>Please Select</option>
                <option v-for="rep in salesRepGroup" :key="rep.rep_id" :value="rep.rep_id">{{ rep.name }}</option>
              </select>
            </div>
          </div>

          <div class="form-group" v-if="salesRep != '' || businessType != 'NAT'">
            <label for="businessName">Business Name</label>
            <div>
              <input type="text" id="businessName" name="businessName" v-model="businessName" required="required" style="width: 100%" />
              <!--<select id="businessName" name="businessName" v-model="businessName" class="custom-select" required="required">
                <option value="" disabled>Please Select</option>
                <option v-for="cust in businessNameGroup" :key="cust.cust_id" :value="cust.cust_id">{{ cust.name }}</option>
              </select>-->
            </div>
          </div>

          <div class="form-group" v-else>
            <label for="natState">State / Distributor</label>
            <div>
              <select id="natState" name="natState" v-model="businessName" class="custom-select" required="required">
                <option value="" disabled>Please Select</option>
                <option v-for="st in natStateList" :key="st" :value="st">{{ st }}</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <div class="form-check">
              <input class="form-check-input" v-model="terms" type="checkbox" :value="terms" id="defaultCheck1" />
              <label class="form-check-label" for="defaultCheck1"> I agree to the <a href="/terms">terms & conditions</a> </label>
            </div>
          </div>
          <div class="form-group">
            <button name="submit" type="submit" class="btn btn-primary">Continue</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      currentStep: 1,
      errors: [],
      forgotPassword: false,
      state: '',
      businessName: '',
      businessType: '',
      salesRep: '',
      terms: true,
      natStateList: [
        'ALASKA: Specialty Imports',
        'ARIZONA: Quench',
        'ARIZONA: Synergy Fine Wines',
        'ARKANSAS: Natural State Distributing',
        'ALABAMA: Grassroots Wine',
        'COLORADO: Maverick Wine Company',
        'DC: Prestige-Ledroit',
        'DC: Bacchus Importers',
        'DELAWARE: Prestige-Ledroit',
        'DELAWARE: Bacchus Importers',
        'FLORIDA: Southern Wines and Spirits',
        'GEORGIA: Winebow',
        'GEORGIA: Northeast Sales & Distribution',
        'HAWAII: Southern Wine and Spirits',
        'IDAHO: Northwest Specialty Beverage',
        'IOWA: Okoboji Wines',
        'ILLINOIS: Maverick Wine Company',
        'KANSAS: LDF Sales & Distributing',
        'LOUISIANA: Wines Unlimited',
        'MAINE: South Portland Wine Co.',
        'MASSCHUSETTS: Arborway Imports',
        'MASSCHUSETTS: Atlantic Importing Co.',
        'MASSCHUSETTS: Boston Wine Company',
        'MASSCHUSETTS: Ruby Wine',
        'MARYLAND: Prestige-Ledroit',
        'MARYLAND: Bacchus Importers',
        'MICHIGAN: Elite Brands/Imperial Beverages',
        'MINNESOTA: The Wine Company',
        'MINNESOTA: Great Ciao',
        'MISSOURI: A.Bommarito',
        'MISSOURI: Lohr',
        'MISSOURI: Pilar Wine Brokers',
        'MONTANA: Rocky Mountain Wine Co.',
        'NEBRASKA: Quench',
        'NEW HAMPSHIRE: Perfecta',
        'NEW MEXICO: Favorite Brands',
        'NORTH CAROLINA: Bordeaux Fine & Rare',
        'NEVADA: Vin Sauvage',
        'OKLAHOMA: Thirst Wine Merchants',
        'OREGON: Galaxy Wine Co',
        'PUERTO RICO: Ambrosia Fine Foods',
        'PUERTO RICO: B. Fernandez',
        'RHODE ISLAND: Wine Wizards',
        'SOUTH CAROLINA: Grassroots Wine Co',
        'SOUTH DAKOTA: Okoboji Wines',
        'TENNESSE: Athens Distributing',
        'TENNESSE: Empire Distributors Nashville',
        'TENNESSE: Empire Distributors Chattanooga',
        'TENNESSE: Empire Distributors Knoxville',
        'TENNESSE: DET Distributing',
        'TEXAS: MexCor Fine Wine',
        'UTAH: Utah Dept of Alcohol',
        'VIRGINIA: Virginia Imports',
        'VERMONT: Vermont Wine Merchants',
        'WASHINGTON: Cavatappi Distribuzione',
        'WISCONSIN: Left Bank Wine Co.',
        'WEST VIRGINIA: Country Vintner WV',
        'WYOMING: Wyoming Liquor Division',
      ],
    };
  },
  components: {},
  computed: {
    areErrors: function () {
      if (this.errors.length >= 1) {
        return true;
      } else {
        return false;
      }
    },
    salesRepGroup: function () {
      return this.$store.getters.salesRepGroup;
    },
    businessNameGroup: function () {
      return this.$store.getters.businessNameGroup;
    },
  },
  methods: {
    ...mapActions(['getSalesRepGroup', 'getBusinessNameGroup']),
    async firstTasting() {
      this.errors = [];

      if (!this.state) {
        this.errors.push('State required.');
      }
      if (!this.businessType) {
        this.errors.push('Business Type required.');
      }
      if (!this.businessName) {
        this.errors.push('Please select your Business.');
      }
      if (!this.terms) {
        this.errors.push('You must agree to the terms to use this app.');
      }
      if (this.businessType!='NAT') {
      if (!this.salesRep) {
        this.errors.push('Please select your Sales Representative.');
      }
      }
      if (this.errors.length >= 1) {
        console.log('errors found');
        return false;
      } else {
        let accountUpdated = await this.$store
          .dispatch('updateAccount', {
            state: this.state,
            businessName: this.businessName,
            businessType: this.businessType,
            salesRep: this.salesRep,
          })
          .then((response) => {
            return response;
          });

        if (!accountUpdated) {
          this.errors.push('Error Updating Account.');

          console.log('Error Updating Account.');
        } else {
          // NOW GET THE USER META AGAIN>

          let tasting_id = window.localStorage.getItem("tasting_id");
            if (tasting_id) {
              this.$router.push({ name: 'Browse' });
            } else {
              this.$router.push({ name: 'Select a Tasting' });
            }
          return true;
        }
      }
    },

    onChangeState(event) {
      console.log(event.target.value);
      let chosenState = { s: event.target.value };

      console.log(this.getSalesRepGroup(chosenState));
    },
    onChangeRep(event) {
      console.log(event.target.value);
      let chosen = { r: event.target.value, s: this.state };

      console.log(this.getBusinessNameGroup(chosen));
    },
  },
};
</script>
