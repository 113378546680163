<template>
  <div id="top" class="favs page">
    <div class="container content_area_no_head">
      <div class="row">
        <div class="col-12 col-lg-6 offset-lg-3">
          <h1>Select State</h1>
          <h4>Current State: {{ currentState }}</h4>
          This feature allows you to view pricing in different markets.<br /><br />
          <div>
            <select name="selectstate" v-model="selectstate" class="form-control">
              <option value="NY">NY</option>
              <option value="NJ">NJ</option>
              <option value="CT">CT</option>
              <option value="PA">PA</option>
              <option value="RI">RI</option>
              <option value="CA">CA</option>
              <option value="OH">OH</option>
              <option value="KY">KY</option>
              <option value="IN">IN</option>
            </select>
          </div>
          <br />
          <input v-if="showSubmit" type="submit" class="btn btn-primary" value="Update Pricing" @click="changeSelectedState" />
          <div v-if="currentmessage" v-html="currentmessage"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      selectstate: this.$store.getters.user.state,
      currentmessage: '',
      showSubmit: true,
    };
  },
  name: 'State Selection',
  components: {},

  methods: {
    ...mapActions(['fetchPricedata']),
    changeSelectedState() {
      // change the page so that we can stop the user from submitting again.
      this.showSubmit = false;
      this.currentmessage =
        '<strong>Loading new pricing data.</strong> This may take a couple minutes. You will be redirected to the tasting once it is completed.';
      // unset local storage 'chosen_state'
      window.localStorage.removeItem('chosen_state');
      return new Promise((resolve, reject) => {
        this.fetchPricedata(this.selectstate).then((response) => {
          this.currentmessage = '';
          this.showSubmit = true;
          this.$router.push({ name: 'Browse' });
        });
      });
    },
  },
  computed: {
    ...mapGetters(['user', 'loggedIn']),
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
    isAdmin() {
      if (this.$store.getters.user.role == 'admin' || this.$store.getters.user.role == 'editor') {
        return true;
      } else {
        return false;
      }
    },
    currentState() {
      //return this.$store.getters.user.state
      let user_data = JSON.parse(window.localStorage.getItem('user'));
      return user_data.state;
    },
  },
  created() {},
};
</script>
