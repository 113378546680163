<template v-if="alldata && alldata.tables">
  <div class="table_padding_bottom" v-if="hasSkus">
    <!--<router-link :to="{ name: 'Table', params: { table_no: table.table } }">-->

    <div class="table_number" v-bind:id="'tb' + table.table">
      <div class="table_head" @click="tableToggle(table.table)">
        <div id="tourTable" v-if="table.table == 1"></div>
        <div class="table_num">Table {{ table.table }} &nbsp; &nbsp;</div>
        <div class="table_name">{{ table.table_name }}</div>

        <div class="table_expand" v-if="!tablesExpanded[table.table]"></div>
        <div class="table_contract" v-else></div>
      </div>
      <div class="table_visibility" v-if="tablesExpanded[table.table]">
        <!--<div class="table_type">{{ table.table_type }}</div>-->
        <div class="table_summary" v-if="table.table_summary != ''">
          {{ table.table_summary }}
          <div class="attending" v-if="table.attending_producers != ''">Attending Representative: {{ table.attending_producers }}</div>
        </div>
        <div id="tourSku" v-if="table.table == 1"></div>
        <div v-for="(ats, sku) in table.skus" :key="sku">
          <div class="sku_block" v-if="isSkuInAllData(sku)">
            <SkuPreview
              v-bind:sku="sku"
              v-bind:showDetail="false"
              v-bind:l="table.skus[sku].l"
              v-bind:table_no="Number(table.table)"
              v-bind:reviews="table.skus[sku].reviews"
            />
          </div>
          <div class="sku_block" v-else>
            <div class="sku_block_row">
              <div class="sku_name">{{ $getNationalReplacements(sku) }}</div>
              <small> Additional information not available. </small>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--</router-link>-->
  </div>
</template>

<script>
import SkuPreview from '@/components/SkuPreview.vue';
import { mapGetters, mapActions, mapState } from 'vuex';

export default {
  name: 'Table',
  components: {
    SkuPreview,
  },
  props: {
    table: Object,
    alldata: Object,
    //tablesExpanded: Object,
  },
  data() {
    return {
      tablesExpanded: [],
    };
  },
  computed: {
    ...mapGetters(['getTablesExpandedVu']),
    ...mapState(['tablesExpandedVu']),
    hasSkus() {
      if (Object.keys(this.table.skus).length >= 1) {
        return true;
      } else {
        return false;
      }
    },

    showTablesExpanded() {
      return true;
    },
  },

  methods: {
    ...mapActions(['setTablesExpandedVu', 'setTableFlop']),
    tableToggle(tableno) {
      //console.log(tableno);
      this.setTableFlop(tableno);
      this.tablesExpanded = this.getTablesExpandedVu;
    },
    checkTables() {
      //console.log('checking for table changes.');
      this.tablesExpanded = this.getTablesExpandedVu;
    },
    isSkuInAllData(sku) {
      console.log('Checking SKU:', sku);
      console.log('All SKUs available in alldata:', this.alldata.skus);
      return this.alldata['skus'] && this.alldata['skus'][sku];
    },
    hasRatings(sku) {
      // Check if there are ratings for this.sku
      const ratingsExist = this.alldata.ratings && this.alldata.ratings[sku];
      const hasNonEmptyRatings = ratingsExist && Object.keys(this.alldata.ratings[sku]).length > 0;
      const isRZeroAndNEmpty = ratingsExist && this.alldata.ratings[sku].r == 0 && this.alldata.ratings[sku].n == '';
      // Assuming 'lists' is a property of this.alldata and contains the lists
      const lists = this.alldata.lists;

      // Iterate over each list in the lists object
      var listRatingsExist = false;
      for (const listName in lists) {
        if (listName != 'Uncategorized') {
          if (lists.hasOwnProperty(listName)) {
            const list = lists[listName];
            if (list.includes(sku)) {
              listRatingsExist = true;
              //console.log('Found in list: ' + listName + ' for sku: ' + sku);
              break;
            }
          }
        }
      }
      if (!listRatingsExist) {
        //console.log('No list found for sku: ' + sku);
      }
      return listRatingsExist || (hasNonEmptyRatings && !isRZeroAndNEmpty);
    },
  },
  created() {
    this.tablesExpanded = this.getTablesExpandedVu;
  },
  watch: {
    getTablesExpandedVu(newer, older) {
      //console.log('---' + newer + '---' + older);
      this.tablesExpanded = this.getTablesExpandedVu;
    },
  },
};
</script>

<style scoped lang="scss"></style>
