<template>
  <div id="top" class="lists page">
    <div class="container content_area">
      <div class="spacer"></div>
      <div class="row">
        <div class="col-12 col-lg-6 offset-lg-3">
          <h1>My Lists</h1>

          <div class="alert alert-secondary" role="alert">
            The "Notes & Ratings" section includes any SKUs you've interacted with but haven't assigned to a particular list.
          </div>

          <div class="list_block" v-for="(index, item) in alldata.lists" :key="index">
            <div v-if="alldata.lists[item].length >= 1">
              <h2 @click="tableToggle(item)">
                {{ item === 'Uncategorized' ? 'Notes & Ratings' : item }}

                <div class="table_expand">+</div>
              </h2>
              <div class="ml-4 table_visibility" v-show="tablesExpanded[item]">
                <div class="list_controls text-left" v-if="item != 'Uncategorized'">
                  <button
                    class="btn btn-outline-secondary"
                    style="margin-right: 15px"
                    @click="editListname(item, currentTastingDeets.tasting_id, user.token)"
                  >
                    Edit List Name
                  </button>
                  <button class="btn btn-outline-secondary" @click="deleteTablename(item)">Delete List</button>
                </div>
                <div class="table_block">
                  <div class="row" v-for="(sku, index) in alldata.lists[item]" :key="index">
                    <div class="sku_block_row col-12" dv-if="sku != null">
                      <div class="sku_name">
                        <router-link :to="{ name: 'Sku', params: { sku_id: sku, table_no: getTableNo(sku) } }"
                          ><SkuName v-bind:sku="sku"
                        /></router-link>
                      </div>
                      <small>{{ sku }}</small>
                      <div class="standard_case" v-if="get_sku_notes(sku)">My Notes: {{ get_sku_notes(sku) }}</div>
                      <div v-if="item == 'Uncategorized'">
                        <SkuRating
                          v-bind:sku="sku"
                          v-bind:showButton="true"
                          v-bind:listButtonText="`Add to List`"
                          v-bind:buttonClass="`btn btn-outline-secondary`"
                        />
                      </div>
                      <div v-else>
                        <SkuRating v-bind:sku="sku" v-bind:showButton="false" />
                      </div>

                      <div class="sku_remove_button" v-if="item != 'Uncategorized'">
                        <button class="btn btn-outline-secondary" @click="removeSkuFromList(sku, item)" style="margin-top: 15px">
                          Remove
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import SkuRating from '@/components/SkuRating.vue';
import SkuName from '@/components/SkuName.vue';
import SkuNote from '@/components/SkuNote.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'Favorites',
  components: {
    SkuRating,
    SkuName,
    SkuNote,
  },
  data() {
    return {
      tablesExpanded: { 0: false, 1: false, 2: false, 3: false, 4: false, 5: false, 6: false },
      counter: 0,
    };
  },
  methods: {
    ...mapActions(['fetchAlldata', 'deleteListAct', 'removeSkuFromListAct', 'toggleModalListname']),
    getTableNo(sku) {
      //console.log('Searching for SKU:', sku); // Debugging log to see the SKU being searched
      for (const tableNo in this.alldata.tables) {
        const table = this.alldata.tables[tableNo];
        //console.log(`Checking table ${tableNo}`, table.skus); // See the SKUs in the current table

        // Check if the sku exists within this table's skus
        if (table.skus && sku in table.skus) {
          //console.log(`Found SKU: ${sku} in table ${tableNo}`); // Confirm when and where the SKU is found
          return table.table; // Assuming 'table' is the correct property that holds the table number
        }
      }
      //console.log(`SKU: ${sku} not found in any table`); // Indicate if the SKU wasn't found
      return null;
    },
    tableToggle(tableno) {
      if (typeof this.tablesExpanded[tableno] !== 'undefined') {
        if (this.tablesExpanded[tableno] == true) {
          for (const atable in this.tablesExpanded) {
            this.tablesExpanded[atable] = false;
          }
        } else {
          for (const atable in this.tablesExpanded) {
            this.tablesExpanded[atable] = false;
          }
          this.tablesExpanded[tableno] = true;
        }

        //console.log('expand1');
      } else {
        for (const atable in this.tablesExpanded) {
          this.tablesExpanded[atable] = false;
        }
        this.tablesExpanded[tableno] = true;
        //console.log('expand3');
      }
    },
    get_sku_name(sku) {
      if (sku == null) {
        return '';
      }
      if (typeof this.alldata.skus[sku] != 'undefined') {
        return this.alldata.skus[sku].name;
      } else {
        return 'Sku Not a part of this tasting.';
      }
    },
    get_sku_notes(sku) {
      if (sku == null) {
        return '';
      }
      if (typeof this.alldata.ratings[sku] != 'undefined') {
        return this.alldata.ratings[sku].n;
      } else {
        return null;
      }
    },
    incrementCounter() {
      this.counter = ++this.counter;
    },
    editListname(listname, tasting_id, token) {
      //this.deleteListAct(item);
      const payload = { listname: listname, tasting_id: tasting_id, token: token };
      this.toggleModalListname(payload);
      //console.log('tasting: '+ tasting_id + ' user: '+ token + ' listname: '+ listname);
    },
    deleteTablename(item) {
      if (confirm('Are you sure?\nPress OK to Delete List "' + item + '"')) {
        this.deleteListAct(item);
        //alert('Deleted List ' + item);
      }
    },
    removeSkuFromList(sku, item) {
      let payload = { sku: sku, listname: item };
      this.removeSkuFromListAct(payload);
      alert('Removed ' + sku + ' from ' + item);
    },
  },
  computed: {
    ...mapGetters(['alldata', 'currentTastingDeets', 'user']),
  },
  watch: {
    // LEAVING THIS HERE TO KEEP TRACK OF LIST NAMES
    //sku: 'findRating',
  },
  created() {
    this.fetchAlldata();
  },
};
</script>
