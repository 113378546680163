//import Vue from 'vue'
import Vuex from "vuex";
//Vue.use(Vuex);

import appdata from "./modules/appdata";


export default new Vuex.Store({
  modules: {
    appdata,
    
  },
});
