<template>
  <div class="login page">
    <div class="container content_area">
      <button class="btn btn-primary btn-top" @click="goBack">
        <img src="@/assets/arrow-left.svg" style="height: 25px" /> <small>BACK</small>
      </button>
      <div class="login-form">
        <h2 class="login-heading">Login</h2>
        <div class="alert alert-danger" role="alert" v-html="error" v-if="error"></div>
        <form action="#" @submit.prevent="login">
          <div class="">
            <label for="email">Username or Email</label>
            <input type="text" name="username" id="username" class="form-control login-input" v-model="username" />
          </div>

          <div class="mb-more">
            <label for="password">Password</label>
            <input type="password" name="password" id="password" class="form-control login-input" v-model="password" />
          </div>

          <div class="">
            <button type="submit" class="btn btn-primary">Login</button>
          </div>

          <div class="pt-3">
            <router-link class="btn btn-secondary mt-2" :to="{ name: 'Access Pass' }">NOT SURE IF YOU HAVE AN ACCOUNT &gt;</router-link>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'Login',
  data() {
    return {
      username: '',
      password: '',
      error: false,
    };
  },
  methods: {
    ...mapActions(['toggleModal', 'saveAllData']),
    goBack() {
      if (true) {
        this.$router.go(-1);
      }
    },
    login() {
      this.saveAllData();
      this.$store
        .dispatch('retrieveToken', {
          username: this.username,
          password: this.password,
        })
        .then((response) => {
          //console.log(this.$store.getters.user);
          let user_data = JSON.parse(window.localStorage.getItem('user'));
          if (user_data.state != false) {
            let tasting_id = window.localStorage.getItem('tasting_id');
            if (tasting_id) {
              this.$router.push({ name: 'Browse' });
            } else {
              this.$router.push({ name: 'Select a Tasting' });
            }
          } else {
            this.$router.push({ name: 'First Tasting' });
          }
        })
        .catch((err) => {
          this.error = err.data.error; // reject
          //this.error = this.error + '<br /> to gain temporary access go <a href="/accesspass" class="btn btn-primary">here</a>.';
        });
    },
  },
};
</script>
