<template>
  <svg id="mapsvg154" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1399.9 3966.19">
    <g id="VENUE">
      <path
        class="cls-12"
        d="M862.63,1817.28h3.76c46.43,0,84,37.57,84,84h433.65v-553.43h-31.72v7.93h-370.22v-7.93h-31.72c-.02,46.42-37.58,83.97-84,83.97h-3.76v-83.97h-112.27v553.43h112.27v-84Z"
      />
      <g>
        <path class="cls-3" d="M866.39,1817.28c46.43,0,84,37.57,84,84v7.87" />
        <rect class="cls-4" x="818.89" y="1861.02" width="91.25" height="3.76" transform="translate(-998.39 2727.41) rotate(-90)" />
      </g>
      <rect class="cls-12" x="1129.35" y="15.86" width="254.69" height="804.88" />
      <rect class="cls-12" x="15.86" y="1670.85" width="473.24" height="230.43" />
      <rect class="cls-12" x="15.86" y="188.68" width="151.22" height="932.86" />
      <rect class="cls-12" x="461.7" y="15.86" width="288.04" height="172.82" />
      <path class="cls-10" d="M488.48,1673.49v225.15h-88.33v-225.15h88.33M491.12,1670.85h-93.61v230.43h93.61v-230.43h0Z" />
      <line class="cls-6" x1="253.49" y1="1670.85" x2="253.49" y2="1345.84" />
      <line class="cls-6" x1="491.12" y1="1670.85" x2="16.53" y2="1670.85" />
      <line class="cls-7" x1="491.12" y1="1781.37" x2="397.51" y2="1781.37" />
      <line class="cls-7" x1="491.12" y1="1796.36" x2="397.51" y2="1796.36" />
      <line class="cls-7" x1="491.12" y1="1811.35" x2="397.51" y2="1811.35" />
      <line class="cls-7" x1="491.12" y1="1826.34" x2="397.51" y2="1826.34" />
      <line class="cls-7" x1="491.12" y1="1841.32" x2="397.51" y2="1841.32" />
      <line class="cls-7" x1="491.12" y1="1856.31" x2="397.51" y2="1856.31" />
      <line class="cls-7" x1="491.12" y1="1871.3" x2="397.51" y2="1871.3" />
      <line class="cls-7" x1="491.12" y1="1766.38" x2="397.51" y2="1766.38" />
      <line class="cls-7" x1="491.12" y1="1736.4" x2="397.51" y2="1736.4" />
      <line class="cls-7" x1="491.12" y1="1721.41" x2="397.51" y2="1721.41" />
      <line class="cls-7" x1="491.12" y1="1706.42" x2="397.51" y2="1706.42" />
      <line class="cls-8" x1="491.12" y1="1676.44" x2="397.51" y2="1676.44" />
      <line class="cls-7" x1="397.51" y1="1751.39" x2="491.12" y2="1751.39" />
      <line class="cls-7" x1="491.12" y1="1886.29" x2="397.51" y2="1886.29" />
      <line class="cls-7" x1="491.12" y1="1691.43" x2="397.51" y2="1691.43" />
      <line class="cls-5" x1="1384.04" y1="1901.28" x2="950.4" y2="1901.28" />
      <g>
        <path class="cls-3" d="M866.39,1431.83c46.43,0,84-37.57,84-84v-7.87" />
        <rect class="cls-4" x="818.89" y="1384.32" width="91.25" height="3.76" transform="translate(2250.71 521.69) rotate(90)" />
      </g>
      <polyline class="cls-5" points="862.63 1901.28 750.36 1901.28 750.36 1347.85 862.63 1347.85" />
      <line class="cls-9" x1="489.1" y1="1670.85" x2="2.95" y2="1670.85" />
      <rect class="cls-10" x="245.45" y="1345.84" width="16.09" height="325.01" />
      <g>
        <rect class="cls-10" x="1352.33" y="1339.92" width="31.72" height="15.86" />
        <g>
          <rect class="cls-10" x="1143.43" y="1339.92" width="47.57" height="15.86" />
          <rect class="cls-10" x="950.39" y="1339.92" width="31.72" height="15.86" />
        </g>
      </g>
      <path class="cls-10" d="M1349.68,1342.57v10.57h-156.04v-10.57h156.04M1352.33,1339.92h-161.32v15.86h161.32v-15.86h0Z" />
      <path class="cls-10" d="M1140.79,1342.57v10.57h-156.04v-10.57h156.04M1143.43,1339.92h-161.32v15.86h161.32v-15.86h0Z" />
      <g>
        <path class="cls-3" d="M388.59,1431.68c46.43,0,84-37.57,84-84v-7.87" />
        <rect class="cls-3" x="341.09" y="1384.18" width="91.25" height="3.76" transform="translate(1772.77 999.35) rotate(90)" />
      </g>
      <g>
        <path class="cls-3" d="M150.96,1431.68c46.43,0,84-37.57,84-84v-7.87" />
        <rect class="cls-4" x="103.46" y="1384.18" width="91.25" height="3.76" transform="translate(1535.14 1236.98) rotate(90)" />
      </g>
      <rect class="cls-10" x="2.95" y="1339.81" width="144.25" height="16.09" />
      <g>
        <rect class="cls-10" x="234.97" y="1339.81" width="149.86" height="16.09" />
        <polygon
          class="cls-10"
          points="499.16 1339.81 472.6 1339.81 472.6 1355.89 483.08 1355.89 483.08 1901.28 499.16 1901.28 499.16 1339.81"
        />
      </g>
      <g>
        <path class="cls-3" d="M735.28,3714.26c-46.43,0-84-37.57-84-84v-7.87" />
        <rect class="cls-4" x="691.54" y="3666.76" width="91.25" height="3.76" transform="translate(4405.8 2931.47) rotate(90)" />
      </g>
      <g>
        <path class="cls-3" d="M567.27,3714.26c46.43,0,84-37.57,84-84v-7.87" />
        <rect class="cls-4" x="519.77" y="3666.76" width="91.25" height="3.76" transform="translate(4234.03 3103.25) rotate(90)" />
      </g>
      <polygon
        class="cls-10"
        points="487.71 3702.76 15.86 3702.76 15.86 15.86 1384.04 15.86 1384.04 3702.76 891.88 3702.76 891.88 3718.61 1384.04 3718.61 1399.9 3718.61 1399.9 3702.76 1399.9 15.86 1399.9 0 1384.04 0 15.86 0 0 0 0 15.86 0 3702.76 0 3718.61 15.86 3718.61 487.71 3718.61 487.71 3702.76"
      />
      <g>
        <polygon
          class="cls-10"
          points="964.49 3622.39 739.05 3622.39 739.05 3639.86 947.02 3639.86 947.02 3718.61 964.49 3718.61 964.49 3622.39"
        />
        <polygon
          class="cls-10"
          points="563.51 3639.86 563.51 3622.39 415.1 3622.39 415.1 3718.61 432.57 3718.61 432.57 3639.86 563.51 3639.86"
        />
      </g>
      <path class="cls-10" d="M726.37,4.32v23.07h-241.31V4.32h241.31M729.01,1.68h-246.59v28.36h246.59V1.68h0Z" />
    </g>
    <g id="EXTRAS">
      <path
        class="cls-2"
        d="M1045.73,3462.24c18.43,0,33.42-14.99,33.42-33.42s-14.99-33.42-33.42-33.42-33.42,14.99-33.42,33.42,14.99,33.42,33.42,33.42Z"
      />
      <path
        class="cls-2"
        d="M1045.73,2665.38c-18.43,0-33.42,14.99-33.42,33.42s14.99,33.42,33.42,33.42,33.42-14.99,33.42-33.42-14.99-33.42-33.42-33.42Z"
      />
      <path
        class="cls-2"
        d="M1162.09,936.49c-18.43,0-33.42,14.99-33.42,33.42s14.99,33.42,33.42,33.42,33.42-14.99,33.42-33.42-14.99-33.42-33.42-33.42Z"
      />
      <path
        class="cls-2"
        d="M568.01,1986.3c-18.43,0-33.42,14.99-33.42,33.42s14.99,33.42,33.42,33.42,33.42-14.99,33.42-33.42-14.99-33.42-33.42-33.42Z"
      />
      <path
        class="cls-2"
        d="M1162.09,1225.18c18.43,0,33.42-14.99,33.42-33.42s-14.99-33.42-33.42-33.42-33.42,14.99-33.42,33.42,14.99,33.42,33.42,33.42Z"
      />
      <rect class="cls-2" x="1241.54" y="1979.15" width="62.05" height="162.98" />
      <rect class="cls-2" x="1313.01" y="1085.84" width="62.02" height="162.9" />
      <path
        class="cls-2"
        d="M1269.57,2291.46c-18.43,0-33.42,14.99-33.42,33.42s14.99,33.42,33.42,33.42,33.42-14.99,33.42-33.42-14.99-33.42-33.42-33.42Z"
      />
      <rect class="cls-2" x="1313.01" y="912.94" width="62.02" height="162.9" />
      <rect class="cls-2" x="929.07" y="1981.5" width="191.71" height="76.45" />
      <path
        class="cls-2"
        d="M348.31,2254.19c-18.43,0-33.42,14.99-33.42,33.42s14.99,33.42,33.42,33.42,33.42-14.99,33.42-33.42-14.99-33.42-33.42-33.42Z"
      />
      <path
        class="cls-2"
        d="M122.08,1948.42c-18.44,0-33.44,15-33.44,33.44s15,33.44,33.44,33.44,33.44-15,33.44-33.44-15-33.44-33.44-33.44Z"
      />
      <rect class="cls-2" x="91.05" y="3464.89" width="62.05" height="162.98" />
      <path
        class="cls-2"
        d="M122.08,3277.25c-33.01,0-59.86,26.85-59.86,59.86s26.85,59.86,59.86,59.86,59.86-26.85,59.86-59.86-26.85-59.86-59.86-59.86Z"
      />
      <rect class="cls-2" x="756.5" y="1987.9" width="162.98" height="62.05" />
      <path
        class="cls-2"
        d="M941.04,1225.18c18.43,0,33.42-14.99,33.42-33.42s-14.99-33.42-33.42-33.42-33.42,14.99-33.42,33.42,14.99,33.42,33.42,33.42Z"
      />
      <path
        class="cls-2"
        d="M348.31,3051.05c18.43,0,33.42-14.99,33.42-33.42s-14.99-33.42-33.42-33.42-33.42,14.99-33.42,33.42,14.99,33.42,33.42,33.42Z"
      />
      <path
        class="cls-2"
        d="M941.04,936.49c-18.43,0-33.42,14.99-33.42,33.42s14.99,33.42,33.42,33.42,33.42-14.99,33.42-33.42-14.99-33.42-33.42-33.42Z"
      />
      <g>
        <path
          class="cls-2"
          d="M220.87,3337.67v-.52c0-8.64,4.48-14.04,10.6-14.04,2.52,0,4.44.64,6.36,2.2l-1.6,2.36c-1.16-1.12-2.72-1.92-4.72-1.92-4.36,0-7.8,4.28-7.8,11.44v.36c0,7.48,3.32,11.56,7.92,11.56,1.48,0,2.8-.44,3.76-1.08v-8.92h-5.36v-2.56h8.04v12.96c-1.68,1.2-4,2.16-6.64,2.16-6.32,0-10.56-5.12-10.56-14Z"
        />
        <path class="cls-2" d="M243.51,3323.4h2.8v25.4h9.8v2.6h-12.6v-28Z" />
        <path
          class="cls-2"
          d="M266.67,3323.24h2.92l8.32,28.16h-2.92l-2.12-7.28h-9.6l-2.12,7.28h-2.76l8.28-28.16ZM272.15,3341.55l-4.04-14.16-4.12,14.16h8.16Z"
        />
        <path
          class="cls-2"
          d="M279.91,3348.35l1.76-2.24c1.72,1.68,3.68,3,6.04,3,2.92,0,4.84-2.08,4.84-4.64v-.04c0-1.96-.84-3.52-4.96-5.92-5.12-2.96-6.56-5.12-6.56-8.32v-.08c0-3.96,3.08-7.04,7.52-7.04,2.28,0,4.72.8,6.8,2.8l-1.72,2.2c-1.52-1.44-3.28-2.4-5.2-2.4-2.76,0-4.56,1.92-4.56,4.24,0,2.2,1,3.56,5.32,6.12,5.08,3,6.2,5.24,6.2,8.32v.04c0,4.16-3.28,7.32-7.64,7.32-2.72,0-5.32-.92-7.84-3.36Z"
        />
        <path
          class="cls-2"
          d="M297.55,3348.35l1.76-2.24c1.72,1.68,3.68,3,6.04,3,2.92,0,4.84-2.08,4.84-4.64v-.04c0-1.96-.84-3.52-4.96-5.92-5.12-2.96-6.56-5.12-6.56-8.32v-.08c0-3.96,3.08-7.04,7.52-7.04,2.28,0,4.72.8,6.8,2.8l-1.72,2.2c-1.52-1.44-3.28-2.4-5.2-2.4-2.76,0-4.56,1.92-4.56,4.24,0,2.2,1,3.56,5.32,6.12,5.08,3,6.2,5.24,6.2,8.32v.04c0,4.16-3.28,7.32-7.64,7.32-2.72,0-5.32-.92-7.84-3.36Z"
        />
        <path
          class="cls-2"
          d="M315.12,3323.4h2.92l5.04,22.76,5.28-22.8h2.48l5.28,22.8,5.04-22.76h2.88l-6.6,28.16h-2.6l-5.28-22.44-5.28,22.44h-2.6l-6.56-28.16Z"
        />
        <path
          class="cls-2"
          d="M352.91,3323.24h2.92l8.32,28.16h-2.92l-2.12-7.28h-9.6l-2.12,7.28h-2.76l8.28-28.16ZM358.39,3341.55l-4.04-14.16-4.12,14.16h8.16Z"
        />
        <path
          class="cls-2"
          d="M368.35,3323.4h6.76c2.84,0,5.16.88,6.68,2.4,1.4,1.4,2.2,3.48,2.2,6v.12c0,4.24-2.28,6.84-5.52,7.92l6.24,11.56h-3.2l-5.84-10.92c-.36.04-.72.04-1.08.04h-3.44v10.88h-2.8v-28ZM374.27,3337.95c4.96,0,6.96-2.48,6.96-6v-.04c0-3.92-2.44-5.92-6.32-5.92h-3.76v11.96h3.12Z"
        />
        <path class="cls-2" d="M389,3323.4h13.52v2.6h-10.72v9.96h9.64v2.6h-9.64v10.24h10.88v2.6h-13.68v-28Z" />
      </g>
      <g>
        <path
          class="cls-2"
          d="M188.78,3546.9v-.44c0-9.04,4.48-14.08,10.12-14.08,2.52,0,4.36.96,5.84,2.32l-1.56,2.2c-1.2-1.08-2.64-1.88-4.36-1.88-4,0-7.2,4.4-7.2,11.48v.28c0,7.28,3.2,11.52,7.16,11.52,1.72,0,3.24-.84,4.56-2.08l1.52,2.16c-1.64,1.52-3.72,2.56-6.24,2.56-5.64,0-9.84-4.92-9.84-14.04Z"
        />
        <path class="cls-2" d="M209.14,3532.67h2.8v12.6h10.52v-12.6h2.84v27.99h-2.84v-12.8h-10.52v12.8h-2.8v-27.99Z" />
        <path class="cls-2" d="M231.09,3532.67h13.52v2.6h-10.72v9.96h9.64v2.6h-9.64v10.24h10.88v2.6h-13.68v-27.99Z" />
        <path
          class="cls-2"
          d="M248.3,3546.9v-.44c0-9.04,4.48-14.08,10.12-14.08,2.52,0,4.36.96,5.84,2.32l-1.56,2.2c-1.2-1.08-2.64-1.88-4.36-1.88-4,0-7.2,4.4-7.2,11.48v.28c0,7.28,3.2,11.52,7.16,11.52,1.72,0,3.24-.84,4.56-2.08l1.52,2.16c-1.64,1.52-3.72,2.56-6.24,2.56-5.64,0-9.84-4.92-9.84-14.04Z"
        />
        <path class="cls-2" d="M268.66,3532.67h2.8v15.6l10.12-15.6h3.28l-8.2,12.28,8.8,15.72h-3.28l-7.4-13.12-3.32,4.92v8.2h-2.8v-27.99Z" />
        <path class="cls-2" d="M286.82,3547.3h9.28v2.72h-9.28v-2.72Z" />
        <path class="cls-2" d="M301.38,3532.67h2.8v27.99h-2.8v-27.99Z" />
        <path class="cls-2" d="M310.26,3532.67h2.72l11.24,21.56v-21.56h2.72v27.99h-2.44l-11.52-22.12v22.12h-2.72v-27.99Z" />
      </g>
      <g>
        <path
          class="cls-2"
          d="M197.45,1947.15h5.8c6.72,0,11.2,5.12,11.2,13.72v.56c0,8.56-4.48,13.72-11.2,13.72h-5.8v-28ZM200.25,1949.71v22.88h2.88c5.04,0,8.48-4.04,8.48-11.2v-.48c0-7.12-3.44-11.2-8.48-11.2h-2.88Z"
        />
        <path class="cls-2" d="M219.65,1947.15h2.8v28h-2.8v-28Z" />
        <path
          class="cls-2"
          d="M228.53,1947.15h6.76c2.84,0,5.16.88,6.68,2.4,1.4,1.4,2.2,3.48,2.2,6v.12c0,4.24-2.28,6.84-5.52,7.92l6.24,11.56h-3.2l-5.84-10.92c-.36.04-.72.04-1.08.04h-3.44v10.88h-2.8v-28ZM234.45,1961.71c4.96,0,6.96-2.48,6.96-6v-.04c0-3.92-2.44-5.92-6.32-5.92h-3.76v11.96h3.12Z"
        />
        <path class="cls-2" d="M253.21,1949.75h-6.08v-2.6h15v2.6h-6.08v25.4h-2.84v-25.4Z" />
        <path class="cls-2" d="M271.57,1963.62l-7.8-16.48h3.08l6.16,13.32,6.16-13.32h3l-7.8,16.44v11.56h-2.8v-11.52Z" />
        <path
          class="cls-2"
          d="M196.58,2003.42v-.52c0-8.64,4.48-14.04,10.6-14.04,2.52,0,4.44.64,6.36,2.2l-1.6,2.36c-1.16-1.12-2.72-1.92-4.72-1.92-4.36,0-7.8,4.28-7.8,11.44v.36c0,7.48,3.32,11.56,7.92,11.56,1.48,0,2.8-.44,3.76-1.08v-8.92h-5.36v-2.56h8.04v12.96c-1.68,1.2-4,2.16-6.64,2.16-6.32,0-10.56-5.12-10.56-14Z"
        />
        <path class="cls-2" d="M219.21,1989.15h2.8v25.4h9.8v2.6h-12.6v-28Z" />
        <path
          class="cls-2"
          d="M242.37,1988.99h2.92l8.32,28.16h-2.92l-2.12-7.28h-9.6l-2.12,7.28h-2.76l8.28-28.16ZM247.85,2007.3l-4.04-14.16-4.12,14.16h8.16Z"
        />
        <path
          class="cls-2"
          d="M255.62,2014.1l1.76-2.24c1.72,1.68,3.68,3,6.04,3,2.92,0,4.84-2.08,4.84-4.64v-.04c0-1.96-.84-3.52-4.96-5.92-5.12-2.96-6.56-5.12-6.56-8.32v-.08c0-3.96,3.08-7.04,7.52-7.04,2.28,0,4.72.8,6.8,2.8l-1.72,2.2c-1.52-1.44-3.28-2.4-5.2-2.4-2.76,0-4.56,1.92-4.56,4.24,0,2.2,1,3.56,5.32,6.12,5.08,3,6.2,5.24,6.2,8.32v.04c0,4.16-3.28,7.32-7.64,7.32-2.72,0-5.32-.92-7.84-3.36Z"
        />
        <path
          class="cls-2"
          d="M273.26,2014.1l1.76-2.24c1.72,1.68,3.68,3,6.04,3,2.92,0,4.84-2.08,4.84-4.64v-.04c0-1.96-.84-3.52-4.96-5.92-5.12-2.96-6.56-5.12-6.56-8.32v-.08c0-3.96,3.08-7.04,7.52-7.04,2.28,0,4.72.8,6.8,2.8l-1.72,2.2c-1.52-1.44-3.28-2.4-5.2-2.4-2.76,0-4.56,1.92-4.56,4.24,0,2.2,1,3.56,5.32,6.12,5.08,3,6.2,5.24,6.2,8.32v.04c0,4.16-3.28,7.32-7.64,7.32-2.72,0-5.32-.92-7.84-3.36Z"
        />
        <path
          class="cls-2"
          d="M290.82,1989.15h2.92l5.04,22.76,5.28-22.8h2.48l5.28,22.8,5.04-22.76h2.88l-6.6,28.16h-2.6l-5.28-22.44-5.28,22.44h-2.6l-6.56-28.16Z"
        />
        <path
          class="cls-2"
          d="M328.61,1988.99h2.92l8.32,28.16h-2.92l-2.12-7.28h-9.6l-2.12,7.28h-2.76l8.28-28.16ZM334.09,2007.3l-4.04-14.16-4.12,14.16h8.16Z"
        />
        <path
          class="cls-2"
          d="M344.06,1989.15h6.76c2.84,0,5.16.88,6.68,2.4,1.4,1.4,2.2,3.48,2.2,6v.12c0,4.24-2.28,6.84-5.52,7.92l6.24,11.56h-3.2l-5.84-10.92c-.36.04-.72.04-1.08.04h-3.44v10.88h-2.8v-28ZM349.97,2003.71c4.96,0,6.96-2.48,6.96-6v-.04c0-3.92-2.44-5.92-6.32-5.92h-3.76v11.96h3.12Z"
        />
        <path class="cls-2" d="M364.7,1989.15h13.52v2.6h-10.72v9.96h9.64v2.6h-9.64v10.24h10.88v2.6h-13.68v-28Z" />
      </g>
      <g>
        <path
          class="cls-2"
          d="M823.18,2094.64h6.76c2.84,0,5.16.88,6.68,2.4,1.4,1.4,2.2,3.48,2.2,6v.12c0,4.24-2.28,6.84-5.52,7.92l6.24,11.56h-3.2l-5.84-10.92c-.36.04-.72.04-1.08.04h-3.44v10.88h-2.8v-28ZM829.1,2109.2c4.96,0,6.96-2.48,6.96-6v-.04c0-3.92-2.44-5.92-6.32-5.92h-3.76v11.96h3.12Z"
        />
        <path class="cls-2" d="M843.82,2094.64h13.52v2.6h-10.72v9.96h9.64v2.6h-9.64v10.24h10.88v2.6h-13.68v-28Z" />
        <path class="cls-2" d="M862.18,2094.64h13.24v2.6h-10.44v10.36h9.36v2.6h-9.36v12.44h-2.8v-28Z" />
        <path
          class="cls-2"
          d="M879.82,2094.64h6.76c2.84,0,5.16.88,6.68,2.4,1.4,1.4,2.2,3.48,2.2,6v.12c0,4.24-2.28,6.84-5.52,7.92l6.24,11.56h-3.2l-5.84-10.92c-.36.04-.72.04-1.08.04h-3.44v10.88h-2.8v-28ZM885.74,2109.2c4.96,0,6.96-2.48,6.96-6v-.04c0-3.92-2.44-5.92-6.32-5.92h-3.76v11.96h3.12Z"
        />
        <path class="cls-2" d="M900.46,2094.64h13.52v2.6h-10.72v9.96h9.64v2.6h-9.64v10.24h10.88v2.6h-13.68v-28Z" />
        <path
          class="cls-2"
          d="M916.82,2119.6l1.76-2.24c1.72,1.68,3.68,3,6.04,3,2.92,0,4.84-2.08,4.84-4.64v-.04c0-1.96-.84-3.52-4.96-5.92-5.12-2.96-6.56-5.12-6.56-8.32v-.08c0-3.96,3.08-7.04,7.52-7.04,2.28,0,4.72.8,6.8,2.8l-1.72,2.2c-1.52-1.44-3.28-2.4-5.2-2.4-2.76,0-4.56,1.92-4.56,4.24,0,2.2,1,3.56,5.32,6.12,5.08,3,6.2,5.24,6.2,8.32v.04c0,4.16-3.28,7.32-7.64,7.32-2.72,0-5.32-.92-7.84-3.36Z"
        />
        <path class="cls-2" d="M936.9,2094.64h2.8v12.6h10.52v-12.6h2.84v28h-2.84v-12.8h-10.52v12.8h-2.8v-28Z" />
        <path class="cls-2" d="M958.86,2094.64h2.96l6.68,14.68,6.68-14.68h3v28h-2.76v-22.44l-6.88,14.92h-.12l-6.84-14.88v22.4h-2.72v-28Z" />
        <path class="cls-2" d="M983.98,2094.64h13.52v2.6h-10.72v9.96h9.64v2.6h-9.64v10.24h10.88v2.6h-13.68v-28Z" />
        <path class="cls-2" d="M1002.34,2094.64h2.72l11.24,21.56v-21.56h2.72v28h-2.44l-11.52-22.12v22.12h-2.72v-28Z" />
        <path class="cls-2" d="M1029.18,2097.24h-6.08v-2.6h15v2.6h-6.08v25.4h-2.84v-25.4Z" />
        <path
          class="cls-2"
          d="M1039.86,2119.6l1.76-2.24c1.72,1.68,3.68,3,6.04,3,2.92,0,4.84-2.08,4.84-4.64v-.04c0-1.96-.84-3.52-4.96-5.92-5.12-2.96-6.56-5.12-6.56-8.32v-.08c0-3.96,3.08-7.04,7.52-7.04,2.28,0,4.72.8,6.8,2.8l-1.72,2.2c-1.52-1.44-3.28-2.4-5.2-2.4-2.76,0-4.56,1.92-4.56,4.24,0,2.2,1,3.56,5.32,6.12,5.08,3,6.2,5.24,6.2,8.32v.04c0,4.16-3.28,7.32-7.64,7.32-2.72,0-5.32-.92-7.84-3.36Z"
        />
      </g>
      <path
        class="cls-2"
        d="M215.43,1546.86l-9.96-32.9c-1.32-4.89-5.07-12.59-17.1-12.59h-13.91c-12.22,0-15.79,7.71-17.11,12.59l-9.96,32.9c-1.13,3.19,0,5.45,2.25,6.01,2.63.75,4.7-.75,5.64-3.38l8.46-29.14c.57-1.88,1.7-2.82,3.2-2.25,1.5.56,1.5,2.07.94,3.76l-15.6,52.45h17.48v39.67c0,6.01,3.2,6.58,4.7,6.58,1.32,0,4.32-.57,4.32-6.39v-37.78c0-1.69.57-2.44,2.45-2.44,2.06,0,2.62.75,2.62,2.44v37.78c0,5.82,3.39,6.39,4.7,6.39,1.5,0,4.51-.57,4.51-6.58v-39.67h17.48s-15.6-52.45-15.6-52.45c-.75-1.69-.57-3.2.94-3.76,1.51-.57,2.63.37,3.2,2.25l8.46,29.14c.95,2.62,3,4.13,5.64,3.38,2.25-.56,3.39-2.82,2.25-6.01M193.44,1484.83c0-6.39-5.45-11.66-11.84-11.66-6.58,0-11.84,5.27-11.84,11.66,0,6.77,5.27,12.03,11.84,12.03,6.39,0,11.84-5.26,11.84-12.03"
      />
      <path
        class="cls-2"
        d="M115.45,1552.5v-33.08c0-14.66-7.33-18.43-18.98-18.43h-19.55c-11.84,0-18.98,3.77-18.98,18.43v33.08c0,4.89,1.5,6.2,4.51,6.2,2.63,0,4.7-1.13,4.7-6.2v-25.75c0-3,1.5-3.39,2.25-3.39.75,0,2.45,0,2.45,3.39v87.22c0,6.01,4.7,6.58,6.39,6.58,1.5,0,6.77-.57,6.77-6.39v-51.13c0-1.88.37-2.63,1.69-2.63,1.5,0,1.5,1.32,1.5,2.63v51.13c0,5.64,4.89,6.39,6.59,6.39,1.87,0,6.58-.57,6.58-6.58v-87.22c0-2.82,1.69-3.39,2.45-3.39s2.25.57,2.25,3.39v25.75c0,4.89,1.87,6.2,4.51,6.2,3.2,0,4.89-1.32,4.89-6.2M98.53,1484.83c0-6.39-5.27-11.66-11.66-11.66-9.73,0-16.36,10.91-8.67,20.37,9.45,7.62,20.32,1.02,20.32-8.71"
      />
      <path
        class="cls-2"
        d="M452.24,1546.86l-9.96-32.9c-1.32-4.89-5.07-12.59-17.1-12.59h-13.91c-12.22,0-15.79,7.71-17.11,12.59l-9.96,32.9c-1.13,3.19,0,5.45,2.25,6.01,2.63.75,4.7-.75,5.64-3.38l8.46-29.14c.57-1.88,1.7-2.82,3.2-2.25,1.5.56,1.5,2.07.94,3.76l-15.6,52.45h17.48v39.67c0,6.01,3.2,6.58,4.7,6.58,1.32,0,4.32-.57,4.32-6.39v-37.78c0-1.69.57-2.44,2.45-2.44,2.06,0,2.62.75,2.62,2.44v37.78c0,5.82,3.39,6.39,4.7,6.39,1.5,0,4.51-.57,4.51-6.58v-39.67h17.48s-15.6-52.45-15.6-52.45c-.75-1.69-.57-3.2.94-3.76,1.51-.57,2.63.37,3.2,2.25l8.46,29.14c.95,2.62,3,4.13,5.64,3.38,2.25-.56,3.39-2.82,2.25-6.01M430.25,1484.83c0-6.39-5.45-11.66-11.84-11.66-6.58,0-11.84,5.27-11.84,11.66,0,6.77,5.27,12.03,11.84,12.03,6.39,0,11.84-5.26,11.84-12.03"
      />
      <path
        class="cls-2"
        d="M352.26,1552.5v-33.08c0-14.66-7.33-18.43-18.98-18.43h-19.55c-11.84,0-18.98,3.77-18.98,18.43v33.08c0,4.89,1.5,6.2,4.51,6.2,2.63,0,4.7-1.13,4.7-6.2v-25.75c0-3,1.5-3.39,2.25-3.39.75,0,2.45,0,2.45,3.39v87.22c0,6.01,4.7,6.58,6.39,6.58,1.5,0,6.77-.57,6.77-6.39v-51.13c0-1.88.37-2.63,1.69-2.63,1.5,0,1.5,1.32,1.5,2.63v51.13c0,5.64,4.89,6.39,6.59,6.39,1.87,0,6.58-.57,6.58-6.58v-87.22c0-2.82,1.69-3.39,2.45-3.39s2.25.57,2.25,3.39v25.75c0,4.89,1.87,6.2,4.51,6.2,3.2,0,4.89-1.32,4.89-6.2M335.35,1484.83c0-6.39-5.27-11.66-11.66-11.66-9.73,0-16.36,10.91-8.67,20.37,9.45,7.62,20.32,1.02,20.32-8.71"
      />
      <rect class="cls-11" x="88.68" y="3896.8" width="69.39" height="69.39" />
      <rect class="cls-1" x="318.06" y="3896.8" width="69.39" height="69.39" />
      <g>
        <path
          class="cls-2"
          d="M179.64,3921.03h2.23l3.84,17.36,4.03-17.39h1.89l4.03,17.39,3.84-17.36h2.2l-5.03,21.47h-1.98l-4.03-17.11-4.03,17.11h-1.98l-5-21.47Z"
        />
        <path
          class="cls-2"
          d="M203.34,3931.95v-.46c0-6.65,3.05-10.77,7.26-10.77s7.23,4.06,7.23,10.74v.46c0,6.65-3.08,10.77-7.29,10.77s-7.2-4.06-7.2-10.74ZM215.63,3931.89v-.37c0-5.52-2.2-8.78-5.06-8.78s-5.03,3.26-5.03,8.78v.37c0,5.52,2.17,8.78,5.03,8.78s5.06-3.26,5.06-8.78Z"
        />
        <path
          class="cls-2"
          d="M221.28,3921.03h5.16c2.17,0,3.93.67,5.09,1.83,1.07,1.07,1.68,2.65,1.68,4.58v.09c0,3.23-1.74,5.22-4.21,6.04l4.76,8.81h-2.44l-4.45-8.33c-.27.03-.55.03-.82.03h-2.62v8.3h-2.13v-21.35ZM225.79,3932.13c3.78,0,5.31-1.89,5.31-4.58v-.03c0-2.99-1.86-4.51-4.82-4.51h-2.87v9.12h2.38Z"
        />
        <path class="cls-2" d="M236.71,3921.03h2.13v19.37h7.47v1.98h-9.61v-21.35Z" />
        <path
          class="cls-2"
          d="M249.07,3921.03h4.42c5.12,0,8.54,3.9,8.54,10.46v.43c0,6.53-3.42,10.46-8.54,10.46h-4.42v-21.35ZM251.2,3922.98v17.45h2.2c3.84,0,6.47-3.08,6.47-8.54v-.37c0-5.43-2.62-8.54-6.47-8.54h-2.2Z"
        />
      </g>
      <g>
        <path class="cls-2" d="M410.14,3921.03h10.1v1.98h-7.96v7.9h7.14v1.98h-7.14v9.49h-2.13v-21.35Z" />
        <path
          class="cls-2"
          d="M423.29,3921.03h5.16c2.17,0,3.93.67,5.09,1.83,1.07,1.07,1.68,2.65,1.68,4.58v.09c0,3.23-1.74,5.22-4.21,6.04l4.76,8.82h-2.44l-4.45-8.33c-.27.03-.55.03-.82.03h-2.62v8.3h-2.13v-21.35ZM427.81,3932.14c3.78,0,5.31-1.89,5.31-4.58v-.03c0-2.99-1.86-4.51-4.82-4.51h-2.87v9.12h2.38Z"
        />
        <path
          class="cls-2"
          d="M443.61,3920.91h2.23l6.34,21.47h-2.23l-1.62-5.55h-7.32l-1.62,5.55h-2.1l6.31-21.47ZM447.79,3934.88l-3.08-10.8-3.14,10.8h6.22Z"
        />
        <path class="cls-2" d="M455.08,3921.03h2.07l8.57,16.44v-16.44h2.07v21.35h-1.86l-8.78-16.87v16.87h-2.07v-21.35Z" />
        <path
          class="cls-2"
          d="M471.27,3931.89v-.33c0-6.89,3.42-10.74,7.72-10.74,1.92,0,3.33.73,4.45,1.77l-1.19,1.68c-.92-.82-2.01-1.43-3.32-1.43-3.05,0-5.49,3.36-5.49,8.75v.21c0,5.55,2.44,8.78,5.46,8.78,1.31,0,2.47-.64,3.48-1.59l1.16,1.65c-1.25,1.16-2.84,1.95-4.76,1.95-4.3,0-7.5-3.75-7.5-10.71Z"
        />
        <path class="cls-2" d="M486.49,3921.03h10.31v1.98h-8.17v7.6h7.35v1.98h-7.35v7.81h8.3v1.98h-10.43v-21.35Z" />
      </g>
      <g>
        <path
          class="cls-2"
          d="M90.97,3788.28h13.89l11.31,18.36,11.31-18.36h13.89v47.42h-13.07v-27.23l-12.13,18.56h-.27l-12.06-18.49v27.17h-12.87v-47.42Z"
        />
        <path
          class="cls-2"
          d="M167.38,3787.94h12.67l20.19,47.76h-14.09l-3.39-8.54h-18.36l-3.39,8.54h-13.75l20.12-47.76ZM178.89,3816.94l-5.28-13.41-5.35,13.41h10.64Z"
        />
        <path
          class="cls-2"
          d="M206.05,3788.28h20.39c11.92,0,19.65,6.17,19.65,16.6v.14c0,11.25-8.6,17.14-20.32,17.14h-6.57v13.55h-13.14v-47.42ZM225.5,3811.86c4.61,0,7.52-2.51,7.52-6.23v-.14c0-4.07-2.91-6.17-7.59-6.17h-6.23v12.53h6.3Z"
        />
        <path
          class="cls-2"
          d="M273.79,3788.28h13.14v19.24l16.39-19.24h15.51l-17.95,20.32,18.56,27.1h-15.72l-11.99-17.61-4.81,5.28v12.33h-13.14v-47.42Z"
        />
        <path class="cls-2" d="M324.93,3788.28h38.14v11.11h-25.13v7.25h22.76v10.37h-22.76v7.52h25.47v11.18h-38.48v-47.42Z" />
        <path class="cls-2" d="M386.1,3817.95l-18.09-29.67h14.97l9.76,17.28,9.82-17.28h14.7l-18.02,29.47v17.95h-13.14v-17.75Z" />
        <path class="cls-2" d="M419.09,3799.05h13.35v13.21h-13.35v-13.21ZM419.09,3822.49h13.35v13.21h-13.35v-13.21Z" />
      </g>
    </g>
    <g id="TABLES">
      <g id="TABLE-20">
        <path
          class="cls-2"
          d="M1168.27,3377.51l9.05-11.55c2.4-3.1,3.35-4.55,3.35-6.4,0-1.75-1.25-3.15-3.2-3.15s-3.6,1.3-5.75,3.4l-4-6.05c3.35-3.35,7-5.05,10.9-5.05,5.9,0,10.15,3.75,10.15,9.7v.15c0,3.7-1.35,6.5-5.3,11.3l-5.65,6.95h10.8v7.5h-20.35v-6.8Z"
        />
        <path
          class="cls-2"
          d="M1191.72,3367.56v-1.45c0-11.05,4.6-17.45,12.2-17.45s12.1,6.35,12.1,17.35v1.5c0,11.05-4.6,17.45-12.15,17.45s-12.15-6.35-12.15-17.4ZM1207.77,3367.51v-1.4c0-7.25-1.6-9.95-3.9-9.95s-3.9,2.65-3.9,9.9v1.4c0,7.3,1.55,10,3.95,10s3.85-2.65,3.85-9.95Z"
        />
        <rect class="cls-1" x="1240.97" y="3284.93" width="62.02" height="162.9" />
      </g>
      <g id="TABLE-19">
        <path class="cls-2" d="M1176.92,3184.56l-4.35,1.25-.6-7.4,7.3-2.2h5.9v35.2h-8.25v-26.85Z" />
        <path
          class="cls-2"
          d="M1203.52,3197.86c-1.55,1.1-3.35,1.75-5.35,1.75-4.5,0-9.1-3.15-9.1-10.95v-.25c0-7.9,4.95-12.65,10.95-12.65,3.2,0,5.65,1,7.5,2.9,2.6,2.6,4.1,6.8,4.1,14.25v.3c0,13.3-5.45,18.8-13.3,18.8-2.85,0-5.25-.65-8.35-2.6l2.85-6.6c1.45,1,3.15,2,5.25,2,3.45,0,5.25-2.8,5.45-6.95ZM1203.57,3187.96v-.1c0-3.35-1.2-5.3-3.35-5.3s-3.3,1.95-3.3,5.3v.1c0,3.35,1.15,5.3,3.35,5.3s3.3-1.95,3.3-5.3Z"
        />
        <rect class="cls-1" x="1240.97" y="3112.04" width="62.02" height="162.9" />
      </g>
      <g id="TABLE-18">
        <path class="cls-2" d="M1177.1,2925.21l-4.35,1.25-.6-7.4,7.3-2.2h5.9v35.2h-8.25v-26.85Z" />
        <path
          class="cls-2"
          d="M1189.05,2942.56v-.3c0-4.15,2-6.8,4.8-8.4-2.35-1.55-4.1-4.15-4.1-7.6v-.25c0-5.6,4.35-9.6,10.65-9.6s10.65,3.95,10.65,9.55v.25c0,3.45-1.75,6.05-4.15,7.65,2.8,1.55,4.85,4.15,4.85,8.4v.3c0,6.15-4.65,10.15-11.35,10.15s-11.35-3.95-11.35-10.15ZM1203.85,2941.71v-.1c0-2.9-1.45-4.55-3.45-4.55s-3.45,1.65-3.45,4.55v.1c0,2.95,1.45,4.55,3.45,4.55s3.45-1.6,3.45-4.55ZM1203.5,2926.86c0-2.75-1.4-4.1-3.1-4.1s-3.05,1.35-3.05,4.1,1.3,4.25,3.05,4.25,3.1-1.55,3.1-4.25Z"
        />
        <rect class="cls-1" x="1240.97" y="2852.69" width="62.02" height="162.9" />
      </g>
      <g id="TABLE-17">
        <path class="cls-2" d="M1178.22,2752.31l-4.35,1.25-.6-7.4,7.3-2.2h5.9v35.2h-8.25v-26.85Z" />
        <path class="cls-2" d="M1202.02,2751.86h-11.55v-7.7h20.15v5.65l-10.25,29.35h-8.45l10.1-27.3Z" />
        <rect class="cls-1" x="1240.97" y="2679.79" width="62.02" height="162.9" />
      </g>
      <g id="TABLE-16">
        <path class="cls-2" d="M1176.61,2579.42l-4.35,1.25-.6-7.4,7.3-2.2h5.9v35.2h-8.25v-26.85Z" />
        <path
          class="cls-2"
          d="M1193.02,2604.02c-2.6-2.6-4.1-6.85-4.1-14.2v-.25c0-13.35,5.45-18.9,13.3-18.9,2.85,0,5.2.7,7.8,2.25l-2.85,6.6c-.9-.65-2.6-1.65-4.7-1.65-3.5,0-5.3,2.8-5.5,7.25,1.55-1.05,3.35-1.7,5.4-1.7,4.5,0,9.1,3.15,9.1,10.8v.2c0,7.75-4.95,12.5-10.95,12.5-3.2,0-5.65-1.05-7.5-2.9ZM1203.62,2594.97v-.05c0-3.2-1.15-5.15-3.35-5.15s-3.3,1.95-3.3,5.15v.05c0,3.2,1.2,5.15,3.35,5.15s3.3-1.95,3.3-5.15Z"
        />
        <rect class="cls-1" x="1240.97" y="2506.89" width="62.02" height="162.9" />
      </g>
      <g id="TABLE-15">
        <path class="cls-2" d="M885.11,2545.54l-4.35,1.25-.6-7.4,7.3-2.2h5.9v35.2h-8.25v-26.85Z" />
        <path
          class="cls-2"
          d="M896.66,2569.74l3.25-6.7c2.05,1.45,3.8,2.35,5.85,2.35,2.65,0,4.25-1.8,4.25-4.5v-.05c0-2.7-1.8-4.25-4.25-4.25-1.4,0-2.5.4-3.5,1l-4.25-2.45.95-17.75h17.75v7.45h-11.45l-.3,5.4c.95-.25,2.05-.45,3.3-.45,5.1,0,9.85,3.1,9.85,10.75v.2c0,7.8-5.1,12.25-11.8,12.25-3.5,0-6.85-1.15-9.65-3.25Z"
        />
        <rect class="cls-1" x="788.47" y="2473.02" width="62.02" height="162.9" />
      </g>
      <g id="TABLE-14">
        <path class="cls-2" d="M883.76,2718.44l-4.35,1.25-.6-7.4,7.3-2.2h5.9v35.2h-8.25v-26.85Z" />
        <path
          class="cls-2"
          d="M908.51,2739.04h-12.55l-.55-5.85,12.7-23.05h8.15v22.2h3.45v6.7h-3.45v6.25h-7.75v-6.25ZM908.56,2732.34v-10.65l-5.6,10.65h5.6Z"
        />
        <rect class="cls-1" x="788.47" y="2645.91" width="62.02" height="162.9" />
      </g>
      <g id="TABLE-13">
        <path class="cls-2" d="M885.31,2977.78l-4.35,1.25-.6-7.4,7.3-2.2h5.9v35.2h-8.25v-26.85Z" />
        <path
          class="cls-2"
          d="M896.61,3001.98l3.3-6.55c1.05.8,3.1,2.25,5.7,2.25,2.45,0,4.2-1.55,4.2-3.9v-.1c0-2.65-2.3-4.25-7-4.25h-.85l-.5-5,6.6-7.4h-9.8v-7.4h19.2v6.75l-7.15,7.7c4.15,1.15,7.55,4,7.55,9.65v.15c0,7.05-4.75,11.35-11.5,11.35-3.8,0-7.25-1.2-9.75-3.25Z"
        />
        <rect class="cls-1" x="788.47" y="2905.26" width="62.02" height="162.9" />
      </g>
      <g id="TABLE-12">
        <path class="cls-2" d="M885.11,3150.68l-4.35,1.25-.6-7.4,7.3-2.2h5.9v35.2h-8.25v-26.85Z" />
        <path
          class="cls-2"
          d="M897.46,3170.73l9.05-11.55c2.4-3.1,3.35-4.55,3.35-6.4,0-1.75-1.25-3.15-3.2-3.15s-3.6,1.3-5.75,3.4l-4-6.05c3.35-3.35,7-5.05,10.9-5.05,5.9,0,10.15,3.75,10.15,9.7v.15c0,3.7-1.35,6.5-5.3,11.3l-5.65,6.95h10.8v7.5h-20.35v-6.8Z"
        />
        <rect class="cls-1" x="788.47" y="3078.16" width="62.02" height="162.9" />
      </g>
      <g id="TABLE-11">
        <path class="cls-2" d="M686.27,3349.56l-4.35,1.25-.6-7.4,7.3-2.2h5.9v35.2h-8.25v-26.85Z" />
        <path class="cls-2" d="M702.82,3349.56l-4.35,1.25-.6-7.4,7.3-2.2h5.9v35.2h-8.25v-26.85Z" />
        <rect class="cls-1" x="615.57" y="3251.06" width="162.9" height="62.02" />
      </g>
      <g id="TABLE-10">
        <path class="cls-2" d="M478.53,3150.68l-4.35,1.25-.6-7.4,7.3-2.2h5.9v35.2h-8.25v-26.85Z" />
        <path
          class="cls-2"
          d="M490.83,3160.78v-1.45c0-11.05,4.6-17.45,12.2-17.45s12.1,6.35,12.1,17.35v1.5c0,11.05-4.6,17.45-12.15,17.45s-12.15-6.35-12.15-17.4ZM506.88,3160.73v-1.4c0-7.25-1.6-9.95-3.9-9.95s-3.9,2.65-3.9,9.9v1.4c0,7.3,1.55,10,3.95,10s3.85-2.65,3.85-9.95Z"
        />
        <rect class="cls-1" x="543.55" y="3078.16" width="62.02" height="162.9" />
      </g>
      <g id="TABLE-9">
        <path
          class="cls-2"
          d="M497.83,2992.32c-1.55,1.1-3.35,1.75-5.35,1.75-4.5,0-9.1-3.15-9.1-10.95v-.25c0-7.9,4.95-12.65,10.95-12.65,3.2,0,5.65,1,7.5,2.9,2.6,2.6,4.1,6.8,4.1,14.25v.3c0,13.3-5.45,18.8-13.3,18.8-2.85,0-5.25-.65-8.35-2.6l2.85-6.6c1.45,1,3.15,2,5.25,2,3.45,0,5.25-2.8,5.45-6.95ZM497.88,2982.42v-.1c0-3.35-1.2-5.3-3.35-5.3s-3.3,1.95-3.3,5.3v.1c0,3.35,1.15,5.3,3.35,5.3s3.3-1.95,3.3-5.3Z"
        />
        <rect class="cls-1" x="543.55" y="2905.26" width="62.02" height="162.9" />
      </g>
      <g id="TABLE-8">
        <path
          class="cls-2"
          d="M483.35,2735.79v-.3c0-4.15,2-6.8,4.8-8.4-2.35-1.55-4.1-4.15-4.1-7.6v-.25c0-5.6,4.35-9.6,10.65-9.6s10.65,3.95,10.65,9.55v.25c0,3.45-1.75,6.05-4.15,7.65,2.8,1.55,4.85,4.15,4.85,8.4v.3c0,6.15-4.65,10.15-11.35,10.15s-11.35-3.95-11.35-10.15ZM498.16,2734.94v-.1c0-2.9-1.45-4.55-3.45-4.55s-3.45,1.65-3.45,4.55v.1c0,2.95,1.45,4.55,3.45,4.55s3.45-1.6,3.45-4.55ZM497.81,2720.09c0-2.75-1.4-4.1-3.1-4.1s-3.05,1.35-3.05,4.1,1.3,4.25,3.05,4.25,3.1-1.55,3.1-4.25Z"
        />
        <rect class="cls-1" x="543.55" y="2645.91" width="62.02" height="162.9" />
      </g>
      <g id="TABLE-7">
        <path class="cls-2" d="M496.33,2545.09h-11.55v-7.7h20.15v5.65l-10.25,29.35h-8.45l10.1-27.3Z" />
        <rect class="cls-11" x="543.55" y="2473.02" width="62.02" height="162.9" />
      </g>
      <g id="TABLE-6">
        <path
          class="cls-2"
          d="M689.92,2371.26c-2.6-2.6-4.1-6.85-4.1-14.2v-.25c0-13.35,5.45-18.9,13.3-18.9,2.85,0,5.2.7,7.8,2.25l-2.85,6.6c-.9-.65-2.6-1.65-4.7-1.65-3.5,0-5.3,2.8-5.5,7.25,1.55-1.05,3.35-1.7,5.4-1.7,4.5,0,9.1,3.15,9.1,10.8v.2c0,7.75-4.95,12.5-10.95,12.5-3.2,0-5.65-1.05-7.5-2.9ZM700.52,2362.21v-.05c0-3.2-1.15-5.15-3.35-5.15s-3.3,1.95-3.3,5.15v.05c0,3.2,1.2,5.15,3.35,5.15s3.3-1.95,3.3-5.15Z"
        />
        <rect class="cls-11" x="615.57" y="2401" width="162.9" height="62.02" />
      </g>
      <g id="TABLE-5">
        <path
          class="cls-2"
          d="M190.98,2365.33l3.25-6.7c2.05,1.45,3.8,2.35,5.85,2.35,2.65,0,4.25-1.8,4.25-4.5v-.05c0-2.7-1.8-4.25-4.25-4.25-1.4,0-2.5.4-3.5,1l-4.25-2.45.95-17.75h17.75v7.45h-11.45l-.3,5.4c.95-.25,2.05-.45,3.3-.45,5.1,0,9.85,3.1,9.85,10.75v.2c0,7.8-5.1,12.25-11.8,12.25-3.5,0-6.85-1.15-9.65-3.25Z"
        />
        <rect class="cls-11" x="91.05" y="2268.6" width="62.02" height="162.9" />
      </g>
      <g id="TABLE-4">
        <path
          class="cls-2"
          d="M202.84,2534.62h-12.55l-.55-5.85,12.7-23.05h8.15v22.2h3.45v6.7h-3.45v6.25h-7.75v-6.25ZM202.89,2527.92v-10.65l-5.6,10.65h5.6Z"
        />
        <rect class="cls-11" x="91.05" y="2441.5" width="62.02" height="162.9" />
      </g>
      <g id="TABLE-3">
        <path
          class="cls-2"
          d="M190.95,2797.57l3.3-6.55c1.05.8,3.1,2.25,5.7,2.25,2.45,0,4.2-1.55,4.2-3.9v-.1c0-2.65-2.3-4.25-7-4.25h-.85l-.5-5,6.6-7.4h-9.8v-7.4h19.2v6.75l-7.15,7.7c4.15,1.15,7.55,4,7.55,9.65v.15c0,7.05-4.75,11.35-11.5,11.35-3.8,0-7.25-1.2-9.75-3.25Z"
        />
        <rect class="cls-11" x="91.05" y="2700.85" width="62.02" height="162.9" />
      </g>
      <g id="TABLE-2">
        <path
          class="cls-2"
          d="M191.8,2966.32l9.05-11.55c2.4-3.1,3.35-4.55,3.35-6.4,0-1.75-1.25-3.15-3.2-3.15s-3.6,1.3-5.75,3.4l-4-6.05c3.35-3.35,7-5.05,10.9-5.05,5.9,0,10.15,3.75,10.15,9.7v.15c0,3.7-1.35,6.5-5.3,11.3l-5.65,6.95h10.8v7.5h-20.35v-6.8Z"
        />
        <rect class="cls-11" x="91.05" y="2873.74" width="62.02" height="162.9" />
      </g>
      <g id="TABLE-1">
        <path class="cls-2" d="M199.48,3119.16l-4.35,1.25-.6-7.4,7.3-2.2h5.9v35.2h-8.25v-26.85Z" />
        <rect class="cls-11" x="91.05" y="3046.64" width="62.02" height="162.9" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  data() {
    return {
      mapid: 154,
    };
  },
};
</script>
