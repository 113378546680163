<template>
  <div id="top" class="favs page">
    <div class="container content_area_no_head">
      <div class="row">
        <div class="col-12 col-lg-6 offset-lg-3">
          <h1>My Account</h1>
          <ul class="navbar-nav mr-auto">
            <li class="nav-item">
              <router-link class="nav-link" :to="{ name: 'Introduction' }">About This Tasting</router-link>
            </li>
            <li class="nav-item" v-if="isAdmin">
              <router-link class="nav-link" :to="{ name: 'State Selection' }">Select State</router-link>
            </li>
            <li class="nav-item" v-if="loggedIn">
              <router-link class="nav-link" :to="{ name: 'My Lists' }">My Lists</router-link>
            </li>
            <li class="nav-item" v-if="loggedIn">
              <a href="https://www.skurnik.com/tastings-attended/" class="nav-link">My Past Tastings</a>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="{ name: 'Select a Tasting' }">Select a Tasting</router-link>
            </li>

            <li class="nav-item">
              <router-link class="nav-link" :to="{ name: 'Tutorial' }">Help</router-link>
            </li>
            <li class="nav-item"><a href="https://www.skurnik.com/feedback/" class="nav-link">Feedback</a></li>
            <li class="nav-item" v-if="loggedIn">
              <router-link class="nav-link" :to="{ name: 'Logout' }">Logout</router-link>
            </li>
            <!-- <li class="nav-item" v-if="isAdmin">
              <a class="nav-link" href="http://tastings.rhineheart.madisonmott.com/admin-tastings.php" id="dropdown01">ADMIN</a>
            </li> -->
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'My Account',
  components: {},

  methods: {},
  computed: {
    ...mapGetters(['user', 'loggedIn']),
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
    isAdmin() {
      if (this.$store.getters.user.role == 'admin' || this.$store.getters.user.role == 'editor') {
        return true;
      } else {
        // get user.role from local storage
        var user = JSON.parse(localStorage.getItem('user'));
        if (user.role == 'admin' || user.role == 'editor') {
          return true;
        }
        return false;
      }
    },
  },
  created() {},
};
</script>
