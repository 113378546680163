<template>
  <div class="login page">
    <div class="container content_area">
      <button class="btn btn-primary btn-top" @click="goBack">
        <img src="@/assets/arrow-left.svg" style="height: 25px" /> <small>BACK</small>
      </button>
      <div class="login-form">
        <h2 class="login-heading">GET QUICK ACCESS</h2>
        <div class="alert alert-danger" role="alert" v-html="error" v-if="error"></div>
        <div class="alert alert-success" role="alert" v-html="success" v-if="success"></div>
        <p>
          Enter the email that you registered with on our website, and receive a link for you to access your account instantly, with an
          opportunity to reset your password post-tasting.
        </p>
        <form action="#" @submit.prevent="login">
          <div class="">
            <label for="email">Email</label>
            <input type="text" name="username" id="username" class="form-control login-input" v-model="username" />
          </div>

          <div class="">
            <button type="submit" class="btn btn-primary">Email Access Pass</button>
          </div>

          <div class="pt-5">
            Don't have a Skurnik.com Trade account?<br />
            <router-link class="btn btn-secondary mt-2" :to="{ name: 'Register' }">Register &gt;</router-link>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'Access Pass',
  data() {
    return {
      username: '',
      password: '',
      error: false,
      success: false,
    };
  },
  methods: {
    ...mapActions(['toggleModal', 'saveAllData']),
    goBack() {
      if (true) {
        this.$router.go(-1);
      }
    },
    login() {
      this.saveAllData();
      this.$store
        .dispatch('sendAccessPass', {
          username: this.username,
        })
        .then((response) => {
          //console.log(this.$store.getters.user);
          //console.log(response)
          if (response.data.success) {
            this.success = response.data.error;
          } else {
            this.error = response.data.error;
          }
        })
        .catch((err) => {
          this.error = err.data.error; // reject
        });
    },
  },
};
</script>
