<template>
  <div class="search_page page">
    <TastingHeader v-bind:tastingInfo="currentTastingDeets" />
    <div class="container content_area_sm">
      <div class="spacer"></div>
      <div class="search-wrapper">
        <div class="row" v-show="firstLevelFilter != 'NONE'">
          <div class="col-12 text-center search-steps">
            <small>TYPE</small>
            <h2 class="text-center">{{ firstLevelFilter }}</h2>
            <div class="text-center" v-show="secondLevelFilter != 'NONE'">
              <img src="@/assets/angle-down.svg" style="height: 40px" /><br />
              <small>{{ secondLevelFilterType.replace('_', ' ').replace('_', ' ').replace('appelation', 'appellation') }}</small>
              <h2 class="text-center">
                {{ secondLevelFilter }}
              </h2>
            </div>
            <div class="text-center" v-show="thirdLevelFilter != 'NONE'">
              <img src="@/assets/angle-down.svg" style="height: 40px" /><br />
              <small>{{ thirdLevelFilterType.replace('_', ' ').replace('_', ' ').replace('appelation', 'appellation') }}</small>
              <h2 class="text-center">
                {{ thirdLevelFilter }}
              </h2>
            </div>
          </div>
        </div>

        <div class="form-row" v-show="firstLevelFilter == 'NONE'">
          <div class="col-12"><h1 class="text-center">Search & Filter</h1></div>
          <div class="col-12"><h2>Select Type</h2></div>
          <div class="col-12 spirit_type_search">
            <!--<select name="types" id="types" class="form-control" v-model="firstLevelFilter">
              <option>Please Select</option>
              <option v-for="(item, index) in itemTypesR" :key="index">{{ item }}</option>
            </select>-->
            <div v-for="(item, index) in itemTypesR" :key="index" class="text-center">
              <!-- <input type="radio" :id="item" :value="item" v-model="firstLevelFilter" />
              <label style="display: inline; text-transform: capitalize" :for="item">{{ item }}</label> -->

              <button class="btn btn-primary" style="width: 100%; margin-top: 20px" @click="firstLevelFilter = item">
                {{ item }}
                <img :src="'/img/' + item + '.svg'" style="height: 30px; vertical-align: middle; margin-top: -5px; display: none" />
              </button>
            </div>
          </div>
        </div>
        <!-- allow user to view results. -->
        <div class="padd_section">
          <div class="form-row" v-show="firstLevelFilter != 'NONE'">
            <div class="col-12">
              <router-link to="/browse"
                ><button class="btn btn-primary" style="width: 100%; margin-top: 20px" :disabled="resultCount < 1">
                  View {{ resultCount }} {{ pluralResults }}
                </button></router-link
              >
            </div>
            <div class="col-12">
              <button class="btn btn-secondary btn-fw" style="width: 100%; margin-top: 20px" @click="clearFilter">Clear Filter</button>
            </div>
          </div>
        </div>

        <!-- START SECOND LEVEL FILTERS -->
        <div class="padd_section" v-show="resultCount >= 4">
          <div class="form-row" v-show="firstLevelFilter == 'WINE'">
            <div class="col-12" v-show="secondLevelFilterType == 'NONE'">
              <h3 style="text-align: center">Add a Filter?</h3>

              <select name="wineFilters" id="wineFilters" class="form-control" v-model="secondLevelFilterType">
                <option value="NONE" selected>Choose a Wine Filter</option>
                <option v-for="(item, index) in wineFilters" :key="index" :value="item.field">{{ item.name }}</option>
              </select>
            </div>
          </div>
          <div class="form-row" v-show="firstLevelFilter == 'SPIRITS'">
            <div class="col-12" v-show="secondLevelFilterType == 'NONE'">
              <select name="spiritFilters" id="spiritFilters" class="form-control" v-model="secondLevelFilterType">
                <option value="NONE" selected>Choose a Spirit Filter</option>
                <option v-for="(item, index) in spiritFilters" :key="index" :value="item.field">{{ item.name }}</option>
              </select>
            </div>
          </div>
          <div class="form-row" v-show="firstLevelFilter == 'CIDER'">
            <div class="col-12" v-show="secondLevelFilterType == 'NONE'">
              <select name="ciderFilters" id="ciderFilters" class="form-control" v-model="secondLevelFilterType">
                <option value="NONE" selected>Choose a Cider Filter</option>
                <option v-for="(item, index) in ciderFilters" :key="index" :value="item.field">{{ item.name }}</option>
              </select>
            </div>
          </div>
          <div class="form-row" v-show="firstLevelFilter == 'SAKE'">
            <div class="col-12" v-show="secondLevelFilterType == 'NONE'">
              <select name="sakeFilters" id="sakeFilters" class="form-control" v-model="secondLevelFilterType">
                <option value="NONE" selected>Choose a Sake Filter</option>
                <option v-for="(item, index) in sakeFilters" :key="index" :value="item.field">{{ item.name }}</option>
              </select>
            </div>
          </div>
          <div class="form-row">
            <div class="col-12" v-show="secondLevelFilterOptions.length && secondLevelFilter == 'NONE'">
              Select {{ secondLevelFilterType.replace('_', ' ').replace('_', ' ').replace('appelation', 'appellation') }}<br />
              <select name="secondFilters" id="secondFilters" class="form-control" v-model="secondLevelFilter">
                <option value="NONE" selected>Please Select</option>
                <option v-for="(item, index) in secondLevelFilterOptions" :key="index">{{ item }}</option>
              </select>
            </div>
          </div>
        </div>

        <!-- START THIRD LEVEL FILTERS -->
        <div class="padd_section" v-show="resultCount >= 4">
          <div class="form-row">
            <div class="col-12" v-show="firstLevelFilter == 'WINE' && thirdLevelFilterType == 'NONE' && secondLevelFilter != 'NONE'">
              <h5 style="text-align: center">Add Another Filter?</h5>

              <select name="wineFilters" id="wineFilters" class="form-control" v-model="thirdLevelFilterType">
                <option value="NONE" selected>Choose a Wine Filter</option>
                <option v-for="(item, index) in wineFilters" :key="index" :value="item.field">{{ item.name }}</option>
              </select>
            </div>
          </div>
          <div class="form-row">
            <div class="col-12" v-show="firstLevelFilter == 'SPIRITS' && thirdLevelFilterType == 'NONE' && secondLevelFilter != 'NONE'">
              <h5 style="text-align: center">Add Another Filter?</h5>

              <select name="spiritFilters" id="spiritFilters" class="form-control" v-model="thirdLevelFilterType">
                <option value="NONE" selected>Choose a Spirit Filter</option>
                <option v-for="(item, index) in spiritFilters" :key="index" :value="item.field">{{ item.name }}</option>
              </select>
            </div>
          </div>
          <div class="form-row">
            <div class="col-12" v-show="firstLevelFilter == 'CIDER' && thirdLevelFilterType == 'NONE' && secondLevelFilter != 'NONE'">
              <h5 style="text-align: center">Add Another Filter?</h5>

              <select name="ciderFilters" id="ciderFilters" class="form-control" v-model="thirdLevelFilterType">
                <option value="NONE" selected>Choose a Cider Filter</option>
                <option v-for="(item, index) in ciderFilters" :key="index" :value="item.field">{{ item.name }}</option>
              </select>
            </div>
          </div>
          <div class="form-row">
            <div class="col-12" v-show="firstLevelFilter == 'SAKE' && thirdLevelFilterType == 'NONE' && secondLevelFilter != 'NONE'">
              <h5 style="text-align: center">Add Another Filter?</h5>

              <select name="sakeFilters" id="sakeFilters" class="form-control" v-model="thirdLevelFilterType">
                <option value="NONE" selected>Choose a Sake Filter</option>
                <option v-for="(item, index) in sakeFilters" :key="index" :value="item.field">{{ item.name }}</option>
              </select>
            </div>
          </div>
          <div class="form-row">
            <div class="col-12" v-show="thirdLevelFilterOptions.length && thirdLevelFilter == 'NONE'">
              Select {{ thirdLevelFilterType.replace('_', ' ').replace('_', ' ').replace('appelation', 'appellation') }}<br />
              <select name="thirdFilters" id="thirdFilters" class="form-control" v-model="thirdLevelFilter">
                <option value="NONE" selected>Please Select</option>
                <option v-for="(item, index) in thirdLevelFilterOptions" :key="index">{{ item }}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="padd_section">
          <div class="form-row">
            <div class="col-12 text-center" v-show="firstLevelFilter != 'NONE'">
              Select Max Bottle Price: <strong>${{ slider }}</strong>

              <input type="range" :min="minCasePrice" :max="maxCasePrice" step="1" v-model="slider" />
            </div>
          </div>
        </div>

        <!--<pre>   {{ resultData }}</pre>-->
      </div>
    </div>
  </div>
</template>

<script>
//import SkuPreview from '@/components/SkuPreview.vue';
import { mapGetters, mapActions } from 'vuex';
import TastingHeader from '@/components/TastingHeader.vue';

export default {
  name: 'SearchPage',
  components: { TastingHeader },
  computed: {
    ...mapGetters(['alldata', 'currentTasting', 'currentTastingDeets']),
    pluralResults() {
      if (this.resultCount == 1) {
        return `Result`;
      } else {
        return `Results`;
      }
    },
    itemTypesR() {
      const itemTypes = [];
      for (const sku in this.alldata.skus) {
        if (
          typeof this.alldata.skus[sku].type != 'undefined' &&
          this.alldata.skus[sku].type != '' &&
          this.alldata.skus[sku].type != 'GLASS' &&
          this.alldata.skus[sku].type != 'SPECWINE' &&
          this.alldata.skus[sku].type != 'SYRUP'
        ) {
          if (itemTypes.indexOf(this.alldata.skus[sku].type) === -1) {
            itemTypes.push(this.alldata.skus[sku].type);
          }
        }
      }
      if (itemTypes.length == 1) {
        this.firstLevelFilter = itemTypes[0];
      }
      return itemTypes;
    },
  },
  data() {
    return {
      filteredData: [],
      resultData: [],
      activeType: 'All',
      slider: '12',
      maxCasePrice: 1000,
      minCasePrice: 1,
      activeCountry: 'All',
      itemCountriesR: this.itemCountries,
      activeRegion: 'All',
      itemRegionsR: this.itemRegions,
      search: '',
      value: 0,
      resultCount: 0,
      currentFilters: '',
      firstLevelFilter: 'NONE',
      secondLevelFilterType: 'NONE',
      secondLevelFilter: 'NONE',
      secondLevelFilterOptions: [],
      thirdLevelFilterType: 'NONE',
      thirdLevelFilter: 'NONE',
      thirdLevelFilterOptions: [],
      wineFilters: [
        { name: 'Producer', field: 'producer' },
        { name: 'Color', field: 'color' },
        { name: 'Country', field: 'country' },
        { name: 'Region', field: 'region' },
        { name: 'Grape Variety', field: 'varietal' },
        { name: 'Vintage', field: 'vintage' },
        { name: 'Farming Practice', field: 'farming_practice' },
        { name: 'Appellation', field: 'appelation' },
      ],
      spiritFilters: [
        { name: 'Producer', field: 'producer' },
        { name: 'Spirit Type', field: 'spirit_type' },
        { name: 'Spirit Sub Type', field: 'spirit_sub_type' },
        { name: 'Country', field: 'country' },
        { name: 'Region', field: 'region' },

        { name: 'Vintage', field: 'vintage' },
        { name: 'Farming Practice', field: 'farming_practice' },
      ],
      sakeFilters: [
        { name: 'Producer', field: 'producer' },
        // TEMP REMOVED { name: 'Sake Type', field: 'spirit_sub_type' },
        { name: 'Region', field: 'region' },
        { name: 'Rice Variety', field: 'varietal' },
        // TEMP REMOVED { name: 'RS Level', field: 'rs_level' },
        { name: 'Sake Type', field: 'sake_type' },
       // { name: 'Rice Category', field: 'rice_category' },
      ],
      ciderFilters: [
        { name: 'Producer', field: 'producer' },
        { name: 'Color', field: 'color' },
        { name: 'Country', field: 'country' },
        { name: 'Region', field: 'region' },

        { name: 'Vintage', field: 'vintage' },
        { name: 'Farming Practice', field: 'farming_practice' },
       
        { name: 'RS Level', field: 'rs_level' },
      ],
      fortifiedFilters: [
        { name: 'Producer', field: 'producer' },
       
        { name: 'Country', field: 'country' },
        
        { name: 'Region', field: 'region' },
         { name: 'Appellation', field: 'appelation' },
        { name: 'Grape Variety', field: 'varietal' },
        { name: 'Color', field: 'color' },
        { name: 'Vintage', field: 'vintage' },
        { name: 'Farming Practice', field: 'farming_practice' },
        
      ],
    };
  },
  watch: {
    firstLevelFilter: 'getfilteredData', // 1
    slider: 'getfilteredData', // 1
    secondLevelFilter: 'getfilteredData',
    thirdLevelFilter: 'getfilteredData',
    secondLevelFilterType: 'getFilterOptions',
    thirdLevelFilterType: 'getFilterOptionsThird',
  },
  methods: {
    ...mapActions(['fetchAlldata', 'setResultTables', 'setResultFilters']),
    viewResults() {
      //this.$store.commit('hideIntro');
      this.$router.push({ name: 'Browse' });
    },
    clearFilter() {
      //this.$store.commit('hideIntro');
      (this.firstLevelFilter = 'NONE'),
        (this.secondLevelFilterType = 'NONE'),
        (this.secondLevelFilter = 'NONE'),
        (this.secondLevelFilterOptions = []),
        (this.thirdLevelFilterType = 'NONE'),
        (this.thirdLevelFilter = 'NONE'),
        (this.thirdLevelFilterOptions = []),
        (this.resultCount = 0),
        (this.slider = 12),
        (this.currentFilters = false),
        this.getfilteredData();
    },
    getFilterOptions() {
      const type = this.secondLevelFilterType;
      const items = [];

      console.log('looking for second filter opts');
      for (const table in this.resultData) {
        //console.log(this.filteredData[table]);
        for (const sku in this.resultData[table].skus) {
          if (typeof this.resultData[table].skus[sku] != 'undefined') {
            //console.log(this.resultData[table].skus[sku][type]);
            if (typeof this.resultData[table].skus[sku][type] != 'undefined' && this.resultData[table].skus[sku][type] != '') {
              if (items.indexOf(this.resultData[table].skus[sku][type]) === -1) {
                items.push(this.resultData[table].skus[sku][type]);
              }
            }
          }
        }
      }
      items.sort();
      
      if (type=='vintage') { items.reverse();}
      this.secondLevelFilterOptions = items;
    },
    getFilterOptionsThird() {
      const type = this.thirdLevelFilterType;
      const items = [];
      console.log('looking for third filter opts');
      for (const table in this.resultData) {
        //console.log(this.filteredData[table]);
        for (const sku in this.resultData[table].skus) {
          if (typeof this.resultData[table].skus[sku] != 'undefined') {
            //console.log(this.resultData[table].skus[sku][type]);
            if (typeof this.resultData[table].skus[sku][type] != 'undefined' && this.resultData[table].skus[sku][type] != '') {
              if (items.indexOf(this.resultData[table].skus[sku][type]) === -1) {
                items.push(this.resultData[table].skus[sku][type]);
              }
            }
          }
        }
      }
      items.sort();
      if (type=='vintage') { items.reverse();}
      this.thirdLevelFilterOptions = items;
    },
    getfilteredData(whatChanged) {
      const filteredDataByfilters = new Object();
      const filteredDataBySearch = new Object();
      // first check if filters where selected
      this.resultData = JSON.parse(JSON.stringify(this.$store.getters.alldata.tables));
      this.resultFilters = false;
      this.resultCount = 0;
      //console.log('before first ' + this.resultCount);

      if (this.firstLevelFilter != 'NONE') {
        this.resultCount = 0;
        for (const table in this.resultData) {
          //console.log(this.filteredData[table]);
          for (const sku in this.resultData[table].skus) {
            //console.log(this.filteredData[table].skus[sku]);
            if (this.resultData[table].skus[sku].type != this.firstLevelFilter) {
              delete this.resultData[table].skus[sku];
            } else {
              this.resultCount++;
            }
          }
        }
        this.currentFilters = this.firstLevelFilter;
      }
      //console.log('Before 2nd ' + this.resultCount);

      if (this.secondLevelFilter != 'NONE') {
        this.resultCount = 0;
        const type = this.secondLevelFilterType;
        for (const table in this.resultData) {
          //console.log(this.filteredData[table]);
          for (const sku in this.resultData[table].skus) {
            //console.log(this.filteredData[table].skus[sku]);
            if (this.resultData[table].skus[sku][type] != this.secondLevelFilter) {
              delete this.resultData[table].skus[sku];
            } else {
              this.resultCount++;
            }
          }
        }
        this.currentFilters = this.firstLevelFilter + ' &nbsp;&gt;&nbsp; ' + this.secondLevelFilter;
      }
      //console.log('BEFORE 3rd ' + this.resultCount);

      if (this.thirdLevelFilter != 'NONE') {
        this.resultCount = 0;
        const type = this.thirdLevelFilterType;
        for (const table in this.resultData) {
          //console.log(this.filteredData[table]);
          for (const sku in this.resultData[table].skus) {
            //console.log(this.filteredData[table].skus[sku]);
            if (this.resultData[table].skus[sku][type] != this.thirdLevelFilter) {
              delete this.resultData[table].skus[sku];
            } else {
              this.resultCount++;
            }
          }
        }
        this.currentFilters =
          this.firstLevelFilter + ' &nbsp;&gt;&nbsp; ' + this.secondLevelFilter + ' &nbsp;&gt;&nbsp; ' + this.thirdLevelFilter;
      }
      //console.log('BEFORE MAX & MIN ' + this.resultCount);

      //get our max and min prices.
      const prices = [];
      for (const table in this.resultData) {
        //console.log(this.filteredData[table]);
        for (const sku in this.resultData[table].skus) {
          if (this.resultData[table].skus[sku].max_case != null) {
            if (prices.indexOf(parseInt(this.resultData[table].skus[sku].max_case)) === -1) {
              prices.push(parseInt(this.resultData[table].skus[sku].max_case));
              //console.log(this.resultData[table].skus[sku].max_case);
            }
          }
        }
      }
      //console.log(prices);
      //console.log('MAX : ' + Math.max(...prices));
      //console.log('MIN : ' + Math.min(...prices));
      this.maxCasePrice = Math.max(...prices);
      this.minCasePrice = Math.min(...prices);
      this.maxCasePrice = this.maxCasePrice + 1;
      this.minCasePrice = this.minCasePrice - 1;
      if (this.slider == 12 || this.slider > this.maxCasePrice) {
        this.slider = this.maxCasePrice;
      }

      if (this.slider != null) {
        this.resultCount = 0;
        for (const table in this.resultData) {
          //console.log(this.filteredData[table]);
          for (const sku in this.resultData[table].skus) {
            //console.log(this.filteredData[table].skus[sku]);
            if (parseInt(this.resultData[table].skus[sku].max_case) > parseInt(this.slider)) {
              delete this.resultData[table].skus[sku];
            } else {
              this.resultCount++;
            }
          }
        }
      }

      this.$store.dispatch('setResultTables', this.resultData);
      this.$store.dispatch('setResultCount', this.resultCount);
      this.$store.dispatch('setResultFilters', this.currentFilters);
      if (false) {
        if (this.firstLevelFilter != 'NONE' && this.resultCount <= 2) {
          this.$router.push({ name: 'Browse' });
        }
      }
    },
  },
  created() {
    this.fetchAlldata();
  },
  mounted() {
    this.getfilteredData();
  },
};
</script>
