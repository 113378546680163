<template>
  <navHeader v-if="showNav" />
  <div v-if="!currentlyOnline" class="onlineMessage">
      <h1>Whoops!<br />No Internet</h1>
<div v-if="wifi_user!='NOTSET'">
       <h2>We've got you covered!</h2>
       
      Wifi Network: <strong>{{wifi_user}}</strong><br />
         Password: <strong>{{wifi_pass}}</strong>
         </div>

         <div v-else>
           Visit the registration area to get this location's wifi info.
           </div>
      <br />

      <a class="btn btn-secondary" @click="closeWarning">dismiss</a>
    </div>
    
  <!-- <div class="content_top_pad"></div> -->
  <!-- test -->
  <router-view v-slot="{ Component }">
    <transition name="fade">
      <component :is="Component" />
    </transition>
  </router-view>

  <teleport to="#modals">
    <div v-if="showModal" class="modal show fade">
      <modalLoginRegister />
    </div>
    <div v-if="showModalTag" class="modal show fade">
      <modalAddTag />
    </div>
    <div v-if="showModalNote" class="modal show fade">
      <modalAddNote />
    </div>
    <div v-if="showModalNotice" class="modal show fade">
      <modalAddNotice />
    </div>
     <div v-if="showModalListname" class="modal show fade">
      <modalListname />
    </div>
  </teleport>
  <!-- <div class="content_bot_pad"></div> -->
  <navFooter v-if="showFooter" />
</template>
<script>
import { store, mapGetters, mapActions } from 'vuex';
import navHeader from '@/components/navHeader.vue';
import navFooter from '@/components/navFooter.vue';
import modalLoginRegister from '@/components/modals/ModalLoginRegister.vue';
import modalAddTag from '@/components/modals/ModalAddTag.vue';
import modalAddNote from '@/components/modals/ModalAddNote.vue';
import modalAddNotice from '@/components/modals/ModalNotice.vue';
import modalListname from '@/components/modals/ModalListname.vue';
export default {
  data() {
    return {
      //showModal:false,
      //hasError:false,
      wifi_user: 'NOTSET',
      wifi_pass: String
    };
  },
  components: {
    navHeader,
    navFooter,
    modalLoginRegister,
    modalAddTag,
    modalAddNote,
    modalAddNotice,
    modalListname
  },
  computed: {
    ...mapGetters(['currentlyOnline']),
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
    showModal() {
      return this.$store.getters.showModal;
    },
    showModalTag() {
      return this.$store.getters.showModalTag;
    },
    showModalNote() {
      return this.$store.getters.showModalNote;
    },
    showModalNotice() {
      return this.$store.getters.showModalNotice;
    }, showModalListname() {
      return this.$store.getters.showModalListname;
    },
    isMap() {
      return this.$route.name === 'Map';
    },
    
    showNav() {
      if (
        this.$route.name === 'Welcome' //||
        //this.$route.name === 'Introduction' ||
        //this.$route.name === 'Login' ||
        //this.$route.name === 'Register' ||
        //this.$route.name === 'First Tasting' ||
        //this.$route.name === 'Select a Tasting'
      ) {
        return false;
      } else {
        return true;
      }
    },
    showFooter() {
      if (
        this.$route.name === 'Welcome' ||
        this.$route.name === 'Introduction' ||
        this.$route.name === 'Cover' ||
        this.$route.name === 'Login' ||
        this.$route.name === 'Register' ||
        this.$route.name === 'First Tasting' ||
        this.$route.name === 'Select a Tasting' ||
        this.$route.name === 'Tutorial 1' ||
        this.$route.name === 'Tutorial 2' ||
        this.$route.name === 'Tutorial 3' ||
        this.$route.name === 'Tutorial 4' ||
        this.$route.name === 'Tutorial 5'||
        this.$route.name === 'Tutorial'
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  watch: {
    $route() {
      
      this.$store.dispatch('closeModal');
      var ct = window.localStorage.getItem("currentTastingDeets");
  
  if (typeof(ct) == 'undefined' || ct == null) { return ;} else {
  var thedata = JSON.parse(ct);
  this.wifi_user = thedata.wifi_user;
  this.wifi_pass = thedata.wifi_pass;
  }
    },
   
  },
  
  methods: {
    // PUT OUR FUNCTIONS HERE FOR INTERACTION.
    ...mapActions(['closeWarning']),
    

  },
  updated() {},
  created() {
    


  },
};
</script>
<style lang="scss">
@import 'styles/webfonts/fonts.css';
</style>
