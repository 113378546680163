<template>
  <div id="top" class="table_block page">
    <div class="container content_area">
      <div class="row">
        <div class="col-12 sp_title">
          <h1>{{ thisTable.table_name }}</h1>
          <h3>Table: {{ thisTable.table }}</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="table_type">{{ thisTable.table_type }}</div>
          <div class="table_summary">{{ thisTable.table_summary }}</div>
          <div v-for="sku in thisTable.skus" :key="sku">
            <div class="sku_block">
              <SkuPreview v-bind:sku="sku.sku" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { useRoute } from 'vue-router';
import { computed } from 'vue';
import { mapGetters, mapActions } from 'vuex';
import SkuPreview from '@/components/SkuPreview.vue';

export default {
  name: 'TableDetail',
  components: {
    SkuPreview,
  },
  props: ['table_no'],
  data() {
    return {
      route: useRoute(),
      table_info: {},
      producer_info: {},
    };
  },
  methods: {
    ...mapActions(['fetchAlldata']),

    producerInfo() {
      let sku = this.table_no;
      for (const producerCode in this.alldata.producers) {
        if (producerCode == sku.substring(0, 6)) {
          this.producer_info = this.alldata.producers[producerCode];
        }
      }
    },
  },
  computed: {
    ...mapGetters(['alldata']),
    thisTable: function () {
      let tableNo = this.table_no;
      let allTablesStore = this.$store.getters.alldata.tables;
      //console.log(allTablesStore);
      for (const table in allTablesStore) {
        if (tableNo == allTablesStore[table].table) {
          return allTablesStore[table];
        }
      }
      //this.$store.getters.alldata.tables;
    },
  },

  created() {
    //this.fetchAlldata();
    //this.producerInfo();
    //this.tableInfo();
  },
  mounted() {},
};
</script>
