<template>
  <svg id="mapsvg145" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1401.58 3573.29">
    <g id="VENUE">
      <g>
        <path
          class="cls-8"
          d="M124.68,2324.11c-.78-.37-1.38-.24-1.95-.15-.4.83-.23,1.66-.23,2.45,0,26.43,0,52.86,0,79.29,0,.75.01,1.5-.03,2.24-.23,3.54-2.59,5.78-6.05,5.77-3.45,0-5.88-2.27-6.13-5.74-.06-.74-.05-1.49-.05-2.24,0-15.15-.02-30.29.02-45.44,0-1.42-.25-2.23-1.91-1.72-.51.63-.27,1.41-.27,2.13-.02,15.33,0,30.67-.02,46,0,4.44-2.34,7.03-6.25,7.02-3.26,0-5.7-2.21-5.99-5.47-.08-.87-.04-1.74-.04-2.62,0-26.43,0-52.86,0-79.29,0-.74,0-1.48,0-2.17-.77-.45-1.4-.37-2.24-.09,0,.64,0,1.31,0,1.98,0,9.16,0,18.33,0,27.49,0,2.16-.64,3.94-2.77,4.85-2.77,1.19-5.72-.36-6.3-3.32-.12-.61-.16-1.24-.17-1.86,0-10.6-.04-21.19,0-31.79.03-6.73,3.8-11.96,9.75-13.76,1.38-.42,2.79-.6,4.24-.6,7.11.01,14.21-.01,21.32.02,8.05.03,14.13,5.88,14.23,13.98.13,10.91.06,21.82.02,32.73-.01,3.14-1.82,5-4.6,4.99-2.75-.01-4.53-1.89-4.54-5.05-.05-9.16-.02-18.33-.02-27.49,0-.74,0-1.47,0-2.14Z"
        />
        <path
          class="cls-8"
          d="M119.69,2293.35c.04,5.78-4.71,10.64-10.48,10.71-5.89.08-10.75-4.68-10.8-10.56-.04-5.86,4.79-10.73,10.65-10.72,5.78.01,10.58,4.79,10.62,10.58Z"
        />
      </g>
      <g>
        <path
          class="cls-8"
          d="M97.55,2092.53h-13.3c4.75-16.52,9.44-32.81,14.15-49.2-1.59-.61-2.29-.18-2.74,1.38-2.43,8.43-4.96,16.83-7.46,25.25-.28.95-.52,1.92-1.08,2.77-1.07,1.65-2.77,2.37-4.68,1.97-1.78-.38-3.1-1.9-3.32-3.82-.12-1.07.09-2.09.41-3.12,3.01-9.88,5.99-19.78,9.01-29.66,1.88-6.16,7.1-10.06,13.52-10.11,4.61-.03,9.23-.03,13.84,0,6.21.04,11.74,3.92,13.6,9.84,3.17,10.09,6.18,20.24,9.25,30.36.13.42.24.84.3,1.27.31,2.46-1.08,4.68-3.24,5.23-2.31.58-4.43-.53-5.27-3.04-1.24-3.72-2.29-7.5-3.41-11.26-1.55-5.19-3.14-10.37-4.59-15.58-.46-1.65-1.22-2.15-2.97-1.49,4.74,16.3,9.48,32.58,14.3,49.15h-12.99c-.57.93-.38,1.7-.38,2.43-.01,11.6,0,23.19-.02,34.79-.01,3.9-3.37,6.18-6.9,4.78-1.98-.78-2.94-2.35-2.94-4.93-.01-11.53,0-23.07-.01-34.6,0-.73.17-1.49-.21-2.22-.85-.29-1.7-.2-2.56-.07-.47.7-.26,1.47-.26,2.19-.01,11.41,0,22.82-.01,34.23,0,2.93-1.01,4.64-3.1,5.43-3.54,1.34-6.88-.98-6.88-4.88-.03-11.53-.01-23.07-.01-34.6,0-.74,0-1.47,0-2.49Z"
        />
        <path
          class="cls-8"
          d="M119.7,2014.21c.02,5.96-4.69,10.72-10.6,10.7-5.81-.01-10.52-4.72-10.57-10.57-.05-5.9,4.75-10.74,10.62-10.72,5.83.02,10.53,4.73,10.55,10.59Z"
        />
      </g>
      <g>
        <g>
          <path
            class="cls-11"
            d="M371.25,2584.6v64.01h-115.31v-64.01h115.31M371.25,2583.6h-115.31c-.55,0-1,.45-1,1v64.01c0,.55.45,1,1,1h115.31c.55,0,1-.45,1-1v-64.01c0-.55-.45-1-1-1h0Z"
          />
          <rect class="cls-4" x="508.39" y="1380.88" width="2.9" height="55.04" />
          <rect class="cls-4" x="1300.15" y="126.72" width="9.66" height="193.01" />
          <line class="cls-4" x1="1300.15" y1="125.65" x2="1309.81" y2="125.65" />
          <line class="cls-4" x1="1001.79" y1="319.73" x2="1010.48" y2="319.73" />
          <rect class="cls-4" x="1001.79" y="126.76" width="8.69" height="192.97" />
          <line class="cls-4" x1="1001.79" y1="125.65" x2="1010.48" y2="125.65" />
          <rect class="cls-4" x="1036.55" y="125.65" width="235.6" height="168.97" />
          <rect class="cls-4" x="1036.55" y=".5" width="2.9" height="117.45" />
          <polyline class="cls-4" points="1036.55 294.62 1036.55 161.38 1272.15 161.38 1272.15 294.62" />
          <line class="cls-4" x1="1272.15" y1="177.79" x2="1036.55" y2="177.79" />
          <line class="cls-4" x1="1272.15" y1="195.17" x2="1036.55" y2="195.17" />
          <line class="cls-4" x1="1272.15" y1="211.58" x2="1036.55" y2="211.58" />
          <line class="cls-4" x1="1272.15" y1="228" x2="1036.55" y2="228" />
          <line class="cls-4" x1="1272.15" y1="244.41" x2="1036.55" y2="244.41" />
          <line class="cls-4" x1="1272.15" y1="260.83" x2="1036.55" y2="260.83" />
          <line class="cls-4" x1="1272.15" y1="278.21" x2="1036.55" y2="278.21" />
          <line class="cls-4" x1="1272.15" y1="294.62" x2="1036.55" y2="294.62" />
          <line class="cls-4" x1="495.95" y1="3420.08" x2="334.83" y2="3420.08" />
          <line class="cls-4" x1="496.35" y1="3322.28" x2="334.83" y2="3322.28" />
          <polygon
            class="cls-4"
            points="362.09 3175.59 334.83 3175.59 334.83 3556.75 332.02 3556.75 332.02 3172.78 362.09 3172.78 362.09 3175.59"
          />
          <polyline class="cls-4" points="469.09 3175.59 496.35 3175.59 496.35 3172.78 469.09 3172.78 469.09 3175.59" />
          <line class="cls-4" x1="496.35" y1="3339.12" x2="334.83" y2="3339.12" />
          <line class="cls-4" x1="496.35" y1="3354.35" x2="334.83" y2="3354.35" />
          <line class="cls-4" x1="495.95" y1="3370.78" x2="334.83" y2="3370.78" />
          <line class="cls-4" x1="496.35" y1="3388.42" x2="334.83" y2="3388.42" />
          <line class="cls-4" x1="496.35" y1="3404.05" x2="334.83" y2="3404.05" />
          <rect class="cls-3" x="410.98" y="3322.28" width="10.02" height="97.8" />
          <rect class="cls-4" x="410.98" y="3322.28" width="10.02" height="97.8" />
          <rect class="cls-6" x="303.51" y="1593.22" width="21.3" height="561.93" />
          <rect class="cls-2" x="74.47" y="3552.17" width="194.28" height="16.63" />
          <rect class="cls-4" x="151.2" y="2442.2" width="53.3" height="3.94" />
          <path class="cls-4" d="M204.5,2392.83c-29.44,0-53.3,23.87-53.3,53.3" />
          <rect class="cls-4" x="363.07" y="3508.55" width="2.81" height="53.51" transform="translate(728.95 7070.61) rotate(-180)" />
          <rect class="cls-4" x="467.27" y="3508.55" width="2.81" height="53.51" />
          <path class="cls-4" d="M365.88,3508.55c28,0,50.7,22.7,50.7,50.7" />
          <path class="cls-4" d="M467.27,3508.55c-28,0-50.7,22.7-50.7,50.7" />
          <rect class="cls-4" x="336.74" y="3144.63" width="53.5" height="2.81" transform="translate(-2782.54 3509.53) rotate(-90)" />
          <rect class="cls-4" x="440.94" y="3144.63" width="53.5" height="2.81" transform="translate(3613.72 2678.34) rotate(90)" />
          <path class="cls-4" d="M362.09,3119.28c29.55,0,53.5,23.95,53.5,53.5" />
          <path class="cls-4" d="M415.59,3172.78c0-29.55,23.95-53.5,53.5-53.5" />
          <rect class="cls-4" x="151.2" y="1952.8" width="53.3" height="3.94" />
          <path class="cls-4" d="M204.5,2006.1c-29.44,0-53.3-23.87-53.3-53.3" />
          <line class="cls-4" x1="272.17" y1="2648.61" x2="272.17" y2="2584.6" />
          <line class="cls-4" x1="288.41" y1="2648.61" x2="288.41" y2="2584.6" />
          <line class="cls-4" x1="304.64" y1="2648.61" x2="304.64" y2="2584.6" />
          <line class="cls-4" x1="320.87" y1="2648.61" x2="320.87" y2="2584.6" />
          <line class="cls-4" x1="337.1" y1="2648.61" x2="337.1" y2="2584.6" />
          <line class="cls-4" x1="353.34" y1="2648.61" x2="353.34" y2="2584.6" />
          <rect class="cls-4" x="971.86" y="1854.79" width="3.08" height="126.67" transform="translate(2891.53 944.73) rotate(90)" />
          <path class="cls-4" d="M1037.04,2046.34c-70.12,0-126.97-56.85-126.97-126.97" />
          <rect class="cls-4" x="376.22" y="1575.12" width="122.63" height="2.9" />
          <path class="cls-4" d="M498.85,1455.39c-67.72,0-122.63,54.9-122.63,122.63" />
          <rect class="cls-4" x="504.64" y="1575.12" width="61.31" height="2.9" />
          <rect class="cls-4" x="504.64" y="1455.39" width="61.31" height="2.9" />
          <path class="cls-4" d="M565.96,1455.39c0,33.86-27.45,61.31-61.31,61.31" />
          <path class="cls-4" d="M504.64,1516.7c33.86,0,61.31,27.45,61.31,61.31" />
          <path class="cls-4" d="M453.35,1435.92c0-30.4,24.64-55.04,55.04-55.04" />
          <rect class="cls-4" x="444.41" y="619.03" width="130.35" height="2.93" transform="translate(1130.08 110.92) rotate(90)" />
          <path class="cls-4" d="M377.76,685.68c0-71.99,58.36-130.35,130.35-130.35" />
          <line class="cls-4" x1="1036.74" y1="1916.59" x2="1036.74" y2="2046.34" />
          <line class="cls-4" x1="453.35" y1="1435.92" x2="508.39" y2="1435.92" />
          <line class="cls-4" x1="377.76" y1="685.68" x2="511.05" y2="685.68" />
          <g>
            <path
              class="cls-5"
              d="M977.18,381.32c-4.63-2.68-9.62-4.01-14.97-4.01s-10.34,1.34-14.98,4.01c-4.63,2.68-8.28,6.33-10.96,10.96-2.67,4.63-4.02,9.62-4.02,14.97s1.34,10.34,4.02,14.97c2.67,4.63,6.32,8.29,10.96,10.96,4.63,2.68,9.63,4.01,14.98,4.01s10.34-1.34,14.97-4.01c4.63-2.68,8.29-6.33,10.97-10.96,2.67-4.63,4.01-9.62,4.01-14.97s-1.33-10.34-4.01-14.97c-2.67-4.63-6.33-8.29-10.97-10.96Z"
            />
            <path
              class="cls-5"
              d="M977.18,789.72c-4.63-2.68-9.62-4.01-14.97-4.01s-10.34,1.34-14.98,4.01c-4.63,2.67-8.28,6.33-10.96,10.96-2.67,4.63-4.02,9.62-4.02,14.98s1.34,10.34,4.02,14.97c2.67,4.63,6.32,8.28,10.96,10.96,4.63,2.68,9.63,4.02,14.98,4.02s10.34-1.33,14.97-4.02c4.63-2.67,8.29-6.32,10.97-10.96,2.67-4.63,4.01-9.62,4.01-14.97s-1.33-10.35-4.01-14.98c-2.67-4.63-6.33-8.29-10.97-10.96Z"
            />
            <path
              class="cls-5"
              d="M977.18,1205.67c-4.63-2.67-9.62-4.02-14.97-4.02s-10.34,1.34-14.98,4.02c-4.63,2.67-8.28,6.32-10.96,10.96s-4.02,9.62-4.02,14.98,1.34,10.34,4.02,14.97c2.67,4.63,6.32,8.29,10.96,10.97,4.63,2.67,9.63,4.01,14.98,4.01s10.34-1.33,14.97-4.01c4.63-2.67,8.29-6.33,10.97-10.97,2.67-4.63,4.01-9.62,4.01-14.97s-1.33-10.34-4.01-14.98c-2.67-4.63-6.33-8.28-10.97-10.96Z"
            />
            <path
              class="cls-5"
              d="M977.18,1621.62c-4.63-2.67-9.62-4.02-14.97-4.02s-10.34,1.34-14.98,4.02c-4.63,2.67-8.28,6.32-10.96,10.96-2.67,4.63-4.02,9.63-4.02,14.98s1.34,10.34,4.02,14.98c2.67,4.63,6.32,8.28,10.96,10.96,4.63,2.67,9.63,4.01,14.98,4.01s10.34-1.33,14.97-4.01c4.63-2.67,8.29-6.33,10.97-10.96,2.67-4.63,4.01-9.63,4.01-14.98s-1.33-10.34-4.01-14.98c-2.67-4.63-6.33-8.28-10.97-10.96Z"
            />
            <rect class="cls-3" x="267.5" y="2077" width="22.83" height="22.83" />
            <rect class="cls-3" x="293.16" y="2961.98" width="26.03" height="25.23" />
            <rect class="cls-3" x="292.76" y="2681.01" width="26.04" height="25.24" />
          </g>
          <rect class="cls-4" x="1268.55" y=".5" width="2.9" height="117.45" />
          <path class="cls-7" d="M1036.55.5c64.86,0,117.45,52.58,117.45,117.45" />
          <path class="cls-7" d="M1154,117.95c0-64.86,52.58-117.45,117.45-117.45" />
        </g>
        <g>
          <polygon
            class="cls-9"
            points="510.9 1578.8 504.64 1578.8 504.64 1578.02 498.85 1578.02 498.85 1578.8 495.56 1578.8 495.56 3556.35 470.08 3556.35 470.08 3573.29 510.9 3573.29 510.9 1930.86 910.06 1930.86 910.06 1915.13 510.9 1915.13 510.9 1578.8"
          />
          <rect class="cls-10" x="495.56" y="685.68" width="15.49" height="695.21" />
          <polygon
            class="cls-9"
            points="495.56 115.99 495.56 125.65 495.56 555.33 511.05 555.33 511.05 125.65 1036.55 125.65 1036.55 110.16 495.56 110.16 495.56 115.99"
          />
          <polygon
            class="cls-9"
            points="1386.09 110.16 1271.45 110.16 1271.45 125.65 1386.09 125.65 1386.09 540.84 1350.36 540.84 1350.36 558.22 1329.12 558.22 1329.12 699.19 1350.36 699.19 1350.36 716.57 1386.09 716.57 1386.09 1915.13 1036.74 1915.13 1036.74 1930.86 1401.58 1930.86 1401.58 1915.8 1401.58 1915.13 1401.58 125.65 1401.58 110.16 1386.09 110.16"
          />
          <polygon
            class="cls-9"
            points="286.75 2472.09 214.13 2472.09 214.13 2446.13 198.8 2446.13 198.8 2472.09 19.37 2472.09 19.37 2224.42 198.8 2224.42 198.8 2392.83 214.13 2392.83 214.13 2006.05 198.8 2006.05 198.8 2205.04 19.37 2205.04 19.37 1933.47 198.8 1933.47 198.8 1952.75 214.13 1952.75 214.13 1934.64 277.93 1934.64 277.93 2032.83 303.51 2032.83 303.51 1563.16 232.97 1563.16 232.97 1510.99 259.42 1510.99 259.42 1484.14 286.28 1484.14 286.28 1450.07 495.56 1450.07 495.56 1454.58 498.85 1454.58 498.85 1455.39 504.64 1455.39 504.64 1454.58 510.9 1454.58 510.9 1440.77 510.9 1436.88 510.9 1435.92 495.56 1435.92 495.56 1436.88 217.46 1436.88 217.46 1508.29 218.71 1574.59 289.59 1574.59 288.38 1916.78 201.94 1916.78 198.8 1916.78 0 1916.78 0 2483.15 0 2486.29 0 2651.75 .51 2651.75 .51 3573.29 363.07 3573.29 363.07 3556.35 320.24 3556.35 320.24 3204.05 318.8 3204.05 303.69 3204.05 292.76 3204.05 292.76 3229.29 303.69 3229.29 303.69 3556.35 271.9 3556.35 269.4 3556.35 268.75 3556.35 268.75 3568.81 74.47 3568.81 74.47 3556.35 73.96 3556.35 71.33 3556.35 20.15 3556.35 20.15 2666.91 371.25 2666.91 371.25 2648.61 353.34 2648.61 347.7 2648.61 19.37 2648.61 19.37 2486.29 201.94 2486.29 303.1 2486.29 303.1 2385.94 353.34 2385.94 353.34 2584.6 371.25 2584.6 371.25 2369.97 286.75 2369.97 286.75 2472.09"
          />
        </g>
      </g>
    </g>
    <g id="EXTRAS">
      <g>
        <path
          class="cls-8"
          d="M839.65,173.41c-10.93,0-19.82,8.89-19.82,19.82s8.89,19.82,19.82,19.82,19.82-8.89,19.82-19.82-8.89-19.82-19.82-19.82Z"
        />
        <path
          class="cls-8"
          d="M839.65,384.64c10.93,0,19.82-8.89,19.82-19.82s-8.89-19.82-19.82-19.82-19.82,8.89-19.82,19.82,8.89,19.82,19.82,19.82Z"
        />
        <path
          class="cls-8"
          d="M1094.52,624.59c-10.93,0-19.82,8.89-19.82,19.82s8.89,19.82,19.82,19.82,19.82-8.89,19.82-19.82-8.89-19.82-19.82-19.82Z"
        />
        <path
          class="cls-8"
          d="M829.9,664.24c10.93,0,19.82-8.89,19.82-19.82s-8.89-19.82-19.82-19.82-19.82,8.89-19.82,19.82,8.89,19.82,19.82,19.82Z"
        />
        <path
          class="cls-8"
          d="M1100.85,1414.31c-10.93,0-19.82,8.89-19.82,19.82s8.89,19.82,19.82,19.82,19.82-8.89,19.82-19.82-8.89-19.82-19.82-19.82Z"
        />
        <path
          class="cls-8"
          d="M1003.85,1867.37c10.93,0,19.82-8.89,19.82-19.82s-8.89-19.82-19.82-19.82-19.82,8.89-19.82,19.82,8.89,19.82,19.82,19.82Z"
        />
        <path
          class="cls-8"
          d="M1094.52,1040.33c10.93,0,19.82-8.89,19.82-19.82s-8.89-19.82-19.82-19.82-19.82,8.89-19.82,19.82,8.89,19.82,19.82,19.82Z"
        />
        <path
          class="cls-8"
          d="M581.85,1067.92c10.93,0,19.82-8.89,19.82-19.82s-8.89-19.82-19.82-19.82-19.82,8.89-19.82,19.82,8.89,19.82,19.82,19.82Z"
        />
        <rect class="cls-8" x="564" y="219.06" width="39.65" height="140.04" />
        <rect class="cls-8" x="609.65" y="173.41" width="140.04" height="39.65" />
        <path
          class="cls-8"
          d="M743.86,364.82c0-10.93-8.89-19.82-19.82-19.82s-19.82,8.89-19.82,19.82,8.89,19.82,19.82,19.82,19.82-8.89,19.82-19.82Z"
        />
        <rect class="cls-8" x="522.68" y="1638.97" width="140.04" height="39.65" />
        <rect class="cls-8" x="564" y="365.09" width="39.65" height="103.48" />
        <rect class="cls-8" x="668.72" y="1684.61" width="39.65" height="140.04" />
        <path
          class="cls-8"
          d="M844.1,1690.19c23.52,0,42.65-19.13,42.65-42.65s-19.13-42.65-42.65-42.65-42.65,19.13-42.65,42.65,19.13,42.65,42.65,42.65Z"
        />
      </g>
      <g>
        <g>
          <path
            class="cls-8"
            d="M649.05,272.45l1.32-1.68c1.29,1.26,2.76,2.25,4.53,2.25,2.19,0,3.63-1.56,3.63-3.48v-.03c0-1.47-.63-2.64-3.72-4.44-3.84-2.22-4.92-3.84-4.92-6.24v-.06c0-2.97,2.31-5.28,5.64-5.28,1.71,0,3.54.6,5.1,2.1l-1.29,1.65c-1.14-1.08-2.46-1.8-3.9-1.8-2.07,0-3.42,1.44-3.42,3.18,0,1.65.75,2.67,3.99,4.59,3.81,2.25,4.65,3.93,4.65,6.24v.03c0,3.12-2.46,5.49-5.73,5.49-2.04,0-3.99-.69-5.88-2.52Z"
          />
          <path class="cls-8" d="M663.8,253.74h2.04l8.43,16.17v-16.17h2.04v21h-1.83l-8.64-16.59v16.59h-2.04v-21Z" />
          <path
            class="cls-8"
            d="M685.4,253.62h2.19l6.24,21.12h-2.19l-1.59-5.46h-7.2l-1.59,5.46h-2.07l6.21-21.12ZM689.51,267.35l-3.03-10.62-3.09,10.62h6.12Z"
          />
          <path
            class="cls-8"
            d="M695.09,264.42v-.33c0-6.78,3.36-10.56,7.59-10.56,1.89,0,3.27.72,4.38,1.74l-1.17,1.65c-.9-.81-1.98-1.41-3.27-1.41-3,0-5.4,3.3-5.4,8.61v.21c0,5.46,2.4,8.64,5.37,8.64,1.29,0,2.43-.63,3.42-1.56l1.14,1.62c-1.23,1.14-2.79,1.92-4.68,1.92-4.23,0-7.38-3.69-7.38-10.53Z"
          />
          <path class="cls-8" d="M710.07,253.74h2.1v11.7l7.59-11.7h2.46l-6.15,9.21,6.6,11.79h-2.46l-5.55-9.84-2.49,3.69v6.15h-2.1v-21Z" />
          <path
            class="cls-8"
            d="M723.24,272.45l1.32-1.68c1.29,1.26,2.76,2.25,4.53,2.25,2.19,0,3.63-1.56,3.63-3.48v-.03c0-1.47-.63-2.64-3.72-4.44-3.84-2.22-4.92-3.84-4.92-6.24v-.06c0-2.97,2.31-5.28,5.64-5.28,1.71,0,3.54.6,5.1,2.1l-1.29,1.65c-1.14-1.08-2.46-1.8-3.9-1.8-2.07,0-3.42,1.44-3.42,3.18,0,1.65.75,2.67,3.99,4.59,3.81,2.25,4.65,3.93,4.65,6.24v.03c0,3.12-2.46,5.49-5.73,5.49-2.04,0-3.99-.69-5.88-2.52Z"
          />
          <path
            class="cls-8"
            d="M659.72,302.06c-1.32,1.77-2.88,2.94-5.1,2.94-3.03,0-5.19-2.4-5.19-5.49v-.12c0-2.52,1.38-4.71,3.93-6.42-1.38-2.1-2.01-3.45-2.01-5.01v-.12c0-2.34,1.74-4.41,4.38-4.41s4.14,2.01,4.14,4.26v.15c0,2.4-1.47,4.05-3.84,5.61l3.63,5.31c.57-1.29.99-2.64,1.44-4.38l1.71.51c-.54,2.19-1.14,3.93-1.95,5.55l2.58,3.54-1.59,1.05-2.13-2.97ZM658.64,300.63l-4.29-6.24c-1.98,1.32-2.82,3.15-2.82,4.89v.12c0,2.13,1.32,3.75,3.24,3.75,1.59,0,2.79-.99,3.87-2.52ZM657.95,287.88v-.06c0-1.47-.9-2.64-2.25-2.64-1.44,0-2.43,1.2-2.43,2.67v.06c0,1.2.42,2.28,1.77,4.2,1.98-1.2,2.91-2.61,2.91-4.23Z"
          />
          <path
            class="cls-8"
            d="M671.9,283.74h5.07c2.13,0,3.87.66,5.01,1.8,1.05,1.05,1.65,2.61,1.65,4.5v.09c0,3.18-1.71,5.13-4.14,5.94l4.68,8.67h-2.4l-4.38-8.19c-.27.03-.54.03-.81.03h-2.58v8.16h-2.1v-21ZM676.34,294.66c3.72,0,5.22-1.86,5.22-4.5v-.03c0-2.94-1.83-4.44-4.74-4.44h-2.82v8.97h2.34Z"
          />
          <path class="cls-8" d="M687.08,283.74h10.14v1.95h-8.04v7.47h7.23v1.95h-7.23v7.68h8.16v1.95h-10.26v-21Z" />
          <path class="cls-8" d="M700.55,283.74h9.93v1.95h-7.83v7.77h7.02v1.95h-7.02v9.33h-2.1v-21Z" />
          <path
            class="cls-8"
            d="M713.48,283.74h5.07c2.13,0,3.87.66,5.01,1.8,1.05,1.05,1.65,2.61,1.65,4.5v.09c0,3.18-1.71,5.13-4.14,5.94l4.68,8.67h-2.4l-4.38-8.19c-.27.03-.54.03-.81.03h-2.58v8.16h-2.1v-21ZM717.92,294.66c3.72,0,5.22-1.86,5.22-4.5v-.03c0-2.94-1.83-4.44-4.74-4.44h-2.82v8.97h2.34Z"
          />
          <path class="cls-8" d="M728.66,283.74h10.14v1.95h-8.04v7.47h7.23v1.95h-7.23v7.68h8.16v1.95h-10.26v-21Z" />
          <path
            class="cls-8"
            d="M740.63,302.45l1.32-1.68c1.29,1.26,2.76,2.25,4.53,2.25,2.19,0,3.63-1.56,3.63-3.48v-.03c0-1.47-.63-2.64-3.72-4.44-3.84-2.22-4.92-3.84-4.92-6.24v-.06c0-2.97,2.31-5.28,5.64-5.28,1.71,0,3.54.6,5.1,2.1l-1.29,1.65c-1.14-1.08-2.46-1.8-3.9-1.8-2.07,0-3.42,1.44-3.42,3.18,0,1.65.75,2.67,3.99,4.59,3.81,2.25,4.65,3.93,4.65,6.24v.03c0,3.12-2.46,5.49-5.73,5.49-2.04,0-3.99-.69-5.88-2.52Z"
          />
          <path class="cls-8" d="M755.39,283.74h2.1v9.45h7.89v-9.45h2.13v21h-2.13v-9.6h-7.89v9.6h-2.1v-21Z" />
          <path
            class="cls-8"
            d="M771.56,283.74h2.22l5.01,11.01,5.01-11.01h2.25v21h-2.07v-16.83l-5.16,11.19h-.09l-5.13-11.16v16.8h-2.04v-21Z"
          />
          <path class="cls-8" d="M790.1,283.74h10.14v1.95h-8.04v7.47h7.23v1.95h-7.23v7.68h8.16v1.95h-10.26v-21Z" />
          <path class="cls-8" d="M803.57,283.74h2.04l8.43,16.17v-16.17h2.04v21h-1.83l-8.64-16.59v16.59h-2.04v-21Z" />
          <path class="cls-8" d="M823.4,285.69h-4.56v-1.95h11.25v1.95h-4.56v19.05h-2.13v-19.05Z" />
          <path
            class="cls-8"
            d="M831.11,302.45l1.32-1.68c1.29,1.26,2.76,2.25,4.53,2.25,2.19,0,3.63-1.56,3.63-3.48v-.03c0-1.47-.63-2.64-3.72-4.44-3.84-2.22-4.92-3.84-4.92-6.24v-.06c0-2.97,2.31-5.28,5.64-5.28,1.71,0,3.54.6,5.1,2.1l-1.29,1.65c-1.14-1.08-2.46-1.8-3.9-1.8-2.07,0-3.42,1.44-3.42,3.18,0,1.65.75,2.67,3.99,4.59,3.81,2.25,4.65,3.93,4.65,6.24v.03c0,3.12-2.46,5.49-5.73,5.49-2.04,0-3.99-.69-5.88-2.52Z"
          />
        </g>
        <g>
          <g>
            <path
              class="cls-8"
              d="M604.8,1592.65v-.33c0-6.78,3.36-10.56,7.59-10.56,1.89,0,3.27.72,4.38,1.74l-1.17,1.65c-.9-.81-1.98-1.41-3.27-1.41-3,0-5.4,3.3-5.4,8.61v.21c0,5.46,2.4,8.64,5.37,8.64,1.29,0,2.43-.63,3.42-1.56l1.14,1.62c-1.23,1.14-2.79,1.92-4.68,1.92-4.23,0-7.38-3.69-7.38-10.53Z"
            />
            <path class="cls-8" d="M619.77,1581.97h2.1v9.45h7.89v-9.45h2.13v21h-2.13v-9.6h-7.89v9.6h-2.1v-21Z" />
            <path class="cls-8" d="M635.94,1581.97h10.14v1.95h-8.04v7.47h7.23v1.95h-7.23v7.68h8.16v1.95h-10.26v-21Z" />
            <path
              class="cls-8"
              d="M648.54,1592.65v-.33c0-6.78,3.36-10.56,7.59-10.56,1.89,0,3.27.72,4.38,1.74l-1.17,1.65c-.9-.81-1.98-1.41-3.27-1.41-3,0-5.4,3.3-5.4,8.61v.21c0,5.46,2.4,8.64,5.37,8.64,1.29,0,2.43-.63,3.42-1.56l1.14,1.62c-1.23,1.14-2.79,1.92-4.68,1.92-4.23,0-7.38-3.69-7.38-10.53Z"
            />
            <path
              class="cls-8"
              d="M663.51,1581.97h2.1v11.7l7.59-11.7h2.46l-6.15,9.21,6.6,11.79h-2.46l-5.55-9.84-2.49,3.69v6.15h-2.1v-21Z"
            />
            <path class="cls-8" d="M676.83,1592.95h6.96v2.04h-6.96v-2.04Z" />
            <path class="cls-8" d="M687.45,1581.97h2.1v21h-2.1v-21Z" />
            <path class="cls-8" d="M693.81,1581.97h2.04l8.43,16.17v-16.17h2.04v21h-1.83l-8.64-16.59v16.59h-2.04v-21Z" />
          </g>
          <g>
            <path
              class="cls-8"
              d="M777.23,1737.32v-.39c0-6.48,3.36-10.53,7.95-10.53,1.89,0,3.33.48,4.77,1.65l-1.2,1.77c-.87-.84-2.04-1.44-3.54-1.44-3.27,0-5.85,3.21-5.85,8.58v.27c0,5.61,2.49,8.67,5.94,8.67,1.11,0,2.1-.33,2.82-.81v-6.69h-4.02v-1.92h6.03v9.72c-1.26.9-3,1.62-4.98,1.62-4.74,0-7.92-3.84-7.92-10.5Z"
            />
            <path class="cls-8" d="M793.91,1726.61h2.1v19.05h7.35v1.95h-9.45v-21Z" />
            <path
              class="cls-8"
              d="M810.98,1726.49h2.19l6.24,21.12h-2.19l-1.59-5.46h-7.2l-1.59,5.46h-2.07l6.21-21.12ZM815.09,1740.23l-3.03-10.62-3.09,10.62h6.12Z"
            />
            <path
              class="cls-8"
              d="M820.61,1745.33l1.32-1.68c1.29,1.26,2.76,2.25,4.53,2.25,2.19,0,3.63-1.56,3.63-3.48v-.03c0-1.47-.63-2.64-3.72-4.44-3.84-2.22-4.92-3.84-4.92-6.24v-.06c0-2.97,2.31-5.28,5.64-5.28,1.71,0,3.54.6,5.1,2.1l-1.29,1.65c-1.14-1.08-2.46-1.8-3.9-1.8-2.07,0-3.42,1.44-3.42,3.18,0,1.65.75,2.67,3.99,4.59,3.81,2.25,4.65,3.93,4.65,6.24v.03c0,3.12-2.46,5.49-5.73,5.49-2.04,0-3.99-.69-5.88-2.52Z"
            />
            <path
              class="cls-8"
              d="M833.54,1745.33l1.32-1.68c1.29,1.26,2.76,2.25,4.53,2.25,2.19,0,3.63-1.56,3.63-3.48v-.03c0-1.47-.63-2.64-3.72-4.44-3.84-2.22-4.92-3.84-4.92-6.24v-.06c0-2.97,2.31-5.28,5.64-5.28,1.71,0,3.54.6,5.1,2.1l-1.29,1.65c-1.14-1.08-2.46-1.8-3.9-1.8-2.07,0-3.42,1.44-3.42,3.18,0,1.65.75,2.67,3.99,4.59,3.81,2.25,4.65,3.93,4.65,6.24v.03c0,3.12-2.46,5.49-5.73,5.49-2.04,0-3.99-.69-5.88-2.52Z"
            />
            <path
              class="cls-8"
              d="M846.41,1726.61h2.19l3.78,17.07,3.96-17.1h1.86l3.96,17.1,3.78-17.07h2.16l-4.95,21.12h-1.95l-3.96-16.83-3.96,16.83h-1.95l-4.92-21.12Z"
            />
            <path
              class="cls-8"
              d="M874.46,1726.49h2.19l6.24,21.12h-2.19l-1.59-5.46h-7.2l-1.59,5.46h-2.07l6.21-21.12ZM878.57,1740.23l-3.03-10.62-3.09,10.62h6.12Z"
            />
            <path
              class="cls-8"
              d="M885.74,1726.61h5.07c2.13,0,3.87.66,5.01,1.8,1.05,1.05,1.65,2.61,1.65,4.5v.09c0,3.18-1.71,5.13-4.14,5.94l4.68,8.67h-2.4l-4.38-8.19c-.27.03-.54.03-.81.03h-2.58v8.16h-2.1v-21ZM890.18,1737.53c3.72,0,5.22-1.86,5.22-4.5v-.03c0-2.94-1.83-4.44-4.74-4.44h-2.82v8.97h2.34Z"
            />
            <path class="cls-8" d="M900.92,1726.61h10.14v1.95h-8.04v7.47h7.23v1.95h-7.23v7.68h8.16v1.95h-10.26v-21Z" />
          </g>
          <g>
            <path class="cls-8" d="M380.26,3439.03h9.93v1.95h-7.83v7.77h7.02v1.95h-7.02v9.33h-2.1v-21Z" />
            <path
              class="cls-8"
              d="M393.19,3439.03h5.07c2.13,0,3.87.66,5.01,1.8,1.05,1.05,1.65,2.61,1.65,4.5v.09c0,3.18-1.71,5.13-4.14,5.94l4.68,8.67h-2.4l-4.38-8.19c-.27.03-.54.03-.81.03h-2.58v8.16h-2.1v-21ZM397.63,3449.95c3.72,0,5.22-1.86,5.22-4.5v-.03c0-2.94-1.83-4.44-4.74-4.44h-2.82v8.97h2.34Z"
            />
            <path
              class="cls-8"
              d="M407.65,3449.77v-.45c0-6.54,3-10.59,7.14-10.59s7.11,3.99,7.11,10.56v.45c0,6.54-3.03,10.59-7.17,10.59s-7.08-3.99-7.08-10.56ZM419.74,3449.71v-.36c0-5.43-2.16-8.64-4.98-8.64s-4.95,3.21-4.95,8.64v.36c0,5.43,2.13,8.64,4.95,8.64s4.98-3.21,4.98-8.64Z"
            />
            <path class="cls-8" d="M425.29,3439.03h2.04l8.43,16.17v-16.17h2.04v21h-1.83l-8.64-16.59v16.59h-2.04v-21Z" />
            <path class="cls-8" d="M445.12,3440.98h-4.56v-1.95h11.25v1.95h-4.56v19.05h-2.13v-19.05Z" />
            <path class="cls-8" d="M358.23,3469.03h10.14v1.95h-8.04v7.47h7.23v1.95h-7.23v7.68h8.16v1.95h-10.26v-21Z" />
            <path class="cls-8" d="M371.7,3469.03h2.04l8.43,16.17v-16.17h2.04v21h-1.83l-8.64-16.59v16.59h-2.04v-21Z" />
            <path class="cls-8" d="M391.53,3470.98h-4.56v-1.95h11.25v1.95h-4.56v19.05h-2.13v-19.05Z" />
            <path
              class="cls-8"
              d="M401.01,3469.03h5.07c2.13,0,3.87.66,5.01,1.8,1.05,1.05,1.65,2.61,1.65,4.5v.09c0,3.18-1.71,5.13-4.14,5.94l4.68,8.67h-2.4l-4.38-8.19c-.27.03-.54.03-.81.03h-2.58v8.16h-2.1v-21ZM405.45,3479.95c3.72,0,5.22-1.86,5.22-4.5v-.03c0-2.94-1.83-4.44-4.74-4.44h-2.82v8.97h2.34Z"
            />
            <path
              class="cls-8"
              d="M420.99,3468.91h2.19l6.24,21.12h-2.19l-1.59-5.46h-7.2l-1.59,5.46h-2.07l6.21-21.12ZM425.1,3482.65l-3.03-10.62-3.09,10.62h6.12Z"
            />
            <path class="cls-8" d="M432.27,3469.03h2.04l8.43,16.17v-16.17h2.04v21h-1.83l-8.64-16.59v16.59h-2.04v-21Z" />
            <path
              class="cls-8"
              d="M448.2,3479.71v-.33c0-6.78,3.36-10.56,7.59-10.56,1.89,0,3.27.72,4.38,1.74l-1.17,1.65c-.9-.81-1.98-1.41-3.27-1.41-3,0-5.4,3.3-5.4,8.61v.21c0,5.46,2.4,8.64,5.37,8.64,1.29,0,2.43-.63,3.42-1.56l1.14,1.62c-1.23,1.14-2.79,1.92-4.68,1.92-4.23,0-7.38-3.69-7.38-10.53Z"
            />
            <path class="cls-8" d="M463.17,3469.03h10.14v1.95h-8.04v7.47h7.23v1.95h-7.23v7.68h8.16v1.95h-10.26v-21Z" />
          </g>
          <g>
            <path class="cls-8" d="M408.98,1475.71h-4.56v-1.95h11.25v1.95h-4.56v19.05h-2.13v-19.05Z" />
            <path
              class="cls-8"
              d="M421.76,1473.64h2.19l6.24,21.12h-2.19l-1.59-5.46h-7.2l-1.59,5.46h-2.07l6.21-21.12ZM425.87,1487.38l-3.03-10.62-3.09,10.62h6.12Z"
            />
            <path
              class="cls-8"
              d="M431.39,1492.47l1.32-1.68c1.29,1.26,2.76,2.25,4.53,2.25,2.19,0,3.63-1.56,3.63-3.48v-.03c0-1.47-.63-2.64-3.72-4.44-3.84-2.22-4.92-3.84-4.92-6.24v-.06c0-2.97,2.31-5.28,5.64-5.28,1.71,0,3.54.6,5.1,2.1l-1.29,1.65c-1.14-1.08-2.46-1.8-3.9-1.8-2.07,0-3.42,1.44-3.42,3.18,0,1.65.75,2.67,3.99,4.59,3.81,2.25,4.65,3.93,4.65,6.24v.03c0,3.12-2.46,5.49-5.73,5.49-2.04,0-3.99-.69-5.88-2.52Z"
            />
            <path class="cls-8" d="M448.97,1475.71h-4.56v-1.95h11.25v1.95h-4.56v19.05h-2.13v-19.05Z" />
            <path class="cls-8" d="M458.63,1473.76h2.1v21h-2.1v-21Z" />
            <path class="cls-8" d="M464.99,1473.76h2.04l8.43,16.17v-16.17h2.04v21h-1.83l-8.64-16.59v16.59h-2.04v-21Z" />
            <path
              class="cls-8"
              d="M480.92,1484.46v-.39c0-6.48,3.36-10.53,7.95-10.53,1.89,0,3.33.48,4.77,1.65l-1.2,1.77c-.87-.84-2.04-1.44-3.54-1.44-3.27,0-5.85,3.21-5.85,8.58v.27c0,5.61,2.49,8.67,5.94,8.67,1.11,0,2.1-.33,2.82-.81v-6.69h-4.02v-1.92h6.03v9.72c-1.26.9-3,1.62-4.98,1.62-4.74,0-7.92-3.84-7.92-10.5Z"
            />
            <path
              class="cls-8"
              d="M429.98,1503.76h5.07c2.13,0,3.87.66,5.01,1.8,1.05,1.05,1.65,2.61,1.65,4.5v.09c0,3.18-1.71,5.13-4.14,5.94l4.68,8.67h-2.4l-4.38-8.19c-.27.03-.54.03-.81.03h-2.58v8.16h-2.1v-21ZM434.42,1514.68c3.72,0,5.22-1.86,5.22-4.5v-.03c0-2.94-1.83-4.44-4.74-4.44h-2.82v8.97h2.34Z"
            />
            <path
              class="cls-8"
              d="M444.44,1514.5v-.45c0-6.54,3-10.59,7.14-10.59s7.11,3.99,7.11,10.56v.45c0,6.54-3.03,10.59-7.17,10.59s-7.08-3.99-7.08-10.56ZM456.53,1514.44v-.36c0-5.43-2.16-8.64-4.98-8.64s-4.95,3.21-4.95,8.64v.36c0,5.43,2.13,8.64,4.95,8.64s4.98-3.21,4.98-8.64Z"
            />
            <path
              class="cls-8"
              d="M461.42,1514.5v-.45c0-6.54,3-10.59,7.14-10.59s7.11,3.99,7.11,10.56v.45c0,6.54-3.03,10.59-7.17,10.59s-7.08-3.99-7.08-10.56ZM473.51,1514.44v-.36c0-5.43-2.16-8.64-4.98-8.64s-4.95,3.21-4.95,8.64v.36c0,5.43,2.13,8.64,4.95,8.64s4.98-3.21,4.98-8.64Z"
            />
            <path
              class="cls-8"
              d="M479.06,1503.76h2.22l5.01,11.01,5.01-11.01h2.25v21h-2.07v-16.83l-5.16,11.19h-.09l-5.13-11.16v16.8h-2.04v-21Z"
            />
            <path class="cls-8" d="M379.19,1533.76h10.14v1.95h-8.04v7.47h7.23v1.95h-7.23v7.68h8.16v1.95h-10.26v-21Z" />
            <path class="cls-8" d="M392.66,1533.76h2.04l8.43,16.17v-16.17h2.04v21h-1.83l-8.64-16.59v16.59h-2.04v-21Z" />
            <path class="cls-8" d="M412.49,1535.71h-4.56v-1.95h11.25v1.95h-4.56v19.05h-2.13v-19.05Z" />
            <path
              class="cls-8"
              d="M421.97,1533.76h5.07c2.13,0,3.87.66,5.01,1.8,1.05,1.05,1.65,2.61,1.65,4.5v.09c0,3.18-1.71,5.13-4.14,5.94l4.68,8.67h-2.4l-4.38-8.19c-.27.03-.54.03-.81.03h-2.58v8.16h-2.1v-21ZM426.41,1544.68c3.72,0,5.22-1.86,5.22-4.5v-.03c0-2.94-1.83-4.44-4.74-4.44h-2.82v8.97h2.34Z"
            />
            <path
              class="cls-8"
              d="M441.95,1533.64h2.19l6.24,21.12h-2.19l-1.59-5.46h-7.2l-1.59,5.46h-2.07l6.21-21.12ZM446.06,1547.38l-3.03-10.62-3.09,10.62h6.12Z"
            />
            <path class="cls-8" d="M453.23,1533.76h2.04l8.43,16.17v-16.17h2.04v21h-1.83l-8.64-16.59v16.59h-2.04v-21Z" />
            <path
              class="cls-8"
              d="M469.16,1544.44v-.33c0-6.78,3.36-10.56,7.59-10.56,1.89,0,3.27.72,4.38,1.74l-1.17,1.65c-.9-.81-1.98-1.41-3.27-1.41-3,0-5.4,3.3-5.4,8.61v.21c0,5.46,2.4,8.64,5.37,8.64,1.29,0,2.43-.63,3.42-1.56l1.14,1.62c-1.23,1.14-2.79,1.92-4.68,1.92-4.23,0-7.38-3.69-7.38-10.53Z"
            />
            <path class="cls-8" d="M484.13,1533.76h10.14v1.95h-8.04v7.47h7.23v1.95h-7.23v7.68h8.16v1.95h-10.26v-21Z" />
          </g>
          <g>
            <path
              class="cls-8"
              d="M95.35,3389.06v-.33c0-6.78,3.36-10.56,7.59-10.56,1.89,0,3.27.72,4.38,1.74l-1.17,1.65c-.9-.81-1.98-1.41-3.27-1.41-3,0-5.4,3.3-5.4,8.61v.21c0,5.46,2.4,8.64,5.37,8.64,1.29,0,2.43-.63,3.42-1.56l1.14,1.62c-1.23,1.14-2.79,1.92-4.68,1.92-4.23,0-7.38-3.69-7.38-10.53Z"
            />
            <path
              class="cls-8"
              d="M108.97,3389.12v-.45c0-6.54,3-10.59,7.14-10.59s7.11,3.99,7.11,10.56v.45c0,6.54-3.03,10.59-7.17,10.59s-7.08-3.99-7.08-10.56ZM121.06,3389.06v-.36c0-5.43-2.16-8.64-4.98-8.64s-4.95,3.21-4.95,8.64v.36c0,5.43,2.13,8.64,4.95,8.64s4.98-3.21,4.98-8.64Z"
            />
            <path
              class="cls-8"
              d="M130.69,3378.26h2.19l6.24,21.12h-2.19l-1.59-5.46h-7.2l-1.59,5.46h-2.07l6.21-21.12ZM134.8,3392l-3.03-10.62-3.09,10.62h6.12Z"
            />
            <path class="cls-8" d="M143.5,3380.33h-4.56v-1.95h11.25v1.95h-4.56v19.05h-2.13v-19.05Z" />
            <path
              class="cls-8"
              d="M158.2,3389.06v-.33c0-6.78,3.36-10.56,7.59-10.56,1.89,0,3.27.72,4.38,1.74l-1.17,1.65c-.9-.81-1.98-1.41-3.27-1.41-3,0-5.4,3.3-5.4,8.61v.21c0,5.46,2.4,8.64,5.37,8.64,1.29,0,2.43-.63,3.42-1.56l1.14,1.62c-1.23,1.14-2.79,1.92-4.68,1.92-4.23,0-7.38-3.69-7.38-10.53Z"
            />
            <path class="cls-8" d="M173.17,3378.38h2.1v9.45h7.89v-9.45h2.13v21h-2.13v-9.6h-7.89v9.6h-2.1v-21Z" />
            <path class="cls-8" d="M189.34,3378.38h10.14v1.95h-8.04v7.47h7.23v1.95h-7.23v7.68h8.16v1.95h-10.26v-21Z" />
            <path
              class="cls-8"
              d="M201.94,3389.06v-.33c0-6.78,3.36-10.56,7.59-10.56,1.89,0,3.27.72,4.38,1.74l-1.17,1.65c-.9-.81-1.98-1.41-3.27-1.41-3,0-5.4,3.3-5.4,8.61v.21c0,5.46,2.4,8.64,5.37,8.64,1.29,0,2.43-.63,3.42-1.56l1.14,1.62c-1.23,1.14-2.79,1.92-4.68,1.92-4.23,0-7.38-3.69-7.38-10.53Z"
            />
            <path
              class="cls-8"
              d="M216.91,3378.38h2.1v11.7l7.59-11.7h2.46l-6.15,9.21,6.6,11.79h-2.46l-5.55-9.84-2.49,3.69v6.15h-2.1v-21Z"
            />
          </g>
        </g>
      </g>
      <g>
        <rect class="cls-13" x=".9" y="487.57" width="69.39" height="69.39" />
        <rect class="cls-12" x=".9" y="219.12" width="69.39" height="69.39" />
        <rect class="cls-1" x=".9" y="308.6" width="69.39" height="69.39" />
        <rect class="cls-14" x=".79" y="398.08" width="69.39" height="69.39" />
        <g>
          <path
            class="cls-8"
            d="M3.19,110.5h13.9l11.32,18.38,11.32-18.38h13.9v47.47h-13.09v-27.26l-12.14,18.58h-.27l-12.07-18.51v27.19H3.19v-47.47Z"
          />
          <path
            class="cls-8"
            d="M79.67,110.16h12.68l20.21,47.81h-14.1l-3.39-8.54h-18.38l-3.39,8.54h-13.77l20.14-47.81ZM91.2,139.18l-5.29-13.43-5.36,13.43h10.65Z"
          />
          <path
            class="cls-8"
            d="M118.38,110.5h20.41c11.93,0,19.66,6.17,19.66,16.61v.14c0,11.26-8.61,17.16-20.34,17.16h-6.58v13.56h-13.16v-47.47ZM137.84,134.1c4.61,0,7.53-2.51,7.53-6.24v-.14c0-4.07-2.92-6.17-7.59-6.17h-6.24v12.54h6.31Z"
          />
          <path
            class="cls-8"
            d="M186.18,110.5h13.16v19.26l16.41-19.26h15.53l-17.97,20.34,18.58,27.12h-15.73l-12-17.63-4.81,5.29v12.34h-13.16v-47.47Z"
          />
          <path class="cls-8" d="M237.37,110.5h38.18v11.12h-25.16v7.26h22.78v10.37h-22.78v7.53h25.5v11.19h-38.52v-47.47Z" />
          <path class="cls-8" d="M298.6,140.2l-18.11-29.7h14.99l9.76,17.29,9.83-17.29h14.72l-18.04,29.5v17.97h-13.16v-17.77Z" />
          <path class="cls-8" d="M331.62,121.28h13.36v13.22h-13.36v-13.22ZM331.62,144.74h13.36v13.22h-13.36v-13.22Z" />
        </g>
        <g>
          <path
            class="cls-8"
            d="M91.86,243.35h2.23l3.84,17.36,4.03-17.39h1.89l4.03,17.39,3.84-17.36h2.2l-5.03,21.47h-1.98l-4.03-17.11-4.03,17.11h-1.98l-5-21.47Z"
          />
          <path
            class="cls-8"
            d="M115.56,254.27v-.46c0-6.65,3.05-10.77,7.26-10.77s7.23,4.06,7.23,10.74v.46c0,6.65-3.08,10.77-7.29,10.77s-7.2-4.06-7.2-10.74ZM127.85,254.21v-.37c0-5.52-2.2-8.78-5.06-8.78s-5.03,3.26-5.03,8.78v.37c0,5.52,2.17,8.78,5.03,8.78s5.06-3.26,5.06-8.78Z"
          />
          <path
            class="cls-8"
            d="M133.5,243.35h5.16c2.17,0,3.93.67,5.09,1.83,1.07,1.07,1.68,2.65,1.68,4.58v.09c0,3.23-1.74,5.22-4.21,6.04l4.76,8.81h-2.44l-4.45-8.33c-.27.03-.55.03-.82.03h-2.62v8.3h-2.13v-21.35ZM138.01,254.45c3.78,0,5.31-1.89,5.31-4.58v-.03c0-2.99-1.86-4.51-4.82-4.51h-2.87v9.12h2.38Z"
          />
          <path class="cls-8" d="M148.93,243.35h2.13v19.37h7.47v1.98h-9.61v-21.35Z" />
          <path
            class="cls-8"
            d="M161.29,243.35h4.42c5.12,0,8.54,3.9,8.54,10.46v.43c0,6.53-3.42,10.46-8.54,10.46h-4.42v-21.35ZM163.42,245.3v17.45h2.2c3.84,0,6.47-3.08,6.47-8.54v-.37c0-5.43-2.62-8.54-6.47-8.54h-2.2Z"
          />
        </g>
        <g>
          <path class="cls-8" d="M92.99,332.83h10.1v1.98h-7.96v7.9h7.14v1.98h-7.14v9.49h-2.13v-21.35Z" />
          <path
            class="cls-8"
            d="M106.14,332.83h5.16c2.17,0,3.93.67,5.09,1.83,1.07,1.07,1.68,2.65,1.68,4.58v.09c0,3.23-1.74,5.22-4.21,6.04l4.76,8.82h-2.44l-4.45-8.33c-.27.03-.55.03-.82.03h-2.62v8.3h-2.13v-21.35ZM110.65,343.94c3.78,0,5.31-1.89,5.31-4.58v-.03c0-2.99-1.86-4.51-4.82-4.51h-2.87v9.12h2.38Z"
          />
          <path
            class="cls-8"
            d="M126.45,332.71h2.23l6.34,21.47h-2.23l-1.62-5.55h-7.32l-1.62,5.55h-2.1l6.31-21.47ZM130.63,346.68l-3.08-10.8-3.14,10.8h6.22Z"
          />
          <path class="cls-8" d="M137.92,332.83h2.07l8.57,16.44v-16.44h2.07v21.35h-1.86l-8.78-16.87v16.87h-2.07v-21.35Z" />
          <path
            class="cls-8"
            d="M154.12,343.69v-.33c0-6.89,3.42-10.74,7.72-10.74,1.92,0,3.33.73,4.45,1.77l-1.19,1.68c-.92-.82-2.01-1.43-3.32-1.43-3.05,0-5.49,3.36-5.49,8.75v.21c0,5.55,2.44,8.78,5.46,8.78,1.31,0,2.47-.64,3.48-1.59l1.16,1.65c-1.25,1.16-2.84,1.95-4.76,1.95-4.3,0-7.5-3.75-7.5-10.71Z"
          />
          <path class="cls-8" d="M169.34,332.83h10.31v1.98h-8.17v7.6h7.35v1.98h-7.35v7.81h8.3v1.98h-10.43v-21.35Z" />
        </g>
        <g>
          <path
            class="cls-8"
            d="M92.81,436.8v-14.49h2.13v14.52c0,3.36,1.59,5.16,4.15,5.16s4.09-1.74,4.09-5.09v-14.58h2.17v14.46c0,4.64-2.5,7.2-6.28,7.2s-6.25-2.56-6.25-7.17Z"
          />
          <path
            class="cls-8"
            d="M107.78,441.35l1.34-1.71c1.31,1.28,2.81,2.29,4.61,2.29,2.23,0,3.69-1.59,3.69-3.54v-.03c0-1.49-.64-2.68-3.78-4.51-3.9-2.26-5-3.9-5-6.34v-.06c0-3.02,2.35-5.37,5.73-5.37,1.74,0,3.6.61,5.19,2.13l-1.31,1.68c-1.16-1.1-2.5-1.83-3.97-1.83-2.1,0-3.48,1.46-3.48,3.23,0,1.68.76,2.71,4.06,4.67,3.87,2.29,4.73,4,4.73,6.34v.03c0,3.17-2.5,5.58-5.83,5.58-2.07,0-4.06-.7-5.98-2.56Z"
          />
          <path
            class="cls-8"
            d="M127.64,422.19h2.23l6.34,21.47h-2.23l-1.62-5.55h-7.32l-1.62,5.55h-2.1l6.31-21.47ZM131.82,436.16l-3.08-10.8-3.14,10.8h6.22Z"
          />
        </g>
        <g>
          <path class="cls-8" d="M93.17,511.8h2.14v21.35h-2.14v-21.35Z" />
          <path class="cls-8" d="M102.93,513.78h-4.64v-1.98h11.44v1.98h-4.64v19.37h-2.17v-19.37Z" />
          <path
            class="cls-8"
            d="M115.93,511.67h2.23l6.34,21.47h-2.23l-1.62-5.55h-7.32l-1.62,5.55h-2.1l6.31-21.47ZM120.11,525.64l-3.08-10.8-3.14,10.8h6.22Z"
          />
          <path class="cls-8" d="M127.4,511.8h2.13v19.37h7.47v1.98h-9.61v-21.35Z" />
          <path class="cls-8" d="M141.7,524.36l-5.95-12.57h2.35l4.7,10.16,4.7-10.16h2.29l-5.95,12.54v8.82h-2.13v-8.78Z" />
        </g>
      </g>
    </g>
    <g id="TABLES">
      <g id="TABLE-33">
        <g>
          <path
            class="cls-8"
            d="M1252.75,797.9l2.04-4.77c.57.39,1.95,1.23,3.54,1.23,1.32,0,2.16-.75,2.16-1.8v-.06c0-1.23-1.26-2.04-4.02-2.04h-.57l-.3-3.36,3.78-3.66h-5.58v-5.16h12.12v4.74l-4.11,3.93c2.49.78,4.38,2.34,4.38,5.73v.09c0,4.38-2.91,6.9-7.23,6.9-2.31,0-4.56-.6-6.21-1.77Z"
          />
          <path
            class="cls-8"
            d="M1266.73,797.9l2.04-4.77c.57.39,1.95,1.23,3.54,1.23,1.32,0,2.16-.75,2.16-1.8v-.06c0-1.23-1.26-2.04-4.02-2.04h-.57l-.3-3.36,3.78-3.66h-5.58v-5.16h12.12v4.74l-4.11,3.93c2.49.78,4.38,2.34,4.38,5.73v.09c0,4.38-2.91,6.9-7.23,6.9-2.31,0-4.56-.6-6.21-1.77Z"
          />
        </g>
        <rect class="cls-13" x="1300.02" y="736.74" width="39.65" height="103.48" />
      </g>
      <g id="TABLE-32">
        <rect class="cls-13" x="1299.64" y="846.22" width="39.65" height="103.48" />
        <path
          class="cls-8"
          d="M1252.75,907.38l2.04-4.77c.57.39,1.95,1.23,3.54,1.23,1.32,0,2.16-.75,2.16-1.8v-.06c0-1.23-1.26-2.04-4.02-2.04h-.57l-.3-3.36,3.78-3.66h-5.58v-5.16h12.12v4.74l-4.11,3.93c2.49.78,4.38,2.34,4.38,5.73v.09c0,4.38-2.91,6.9-7.23,6.9-2.31,0-4.56-.6-6.21-1.77Z"
        />
        <path
          class="cls-8"
          d="M1267.27,903.93l5.58-6.75c1.08-1.32,1.62-1.92,1.62-2.85,0-.72-.48-1.56-1.59-1.56-1.05,0-2.13.78-3.6,1.95l-2.46-4.5c2.43-1.92,4.59-2.82,7.05-2.82,3.75,0,6.3,2.4,6.3,5.91v.12c0,2.07-.81,3.87-3.06,6.45l-3.21,3.66h6.27v5.22h-12.9v-4.83Z"
        />
      </g>
      <g id="TABLE-31">
        <rect class="cls-14" x="1299.64" y="955.7" width="39.65" height="103.48" />
        <path
          class="cls-8"
          d="M1252.75,1016.86l2.04-4.77c.57.39,1.95,1.23,3.54,1.23,1.32,0,2.16-.75,2.16-1.8v-.06c0-1.23-1.26-2.04-4.02-2.04h-.57l-.3-3.36,3.78-3.66h-5.58v-5.16h12.12v4.74l-4.11,3.93c2.49.78,4.38,2.34,4.38,5.73v.09c0,4.38-2.91,6.9-7.23,6.9-2.31,0-4.56-.6-6.21-1.77Z"
        />
        <path class="cls-8" d="M1269.85,1002.94l-2.52.69-.39-5.13,4.5-1.32h4.26v21.06h-5.85v-15.3Z" />
      </g>
      <g id="TABLE-30">
        <rect class="cls-14" x="1300.02" y="1066.18" width="39.65" height="103.48" />
        <path
          class="cls-8"
          d="M1253.12,1127.34l2.04-4.77c.57.39,1.95,1.23,3.54,1.23,1.32,0,2.16-.75,2.16-1.8v-.06c0-1.23-1.26-2.04-4.02-2.04h-.57l-.3-3.36,3.78-3.66h-5.58v-5.16h12.12v4.74l-4.11,3.93c2.49.78,4.38,2.34,4.38,5.73v.09c0,4.38-2.91,6.9-7.23,6.9-2.31,0-4.56-.6-6.21-1.77Z"
        />
        <path
          class="cls-8"
          d="M1267.67,1118.76v-1.02c0-6.42,2.88-10.47,7.71-10.47s7.62,3.99,7.62,10.41v1.02c0,6.42-2.88,10.47-7.68,10.47s-7.65-3.99-7.65-10.41ZM1277.18,1118.82v-1.17c0-3.63-.63-5.1-1.86-5.1s-1.83,1.44-1.83,5.07v1.17c0,3.63.63,5.1,1.86,5.1s1.83-1.44,1.83-5.07Z"
        />
      </g>
      <g id="TABLE-29">
        <rect class="cls-14" x="1300.02" y="1175.66" width="39.65" height="103.48" />
        <path
          class="cls-8"
          d="M1253.66,1233.37l5.58-6.75c1.08-1.32,1.62-1.92,1.62-2.85,0-.72-.48-1.56-1.59-1.56-1.05,0-2.13.78-3.6,1.95l-2.46-4.5c2.43-1.92,4.59-2.82,7.05-2.82,3.75,0,6.3,2.4,6.3,5.91v.12c0,2.07-.81,3.87-3.06,6.45l-3.21,3.66h6.27v5.22h-12.9v-4.83Z"
        />
        <path
          class="cls-8"
          d="M1276.4,1230.22c-.9.63-2.01,1.05-3.12,1.05-2.67,0-5.49-1.77-5.49-6.48v-.18c0-5.13,3.09-7.86,6.81-7.86,2.13,0,3.63.63,4.86,1.83,1.68,1.62,2.55,4.44,2.55,8.85v.06c0,8.04-3.63,11.07-8.49,11.07-1.8,0-3.15-.24-5.19-1.32l1.68-4.59c.84.48,1.8,1.02,3.18,1.02,2.19,0,3.09-1.62,3.21-3.45ZM1276.34,1224.22v-.06c0-1.8-.48-2.85-1.56-2.85s-1.5,1.05-1.5,2.85v.06c0,1.8.48,2.85,1.56,2.85s1.5-1.05,1.5-2.85Z"
        />
      </g>
      <g id="TABLE-28">
        <rect class="cls-14" x="1300.02" y="1285.15" width="39.65" height="103.48" />
        <path
          class="cls-8"
          d="M1253.66,1342.86l5.58-6.75c1.08-1.32,1.62-1.92,1.62-2.85,0-.72-.48-1.56-1.59-1.56-1.05,0-2.13.78-3.6,1.95l-2.46-4.5c2.43-1.92,4.59-2.82,7.05-2.82,3.75,0,6.3,2.4,6.3,5.91v.12c0,2.07-.81,3.87-3.06,6.45l-3.21,3.66h6.27v5.22h-12.9v-4.83Z"
        />
        <path
          class="cls-8"
          d="M1267.61,1342.02v-.21c0-2.28,1.11-4.02,2.88-5.04-1.41-.96-2.52-2.52-2.52-4.5v-.18c0-3.39,2.76-5.82,6.81-5.82s6.78,2.37,6.78,5.79v.15c0,1.98-1.11,3.54-2.55,4.53,1.74.99,2.91,2.67,2.91,5.04v.21c0,3.66-2.85,6.12-7.17,6.12s-7.14-2.4-7.14-6.09ZM1276.37,1341.33v-.06c0-1.59-.63-2.43-1.59-2.43s-1.59.84-1.59,2.43v.06c0,1.59.6,2.4,1.59,2.4s1.59-.81,1.59-2.4ZM1276.25,1332.81c0-1.5-.6-2.22-1.47-2.22s-1.47.72-1.47,2.22.57,2.25,1.47,2.25,1.47-.78,1.47-2.25Z"
        />
      </g>
      <g id="TABLE-27">
        <rect class="cls-1" x="1299.64" y="1426.17" width="39.65" height="140.04" />
        <path
          class="cls-8"
          d="M1253.29,1502.16l5.58-6.75c1.08-1.32,1.62-1.92,1.62-2.85,0-.72-.48-1.56-1.59-1.56-1.05,0-2.13.78-3.6,1.95l-2.46-4.5c2.43-1.92,4.59-2.82,7.05-2.82,3.75,0,6.3,2.4,6.3,5.91v.12c0,2.07-.81,3.87-3.06,6.45l-3.21,3.66h6.27v5.22h-12.9v-4.83Z"
        />
        <path class="cls-8" d="M1273.81,1491.36h-6.54v-5.37h12.69v3.84l-5.82,17.16h-5.91l5.58-15.63Z" />
      </g>
      <g id="TABLE-26">
        <rect class="cls-1" x="1299.64" y="1572.21" width="39.65" height="103.48" />
        <path
          class="cls-8"
          d="M1253.29,1629.92l5.58-6.75c1.08-1.32,1.62-1.92,1.62-2.85,0-.72-.48-1.56-1.59-1.56-1.05,0-2.13.78-3.6,1.95l-2.46-4.5c2.43-1.92,4.59-2.82,7.05-2.82,3.75,0,6.3,2.4,6.3,5.91v.12c0,2.07-.81,3.87-3.06,6.45l-3.21,3.66h6.27v5.22h-12.9v-4.83Z"
        />
        <path
          class="cls-8"
          d="M1270,1633.37c-1.68-1.62-2.55-4.44-2.55-8.73v-.06c0-8.04,3.63-11.19,8.49-11.19,1.8,0,3.15.27,4.89,1.17l-1.68,4.59c-.54-.33-1.5-.87-2.88-.87-2.22,0-3.12,1.56-3.21,3.6.9-.63,2.01-.96,3.12-.96,2.67,0,5.49,1.77,5.49,6.36v.18c0,5.01-3.09,7.74-6.81,7.74-2.13,0-3.63-.63-4.86-1.83ZM1276.18,1627.91v-.06c0-1.68-.48-2.73-1.56-2.73s-1.5,1.05-1.5,2.73v.06c0,1.68.48,2.73,1.56,2.73s1.5-1.05,1.5-2.73Z"
        />
      </g>
      <g id="TABLE-25">
        <rect class="cls-1" x="1299.64" y="1681.69" width="39.65" height="140.04" />
        <path
          class="cls-8"
          d="M1253.29,1757.68l5.58-6.75c1.08-1.32,1.62-1.92,1.62-2.85,0-.72-.48-1.56-1.59-1.56-1.05,0-2.13.78-3.6,1.95l-2.46-4.5c2.43-1.92,4.59-2.82,7.05-2.82,3.75,0,6.3,2.4,6.3,5.91v.12c0,2.07-.81,3.87-3.06,6.45l-3.21,3.66h6.27v5.22h-12.9v-4.83Z"
        />
        <path
          class="cls-8"
          d="M1267.03,1761.1l1.86-4.89c1.41.84,2.61,1.38,3.75,1.38,1.44,0,2.25-.87,2.25-2.13v-.03c0-1.29-.81-2.04-2.07-2.04-.9,0-1.44.27-2.04.66l-2.97-1.5.54-11.04h11.43v5.19h-7.05l-.12,2.52c.57-.18,1.35-.3,2.04-.3,3.18,0,5.94,1.83,5.94,6.33v.15c0,4.86-3.18,7.5-7.44,7.5-2.25,0-4.32-.66-6.12-1.8Z"
        />
      </g>
      <g id="TABLE-24">
        <rect class="cls-1" x="1153.61" y="1827.73" width="140.04" height="39.65" />
        <path
          class="cls-8"
          d="M1209.36,1804.21l5.58-6.75c1.08-1.32,1.62-1.92,1.62-2.85,0-.72-.48-1.56-1.59-1.56-1.05,0-2.13.78-3.6,1.95l-2.46-4.5c2.43-1.92,4.59-2.82,7.05-2.82,3.75,0,6.3,2.4,6.3,5.91v.12c0,2.07-.81,3.87-3.06,6.45l-3.21,3.66h6.27v5.22h-12.9v-4.83Z"
        />
        <path
          class="cls-8"
          d="M1230.69,1805.68h-7.38l-.36-4.08,7.23-13.62h6.03v13.08h1.95v4.62h-1.95v3.36h-5.52v-3.36ZM1230.78,1801.09v-5.73l-2.76,5.73h2.76Z"
        />
      </g>
      <g id="TABLE-23">
        <rect class="cls-1" x="1044.12" y="1827.73" width="103.48" height="39.65" />
        <path
          class="cls-8"
          d="M1082.42,1804.21l5.58-6.75c1.08-1.32,1.62-1.92,1.62-2.85,0-.72-.48-1.56-1.59-1.56-1.05,0-2.13.78-3.6,1.95l-2.46-4.5c2.43-1.92,4.59-2.82,7.05-2.82,3.75,0,6.3,2.4,6.3,5.91v.12c0,2.07-.81,3.87-3.06,6.45l-3.21,3.66h6.27v5.22h-12.9v-4.83Z"
        />
        <path
          class="cls-8"
          d="M1096.04,1807.66l2.04-4.77c.57.39,1.95,1.23,3.54,1.23,1.32,0,2.16-.75,2.16-1.8v-.06c0-1.23-1.26-2.04-4.02-2.04h-.57l-.3-3.36,3.78-3.66h-5.58v-5.16h12.12v4.74l-4.11,3.93c2.49.78,4.38,2.34,4.38,5.73v.09c0,4.38-2.91,6.9-7.23,6.9-2.31,0-4.56-.6-6.21-1.77Z"
        />
      </g>
      <g id="TABLE-22">
        <g>
          <path
            class="cls-8"
            d="M881.99,1489.41l5.58-6.75c1.08-1.32,1.62-1.92,1.62-2.85,0-.72-.48-1.56-1.59-1.56-1.05,0-2.13.78-3.6,1.95l-2.46-4.5c2.43-1.92,4.59-2.82,7.05-2.82,3.75,0,6.3,2.4,6.3,5.91v.12c0,2.07-.81,3.87-3.06,6.45l-3.21,3.66h6.27v5.22h-12.9v-4.83Z"
          />
          <path
            class="cls-8"
            d="M896.15,1489.41l5.58-6.75c1.08-1.32,1.62-1.92,1.62-2.85,0-.72-.48-1.56-1.59-1.56-1.05,0-2.13.78-3.6,1.95l-2.46-4.5c2.43-1.92,4.59-2.82,7.05-2.82,3.75,0,6.3,2.4,6.3,5.91v.12c0,2.07-.81,3.87-3.06,6.45l-3.21,3.66h6.27v5.22h-12.9v-4.83Z"
          />
        </g>
        <rect class="cls-1" x="825.5" y="1414.31" width="140.04" height="39.65" />
      </g>
      <g id="TABLE-21">
        <g>
          <path
            class="cls-8"
            d="M1011.79,1489.41l5.58-6.75c1.08-1.32,1.62-1.92,1.62-2.85,0-.72-.48-1.56-1.59-1.56-1.05,0-2.13.78-3.6,1.95l-2.46-4.5c2.43-1.92,4.59-2.82,7.05-2.82,3.75,0,6.3,2.4,6.3,5.91v.12c0,2.07-.81,3.87-3.06,6.45l-3.21,3.66h6.27v5.22h-12.9v-4.83Z"
          />
          <path class="cls-8" d="M1028.53,1478.94l-2.52.69-.39-5.13,4.5-1.32h4.26v21.06h-5.85v-15.3Z" />
        </g>
        <polygon class="cls-1" points="1075.02 1453.95 1075.02 1434.13 1075.02 1414.31 971.54 1414.31 971.54 1453.95 1075.02 1453.95" />
      </g>
      <g id="TABLE-20">
        <rect class="cls-1" x="1074.69" y="1267.01" width="39.65" height="103.48" />
        <path
          class="cls-8"
          d="M1131.14,1324.72l5.58-6.75c1.08-1.32,1.62-1.92,1.62-2.85,0-.72-.48-1.56-1.59-1.56-1.05,0-2.13.78-3.6,1.95l-2.46-4.5c2.43-1.92,4.59-2.82,7.05-2.82,3.75,0,6.3,2.4,6.3,5.91v.12c0,2.07-.81,3.87-3.06,6.45l-3.21,3.66h6.27v5.22h-12.9v-4.83Z"
        />
        <path
          class="cls-8"
          d="M1145.33,1319.59v-1.02c0-6.42,2.88-10.47,7.71-10.47s7.62,3.99,7.62,10.41v1.02c0,6.42-2.88,10.47-7.68,10.47s-7.65-3.99-7.65-10.41ZM1154.84,1319.65v-1.17c0-3.63-.63-5.1-1.86-5.1s-1.83,1.44-1.83,5.07v1.17c0,3.63.63,5.1,1.86,5.1s1.83-1.44,1.83-5.07Z"
        />
      </g>
      <g id="TABLE-19">
        <rect class="cls-1" x="1074.69" y="1157.53" width="39.65" height="103.48" />
        <path class="cls-8" d="M1139,1204.77l-2.52.69-.39-5.13,4.5-1.32h4.26v21.06h-5.85v-15.3Z" />
        <path
          class="cls-8"
          d="M1155.08,1212.09c-.9.63-2.01,1.05-3.12,1.05-2.67,0-5.49-1.77-5.49-6.48v-.18c0-5.13,3.09-7.86,6.81-7.86,2.13,0,3.63.63,4.86,1.83,1.68,1.62,2.55,4.44,2.55,8.85v.06c0,8.04-3.63,11.07-8.49,11.07-1.8,0-3.15-.24-5.19-1.32l1.68-4.59c.84.48,1.8,1.02,3.18,1.02,2.19,0,3.09-1.62,3.21-3.45ZM1155.02,1206.09v-.06c0-1.8-.48-2.85-1.56-2.85s-1.5,1.05-1.5,2.85v.06c0,1.8.48,2.85,1.56,2.85s1.5-1.05,1.5-2.85Z"
        />
      </g>
      <g id="TABLE-18">
        <rect class="cls-1" x="1074.69" y="1048.05" width="39.65" height="103.48" />
        <path class="cls-8" d="M1139.3,1095.29l-2.52.69-.39-5.13,4.5-1.32h4.26v21.06h-5.85v-15.3Z" />
        <path
          class="cls-8"
          d="M1146.59,1104.92v-.21c0-2.28,1.11-4.02,2.88-5.04-1.41-.96-2.52-2.52-2.52-4.5v-.18c0-3.39,2.76-5.82,6.81-5.82s6.78,2.37,6.78,5.79v.15c0,1.98-1.11,3.54-2.55,4.53,1.74.99,2.91,2.67,2.91,5.04v.21c0,3.66-2.85,6.12-7.17,6.12s-7.14-2.4-7.14-6.09ZM1155.35,1104.23v-.06c0-1.59-.63-2.43-1.59-2.43s-1.59.84-1.59,2.43v.06c0,1.59.6,2.4,1.59,2.4s1.59-.81,1.59-2.4ZM1155.23,1095.71c0-1.5-.6-2.22-1.47-2.22s-1.47.72-1.47,2.22.57,2.25,1.47,2.25,1.47-.78,1.47-2.25Z"
        />
      </g>
      <g id="TABLE-17">
        <rect class="cls-1" x="1074.69" y="889.2" width="39.65" height="103.48" />
        <path class="cls-8" d="M1140.8,936.45l-2.52.69-.39-5.13,4.5-1.32h4.26v21.06h-5.85v-15.3Z" />
        <path class="cls-8" d="M1154.81,936.12h-6.54v-5.37h12.69v3.84l-5.82,17.16h-5.91l5.58-15.63Z" />
      </g>
      <g id="TABLE-16">
        <rect class="cls-1" x="1074.69" y="779.72" width="39.65" height="103.48" />
        <path class="cls-8" d="M1139,826.96l-2.52.69-.39-5.13,4.5-1.32h4.26v21.06h-5.85v-15.3Z" />
        <path
          class="cls-8"
          d="M1149.05,840.88c-1.68-1.62-2.55-4.44-2.55-8.73v-.06c0-8.04,3.63-11.19,8.49-11.19,1.8,0,3.15.27,4.89,1.17l-1.68,4.59c-.54-.33-1.5-.87-2.88-.87-2.22,0-3.12,1.56-3.21,3.6.9-.63,2.01-.96,3.12-.96,2.67,0,5.49,1.77,5.49,6.36v.18c0,5.01-3.09,7.74-6.81,7.74-2.13,0-3.63-.63-4.86-1.83ZM1155.23,835.42v-.06c0-1.68-.48-2.73-1.56-2.73s-1.5,1.05-1.5,2.73v.06c0,1.68.48,2.73,1.56,2.73s1.5-1.05,1.5-2.73Z"
        />
      </g>
      <g id="TABLE-15">
        <polygon class="cls-1" points="1094.52 670.24 1074.69 670.24 1074.69 773.72 1114.34 773.72 1114.34 670.24 1094.52 670.24" />
        <path class="cls-8" d="M1140.2,717.48l-2.52.69-.39-5.13,4.5-1.32h4.26v21.06h-5.85v-15.3Z" />
        <path
          class="cls-8"
          d="M1147.28,731.37l1.86-4.89c1.41.84,2.61,1.38,3.75,1.38,1.44,0,2.25-.87,2.25-2.13v-.03c0-1.29-.81-2.04-2.07-2.04-.9,0-1.44.27-2.04.66l-2.97-1.5.54-11.04h11.43v5.19h-7.05l-.12,2.52c.57-.18,1.35-.3,2.04-.3,3.18,0,5.94,1.83,5.94,6.33v.15c0,4.86-3.18,7.5-7.44,7.5-2.25,0-4.32-.66-6.12-1.8Z"
        />
      </g>
      <g id="TABLE-14">
        <g>
          <path class="cls-8" d="M1007.19,590.6l-2.52.69-.39-5.13,4.5-1.32h4.26v21.06h-5.85v-15.3Z" />
          <path
            class="cls-8"
            d="M1022.1,602.54h-7.38l-.36-4.08,7.23-13.62h6.03v13.08h1.95v4.62h-1.95v3.36h-5.52v-3.36ZM1022.19,597.95v-5.73l-2.76,5.73h2.76Z"
          />
        </g>
        <polygon class="cls-1" points="1068.69 624.59 965.21 624.59 965.21 664.24 1068.69 664.24 1068.69 644.41 1068.69 624.59" />
      </g>
      <g id="TABLE-13">
        <g>
          <path class="cls-8" d="M898.65,590.6l-2.52.69-.39-5.13,4.5-1.32h4.26v21.06h-5.85v-15.3Z" />
          <path
            class="cls-8"
            d="M905.61,604.52l2.04-4.77c.57.39,1.95,1.23,3.54,1.23,1.32,0,2.16-.75,2.16-1.8v-.06c0-1.23-1.26-2.04-4.02-2.04h-.57l-.3-3.36,3.78-3.66h-5.58v-5.16h12.12v4.74l-4.11,3.93c2.49.78,4.38,2.34,4.38,5.73v.09c0,4.38-2.91,6.9-7.23,6.9-2.31,0-4.56-.6-6.21-1.77Z"
          />
        </g>
        <polygon class="cls-1" points="855.73 624.59 855.73 644.41 855.73 664.24 959.21 664.24 959.21 624.59 855.73 624.59" />
      </g>
      <g id="TABLE-12">
        <polygon class="cls-1" points="849.73 670.24 829.9 670.24 810.08 670.24 810.08 773.72 849.73 773.72 849.73 670.24" />
        <path class="cls-8" d="M766.31,717.48l-2.52.69-.39-5.13,4.5-1.32h4.26v21.06h-5.85v-15.3Z" />
        <path
          class="cls-8"
          d="M773.81,727.95l5.58-6.75c1.08-1.32,1.62-1.92,1.62-2.85,0-.72-.48-1.56-1.59-1.56-1.05,0-2.13.78-3.6,1.95l-2.46-4.5c2.43-1.92,4.59-2.82,7.05-2.82,3.75,0,6.3,2.4,6.3,5.91v.12c0,2.07-.81,3.87-3.06,6.45l-3.21,3.66h6.27v5.22h-12.9v-4.83Z"
        />
      </g>
      <g id="TABLE-11">
        <rect class="cls-1" x="810.08" y="779.72" width="39.65" height="103.48" />
        <path class="cls-8" d="M766.31,826.96l-2.52.69-.39-5.13,4.5-1.32h4.26v21.06h-5.85v-15.3Z" />
        <path class="cls-8" d="M776.39,826.96l-2.52.69-.39-5.13,4.5-1.32h4.26v21.06h-5.85v-15.3Z" />
      </g>
      <g id="TABLE-10">
        <rect class="cls-1" x="810.08" y="889.2" width="39.65" height="103.48" />
        <path class="cls-8" d="M766.31,936.45l-2.52.69-.39-5.13,4.5-1.32h4.26v21.06h-5.85v-15.3Z" />
        <path
          class="cls-8"
          d="M773.84,941.79v-1.02c0-6.42,2.88-10.47,7.71-10.47s7.62,3.99,7.62,10.41v1.02c0,6.42-2.88,10.47-7.68,10.47s-7.65-3.99-7.65-10.41ZM783.35,941.85v-1.17c0-3.63-.63-5.1-1.86-5.1s-1.83,1.44-1.83,5.07v1.17c0,3.63.63,5.1,1.86,5.1s1.83-1.44,1.83-5.07Z"
        />
      </g>
      <g id="TABLE-9">
        <rect class="cls-12" x="810.08" y="1050.18" width="39.65" height="103.48" />
        <path
          class="cls-8"
          d="M780.8,1104.75c-.9.63-2.01,1.05-3.12,1.05-2.67,0-5.49-1.77-5.49-6.48v-.18c0-5.13,3.09-7.86,6.81-7.86,2.13,0,3.63.63,4.86,1.83,1.68,1.62,2.55,4.44,2.55,8.85v.06c0,8.04-3.63,11.07-8.49,11.07-1.8,0-3.15-.24-5.19-1.32l1.68-4.59c.84.48,1.8,1.02,3.18,1.02,2.19,0,3.09-1.62,3.21-3.45ZM780.74,1098.75v-.06c0-1.8-.48-2.85-1.56-2.85s-1.5,1.05-1.5,2.85v.06c0,1.8.48,2.85,1.56,2.85s1.5-1.05,1.5-2.85Z"
        />
      </g>
      <g id="TABLE-8">
        <rect class="cls-12" x="810.08" y="1159.67" width="39.65" height="103.48" />
        <path
          class="cls-8"
          d="M772.01,1216.54v-.21c0-2.28,1.11-4.02,2.88-5.04-1.41-.96-2.52-2.52-2.52-4.5v-.18c0-3.39,2.76-5.82,6.81-5.82s6.78,2.37,6.78,5.79v.15c0,1.98-1.11,3.54-2.55,4.53,1.74.99,2.91,2.67,2.91,5.04v.21c0,3.66-2.85,6.12-7.17,6.12s-7.14-2.4-7.14-6.09ZM780.77,1215.85v-.06c0-1.59-.63-2.43-1.59-2.43s-1.59.84-1.59,2.43v.06c0,1.59.6,2.4,1.59,2.4s1.59-.81,1.59-2.4ZM780.65,1207.33c0-1.5-.6-2.22-1.47-2.22s-1.47.72-1.47,2.22.57,2.25,1.47,2.25,1.47-.78,1.47-2.25Z"
        />
      </g>
      <g id="TABLE-7">
        <rect class="cls-12" x="810.08" y="1269.15" width="39.65" height="103.48" />
        <path class="cls-8" d="M778.73,1316.06h-6.54v-5.37h12.69v3.84l-5.82,17.16h-5.91l5.58-15.63Z" />
      </g>
      <g id="TABLE-6">
        <rect class="cls-12" x="562.03" y="683.79" width="39.65" height="103.48" />
        <path
          class="cls-8"
          d="M626.76,744.95c-1.68-1.62-2.55-4.44-2.55-8.73v-.06c0-8.04,3.63-11.19,8.49-11.19,1.8,0,3.15.27,4.89,1.17l-1.68,4.59c-.54-.33-1.5-.87-2.88-.87-2.22,0-3.12,1.56-3.21,3.6.9-.63,2.01-.96,3.12-.96,2.67,0,5.49,1.77,5.49,6.36v.18c0,5.01-3.09,7.74-6.81,7.74-2.13,0-3.63-.63-4.86-1.83ZM632.93,739.49v-.06c0-1.68-.48-2.73-1.56-2.73s-1.5,1.05-1.5,2.73v.06c0,1.68.48,2.73,1.56,2.73s1.5-1.05,1.5-2.73Z"
        />
      </g>
      <g id="TABLE-5">
        <rect class="cls-12" x="562.03" y="793.27" width="39.65" height="103.48" />
        <path
          class="cls-8"
          d="M624.99,854.4l1.86-4.89c1.41.84,2.61,1.38,3.75,1.38,1.44,0,2.25-.87,2.25-2.13v-.03c0-1.29-.81-2.04-2.07-2.04-.9,0-1.44.27-2.04.66l-2.97-1.5.54-11.04h11.43v5.19h-7.05l-.12,2.52c.57-.18,1.35-.3,2.04-.3,3.18,0,5.94,1.83,5.94,6.33v.15c0,4.86-3.18,7.5-7.44,7.5-2.25,0-4.32-.66-6.12-1.8Z"
        />
      </g>
      <g id="TABLE-4">
        <rect class="cls-12" x="562.03" y="902.76" width="39.65" height="103.48" />
        <path
          class="cls-8"
          d="M631.7,961.5h-7.53l-.33-3.51,7.62-13.83h4.89v13.32h2.07v4.02h-2.07v3.75h-4.65v-3.75ZM631.74,957.48v-6.39l-3.36,6.39h3.36Z"
        />
      </g>
      <g id="TABLE-3">
        <path
          class="cls-8"
          d="M625.14,1151.11l2.04-4.77c.57.39,1.95,1.23,3.54,1.23,1.32,0,2.16-.75,2.16-1.8v-.06c0-1.23-1.26-2.04-4.02-2.04h-.57l-.3-3.36,3.78-3.66h-5.58v-5.16h12.12v4.74l-4.11,3.93c2.49.78,4.38,2.34,4.38,5.73v.09c0,4.38-2.91,6.9-7.23,6.9-2.31,0-4.56-.6-6.21-1.77Z"
        />
        <rect class="cls-12" x="562.03" y="1089.95" width="39.65" height="103.48" />
      </g>
      <g id="TABLE-2">
        <path
          class="cls-8"
          d="M625.5,1257.14l5.58-6.75c1.08-1.32,1.62-1.92,1.62-2.85,0-.72-.48-1.56-1.59-1.56-1.05,0-2.13.78-3.6,1.95l-2.46-4.5c2.43-1.92,4.59-2.82,7.05-2.82,3.75,0,6.3,2.4,6.3,5.91v.12c0,2.07-.81,3.87-3.06,6.45l-3.21,3.66h6.27v5.22h-12.9v-4.83Z"
        />
        <rect class="cls-12" x="562.03" y="1199.43" width="39.65" height="103.48" />
      </g>
      <g id="TABLE-1">
        <path class="cls-8" d="M632.16,1356.16l-2.52.69-.39-5.13,4.5-1.32h4.26v21.06h-5.85v-15.3Z" />
        <rect class="cls-12" x="562.03" y="1308.92" width="39.65" height="103.48" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  data() {
    return {
      mapid: 145,
    };
  },
};
</script>
