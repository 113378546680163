// src/plugins/globalFunctions.js

export default {
    install(app) {
        app.config.globalProperties.$getNationalReplacements = (sku) => {
            return sku;

            // Retrieve the pricing details from localStorage
            const pricing = JSON.parse(window.localStorage.getItem("pricing"));

            // Check if the pricing source contains "national"
            const isNationalPricing = pricing && pricing.source && pricing.source.includes("national");

            // If it's not national pricing, return the original SKU
            if (!isNationalPricing) {
                return sku;
            }

            // SKU replacement mapping
            const replacements = {
                'FR-KAM-30-14': 'FR-KAM-30-14M',
                'FR-KCQ-30-14': 'FR-KCQ-30-14M',
                'FR-KDH-01-NV': 'FR-KDH-01-NVA',
                'FR-KMS-01-NV': 'FR-KMS-01-NVA',
                'FR-KMS-13-NV': 'FR-KMS-13-NVA',
                'FR-KRG-01-NV': 'FR-KRG-01-NVAZ',
                'FR-KRG-20-NV': 'FR-KRG-20-NVA',
                'FR-KRG-42-NV': 'FR-KRG-42-NVAZ',
                'ZA-KER-01-22': 'ZA-KER-01-22A',
                'ZA-KER-80-22': 'ZA-KER-80-22H',
                'ZA-MUL-80-23': 'ZA-MUL-80-23H'
            };

            // Return the replacement SKU if it exists, otherwise return the original SKU
            return replacements[sku] || sku;
        };
    }
};
