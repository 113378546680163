<template>
  <div class="modal-wrapper">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title">ADD TO LIST</h2>

          <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="toggleModalTag">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <i>Easily organize your selections into accessible and downloadable lists.</i>
          <div style="height: 15px"></div>
          <input type="text" v-model="newListName" placeholder="List Name" style="padding-top: 8px; padding-bottom: 11px" />&nbsp;
          <button class="btn btn-primary btn" @click="createNewList">Create List</button>
          <div style="height: 15px"></div>
          <div v-for="(index, item, key) in getLists" :key="index">
            <span v-if="item != 'Uncategorized'">
              <!-- if input is clicked, run updateListName() -->
              <input type="button" class="btn btn-secondary" name="thelist" :value="item" :id="`d` + key" @click="updateListName" /><br />
            </span>
          </div>

          <!-- <h3>Created your own List</h3> -->

          <small>To access your lists head to the My Lists section on the My Account page.</small>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  data() {
    return {
      pickedList: String,
      newListName: '',
    };
  },
  computed: {
    ...mapGetters(['getListSku', 'getLists']),
    showModal() {
      return this.$store.getters.showModalTag;
    },
  },
  watch: {
    pickedList: 'saveListInfo', // 1
  },
  methods: {
    // PUT OUR FUNCTIONS HERE FOR INTERACTION.
    ...mapActions(['toggleModalTag', 'closeModalTag', 'saveListInfoAct', 'saveNewListAct']),

    // create a function when the input button is clicked change pickedList to the value of the button
    updateListName(event) {
      this.pickedList = event.target.value;
    },

    saveListInfo() {
      alert('SKU added to ' + this.pickedList);
      this.saveListInfoAct(this.pickedList);
      this.closeModalTag();
    },
    createNewList() {
      this.saveNewListAct(this.newListName);
      alert('Created List: ' + this.newListName);
      this.closeModalTag();
    },
  },
};
</script>
<style scoped>
.btn-secondary {
  margin-bottom: 0.3rem;
  display: block;
  width: 100%;
}
</style>
