<template>
  <div class="tastings-list page">
    <div class="container content_area">
      <button class="btn btn-primary btn-top" @click="goBack">
        <img src="@/assets/arrow-left.svg" style="height: 25px" /> <small>BACK</small>
      </button>
      <div class="row">
        <div class="col-12">
          <h1>Let's get you in</h1>
          <div class="text-center">
            <div  style="border:1px solid #e8e8e8;padding:15px;">
                <h3>Have a Trade Account?</h3>
                <router-link to="/login" class="btn btn-primary btn-block"
          >Login</router-link>
          <router-link to="/accesspass" class="btn btn-outline-primary btn-block"
          >Email me a Quick Access Link</router-link>
            </div>
            </div>
            <div class="text-center">
            <div  style="border:1px solid #e8e8e8;padding:15px;margin-top:50px;">
                <h3>Need a Trade Account?</h3>
          <router-link to="/register" class="btn btn-secondary btn-block"
          >CREATE A TRADE ACCOUNT</router-link>
          <router-link to="/select-state" class="btn btn-outline-secondary btn-block"
          >I'd like to see the tasting</router-link>
        </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import StateList from '@/components/StateList.vue';

export default {
  name: 'Select a State',
  components: {
    StateList,
  },
  methods: {
  goBack() {
      if (true) {
        this.$router.go(-1);
      }
    }
  },
  created() {
    
    // ------------------------------------------------------
    // First route away from this page if we already have some situations
    // ------------------------------------------------------
    if (window.localStorage.getItem("user")) {
        // user is already logged in. no need to encourage login.
        this.$router.push({ name: 'Browse' });
        
    }
  },

  
};
</script>
