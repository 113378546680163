<template>
  <div class="modal-wrapper">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">-</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="toggleModal">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p style="text-transform: none">
            {{ showModalNoticeContent }}<br /><br />
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="toggleModal">OK</button>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  data() {
    return {};
  },
  computed: {
    showModalNotice() {
      return this.$store.getters.showModalNotice;
    },
    showModalNoticeContent() {
      return this.$store.getters.showModalNoticeContent;
    },
  },
  methods: {
    // PUT OUR FUNCTIONS HERE FOR INTERACTION.
    ...mapActions(['toggleModalNotice']),
  },
};
</script>
