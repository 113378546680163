<template>
  <div class="tastings-list page">
    <div class="container content_area">
      <button class="btn btn-primary btn-top" @click="goBack">
        <img src="@/assets/arrow-left.svg" style="height: 25px" /> <small>BACK</small>
      </button>
      <div class="row">
        <div class="col-12">
          <h1>Select your State</h1>
          <StateList />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import StateList from '@/components/StateList.vue';

export default {
  name: 'Select a State',
  components: {
    StateList,
  },
  methods: {
  goBack() {
      if (true) {
        this.$router.go(-1);
      }
    }
  },
  created() {
    
    // ------------------------------------------------------
    // First route away from this page if we already have some situations
    // ------------------------------------------------------


    // Check if we have  currentTastingDeets in local storage
    if (localStorage.getItem('currentTastingDeets')) {
      this.currentTasting = JSON.parse(localStorage.getItem('currentTastingDeets'));
      if (typeof this.currentTasting.states == 'object' && this.currentTasting.states.length <= 1) {
        // There is only one state in the current tasting, go to the browse page
        this.$router.push({ name: 'Browse' });
      } else {
        // There are multiple states in the current tasting
        // Check if we have user data in local storage
        if (window.localStorage.getItem("user")) {
            let user_data = JSON.parse(window.localStorage.getItem("user"))
            if (user_data.state) {
                // user has a state in local storage, go to the browse page
                this.$router.push({ name: 'Browse' });
            }
        } else {
            // no user data in local storage, allow them to select state.
            
        }
      }
    } else {
        // no tasting info in local storage, go back to welcome page
        this.$router.push({ name: 'Welcome' });
    }
     
  },

  
};
</script>
