<template>
  <div class="tutorial_page page">
    <div class="container content_area">
      <div class="row">
        <div class="col-12 text-center logo_top">
          <img class="img-fluid" src="@/assets/b&w-logo.png" />
          <div class="welc">WELCOME TO OUR DIGITAL TASTING BOOK</div>
        </div>
        <div class="col-12 main_content tutorial_content text-left">
        <div class="row">
            <div class="col-3"><img src="@/assets/n_map.svg" class="icon" /></div>
            <div class="col-9">
                <h3>MAP</h3>
                <p>Get a lay of the land! Tap the map button to orient yourself to the room and tables.</p>
            </div>
            <div class="col-3"><img src="@/assets/n_browse.svg" class="icon" /></div>
            <div class="col-9">
                <h3>TABLES</h3>
                <p>Tap any table to get a full list of what’s on offer.</p>
            </div>
            <div class="col-3"><img src="@/assets/item.svg" class="icon" /></div>
            <div class="col-9">
                <h3>ITEMS</h3>
                <p>Tap on any item to get more detailed information about that product.</p>
            </div>
            <div class="col-3"><img src="@/assets/c_addList.svg" class="icon" /></div>
            <div class="col-9">
                <h3>ADD TO LIST</h3>
                <p>Tap the list button to organize your favorite items from a tasting.</p>
            </div>
            <div class="col-3"><img src="@/assets/n_filter.svg" class="icon" /></div>
            <div class="col-9">
                <h3>SEARCH & FILTER</h3>
                <p>Use the Search & Filter function to focus your tasting experience, by producer, attribute, price, and more!</p>
            </div>



                <div class="col-12"><button class="btn btn-primary btn-fw" @click="hideIntro">Enter Tasting</button></div>


        </div>
        </div>
       
        
        
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'Tutorial Step 1',

  data() {
    return {
      headline: 'MAP',
      content: 'Get a lay of the land! Tap the map button to orient yourself to the room and tables.',
      
    };
  },
  methods: {
    
    hideIntro() {
      this.$store.commit('hideTutorial');
      this.$router.push({ name: 'Browse' });
    },
  },

  computed: {},
  created() {
  },
};
</script>
