<template>
  <div v-if="loggedIn">
    <div Ov-if="edit">
      <form>
        <textarea class="form-control" v-model="modNotes"></textarea>

        <button @click.stop="saveNote(sku)" class="btn btn-primary" type="submit">Save Note</button>
      </form>
    </div>
    <!--<div v-else @click="edit = true">
      <span>{{ modNotes }}</span>
    </div>-->
  </div>
  <div v-else>
    <button class="btn btn-primary" @click="toggleModal">Add Note</button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { useRoute } from 'vue-router';
export default {
  name: 'Notes',
  components: {},
  props: {
    mytype: {
      type: String,
      required: true,
      default: 'ITEM',
    },
  },
  data() {
    return {
      modNotes: '',
      edit: false,
      sku: String,
      route: useRoute(),
    };
  },
  computed: {
    ...mapGetters(['alldata', 'user', 'currentTasting', 'currentTastingDeets', 'getNoteSku']),
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
    itemType: function () {
      var lastChar = this.mytype.slice(-1);
      if (lastChar == 'S') {
        // check last character is string
        return this.mytype.slice(0, -1); // trim last character
      } else {
        return this.mytype;
      }
    },
  },
  methods: {
    // PUT OUR FUNCTIONS HERE FOR INTERACTION.
    ...mapActions(['toggleModalNote', 'closeModalNote', 'saveNote', 'saveAllData']),
    saveNote() {
      //  alert('SKU added to ' + this.pickedList);
      let sku_id = this.route.params.sku_id;
      let payload = { sku: sku_id, note: this.modNotes };
      // Dispatch the saveNote action to your Vuex store
      this.$store.dispatch('saveNote', payload);

      this.saveAllData();
      this.closeModalNote();

      //alert('--'+sku);

      //this.$store.commit('SET_SKU_NOTE', payload);
      //this.edit = false;
      //alert('Saved');
      //this.closeModalNote;
      //alert('ended;')
    },
    updateNote() {
      if (typeof this.$store.getters.alldata.ratings[this.sku] !== 'undefined') {
        //console.log("found notes " + this.sku);
        if (typeof this.$store.getters.alldata.ratings[this.sku].n !== 'undefined') {
          this.modNotes = this.$store.getters.alldata.ratings[this.sku].n;

          //console.log("yes");
        } else {
          //console.log("no");
          this.modNotes = '';
          this.edit = true;
        }
      } else {
        //console.log("no sku found " + this.sku);
        this.modNotes = '';
        this.edit = true;
      }
      if (this.modNotes.trim() == '') {
        this.edit = true;
      }
    },
  },
  watch: {
    //table_info: 'tableShow', // 1

    //setTimeout(this.tableShow(), 5000);
    // },
    sku: 'updateNote',
  },
  created() {
    this.updateNote();
    this.sku = this.route.params.sku_id;
    //alert(this.sku);
    //console.log(this.modNotes);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
