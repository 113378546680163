<template>
  <div class="modal-wrapper">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title">Edit List Name</h2>

          <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="toggleModalListname('','','')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
         
          <div style="height: 15px"></div>
          
          <form>
        
        <textarea class="form-control" v-model="modListname"></textarea>

        <button @click.stop="saveListname()" class="btn btn-primary" type="submit" >Save</button>
       
      </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      modListname:'',
      originalListName: '',
    };
  },
  computed: {
    ...mapGetters(['getListnameEditDeets']),
  },
  methods: {
    // PUT OUR FUNCTIONS HERE FOR INTERACTION.
  
    ...mapActions(['toggleModalListname', 'closeModalListname', 'saveListnameAct']),
    saveListname() {
      var token = this.getListnameEditDeets.token;
      var tasting_id = this.getListnameEditDeets.tasting_id;
      var listname = this.modListname
      const payload = {listname:listname, tasting_id:tasting_id, token:token, previous:this.originalListName};
      this.saveListnameAct(payload);
      //console.log(payload);
      this.closeModalListname();
      //return false;
    }
  },
  created() {
    this.modListname = this.getListnameEditDeets.listname;
    this.originalListName = this.getListnameEditDeets.listname;
  },
};
</script>
