<template>
  <div class="intro_page page">
    <TastingHeader v-bind:tastingInfo="currentTastingDeets" />
    <button class="btn btn-primary" @click="goBack"><img src="@/assets/arrow-left.svg" style="height: 25px" /> <small>BACK</small></button>
    <div class="container content_area">
      <div class="row">
        <div class="col-12">
          <!--<div class="text-center" v-if="currentTastingDeets.intro_img!='NA'">
            <img
              class="mb-4"
              v-bind:src="currentTastingDeets.intro_img"
              v-bind:alt="currentTastingDeets.introduction_title"
              width="120"
              height="120"
            />
          </div>-->
          <div style="padding-top: 50px">
            <p>{{ currentTastingDeets.introduction_title }}</p>
          </div>
          <div v-html="currentTastingDeets.introduction"></div>
          <div v-if="currentTastingDeets.tastingSize == 'large'">
            <img src="@/assets/sig.png" class="intro_sig" />
            <br />Michael and Harmon Skurnik
          </div>
          <div class="spacer"></div>
          <!-- <button class="btn btn-primary btn-fw" @click="hideIntro">Enter Tasting!</button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TastingHeader from '@/components/TastingHeader.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'Tastings',
  components: { TastingHeader },

  data() {
    return {
      chosenTasting: 0,
      tastingsObj: {},
    };
  },
  methods: {
    ...mapActions(['fetchAlldata', 'fetchUser']),
    hideIntro() {
      this.$store.commit('hideIntro');
      if (this.sawTutorial) {
        this.$router.push({ name: 'Browse' });
      } else {
        if (this.currentTastingDeets.tastingSize != 'large') {
          this.$router.push({ name: 'Tutorial 2' });
        } else {
          this.$router.push({ name: 'Tutorial 1' });
        }
      }
    },
    goBack() {
      if (true) {
        this.$router.back();
        if (window.history.length <= 1) {
          this.$router.push({ path: '/browse' });
          return false;
        } else {
          this.$router.go(-1);
          return false;
        }
      }
    },
  },

  computed: {
    ...mapGetters(['alldata', 'currentTasting', 'currentTastingDeets', 'sawTutorial']),
  },
  created() {
    //this.fetchAlldata();
    this.fetchUser();
    //console.log(this.$router);
  },
};
</script>
