<template>
  <div class="rating_short" v-if="!showButton">
    <div v-if="loggedIn">
      <img class="star_rating" :src="myStar1" @mouseover="hoverSku('s1', 1)" @mouseleave="leaveSku('s1', 1)" @click="rateSku('s1', 1)" />
      <img class="star_rating" :src="myStar2" @mouseover="hoverSku('s2', 2)" @mouseleave="leaveSku('s2', 2)" @click="rateSku('s2', 2)" />
      <img class="star_rating" :src="myStar3" @mouseover="hoverSku('s3', 3)" @mouseleave="leaveSku('s3', 3)" @click="rateSku('s3', 3)" />
      <img class="star_rating" :src="myStar4" @mouseover="hoverSku('s4', 4)" @mouseleave="leaveSku('s4', 4)" @click="rateSku('s4', 4)" />
      <img class="star_rating" :src="myStar5" @mouseover="hoverSku('s5', 5)" @mouseleave="leaveSku('s5', 5)" @click="rateSku('s5', 5)" />
      <span class="the_note">{{ getNote }}</span>
    </div>
    <div v-else>
      <!--<button class="btn btn-primary" @click="toggleModal">Rate this {{ itemType }}</button>-->
      <img class="star_rating" :src="myStar1" @mouseover="hoverSku('s1', 1)" @mouseleave="leaveSku('s1', 1)" @click="toggleModal" />
      <img class="star_rating" :src="myStar2" @mouseover="hoverSku('s2', 2)" @mouseleave="leaveSku('s2', 2)" @click="toggleModal" />
      <img class="star_rating" :src="myStar3" @mouseover="hoverSku('s3', 3)" @mouseleave="leaveSku('s3', 3)" @click="toggleModal" />
      <img class="star_rating" :src="myStar4" @mouseover="hoverSku('s4', 4)" @mouseleave="leaveSku('s4', 4)" @click="toggleModal" />
      <img class="star_rating" :src="myStar5" @mouseover="hoverSku('s5', 5)" @mouseleave="leaveSku('s5', 5)" @click="toggleModal" />
    </div>
  </div>
  <div class="rating" v-else>
    <div v-if="loggedIn">
      <img class="star_rating" :src="myStar1" @mouseover="hoverSku('s1', 1)" @mouseleave="leaveSku('s1', 1)" @click="rateSku('s1', 1)" />
      <img class="star_rating" :src="myStar2" @mouseover="hoverSku('s2', 2)" @mouseleave="leaveSku('s2', 2)" @click="rateSku('s2', 2)" />
      <img class="star_rating" :src="myStar3" @mouseover="hoverSku('s3', 3)" @mouseleave="leaveSku('s3', 3)" @click="rateSku('s3', 3)" />
      <img class="star_rating" :src="myStar4" @mouseover="hoverSku('s4', 4)" @mouseleave="leaveSku('s4', 4)" @click="rateSku('s4', 4)" />
      <img class="star_rating" :src="myStar5" @mouseover="hoverSku('s5', 5)" @mouseleave="leaveSku('s5', 5)" @click="rateSku('s5', 5)" />
    </div>
    <div v-else>
      <!--<button class="btn btn-primary" @click="toggleModal">Rate this {{ itemType }}</button>-->
      <img class="star_rating" :src="myStar1" @mouseover="hoverSku('s1', 1)" @mouseleave="leaveSku('s1', 1)" @click="toggleModal" />
      <img class="star_rating" :src="myStar2" @mouseover="hoverSku('s2', 2)" @mouseleave="leaveSku('s2', 2)" @click="toggleModal" />
      <img class="star_rating" :src="myStar3" @mouseover="hoverSku('s3', 3)" @mouseleave="leaveSku('s3', 3)" @click="toggleModal" />
      <img class="star_rating" :src="myStar4" @mouseover="hoverSku('s4', 4)" @mouseleave="leaveSku('s4', 4)" @click="toggleModal" />
      <img class="star_rating" :src="myStar5" @mouseover="hoverSku('s5', 5)" @mouseleave="leaveSku('s5', 5)" @click="toggleModal" />
    </div>
  </div>
  <div style="padding-top: 10px" v-if="showButton">
    <!--<img src="@/assets/info-circle.svg" style="height: 25px" />
        <div class="spacer"></div>-->

    <!--<small> &nbsp;ADD TO LIST</small>-->

    <button @click.stop="toggleModalTag(sku)" :class="buttonClass" v-if="loggedIn">
      {{ listButtonText }}
      <!--<img src="@/assets/list.svg" style="height: 25px; filter: brightness(150%)" />-->
    </button>
    <button @click.stop="toggleModal(sku)" :class="buttonClass" v-else>
      {{ listButtonText }}
      <!--<img src="@/assets/list.svg" style="height: 25px; filter: brightness(150%)" />-->
    </button>
    &nbsp;
    <button @click.stop="toggleModalNote(sku)" :class="buttonClass" v-if="loggedIn">
      {{ noteButtonText }}
      <!--<img src="@/assets/list.svg" style="height: 25px; filter: brightness(150%)" />-->
    </button>
    <button @click.stop="toggleModal(sku)" :class="buttonClass" v-else>
      {{ noteButtonText }}
      <!--<img src="@/assets/list.svg" style="height: 25px; filter: brightness(150%)" />-->
    </button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'Rating',
  components: {},
  props: {
    //msg: String,
    sku: String,
    showButton: { type: Boolean, default: true },
    buttonClass: { type: String, default: 'btn btn-primary' },
    type: {
      type: String,
      required: true,
      default: 'ITEM',
    },

    listButtonText: {
      type: String,
      default: 'Add to List',
    },
    noteButtonText: {
      type: String,
      default: 'Add/Edit Note',
    },
  },
  data() {
    return {
      s1: false,
      s2: false,
      s3: false,
      s4: false,
      s5: false,
      modRating: 0,
    };
  },

  computed: {
    ...mapGetters(['alldata', 'user', 'currentTasting', 'currentTastingDeets', 'currentLiveSku']),

    getNote() {
      // Safely access alldata using optional chaining.
      const ratings = this.$store.getters.alldata?.ratings;
      //console.log('ratings', ratings);
      // Check if ratings exist and if the specific rating for this.sku exists.
      if (ratings && this.sku in ratings) {
        // Now it's safe to access ratings[this.sku].n, but still use optional chaining for .n
        var thenote = ratings[this.sku]?.n || ' ';
        // Split the note into an array of words
        if (thenote.length >= 5) {
          const words = thenote.split(' ');

          // Check if the note has more than 4 words
          if (words.length > 4) {
            // Take the first 4 words and join them back into a string, then append '...'
            thenote = words.slice(0, 4).join(' ') + '...';
          }
        }
        return thenote;
      }

      // Return a default value or message if the rating doesn't exist.
      return ' ';
    },
    itemType: function () {
      var lastChar = this.type.slice(-1);
      if (lastChar == 'S') {
        // check last character is string
        return this.type.slice(0, -1); // trim last character
      } else {
        return this.type;
      }
    },
    myStar1: function () {
      return this.s1 ? require('../assets/' + 'star.svg') : require('../assets/' + 'star-solid.svg');
    },
    myStar2: function () {
      return this.s2 ? require('../assets/' + 'star.svg') : require('../assets/' + 'star-solid.svg');
    },
    myStar3: function () {
      return this.s3 ? require('../assets/' + 'star.svg') : require('../assets/' + 'star-solid.svg');
    },
    myStar4: function () {
      return this.s4 ? require('../assets/' + 'star.svg') : require('../assets/' + 'star-solid.svg');
    },
    myStar5: function () {
      return this.s5 ? require('../assets/' + 'star.svg') : require('../assets/' + 'star-solid.svg');
    },
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
  },
  methods: {
    // PUT OUR FUNCTIONS HERE FOR INTERACTION.
    ...mapActions(['toggleModal', 'toggleModalTag', 'toggleModalNote', 'saveAllData', 'saveSkuRating']),

    correctStars(newRating) {
      this.s1 = false;
      this.s2 = false;
      this.s3 = false;
      this.s4 = false;
      this.s5 = false;
      if (newRating == 5) {
        this.s1 = true;
        this.s2 = true;
        this.s3 = true;
        this.s4 = true;
        this.s5 = true;
      }
      if (newRating == 4) {
        this.s1 = true;
        this.s2 = true;
        this.s3 = true;
        this.s4 = true;
        this.s5 = false;
      }
      if (newRating == 3) {
        this.s1 = true;
        this.s2 = true;
        this.s3 = true;
        this.s4 = false;
        this.s5 = false;
      }
      if (newRating == 2) {
        this.s1 = true;
        this.s2 = true;
        this.s3 = false;
        this.s4 = false;
        this.s5 = false;
      }
      if (newRating == 1) {
        this.s1 = true;
        this.s2 = false;
        this.s3 = false;
        this.s4 = false;
        this.s5 = false;
      }
      if (newRating == 0) {
        this.s1 = false;
        this.s2 = false;
        this.s3 = false;
        this.s4 = false;
        this.s5 = false;
      }
    },
    hoverSku(num, star) {
      this.s1 = false;
      this.s2 = false;
      this.s3 = false;
      this.s4 = false;
      this.s5 = false;
      for (let cc = star; cc >= 1; cc--) {
        this['s' + cc] = true;
      }
    },
    leaveSku(num, star) {
      this.correctStars(this.modRating);
    },
    rateSku(num, setRating) {
      // If the clicked rating is the same as the current rating, set the rating to 0
      if (this.modRating === setRating) {
        this.modRating = 0; // Reset the rating to 0
      } else {
        this.modRating = setRating; // Else, update the rating to the clicked value
      }

      // Prepare the payload with the SKU and the (possibly updated) rating
      let payload = { sku: this.sku, rating: this.modRating };

      // Dispatch the action to save the rating
      this.$store.dispatch('saveSkuRating', payload).then(() => {
        this.saveAllData(); // Call this once the action has successfully completed
      });

      // Update the star display based on the new or reset rating
      this.correctStars(this.modRating);
    },
    findRating() {
      if (typeof this.$store.getters.alldata.ratings[this.sku] !== 'undefined') {
        //console.log('found rating ' + this.sku);
        if (typeof this.$store.getters.alldata.ratings[this.sku].r !== 'undefined') {
          this.modRating = this.$store.getters.alldata.ratings[this.sku].r;
          //console.log('yes');
        } else {
          //console.log('no');
          this.modRating = 0;
        }
      } else {
        //console.log ('no sku found ' + this.sku);
        this.modRating = 0;
      }
      this.correctStars(this.modRating);
    },
  },
  watch: {
    //table_info: 'tableShow', // 1

    //setTimeout(this.tableShow(), 5000);
    // },
    sku: 'findRating',
  },
  created() {
    this.findRating();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.star_rating {
  width: 25px;
}
.rating_short {
  padding-top: 4px;
}
.rating_short > div {
  display: flex; /* Make the container a flex container */
  align-items: center; /* Center-align the flex items vertically */
}

.rating_short span {
  padding-left: 30px; /* Keep the left padding to maintain spacing */
  display: inline-block;
  padding-top: 4px;
}
.the_note {
  text-transform: none;
}
</style>
