<template>
  <div id="top" class="favs page" style="padding-bottom: 80px">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-6 offset-lg-3">
          <h1>Notes & Ratings<br /><small>Ordered by rating</small></h1>
          <div class="dlbutton" style="padding-top: 15px; padding-bottom: 15px">
            <button @click="sendEmail" class="btn btn-outline-primary">Email Me My Data</button>
          </div>
          <!-- Add v-if directive to check for rating > 0 or notes exist -->
          <div class="table_block" v-for="rating in filteredRatings" :key="rating.sku">
            <div class="row">
              <div class="sku_block_row col-12">
                <div class="sku_name">
                  <router-link :to="{ name: 'Sku', params: { sku_id: rating.sku, table_no: getTableNo(rating.sku) } }">
                    <SkuName v-bind:sku="rating.sku" />
                  </router-link>
                </div>
                <small>{{ rating.sku }}</small>
              </div>
            </div>
            <div class="row">
              <div class="col-4"><strong>My Rating:</strong></div>
              <div class="col-8" style="text-transform: lowercase; color: #000">{{ getRating(rating.r) }}</div>
            </div>
            <div class="row" v-if="rating.n">
              <div class="col-4"><strong>Notes:</strong></div>
              <div class="col-8 standard_case" style="color: #000">{{ rating.n }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import SkuRating from '@/components/SkuRating.vue';
import SkuName from '@/components/SkuName.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'Favorites',
  components: {
    SkuRating,
    SkuName,
  },

  methods: {
    ...mapActions(['fetchAlldata', 'emailAllData']),
    getTableNo(sku) {
      //console.log('Searching for SKU:', sku); // Debugging log to see the SKU being searched
      for (const tableNo in this.alldata.tables) {
        const table = this.alldata.tables[tableNo];
        //console.log(`Checking table ${tableNo}`, table.skus); // See the SKUs in the current table

        // Check if the sku exists within this table's skus
        if (table.skus && sku in table.skus) {
          //console.log(`Found SKU: ${sku} in table ${tableNo}`); // Confirm when and where the SKU is found
          return table.table; // Assuming 'table' is the correct property that holds the table number
        }
      }
      //console.log(`SKU: ${sku} not found in any table`); // Indicate if the SKU wasn't found
      return null;
    },
    getRating(stars) {
      if (stars == 1) {
        return '★☆☆☆☆';
      } else if (stars == 2) {
        return '★★☆☆☆';
      } else if (stars == 3) {
        return '★★★☆☆';
      } else if (stars == 4) {
        return '★★★★☆';
      } else if (stars == 5) {
        return '★★★★★';
      } else {
        return 'No Rating';
      }
    },
    sendEmail() {
      // do the action 'emailAllData'
      this.emailAllData();
      alert('Your interactions from this tasting have been sent to the email address associated with your account.');
    },
  },
  computed: {
    ...mapGetters(['alldata']),
    filteredRatings() {
      // Check if alldata.ratings is an object
      if (this.alldata && typeof this.alldata.ratings === 'object') {
        return Object.entries(this.alldata.ratings)
          .map(([sku, rating]) => ({
            sku, // This keeps the SKU available in the template
            ...rating, // Spread the rating details
          }))
          .filter(({ r, n }) => r > 0 || n); // Filter based on rating or notes
      }
      return []; // Return an empty array if conditions are not met
    },
  },
  created() {
    this.fetchAlldata();
  },
};
</script>

<style scoped lang="scss">
.favs {
  .sku_name a {
    text-decoration: none;
    text-transform: none;
    font-weight: 600;
  }
  .table_block .sku_block_row {
    padding-bottom: 0px;
    padding-top: 0px;
  }
  .table_block {
    padding: 1rem;
    background-color: #e8e8e8;
  }
  /* alternate background color of .table_block */
  .table_block:nth-child(odd) {
    background-color: #fafafa;
  }
}
</style>
