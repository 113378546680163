<template>
  <div class="login page">
    <div class="container content_area">
      <div class="login-form">
        <h2 class="login-heading">Using Token</h2>
        <div class="alert alert-danger" role="alert" v-html="error" v-if="error"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'Use TOken',
  data() {
    return {
      password: '',
      error: false,
    };
  },
  props: ['token'],
  methods: {
    ...mapActions(['toggleModal', 'saveAllData']),
    login() {
      this.saveAllData();
      this.$store
        .dispatch('retrieveToken', {
          username: this.username,
          password: this.password,
        })
        .then((response) => {
          //console.log(this.$store.getters.user);
          if (this.$store.getters.user.state != false) {
            this.$router.push({ name: 'Select a Tasting' });
          } else {
            this.$router.push({ name: 'First Tasting' });
          }
        })
        .catch((err) => {
          this.error = err.data.error; // reject
        });
    },
  },
  created() {
    //console.log(this.token)
    this.$store
      .dispatch('retrievePassToken', {
        tk: this.token,
      })
      .then((response) => {
        //console.log(this.$store.getters.user);
        if (this.$store.getters.user.state != false) {
          this.$router.push({ name: 'Select a Tasting' });
        } else {
          this.$router.push({ name: 'First Tasting' });
        }
      })
      .catch((err) => {
        this.error = err.data.error; // reject
      });

    if (false) {
      this.saveAllData();
      this.$store.dispatch('clearAllData');
      localStorage.clear();
      this.$store.dispatch('destroyToken').then((response) => {
        this.$router.push({ name: 'Welcome' });
      });
    }
  },
};
</script>
