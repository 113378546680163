/* eslint-disable no-console */

import { register } from "register-service-worker";
import axios from "axios";
if (process.env.NODE_ENV === "production") {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        "App is being served from cache by a service worker.\n" +
          "For more details, visit https://goo.gl/AFskqB"
      );
    },
    registered() {
      console.log("Service worker has been registered.");
    },
    cached() {
      console.log("Content has been cached for offline use.");
    },
    updatefound() {
      // new content clear cache so user gets the new version
      caches.keys().then(cacheNames => {
        cacheNames.forEach(cacheName => {
          caches.delete(cacheName);
        });
      });
      
      console.log("New content is downloading.");
    },
    updated() {
      console.log("New content is available; please refresh.");
      //localStorage.clear();
      var thedata = JSON.parse(window.localStorage.getItem("alldata"));
  const token = window.localStorage.getItem("access_token");

  var ratings = thedata.ratings
  var lists = thedata.lists
  var user = window.localStorage.getItem("user");
      return new Promise((resolve, reject) => {
        axios
          .post("https://www.skurnik.com/api/tastings/saveme.php", {
            user: user,
        ratings:ratings,
        lists:lists,
        token:token,
          })

          .then((response) => {
            
              console.log(response);
              window.location.reload(true);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });



         
      
    },
    offline() {
      console.log(
        "No internet connection found. App is running in offline mode."
      );
      var lastState = 'offline';
    },
    online() {
      if (lastState == 'offline') {
        console.log('you are back online');
        lastState = 'online'
      } else {
        var lastState = 'online'
        console.log('has internet');
      }
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    },
  });
}
