<template>
  <div class="modal-wrapper">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title">ADD NOTE</h2>

          <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="toggleModalNote">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <i>Add / Edit Your Note</i>
          <div style="height: 15px"></div>

          <SkuNote v-bind:mytype="'WINE'" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import SkuNote from '@/components/SkuNote.vue';
export default {
  data() {
    return {
      skuNotes: String,
      newListName: '',
    };
  },
  components: {
    SkuNote,
  },
  computed: {
    ...mapGetters(['getNoteSku', 'getLists']),
    showModal() {
      return this.$store.getters.showModalNote;
    },
  },
  watch: {
    //pickedList: 'saveListInfo', // 1
  },
  methods: {
    // PUT OUR FUNCTIONS HERE FOR INTERACTION.
    ...mapActions(['toggleModalNote', 'closeModalNote', 'saveSkuNote']),
  },
  created() {
    //this.sku_id = this.$store.getters.getNoteSku;
  },
};
</script>
