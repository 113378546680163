import { createApp } from "vue";

import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import LoadScript from "vue-plugin-load-script";

// Import the global functions plugin
import globalFunctions from "./plugins/globalFunctions";

const app = createApp(App);

// Register the plugins
app.use(store);
app.use(router);
app.use(LoadScript);

// Use the global functions plugin
app.use(globalFunctions);

app.mount("#app");
