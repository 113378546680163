<template>
  <div class="dintro_page dpage" style="position:relative;height:100vh;">
    
    
    <div class=" cover_area"  :style='{ backgroundImage: `url(${currentTastingDeets.cover_img})` }'>
      <button class="btn btn-primary btn-top" @click="goBack">
        <img src="@/assets/arrow-left.svg" style="height: 25px" /> <small>BACK</small>
      </button>
          <div class="row"><div class="col-12" style="padding-right:15px;padding-left:15px;"><button class="btn btn-primary btn-fw " style="display: block;width:90vw;margin:0px auto;" @click="hideIntro">Continue</button></div></div>
         
          
       
    </div>
  </div>
</template>

<script>
import TastingHeader from '@/components/TastingHeader.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'Tastings',
  components: { TastingHeader },

  data() {
    return {
      chosenTasting: 0,
      tastingsObj: {},
    };
  },
  methods: {
    ...mapActions(['fetchAlldata', 'fetchUser']),
    hideIntro() {
      //this.$store.commit('hideIntro');
      // add 'saw_cover' to local storage
      window.localStorage.setItem("saw_cover", "true");
      window.localStorage.setItem("tasting_id",this.currentTastingDeets.tasting_id);
        if (window.localStorage.getItem("user")) {
            let user_data = JSON.parse(window.localStorage.getItem("user"))
            if (user_data.state) {
                // user has a state in local storage, go to the browse page
                this.$router.push({ name: 'Browse' });
            } else {
                this.$router.push({ name: 'Select a State' });
            }
        } else {
        this.$router.push({ name: 'Encourage Login' });
        }
    },
    goBack() {
      if (true) {
        this.$router.go(-1);
      }
    },
  },

  computed: {
    ...mapGetters(['alldata', 'currentTasting', 'currentTastingDeets','sawTutorial']),
  },
  created() {
    
    if (this.currentTastingDeets.cover_img=='') {
        this.$router.push({ name: 'Select a State' });
    }
    if (window.localStorage.getItem("saw_cover")) {
        this.$router.push({ name: 'Browse' });
    }
  },
};
</script>
