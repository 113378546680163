<template>
  {{ skuName }}
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "SkuName",
  components: {
    //FontAwesomeIcon
  },
  props: {
    //msg: String,
    sku: String,
  },
  data() {
    return {
      skuName: "",
    };
  },
  computed: {
    ...mapGetters(["alldata"]),
  },
  methods: {
    ...mapActions(["fetchAlldata"]),
  },
  created() {
    if (typeof this.$store.getters.alldata.skus[this.sku] !== "undefined") {
      //console.log('found sku ' + this.sku);
      if (
        typeof this.$store.getters.alldata.skus[this.sku].name !== "undefined"
      ) {
        this.skuName = this.$store.getters.alldata.skus[this.sku].name;
        //console.log('yes')
      } else {
        //console.log('no');
        this.skuName = "** Sku Missing Name";
      }
    } else {
      //console.log ('no sku found ' + this.sku);
      this.skuName = "** Sku Removed from Tasting";
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.star_rating {
  width: 25px;
}
</style>
