<template>
  <nav class="navbar navbar-expand-md navbar-dark bg-dark fixed-top" style="display: none">
    <div class="col-8">
      <button class="btn btn-primary" @click="goBack">
        <img src="@/assets/arrow-left.svg" style="height: 25px" /> <small>BACK</small>
      </button>
    </div>
    <div class="col-4 text-right d-block d-md-none">
      <!--<button
        class="hamburger hamburger--collapse"
        type="button"
        :class="[toggledNav ? 'is-active' : 'nope']"
        @click="toggledNav = !toggledNav"
      >
        <span class="hamburger-box" style="margin-top: 4px">
          <span class="hamburger-inner"></span>
        </span>
      </button>-->
      <router-link :to="{ name: 'NewSearch' }"
        ><img id="tourSearch" src="@/assets/sliders-h.svg" style="height: 23px; display: inline-block"
      /></router-link>
    </div>

    <div class="collapse navbar-collapse" id="navbarsExampleDefault" :class="{ show: toggledNav }">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <router-link class="nav-link" :to="{ name: 'Select a Tasting' }">Select a Tasting</router-link>
        </li>

        <li class="nav-item" v-if="!loggedIn">
          <router-link class="nav-link" :to="{ name: 'Login' }">Login</router-link>
        </li>
        <li class="nav-item" v-if="!loggedIn">
          <router-link class="nav-link" :to="{ name: 'Register' }">Register</router-link>
        </li>
        <li class="nav-item" v-if="loggedIn">
          <router-link class="nav-link" :to="{ name: 'Logout' }">Logout</router-link>
        </li>
        <!-- <li class="nav-item">
                <a class="nav-link disabled" href="#">Disabled</a>
            </li>-->

        <li class="nav-item" v-if="isAdmin">
          <a class="nav-link" href="http://tastings.rhineheart.madisonmott.com/admin-tastings.php" id="dropdown01">ADMIN</a>
          <!-- <div class="dropdown-menu" aria-labelledby="dropdown01">
            <a class="dropdown-item" href="/admin-tastings.php"
              >View Tastings</a
            >
            <a class="dropdown-item" href="#">Another action</a>
                <a class="dropdown-item" href="#">Something else here</a>
          </div> -->
        </li>

        <!-- <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle"
            href=""
            id="dropdown01"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            >My Account</a
          >
          <div class="dropdown-menu" aria-labelledby="dropdown01">
            <a class="dropdown-item" href="itinerary.php">Itinerary</a>
            <a class="dropdown-item logout" href="#">Logout</a>
          </div>
        </li> -->
      </ul>
      <!--<form class="form-inline my-2 my-lg-0">
          <input class="form-control mr-sm-2" type="text" placeholder="Search" aria-label="Search">
          <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
        </form>-->
    </div>
  </nav>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'NavHeader',
  components: {},
  data() {
    return {
      toggledNav: false,
    };
  },
  methods: {
    goBack() {
      if (true) {
        this.$router.go(-1);
      }
    },
  },
  computed: {
    ...mapGetters(['user', 'currentTasting', 'currentTastingDeets', 'loggedIn']),
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
    isAdmin() {
      if (this.$store.getters.user.role == 'admin') {
        return true;
      } else {
        return false;
      }
    },
    showModal() {
      return this.$store.getters.showModal;
    },
    showMapAndItin() {
      if (this.$store.getters.tastingSize == 'large') {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    $route() {
      this.toggledNav = false;
    },
  },
  created() {},
};
</script>
