<template>
  <div class="sku_block_row" :data-sku="sku">
    <div class="row">
      <div class="col-10">
        <div class="sku_name">
          <router-link :to="{ name: 'SkuDetailLive', params: { sku_id: sku } }">{{ searchResults.skus[sku].name }} </router-link>
          <br /><small>{{ sku }}</small>
        </div>
        <!--{{s}}-->
      </div>
      <div class="col-2 text-right" @click.stop="toggleModalTag" style="padding-top: 5px">
        <!--<img src="@/assets/info-circle.svg" style="height: 25px" />
        <div class="spacer"></div>-->
        <img src="@/assets/list.svg" style="height: 25px" @click.stop="toggleModalTag" />
        <!--<small> &nbsp;ADD TO LIST</small>-->
      </div>
    </div>
  </div>
</template>

<script>
import SkuRating from '@/components/SkuRating.vue';
import SkuAttributes from '@/components/SkuAttributes.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'SkuPreview',
  props: {
    sku: Object,
  },

  components: {
    SkuRating,
    SkuAttributes,
  },
  data() {
    return {
      producer_info: {},
      table_info: {},
    };
  },
  computed: {
    ...mapGetters(['searchResults']),
  },
  methods: {},
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
